import React from "react";

export default function MorningIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11" y="7" width="2" height="5" fill="#FFD100" />
      <rect
        x="18.0605"
        y="9.02515"
        width="2"
        height="5"
        transform="rotate(45 18.0605 9.02515)"
        fill="#FFD100"
      />
      <rect
        width="2"
        height="5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.93945 9.02515)"
        fill="#FFD100"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16C18 12.6863 15.3137 10 12 10C8.68629 10 6 12.6863 6 16H18Z"
        fill="#FFD100"
      />
      <rect x="4" y="16" width="16" height="2" fill="#FFD100" />
    </svg>
  );
}
