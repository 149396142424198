import React from "react";

export default function GlobeIcon({ className, width, height }) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM19.9982 8.66667H16.4172C16.1027 6.64083 15.5048 4.94667 14.7122 3.77417C17.101 4.56383 19.034 6.362 19.9982 8.66667ZM15.3333 12C15.3333 12.6985 15.2978 13.3647 15.2372 14H8.76283C8.70217 13.3647 8.66667 12.6985 8.66667 12C8.66667 11.3015 8.70217 10.6353 8.76283 10H15.237C15.2978 10.6353 15.3333 11.3015 15.3333 12ZM12 20.6667C10.7963 20.6667 9.49017 18.5878 8.9385 15.3333H15.0615C14.5098 18.5878 13.2037 20.6667 12 20.6667ZM8.9385 8.66667C9.49017 5.41217 10.7963 3.33333 12 3.33333C13.2037 3.33333 14.5098 5.41217 15.0615 8.66667H8.9385ZM9.28783 3.77417C8.49517 4.94683 7.89717 6.64083 7.58283 8.66667H4.00183C4.966 6.362 6.899 4.56383 9.28783 3.77417ZM3.5755 10H7.42233C7.366 10.6435 7.33333 11.3098 7.33333 12C7.33333 12.6902 7.366 13.3565 7.4225 14H3.5755C3.42283 13.3567 3.33333 12.6893 3.33333 12C3.33333 11.3107 3.42283 10.6433 3.5755 10ZM4.00183 15.3333H7.58267C7.89717 17.3592 8.495 19.0533 9.28767 20.2258C6.89883 19.4362 4.966 17.638 4.00183 15.3333ZM14.7122 20.2258C15.5048 19.0532 16.1028 17.3592 16.4172 15.3333H19.998C19.034 17.638 17.1012 19.4362 14.7122 20.2258ZM20.4245 14H16.5775C16.634 13.3565 16.6667 12.6902 16.6667 12C16.6667 11.3098 16.634 10.6435 16.5775 10H20.4243C20.5772 10.6433 20.6665 11.3107 20.6665 12C20.6665 12.6893 20.5772 13.3567 20.4245 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
