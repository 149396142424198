export const getScrollTarget = ({ direction, totalCount, visibleItems }) => {
  if (totalCount == null || visibleItems == null) {
    return 0;
  }

  if (visibleItems === 0) {
    return direction === "forward" ? 1 : totalCount - 1;
  }

  const visibleItemsLength = visibleItems.length;
  let itemOffset = Math.round(0.06 * visibleItemsLength);

  if (direction === "forward") {
    const maxVisibleItem = Math.max(...visibleItems);

    return Math.min(maxVisibleItem + itemOffset, totalCount - 1);
  }

  itemOffset = visibleItemsLength;

  const minVisibleItem = Math.min(...visibleItems),
    scrollToIndex =
      minVisibleItem - itemOffset <= 1 ? 0 : minVisibleItem - itemOffset;

  return Math.max(scrollToIndex, 0);
};
