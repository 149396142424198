import React from "react";

export default function Lotus({ className }) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        id="back_circle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 32C64 49.673 49.673 64 32 64C14.3275 64 0 49.673 0 32C0 14.327 14.3275 0 32 0C49.673 0 64 14.327 64 32Z"
        fill="#FF7557"
      />
      <path
        id="Fill 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.732 18.4951C38.7544 22.2519 35.7277 25.3164 31.9709 25.3393C28.212 25.3628 25.1485 22.3345 25.1256 18.5777C25.1032 14.8199 28.1304 11.7553 31.8883 11.7335C35.6451 11.71 38.7085 14.7372 38.732 18.4951Z"
        fill="white"
      />
      <path
        id="Fill 6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9622 45.6917C48.9622 45.6917 26.1334 31.9658 16.3979 47.097C16.3979 47.097 30.1382 59.449 48.9622 45.6917Z"
        fill="white"
      />
      <path
        id="Fill 8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4623 27.3157C35.4623 27.3157 32.6148 36.9887 26.4682 36.9887C21.0447 36.9887 15.8474 25.3274 14.3999 25.2373C14.3999 25.2373 17.5652 21.3946 21.6778 24.0613C29.2996 29.0026 32.433 28.5818 35.4623 27.3157Z"
        fill="white"
      />
      <path
        id="Fill 10"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1646 36.6934C43.812 36.8524 57.3443 23.8934 42.908 18.7185C41.4392 28.7638 34.3 35.071 33.1646 36.6934Z"
        fill="white"
      />
    </svg>
  );
}
