import React from "react";

export default function WoPrePostNatalIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9363 5.01205C13.9363 6.05655 13.0871 7.37918 12.0426 7.37918C10.9981 7.37918 10.1489 6.05655 10.1489 5.01205C10.1489 3.96756 10.8295 3 12.0426 3C13.2854 3 13.9363 3.96756 13.9363 5.01205ZM13.5133 10.1487C14.6584 10.2908 15.5461 11.3441 15.5461 12.6224C15.5461 13.7852 14.8123 14.7617 13.8181 15.0398L14.4394 17.4661C14.4572 17.5401 14.4661 17.62 14.4661 17.6969L14.4217 21.0612C14.4158 21.5464 14.0193 21.937 13.534 21.937H13.5222C13.031 21.9311 12.6404 21.5287 12.6463 21.0375L12.6937 17.7916L12.2824 16.1879L12.2942 17.759C12.2942 17.8005 12.2913 17.8419 12.2854 17.8833L11.8415 21.1677C11.7823 21.6145 11.4006 21.937 10.9627 21.937C10.9213 21.937 10.8828 21.934 10.8414 21.9281C10.3561 21.863 10.0159 21.4162 10.081 20.931L10.5159 17.7087L10.4923 14.448C10.2437 14.4243 10.004 14.2942 9.85018 14.0752L8.1636 11.6785C7.93281 11.353 7.9476 10.9151 8.19911 10.6074L10.4508 7.82599C10.5189 7.74314 10.5988 7.67509 10.6875 7.62183C10.7615 7.681 10.8384 7.73426 10.9154 7.78161C11.2734 8.00352 11.6521 8.11596 12.0427 8.11596C12.4244 8.11596 12.7943 8.00944 13.1464 7.7964C13.2588 7.97098 13.3328 8.17514 13.3535 8.39706L13.5133 10.1487ZM10.0011 11.2051L10.8592 12.4271L10.9272 11.1074L10.8414 10.1694L10.0011 11.2051Z"
        fill="currentColor"
      />
    </svg>
  );
}
