import React from "react";

export default function Insta({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={
        className
          ? className + " tbk-min-w-0 tbk-shrink-0"
          : "tbk-min-w-0 tbk-shrink-0"
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9166 3.84337C8.91302 3.93377 7.17645 4.42364 5.77421 5.81996C4.36707 7.22361 3.88333 8.96688 3.79271 10.9496C3.73637 12.1871 3.40694 21.5362 4.36218 23.988C5.00635 25.6421 6.2751 26.9138 7.94431 27.5601C8.7232 27.863 9.6123 28.0683 10.9166 28.1281C21.8222 28.6217 25.8648 28.3529 27.5695 23.988C27.872 23.2111 28.0802 22.323 28.1378 21.0219C28.6362 10.0883 28.0569 7.71837 26.1563 5.81996C24.6487 4.31613 22.8754 3.29242 10.9166 3.84337ZM11.0169 25.9293C9.82284 25.8755 9.17499 25.6764 8.74268 25.5091C7.65518 25.0864 6.83833 24.2728 6.41828 23.1916C5.69083 21.3286 5.93209 12.4804 5.99699 11.0486C6.06068 9.64619 6.3448 8.3647 7.33432 7.37519C8.55898 6.15355 10.1412 5.55496 20.9146 6.04117C22.3205 6.10469 23.6052 6.38811 24.5972 7.37519C25.8218 8.59682 26.4292 10.191 25.9345 20.9231C25.8806 22.1142 25.681 22.7604 25.5132 23.1916C24.4049 26.0319 21.8551 26.4265 11.0169 25.9293ZM21.0336 9.46404C21.0336 10.2728 21.6913 10.93 22.5032 10.93C23.3152 10.93 23.974 10.2728 23.974 9.46404C23.974 8.65533 23.3152 7.99809 22.5032 7.99809C21.6913 7.99809 21.0336 8.65533 21.0336 9.46404ZM9.67733 15.9852C9.67733 19.4498 12.4928 22.2583 15.966 22.2583C19.4391 22.2583 22.2546 19.4498 22.2546 15.9852C22.2546 12.5207 19.4391 9.71339 15.966 9.71339C12.4928 9.71339 9.67733 12.5207 9.67733 15.9852ZM11.8841 15.9852C11.8841 13.7374 13.7112 11.9135 15.9658 11.9135C18.2205 11.9135 20.0476 13.7374 20.0476 15.9852C20.0476 18.2342 18.2205 20.0581 15.9658 20.0581C13.7112 20.0581 11.8841 18.2342 11.8841 15.9852Z"
        fill="currentColor"
      />
    </svg>
  );
}
