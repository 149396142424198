import React from "react";

export default function ThumbsUp(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.5334 16.6666C38.5334 15.7826 38.1822 14.9347 37.5571 14.3096C36.9319 13.6845 36.0841 13.3333 35.2 13.3333H24.6667L26.2667 5.71663C26.3 5.54996 26.3167 5.36663 26.3167 5.18329C26.3167 4.49996 26.0334 3.86663 25.5834 3.41663L23.8167 1.66663L12.85 12.6333C12.2334 13.25 11.8667 14.0833 11.8667 15V31.6666C11.8667 32.5507 12.2179 33.3985 12.843 34.0236C13.4681 34.6488 14.316 35 15.2 35H30.2C31.5834 35 32.7667 34.1666 33.2667 32.9666L38.3 21.2166C38.45 20.8333 38.5334 20.4333 38.5334 20V16.6666ZM1.8667 35H8.53337V15H1.8667V35Z"
        fill="url(#paint0_linear_318_4335)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4335"
          x1="1.8667"
          y1="1.73878"
          x2="20.4058"
          y2="46.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
