/**
 * Slider Next Button component for navigating to the next slide.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/08/06
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

export const SliderNextButton = ({
  className,
  buttonClassName,
  iconClassName,
  style,
  onClick,
}) => (
  <button
    className={`${className ? `${className} ` : ""}${
      buttonClassName ? `${buttonClassName} ` : ""
    }`}
    style={style}
    onClick={onClick}
  >
    <ArrowRightIcon
      className={`${iconClassName ? `${iconClassName} ` : ""}tbk-inline`}
    />
  </button>
);

export default SliderNextButton;
