import React from "react";

export default function UserPicOIcon({ className, size }) {
  return (
    <svg
      width={size || 16}
      height={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.00008 10.6667C9.5655 10.6667 10.8334 9.39875 10.8334 7.83333C10.8334 6.26792 9.5655 5 8.00008 5C6.43466 5 5.16675 6.26792 5.16675 7.83333C5.16675 9.39875 6.43466 10.6667 8.00008 10.6667ZM8.00006 14C9.34452 14 10.5857 13.5578 11.5861 12.8109C10.3973 12.3264 8.95735 12.0833 8.0001 12.0833C7.04283 12.0833 5.60285 12.3264 4.41406 12.8109C5.41444 13.5578 6.65561 14 8.00006 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
