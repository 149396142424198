import React from "react";

export default function Cookie({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <g clipPath="url(#clip0_11896_12327)">
        <path
          d="M31.3621 14.7677C29.3038 14.0286 28.0378 11.9339 28.1969 10.2798C28.1969 10.0079 28.0339 9.75519 27.8279 9.5767C27.6219 9.39907 27.3024 9.3222 27.0351 9.3652C23.8985 9.82538 21.0263 7.5307 20.7418 4.30509C20.7162 4.01397 20.5568 3.75209 20.3097 3.59553C20.0634 3.43991 19.7567 3.40603 19.4848 3.50766C16.9424 4.43872 14.5401 2.75522 13.5815 0.561606C13.4021 0.15142 12.9571 -0.0747037 12.5186 0.0223584C5.30153 1.63279 0.000366211 8.60351 0.000366211 16C0.000366211 24.7881 7.21221 32 16.0003 32C24.7884 32 32.0002 24.7881 32.0002 16C31.9742 15.4417 31.9703 14.9856 31.3621 14.7677ZM6.57847 17.0081C6.06072 17.0081 5.64097 16.5884 5.64097 16.0706C5.64097 15.5527 6.06072 15.1331 6.57847 15.1331C7.09621 15.1331 7.51596 15.5527 7.51596 16.0706C7.51596 16.5884 7.09615 17.0081 6.57847 17.0081ZM10.3753 24.4375C8.82446 24.4375 7.56284 23.1759 7.56284 21.625C7.56284 20.0742 8.82446 18.8125 10.3753 18.8125C11.9262 18.8125 13.1878 20.0742 13.1878 21.625C13.1878 23.1759 11.9263 24.4375 10.3753 24.4375ZM11.3128 13.1876C10.2792 13.1876 9.43783 12.3462 9.43783 11.3126C9.43783 10.2789 10.2792 9.43757 11.3128 9.43757C12.3464 9.43757 13.1878 10.2789 13.1878 11.3126C13.1878 12.3462 12.3464 13.1876 11.3128 13.1876ZM18.8128 26.3125C17.7792 26.3125 16.9378 25.4711 16.9378 24.4375C16.9378 23.4039 17.7792 22.5625 18.8128 22.5625C19.8464 22.5625 20.6878 23.4039 20.6878 24.4375C20.6878 25.4711 19.8464 26.3125 18.8128 26.3125ZM19.7503 18.8125C18.1994 18.8125 16.9378 17.5509 16.9378 16C16.9378 14.4492 18.1994 13.1876 19.7503 13.1876C21.3012 13.1876 22.5628 14.4492 22.5628 16C22.5628 17.5509 21.3012 18.8125 19.7503 18.8125ZM25.3284 20.7581C24.8106 20.7581 24.3909 20.3383 24.3909 19.8206C24.3909 19.3027 24.8106 18.8831 25.3284 18.8831C25.8461 18.8831 26.2659 19.3027 26.2659 19.8206C26.2659 20.3383 25.8461 20.7581 25.3284 20.7581Z"
          fill="#FF7557"
        />
      </g>
      <defs>
        <clipPath id="clip0_11896_12327">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
