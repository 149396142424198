/* global google */

export const autocompletionRequestBuilder = (autocompletionRequest, input) => {
  const { bounds, location, ...rest } = autocompletionRequest;

  const res = {
    input,
    ...rest,
  };

  if (bounds) {
    res.bounds = new google.maps.LatLngBounds(...bounds);
  }

  if (location) {
    res.location = new google.maps.LatLng(location);
  }

  return res;
};

export default autocompletionRequestBuilder;
