import React from "react";

export default function GiftIcon({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill="#FF7557" />
      <path
        d="M9 16.5239V22.9322C9 23.5217 9.449 24.0002 10 24.0002H15V16.5239H9Z"
        fill="white"
      />
      <path
        d="M23 12.2516H19.836C20.063 12.0861 20.258 11.9216 20.394 11.7742C21.201 10.908 21.201 9.4982 20.394 8.63201C19.61 7.78826 18.244 7.7904 17.461 8.63201C17.027 9.09661 15.877 10.9881 16.036 12.2516H15.964C16.122 10.9881 14.972 9.09661 14.539 8.63201C13.755 7.7904 12.389 7.7904 11.606 8.63201C10.8 9.4982 10.8 10.908 11.605 11.7742C11.742 11.9216 11.937 12.0861 12.164 12.2516H9C8.449 12.2516 8 12.7312 8 13.3197V14.9217C8 15.2165 8.224 15.4557 8.5 15.4557H15V13.3197H17V15.4557H23.5C23.776 15.4557 24 15.2165 24 14.9217V13.3197C24 12.7312 23.552 12.2516 23 12.2516ZM14.941 12.2164C14.941 12.2164 14.899 12.2516 14.756 12.2516C14.065 12.2516 12.746 11.4848 12.315 11.0212C11.896 10.5705 11.896 9.8357 12.315 9.38498C12.518 9.1671 12.787 9.04748 13.073 9.04748C13.358 9.04748 13.627 9.1671 13.83 9.38498C14.504 10.1091 15.174 11.959 14.941 12.2164ZM17.243 12.2516C17.101 12.2516 17.059 12.2174 17.059 12.2164C16.826 11.959 17.496 10.1091 18.17 9.38498C18.573 8.95029 19.278 8.94815 19.685 9.38498C20.105 9.8357 20.105 10.5705 19.685 11.0212C19.254 11.4848 17.935 12.2516 17.243 12.2516Z"
        fill="white"
      />
      <path
        d="M16.998 16.5239V24.0002H21.998C22.55 24.0002 22.998 23.5217 22.998 22.9322V16.5239H16.998Z"
        fill="white"
      />
    </svg>
  );
}
