import React from "react";

export default function YouTube({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={
        className
          ? className + " tbk-min-w-0 tbk-shrink-0"
          : "tbk-min-w-0 tbk-shrink-0"
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0292 19.8191V11.5312C15.9715 12.9157 18.2504 14.2531 20.9456 15.695C18.7226 16.9278 15.9715 18.3109 13.0292 19.8191ZM29.4269 7.40857C28.9193 6.73987 28.0543 6.21994 27.1333 6.04761C24.4264 5.53357 7.53886 5.53211 4.83338 6.04761C4.09485 6.18606 3.43724 6.5204 2.87231 7.04034C0.491964 9.24967 1.23785 21.0976 1.8116 23.0168C2.05287 23.8475 2.36476 24.447 2.75756 24.8402C3.26364 25.3602 3.95656 25.7181 4.75247 25.8786C6.98129 26.3396 18.4638 26.5974 27.0863 25.9478C27.8807 25.8094 28.5839 25.4397 29.1385 24.8977C31.3394 22.6972 31.1894 10.1835 29.4269 7.40857Z"
        fill="currentColor"
      />
    </svg>
  );
}
