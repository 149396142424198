/* global google */
import React, { useState, useEffect, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

// Components
import Dropdown from "../Dropdown/Dropdown";
import GoogleMaps from "./GoogleMaps";
import GoogleMap from "./GoogleMap/GoogleMap";
import Marker from "./Marker/Marker";
import GymBadge from "../LocationSelector/GymBadge/GymBadge";

// Icons
import CloseBigFilledIcon from "../../icons/CloseBigFilledIcon";
import WeightIcon from "../../icons/WeightIcon";

// Hooks
import useGeocoder from "../../hooks/useGeocoder/useGeocoder";
import autocompletionRequestBuilder from "./helpers/autocompletionRequestBuilder";

// Utils
import getAddressComponent from "../../utils/getAddressComponent";
import { POPULAR_LOCATIONS } from "../../constants";

// Images
import gymPin from "./gym-pin.svg";
import gymPinActive from "./gym-pin-active.svg";

const Option = ({
  children,
  className,
  disabled,
  selected,
  readOnly,
  value,
  getOptionValue,
  onClick,
}) => {
  let optionClasses = {
    hover: "hover:tbk-bg-grey-light",
    focused: "tbk-bg-grey-light ",
    selected: "tbk-bg-grey-light ",
    disabled: "tbk-bg-grey-light ",
    readOnly:
      "tbk-bg-grey-light tbk-border-grey-light tbk-text-blue-grey tbk-cursor-disabled",
  };

  const classes = [optionClasses.hover];

  if (disabled) {
    classes.push(optionClasses.disabled);
  }

  if (readOnly) {
    classes.push(optionClasses.readOnly);
  }

  if (selected) {
    classes.push(optionClasses.selected);
  }

  const handleClick = useCallback(() => {
    onClick && onClick({ ...value, label: getOptionValue(value) });
  }, [onClick, value, getOptionValue]);

  return (
    <li
      role="option"
      tabIndex={!(disabled || readOnly) ? "-1" : undefined}
      aria-selected={!!selected}
      className={`tbk-mb-1 tbk-cursor-pointer tbk-select-none tbk-rounded-lg tbk-py-1 tbk-px-2 ${classes.join(
        " "
      )} ${className ? className : ""}`}
      onClick={handleClick}
    >
      {getOptionValue(value)}
    </li>
  );
};

const defaultProps = {
  debounce: 300,
  minLengthAutocomplete: 3,
};

const MAX_GYMS_PER_ROW = 3;
const MAX_GYMS_TO_SHOW = 4;

const ButtonElement = React.forwardRef(
  ({ open, value, showClear, onChange, onClear }, outerRef) => {
    const handleClearClick = useCallback(() => {}, []);

    return (
      <div className="tbk-relative">
        <input
          ref={outerRef}
          className={`tbk-input-field tbk-mb-0.5 tbk-text-ellipsis tbk-bg-basic-white hover:tbk-border-blue-grey${
            open ? " tbk-border-blue-grey" : ""
          }`}
          placeholder="Start entering address or place..."
          value={value}
          onChange={onChange}
        />
        <div
          className="clear-button tbk-absolute tbk-top-1 tbk-right-1 tbk-z-1 tbk-cursor-pointer"
          style={{
            display: showClear ? "block" : "none",
          }}
          onClick={onClear}
        >
          <CloseBigFilledIcon />
        </div>
      </div>
    );
  }
);

export default function LocationPicker({
  className,
  inner,
  selected,
  recentLocations,
  region = "GB",
  gyms,
  minLengthAutocomplete,
  mapHeight,
  debounce,
  onSelect,
  onGymSelect,
  onShowAllGymsClick,
  onGoogleMapsLoaded,
}) {
  const [mapsLoaded, setMapsLoaded] = useState(false);
  // Move to GooglePlacesAutocomplete
  const [placesService, setPlacesService] = useState(undefined);
  // -
  // This also??
  const [autocompleteService, setAutocompleteService] = useState(undefined);
  const [geocoder, setGeocoder] = useState(null);
  const [locationValue, setLocationValue] = useState(
    selected
      ? selected
      : {
          label: "",
          value: null,
        }
  );

  const [locations, setLocations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [addressInfo, setAddressInfo] = useState();
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isMarkerShown /*, setIsMarkerShown*/] = useState(true);
  const [latLng, setLatLng] = useState({
    lat: selected
      ? selected.lat
      : region /*process.env.REACT_APP_REGION*/ === "GB"
      ? 51.509865
      : 34.05,
    lng: selected
      ? selected.lng
      : region /*process.env.REACT_APP_REGION*/ === "GB"
      ? -0.118092
      : -118.238,
  });
  const [isLocationFetching, setIsLocationFetching] = useState(false);
  const [selectedGym, setSelectedGym] = useState(null);
  const [_mapHeight, setMapHeight] = useState();

  const mapRef = React.useRef();
  const mapContainerRef = React.useRef();
  const mapElementRef = React.useRef(null);
  const dropdownInputRef = React.useRef();
  const infoWindowRef = React.useRef();

  const [, lookupAddress] = useGeocoder(geocoder);

  const fetchSuggestions = useDebouncedCallback((value, cb) => {
    if (!autocompleteService) {
      return cb([]);
    }

    if (value.length < minLengthAutocomplete) {
      return cb([]);
    }

    const autocompletionReq = { location: latLng, radius: 10 };

    autocompleteService.getPlacePredictions(
      autocompletionRequestBuilder(autocompletionReq, value),
      (suggestions) => {
        const newLocations = (suggestions || []).map((suggestion) => ({
          label: suggestion.description,
          value: suggestion,
        }));

        cb(newLocations);
        setLocations(newLocations);
      }
    );
  }, debounce);

  const handleGoogleMapsLoaded = useCallback((map) => {
    mapRef.current = map;

    setMapsLoaded(true);
    setGeocoder(new window.google.maps.Geocoder());
    setAutocompleteService(new window.google.maps.places.AutocompleteService());
    setPlacesService(new google.maps.places.PlacesService(map));

    onGoogleMapsLoaded && onGoogleMapsLoaded(map);

    // Create an info window to share between markers.
    infoWindowRef.current = new google.maps.InfoWindow();
  }, []);

  const handleDragEnd = useCallback(
    (e) => {
      if (!e || !e.latLng) {
        return;
      }

      setLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() });

      lookupAddress(e.latLng).then((res) => {
        if (res) {
          setAddressInfo(res);
        }
      });
    },
    [lookupAddress]
  );

  useEffect(() => {
    if (addressInfo) {
      let postalCode = getAddressComponent(
        addressInfo.address_components,
        "postal_code"
      );
      let newLocationValue = {
        label: addressInfo.formatted_address,
        lat: addressInfo.geometry.location.lat(),
        lng: addressInfo.geometry.location.lng(),
        postalCode: postalCode && postalCode.short_name,
        value: addressInfo,
      };
      setLocationValue(newLocationValue);
      setLocations([newLocationValue]); // For at least one location is present
      onSelect && onSelect(newLocationValue);
    } else {
      return;
    }

    if (autocompleteService) {
      autocompleteService.getPlacePredictions(
        autocompletionRequestBuilder(
          { location: addressInfo.geometry.location, radius: 10 }, //autocompletionReq,
          addressInfo
        ),
        (suggestions) => {
          if (suggestions && suggestions.length) {
            setLocations(
              suggestions.map((suggestion) => ({
                label: suggestion.description,
                value: suggestion,
              }))
            );

            setSuggestions(suggestions); // Do we need that?
          }
        }
      );
    }
  }, [addressInfo, autocompleteService, onSelect]);

  useEffect(() => {
    if (
      selected &&
      selected.placeId &&
      (!selectedGym || selectedGym.placeId !== selected.placeId)
    ) {
      setSelectedGym({
        ...selected,
        value: selected.value.place,
      });
    } else if (selected && !selected.placeId) {
      setSelectedGym(null);
    }
  }, [selected, selectedGym]);

  const handleSelect = useCallback(
    (value) => {
      // Google Places format
      if (value && value.value && value.value.description) {
        // From Autocomplete service if description is present
        if (locationValue.label !== value.label || !value.value.geometry) {
          // Locations that came from Autocomplete service doesn't have geometry
          if (placesService) {
            setIsLocationFetching(true);

            placesService.getDetails(
              { placeId: value.value.place_id },
              (placeResult, status) => {
                setIsLocationFetching(false);

                if (status === "OK") {
                  mapRef.current &&
                    mapRef.current.setCenter(placeResult.geometry.location);

                  setAddressInfo(undefined); // prevent returning to the previously found place

                  setLatLng({
                    lat: placeResult.geometry.location.lat(),
                    lng: placeResult.geometry.location.lng(),
                  });

                  let postalCode = getAddressComponent(
                    placeResult.address_components,
                    "postal_code"
                  );

                  // TODO use this format?
                  const newLocationValue = {
                    label: placeResult.formatted_address,
                    value: placeResult,
                    lat: placeResult.geometry.location.lat(),
                    lng: placeResult.geometry.location.lng(),
                    postalCode: postalCode && postalCode.short_name,
                  };

                  setLocationValue(newLocationValue);
                  onSelect && onSelect(newLocationValue);
                } else {
                  console.log(
                    "getDetails was not successful for the following reason: ",
                    status
                  );
                }
              }
            );
          }
        } else if (value.value.geometry) {
          mapRef.current &&
            mapRef.current.setCenter(value.value.geometry.location);

          setLatLng({
            lat: value.value.geometry.location.lat(),
            lng: value.value.geometry.location.lng(),
          });
        }
      } else {
        if (locationValue.label !== value.label) {
          setLocationValue(value);
          onSelect && onSelect(value);
        }

        if (value.value.geometry) {
          mapRef.current &&
            mapRef.current.setCenter(value.value.geometry.location);

          setLatLng({
            lat: value.value.geometry.location.lat,
            lng: value.value.geometry.location.lng,
          });
        }
      }
    },
    [locationValue, onSelect, placesService]
  );

  const handleLocationChange = useCallback(
    (e) => {
      let value = e.target.value;

      setLocationValue({ label: value, value });

      if (value !== "") {
        setIsLocationFetching(true);

        fetchSuggestions(value, (resultArr) => {
          setIsLocationFetching(false);

          if (resultArr.length > 0) {
            setIsLocationDropdownOpen(true);
            setSuggestions(resultArr);

            setAddressInfo(undefined); // prevent returning to the previously found place
          }
        });
      } else {
        setSuggestions([]);
      }

      if (selected && selected.label !== value) {
        onSelect(null);
      }
    },
    [recentLocations, selected]
  );

  const handleGymSelect = useCallback(
    (gym) => {
      setSelectedGym(gym);

      onGymSelect && onGymSelect(gym);
    },
    [onGymSelect]
  );

  useEffect(() => {
    setMapHeight(mapContainerRef.current.clientHeight);
  }, []);

  const clearLocations = useCallback(() => {
    setLocationValue({ label: "", value: null });
    setSelectedGym(null);

    infoWindowRef.current.close();

    onSelect && onSelect(null);

    setSuggestions([]);
    setLocations([]);
  }, [recentLocations, onSelect]);

  const handleClearClick = useCallback(
    (e) => {
      clearLocations();
    },
    [clearLocations]
  );

  const handleClose = useCallback(() => {
    setIsLocationDropdownOpen(false);
  }, []);

  const _gyms = gyms.slice();

  if (selectedGym) {
    gyms.forEach((gym, index) => {
      if (
        _gyms.length > MAX_GYMS_PER_ROW &&
        gym.value === selectedGym.value &&
        !_gyms
          .slice(0, MAX_GYMS_TO_SHOW)
          .find((v) => v.value === selectedGym.value)
      ) {
        _gyms.splice(index, 1);
        _gyms.unshift(gym);
      }
    });
  }

  return (
    <div
      className={`location-selector tbk-flex tbk-h-full tbk-min-h-0 tbk-grow tbk-flex-col tbk-bg-basic-white${
        className ? ` ${className}` : ""
      }`}
    >
      <GoogleMaps mapElement={mapElementRef} onLoaded={handleGoogleMapsLoaded}>
        <div className="tbk-flex tbk-h-full tbk-min-h-0 tbk-grow tbk-flex-col">
          <Dropdown
            listboxId="locations"
            className="tbk-mb-1"
            buttonElement={ButtonElement}
            buttonElementRef={dropdownInputRef}
            buttonElementProps={{
              value:
                selected == null
                  ? locationValue && locationValue.label
                  : selected &&
                    (selected.placeId
                      ? `${selected.value.name} (${selected.label})`
                      : selected.label),

              showClear: !(
                selected === null &&
                (!locationValue || !locationValue.label)
              ),
              onChange: handleLocationChange,
              onClear: handleClearClick,
            }}
            rounded
            showArrow
            open={isLocationDropdownOpen}
            onClose={handleClose}
          >
            <div
              id="locations"
              className="tbk-rounded-lg tbk-bg-basic-white tbk-p-2 tbk-shadow-trube"
            >
              {(suggestions && suggestions.length) ||
              (locations && locations.length) ? null : (
                <div className="tbk-text-secondary tbk-mb-1 tbk-text-blue">
                  {recentLocations && recentLocations.length
                    ? "Recent locations:"
                    : "Popular locations:"}
                </div>
              )}
              <ul tabIndex="-1" role="listbox">
                {(locations && locations.length
                  ? locations
                  : recentLocations && recentLocations.length
                  ? recentLocations
                  : POPULAR_LOCATIONS(region)
                ).map((location, index) => (
                  <Option
                    key={"item_" + index}
                    value={location}
                    getOptionValue={(val) => val.label}
                    selected={selected && selected.label === location.label}
                    onClick={handleSelect}
                  />
                ))}
              </ul>
            </div>
          </Dropdown>

          {/*!isLocationFetching && */ inner}

          {_gyms && _gyms.length ? (
            <div className={selected ? "tbk-mb-1" : "tbk-mt-2 tbk-mb-1"}>
              <div className="tbk-flex">
                <WeightIcon className="tbk-mr-1 tbk-text-primary" />
                <div className="tbk-text-h3-subtitle tbk-mb-1 tbk-text-primary">
                  Training in a Gym or a Studio:
                </div>
              </div>
              <div className="tbk-flex tbk-flex-wrap">
                {_gyms.slice(0, MAX_GYMS_TO_SHOW).map((gym) => (
                  <GymBadge
                    key={gym.value}
                    className="tbk-mr-1 tbk-mb-1"
                    element={"button"}
                    active={selectedGym && selectedGym.value === gym.value}
                    gym={gym}
                    onClick={handleGymSelect}
                  />
                ))}
                {_gyms.length > MAX_GYMS_TO_SHOW ? (
                  <button
                    className="tbk-mb-1 tbk-flex tbk-items-center tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light tbk-px-1 tbk-py-1 tbk-text-primary hover:tbk-bg-grey-main hover:tbk-text-basic-white"
                    onClick={onShowAllGymsClick}
                  >
                    <svg
                      width="20"
                      height="4"
                      viewBox="0 0 20 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="tbk-mr-0.5"
                    >
                      <circle cx="2" cy="2" r="2" fill="currentColor" />
                      <circle cx="10" cy="2" r="2" fill="currentColor" />
                      <circle cx="18" cy="2" r="2" fill="currentColor" />
                    </svg>

                    <span className="tbk-text-small-bold tbk-uppercase">
                      Show all
                    </span>
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}

          <div
            ref={mapContainerRef}
            className="tbk-grow"
            // style={{ height: `${_mapHeight}px` }}
          >
            <div
              ref={mapElementRef}
              className="tbk-rounded-lg"
              style={{ height: "100%" }}
            >
              {mapsLoaded ? (
                <GoogleMap
                  defaultZoom={10}
                  defaultCenter={{ lat: latLng.lat, lng: latLng.lng }}
                  defaultOptions={{
                    disableDefaultUI: true,
                    // styles: [{
                    //   featureType: 'all',
                    //   elementType: 'labels',
                    //   stylers: [{
                    //     visibility: 'off'
                    //   }]
                    // }]
                  }}
                >
                  {isMarkerShown && !selectedGym && (
                    <Marker
                      draggable
                      onDragEnd={handleDragEnd}
                      position={{ lat: latLng.lat, lng: latLng.lng }}
                    />
                  )}
                  {gyms.map((gym) => (
                    <Marker
                      // ref={}
                      position={{ lat: gym.latitude, lng: gym.longitude }}
                      icon={
                        selectedGym && selectedGym.value === gym.value
                          ? gymPinActive
                          : gymPin
                      }
                      title={gym.name}
                      onClick={function (e) {
                        infoWindowRef.current.close();

                        const contentString = `
                          <div id="content">
                          <h1 id="firstHeading" class="firstHeading">${gym.name}</h1>
                          <div id="bodyContent">
                          <p>${gym.address}</p>
                          </div>`;

                        handleGymSelect(gym);

                        infoWindowRef.current.setContent(contentString);
                        infoWindowRef.current.open(mapRef.current, this);
                      }}
                    />
                  ))}
                </GoogleMap>
              ) : null}
            </div>
          </div>
        </div>
      </GoogleMaps>
    </div>
  );
}

LocationPicker.displayName = "LocationPicker";
LocationPicker.defaultProps = defaultProps;
