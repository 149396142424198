import moment from "moment";
import format from "./format";

export default function getModifierKeyFull(date, dateFormat) {
  const momentDate = moment.isMoment(date) ? date : format(date, dateFormat);

  return momentDate
    ? `${momentDate.year()}-${String(momentDate.month() + 1).padStart(
        2,
        "0"
      )}-${String(momentDate.date()).padStart(2, "0")}`
    : "";
}
