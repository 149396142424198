export const getModifierSettings = (modifiers) => {
  const afterHoveredStart =
      modifiers == null ? undefined : modifiers.has("after-hovered-start"),
    beforeHoveredEnd =
      modifiers == null ? undefined : modifiers.has("before-hovered-end"),
    blocked = modifiers == null ? undefined : modifiers.has("blocked"),
    blockedCalendar =
      modifiers == null ? undefined : modifiers.has("blocked-calendar"),
    blockedOutOfRange =
      modifiers == null ? undefined : modifiers.has("blocked-out-of-range"),
    firstDayOfMonth =
      modifiers == null ? undefined : modifiers.has("first-day-of-month"),
    firstDayOfWeek =
      modifiers == null ? undefined : modifiers.has("first-day-of-week"),
    highlightedCalendar =
      modifiers == null ? undefined : modifiers.has("highlighted-calendar"),
    hovered = modifiers == null ? undefined : modifiers.has("hovered"),
    hoveredOffset =
      modifiers == null ? undefined : modifiers.has("hovered-offset"),
    hoveredSpan = modifiers == null ? undefined : modifiers.has("hovered-span"),
    hoveredStartFirstPossibleEnd =
      modifiers == null
        ? undefined
        : modifiers.has("hovered-start-first-possible-end"),
    lastDayOfMonth =
      modifiers == null ? undefined : modifiers.has("last-day-of-month"),
    lastDayOfWeek =
      modifiers == null ? undefined : modifiers.has("last-day-of-week"),
    lastInRange =
      modifiers == null ? undefined : modifiers.has("last-in-range"),
    noSelectedStartBeforeSelectedEnd =
      modifiers == null
        ? undefined
        : modifiers.has("no-selected-start-before-selected-end"),
    selected = isSelected(modifiers),
    selectedEnd = modifiers == null ? undefined : modifiers.has("selected-end"),
    selectedEndInHoveredSpan =
      modifiers == null
        ? undefined
        : modifiers.has("selected-end-in-hovered-span"),
    selectedEndNoSelectedStart =
      modifiers == null
        ? undefined
        : modifiers.has("selected-end-no-selected-start"),
    inSpan = modifiers == null ? undefined : modifiers.has("selected-span"),
    selectedStart =
      modifiers == null ? undefined : modifiers.has("selected-start"),
    selectedStartInHoveredSpan =
      modifiers == null
        ? undefined
        : modifiers.has("selected-start-in-hovered-span"),
    selectedStartNoSelectedEnd =
      modifiers == null
        ? undefined
        : modifiers.has("selected-start-no-selected-end"),
    today = modifiers == null ? undefined : modifiers.has("today");

  return {
    afterHoveredStart,
    beforeHoveredEnd,
    blocked,
    blockedCalendar,
    blockedOutOfRange,
    firstDayOfMonth,
    firstDayOfWeek,
    highlightedCalendar,
    hovered,
    hoveredOffset,
    hoveredSpan,
    hoveredStartFirstPossibleEnd,
    inSpan: hoveredSpan || inSpan,
    lastDayOfMonth,
    lastDayOfWeek,
    lastInRange,
    noSelectedStartBeforeSelectedEnd,
    selected,
    selectedEnd,
    selectedEndInHoveredSpan,
    selectedEndNoSelectedStart,
    selectedSpan: inSpan,
    selectedStart,
    selectedStartInHoveredSpan,
    selectedStartNoSelectedEnd,
    today,
  };
};

function isSelected(day) {
  return (
    (day == null ? undefined : day.has("selected")) ||
    (day == null ? undefined : day.has("selected-start")) ||
    (day == null ? undefined : day.has("selected-end"))
  );
}
