import React from "react";

export default function Cup(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.7997 20.9375C40.4272 18.7175 40.7972 3.92 40.7997 3.7675C40.8047 3.4325 40.6747 3.11 40.4397 2.8725C40.2047 2.635 39.8847 2.5 39.5497 2.5H30.7997V1.25C30.7997 0.56 30.2397 0 29.5497 0H12.0499C11.3599 0 10.7999 0.56 10.7999 1.25V2.5H2.04993C1.71494 2.5 1.39494 2.635 1.15994 2.8725C0.924943 3.11 0.794944 3.4325 0.799944 3.7675C0.802444 3.92 1.16994 18.72 12.7999 20.9375C13.8874 22.3975 15.3423 23.56 17.0498 24.255V28.6725C17.0498 31.245 15.5048 33.5175 13.0999 34.465L11.5749 35.09C11.1074 35.285 10.7999 35.7425 10.7999 36.25V38.75C10.7999 39.44 11.3599 40 12.0499 40H29.5497C30.2397 40 30.7997 39.44 30.7997 38.75V36.25C30.7997 35.7425 30.4922 35.285 30.0222 35.0925L28.4823 34.4625C26.0923 33.5175 24.5498 31.2475 24.5498 28.675V24.2575C26.2598 23.56 27.7148 22.3975 28.7997 20.9375ZM30.7997 15V5H38.2072C37.8872 8.0025 36.4622 15.4625 30.3147 17.92C30.5997 16.99 30.7997 16.0225 30.7997 15ZM3.38243 5H10.7999V15C10.7999 16.025 10.9999 16.995 11.2874 17.9275C5.05741 15.475 3.67992 8.02 3.38243 5Z"
        fill="url(#paint0_linear_318_4967)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4967"
          x1="0.799805"
          y1="0.0865855"
          x2="24.5475"
          y2="52.4754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
