"use client";

import React, { useCallback, useState } from "react";
import ExclamationMarkOIcon from "../../../icons/ExclamationMarkOIcon";

const UserContactsBox = ({
  userFirstName,
  userLastName,
  userPhoneNumber,
  onFirstNameChange,
  onLastNameChange,
}) => {
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [phone, setPhone] = useState(userPhoneNumber);
  const [hasFirstNameError, setHasFirstNameError] = useState(false);
  const [hasLastNameError, setHasLastNameError] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleFirstNameChange = useCallback(
    (evt) => {
      const value = evt.target.value;

      setFirstName(value);

      if (isValidFirstName(value)) {
        setHasFirstNameError(false);
      } else {
        setHasFirstNameError(true);
      }

      onFirstNameChange && onFirstNameChange(value);
    },
    [onFirstNameChange]
  );

  const handleLastNameChange = useCallback(
    (evt) => {
      const value = evt.target.value;

      setLastName(value);

      if (isValidLastName(value)) {
        setHasLastNameError(false);
      } else {
        setHasLastNameError(true);
      }

      onLastNameChange && onLastNameChange(value);
    },
    [onLastNameChange]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log("Form submitted:", { firstName, lastName, phone });
  };

  return (
    <div className="">
      <div className="tbk-mb-4 tbk-grid tbk-grid-cols-2 tbk-gap-2">
        <div>
          <label
            htmlFor="firstName"
            className="tbk-text-small tbk-mb-0.5 tbk-text-primary"
          >
            First name:
          </label>
          <input
            type="text"
            id="firstName"
            className={`tbk-input-field tbk-mb-0 tbk-w-full tbk-pr-2 tbk-text-primary${
              hasFirstNameError ? " tbk-border-coral" : ""
            }`}
            name="firstName"
            placeholder="Your First Name"
            value={firstName}
            onChange={handleFirstNameChange}
          />
        </div>

        <div>
          <label
            htmlFor="lastName"
            className="tbk-text-small tbk-mb-0.5 tbk-text-primary"
          >
            Last name:
          </label>
          <input
            type="text"
            id="lastName"
            className={`tbk-input-field tbk-mb-0 tbk-w-full tbk-pr-2 tbk-text-primary${
              hasLastNameError ? " tbk-border-coral" : ""
            }`}
            name="lastName"
            placeholder="Your Last Name"
            value={lastName}
            onChange={handleLastNameChange}
          />
        </div>

        <div className="tbk-col-span-2">
          <label
            htmlFor="phone"
            className="tbk-text-small tbk-mb-0.5 tbk-text-primary"
          >
            Your Phone:
          </label>
          <input
            type="tel"
            id="phone"
            className={`tbk-input-field tbk-mb-2 tbk-w-full tbk-text-ellipsis tbk-bg-grey-light tbk-text-primary`}
            name="phone"
            placeholder="Your Phone"
            value={phone}
            disabled
            // onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>

      <div className={`tbk-text-small tbk-flex tbk-text-blue-grey`}>
        <ExclamationMarkOIcon className="tbk-mr-1" />
        <div>
          <p className="tbk-mb-0.5">
            We use the contact information to provide booking details.
          </p>
          <p>Please, make sure it's relevant.</p>
        </div>
      </div>
    </div>
  );
};

function isValidFirstName(firstName) {
  // Check if firstName is empty or contains "user"
  return firstName && !firstName.includes("user") && firstName.length > 1;
}

function isValidLastName(lastName) {
  // Check if lastName is empty or does not contain only digits
  return lastName && !/^\d+$/.test(lastName) && lastName.length > 1;
}

export default UserContactsBox;
