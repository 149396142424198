import React from "react";

export default function ContactIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        d="M12.0023 3H11.9977C7.03537 3 3 7.0365 3 12C3 13.9688 3.6345 15.7935 4.71338 17.2751L3.59175 20.6186L7.05113 19.5128C8.47425 20.4555 10.1719 21 12.0023 21C16.9646 21 21 16.9624 21 12C21 7.03763 16.9646 3 12.0023 3ZM17.2391 15.7091C17.022 16.3222 16.1603 16.8307 15.4729 16.9792C15.0026 17.0794 14.3884 17.1593 12.3206 16.302C9.67575 15.2063 7.9725 12.5186 7.83975 12.3442C7.71263 12.1699 6.771 10.9211 6.771 9.62963C6.771 8.33813 7.42688 7.70925 7.69125 7.43925C7.90838 7.21763 8.26725 7.11637 8.6115 7.11637C8.72288 7.11637 8.823 7.122 8.913 7.1265C9.17737 7.13775 9.31012 7.1535 9.4845 7.57088C9.70162 8.094 10.2304 9.3855 10.2934 9.51825C10.3575 9.651 10.4216 9.831 10.3316 10.0054C10.2473 10.1854 10.173 10.2652 10.0402 10.4183C9.9075 10.5712 9.7815 10.6883 9.64875 10.8525C9.52725 10.9954 9.39 11.1484 9.543 11.4128C9.696 11.6715 10.2247 12.5344 11.0033 13.2274C12.0079 14.1217 12.8224 14.4075 13.1137 14.529C13.3309 14.619 13.5896 14.5976 13.7482 14.4289C13.9496 14.2118 14.1982 13.8518 14.4514 13.4974C14.6314 13.2431 14.8586 13.2116 15.0971 13.3016C15.3401 13.386 16.626 14.0216 16.8904 14.1532C17.1547 14.286 17.3291 14.349 17.3932 14.4604C17.4563 14.5718 17.4563 15.0949 17.2391 15.7091Z"
        fill="#203B54"
      ></path>
    </svg>
  );
}
