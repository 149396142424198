import { useEffect, useCallback, useRef } from "react";
import useSyncRef from "../useSyncRef/useSyncRef";

export default function useDebouncedCallback(cb, timeout) {
  const cbRef = useSyncRef(cb),
    timeoutRef = useRef();

  useEffect(
    () => () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    },
    []
  );

  return useCallback(
    (...t) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        cbRef.current(...t);
      }, timeout);
    },
    [timeout, cbRef]
  );
}
