import { useEffect, useState, useRef } from "react";

export default function useHeight() {
  const [height, setHeight] = useState(
    window.innerHeight *
      ((window.visualViewport && window.visualViewport.scale) || 1)
  );
  const timeoutRef = useRef(null);

  useEffect(() => {
    const onResize = () => {
      timeoutRef.current = setTimeout(
        () =>
          window.requestAnimationFrame(() => {
            // eslint-disable-next-line
            let h =
              window.innerHeight *
              ((window.visualViewport && window.visualViewport.scale) || 1);
            setHeight(h);
          }),
        300
      );
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return height;
}
