import React from "react";

export default function SessionTypeIconGreyedOut({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="15.5" fill="currentColor" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.66669C12.2847 6.66669 9.27979 9.67157 9.27979 13.3869C9.27979 18.427 16 25.8672 16 25.8672C16 25.8672 22.7201 18.427 22.7201 13.3869C22.7201 9.67157 19.7153 6.66669 16 6.66669ZM16 16.2673C14.5162 16.2673 13.312 15.0385 13.312 13.5244C13.312 12.0103 14.5162 10.7815 16 10.7815C17.4838 10.7815 18.6881 12.0103 18.6881 13.5244C18.6881 15.0385 17.4838 16.2673 16 16.2673Z"
        fill="white"
      />
    </svg>
  );
}
