import React from "react";

export default function LocationMark(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_318_4379)">
        <path
          d="M20.4001 19.1667C19.295 19.1667 18.2352 18.7277 17.4538 17.9463C16.6724 17.1649 16.2334 16.1051 16.2334 15C16.2334 13.895 16.6724 12.8352 17.4538 12.0538C18.2352 11.2724 19.295 10.8334 20.4001 10.8334C21.5051 10.8334 22.5649 11.2724 23.3463 12.0538C24.1277 12.8352 24.5667 13.895 24.5667 15C24.5667 15.5472 24.459 16.089 24.2496 16.5946C24.0402 17.1001 23.7333 17.5594 23.3463 17.9463C22.9594 18.3332 22.5001 18.6401 21.9946 18.8495C21.4891 19.0589 20.9472 19.1667 20.4001 19.1667ZM20.4001 3.33337C17.3059 3.33337 14.3384 4.56254 12.1505 6.75046C9.96256 8.93839 8.7334 11.9058 8.7334 15C8.7334 23.75 20.4001 36.6667 20.4001 36.6667C20.4001 36.6667 32.0667 23.75 32.0667 15C32.0667 11.9058 30.8376 8.93839 28.6496 6.75046C26.4617 4.56254 23.4943 3.33337 20.4001 3.33337Z"
          fill="url(#paint0_linear_318_4379)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_318_4379"
          x1="8.7334"
          y1="3.40553"
          x2="32.7446"
          y2="40.4846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <clipPath id="clip0_318_4379">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.400391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
