import React from "react";

export default function CustomerSupport(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_318_4799)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3632 4.28577C19.1689 4.2642 17.9819 4.47837 16.8704 4.91604C15.7589 5.3537 14.7446 6.00626 13.8856 6.83637C13.0265 7.66648 12.3396 8.65784 11.8642 9.75372C11.3887 10.8496 11.134 12.0285 11.1147 13.2229V25.7143C11.1147 26.4721 10.8137 27.1988 10.2778 27.7346C9.74202 28.2705 9.0153 28.5715 8.25753 28.5715H4.6861C3.54946 28.5715 2.45937 28.12 1.65565 27.3162C0.85192 26.5125 0.400391 25.4224 0.400391 24.2858L0.400391 18.5715C0.400391 17.4348 0.85192 16.3448 1.65565 15.541C2.45937 14.7373 3.54946 14.2858 4.6861 14.2858H6.82896V13.1715C6.85503 11.4155 7.22677 9.68178 7.92294 8.06944C8.61912 6.45711 9.62609 4.99771 10.8864 3.77459C12.1466 2.55147 13.6355 1.58859 15.2679 0.940952C16.9004 0.293311 18.6444 -0.0264084 20.4004 5.31956e-05C22.1564 -0.0264084 23.9004 0.293311 25.5329 0.940952C27.1653 1.58859 28.6542 2.55147 29.9144 3.77459C31.1747 4.99771 32.1817 6.45711 32.8778 8.06944C33.574 9.68178 33.9458 11.4155 33.9718 13.1715V14.2858H36.1147C37.2513 14.2858 38.3414 14.7373 39.1451 15.541C39.9489 16.3448 40.4004 17.4348 40.4004 18.5715V24.2858C40.4004 25.4224 39.9489 26.5125 39.1451 27.3162C38.3414 28.12 37.2513 28.5715 36.1147 28.5715H33.9718V30.0001C33.9722 31.9308 33.2617 33.7941 31.9758 35.2343C30.69 36.6746 28.9188 37.591 27.0004 37.8086C26.6278 38.4738 26.0846 39.0275 25.4266 39.4128C24.7687 39.798 24.0199 40.0007 23.2575 40.0001H18.9718C17.8352 40.0001 16.7451 39.5485 15.9414 38.7448C15.1376 37.9411 14.6861 36.851 14.6861 35.7143C14.6861 34.5777 15.1376 33.4876 15.9414 32.6839C16.7451 31.8802 17.8352 31.4286 18.9718 31.4286H23.2575C24.8061 31.4286 26.1632 32.2486 26.9147 33.4801C27.7022 33.2991 28.405 32.8564 28.9084 32.2243C29.4118 31.5922 29.686 30.8081 29.6861 30.0001V13.2201C29.6665 12.0195 29.4091 10.8347 28.9289 9.7342C28.4486 8.63371 27.7549 7.63932 26.888 6.80853C26.0211 5.97775 24.9981 5.32704 23.8782 4.89402C22.7582 4.461 21.5636 4.25427 20.3632 4.28577Z"
          fill="url(#paint0_linear_318_4799)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_318_4799"
          x1="0.400391"
          y1="0.0851239"
          x2="24.1495"
          y2="52.4752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <clipPath id="clip0_318_4799">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.400391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
