/**
 * Title with separator
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/11/06
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";

const TitleWithSeparator = ({
  className,
  component: Component = "h3", // default component is h3
  textAlign = "center",
  titleClass,
  separatorClass = "",
  children,
}) => {
  return (
    <Component
      className={`tbk-relative tbk-flex ${
        textAlign === "center" ? "tbk-justify-center" : "tbk-justify-center"
      } tbk-items-center ${className}`}
    >
      <div
        className={`${
          textAlign === "center" ? "tbk-mx-2 tbk-flex-1" : ""
        } tbk-border-t tbk-border-basic-white ${separatorClass}`}
      ></div>
      <div
        className={`${
          textAlign === "left" ? "tbk-text-left" : ""
        } ${titleClass}`}
      >
        {children}
      </div>
      <div
        className={`tbk-mx-2 tbk-flex-1 tbk-border-t tbk-border-basic-white ${separatorClass}`}
      ></div>
    </Component>
  );
};

export default TitleWithSeparator;
