import React from "react";

export default function CalIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5985 5.51323H15.8633V4.80706C15.8633 4.36318 15.5002 4 15.0563 4C14.6124 4 14.2492 4.36318 14.2492 4.80706V5.51323H9.77005V4.80706C9.77005 4.36318 9.40688 4 8.96299 4C8.51911 4 8.15593 4.36318 8.15593 4.80706V5.51323H6.42076C5.53299 5.51323 4.80664 6.23959 4.80664 7.12735V18.3858C4.80664 19.2736 5.53299 19.9999 6.42076 19.9999H17.5783C18.4661 19.9999 19.1925 19.2736 19.1925 18.3858V7.12735C19.2126 6.23959 18.4863 5.51323 17.5985 5.51323ZM17.5783 18.3656H6.42076V11.5864H17.5783V18.3656Z"
        fill="currentColor"
      />
    </svg>
  );
}
