// __d((function(g, r, i, a, m, e, d) {
// "use strict";
// Object.defineProperty(e, "__esModule", {
//     value: !0
// }),
let t;

export default function o() {
  if (!("matchMedia" in window)) {
    return false;
  }

  if (t !== undefined) {
    return t;
  }

  const u = matchMedia("(prefers-reduced-motion: reduce)");

  t = u.matches;

  o !== undefined &&
    u.addListener(() => {
      t = u.matches;
    });

  return t;
}

export const prefersReducedMotionQuery =
  "@media (prefers-reduced-motion: reduce)";

// }
// ), "c6c95d", []);
