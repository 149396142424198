import React from "react";

export default function InvoiceCheck(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_318_4436)">
        <path
          d="M32.6004 19.4737C33.9654 19.4737 35.2719 19.7074 36.5004 20.1358V0H1.40039V37L7.25039 33.1053L13.1004 37L18.9504 33.1053L21.4854 34.78C21.0954 33.6505 20.9004 32.4237 20.9004 31.1579C20.9004 24.7121 26.1459 19.4737 32.6004 19.4737ZM25.7754 31.1579L31.1379 37L40.4004 27.7111L38.1384 24.9653L31.1379 31.9563L28.0374 28.86L25.7754 31.1579Z"
          fill="url(#paint0_linear_318_4436)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_318_4436"
          x1="1.40039"
          y1="0.0800916"
          x2="22.6016"
          y2="49.3795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <clipPath id="clip0_318_4436">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
