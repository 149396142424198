import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

// Components
import Header from "../Header/Header";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import Button from "../Button/Button";

// Icons
import CheckNiceIcon from "../../icons/CheckNiceIcon";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Utils

export default function SuccessPage() {
  const history = useHistory();
  const location = useLocation();

  const { theme } = useTheme();

  const [bookingId, setBookingId] = useState(false);
  const [title, setTitle] = useState("Let's do this!");
  const [msg, setMsg] = useState(
    "Your session with has been successfully booked. You will receive confirmation details to your email on file."
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const bookingId = query.get("bookingId");

    if (bookingId) {
      setBookingId(bookingId);
    }

    const newTitle = query.get("title");

    if (newTitle) {
      setTitle(newTitle);
    }

    const subtitle = query.get("subtitle");

    if (subtitle) {
      setMsg(subtitle);
    }
  }, [location]);

  return (
    <>
      <ScrollToTopOnMount />
      <Header showLinks />

      <div />

      <div className="tbk-flex tbk-min-h-screen tbk-flex-col tbk-items-center tbk-pt-5 tbk-pb-8 md:tbk-justify-center md:tbk-pt-6">
        <div className={"tbk-relative tbk-w-full tbk-p-3 sm:tbk-w-120"}>
          <div className="modal-info tbk-flex tbk-flex-col tbk-items-center">
            <h3 className="tbk-text-title-bold tbk-mt-0 tbk-mb-1 tbk-text-center tbk-text-grey-main">
              {title}
            </h3>
            <p className="tbk-text-main tbk-mb-4 tbk-max-w-full tbk-text-center tbk-text-grey-main">
              {msg}
            </p>
            <CheckNiceIcon size={40} className="tbk-mb-4 tbk-text-green" />
            <Link
              className="tbk-w-full"
              to={`${bookingId ? `/account/bookings/${bookingId}` : "/events"}`}
            >
              <Button className="tbk-w-full">Ok</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
