import React from "react";

export default function ExclamationMarkOIcon({ className, size, style }) {
  return (
    <svg
      width={size || 16}
      height={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-shrink-0${className ? " " + className : ""}`}
      style={style}
    >
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      <rect
        width="2"
        height="10"
        transform="translate(7 3)"
        fill="currentColor"
      />
      <rect x="7" y="3" width="2" height="7" rx="1" fill="white" />
      <rect x="7" y="11" width="2" height="2" rx="1" fill="white" />
    </svg>
  );
}
