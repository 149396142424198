import React from "react";

export default function CupIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6001 12.4219C20.8324 11.4229 20.9989 4.764 21.0001 4.69538C21.0023 4.54463 20.9438 4.3995 20.8381 4.29263C20.7323 4.18575 20.5883 4.125 20.4376 4.125H16.5001V3.5625C16.5001 3.252 16.2481 3 15.9376 3H8.06256C7.75206 3 7.50006 3.252 7.50006 3.5625V4.125H3.56256C3.41181 4.125 3.26781 4.18575 3.16206 4.29263C3.05631 4.3995 2.99781 4.54463 3.00006 4.69538C3.00119 4.764 3.16656 11.424 8.40006 12.4219C8.88944 13.0789 9.54419 13.602 10.3126 13.9147V15.9026C10.3126 17.0603 9.61731 18.0829 8.53506 18.5092L7.84881 18.7905C7.63844 18.8782 7.50006 19.0841 7.50006 19.3125V20.4375C7.50006 20.748 7.75206 21 8.06256 21H15.9376C16.2481 21 16.5001 20.748 16.5001 20.4375V19.3125C16.5001 19.0841 16.3617 18.8783 16.1502 18.7916L15.4572 18.5081C14.3817 18.0829 13.6876 17.0614 13.6876 15.9037V13.9159C14.4571 13.602 15.1118 13.0789 15.6001 12.4219ZM16.5001 9.75V5.25H19.8334C19.6894 6.60113 19.0482 9.95813 16.2818 11.064C16.4101 10.6455 16.5001 10.2101 16.5001 9.75ZM4.16219 5.25H7.50006V9.75C7.50006 10.2112 7.59006 10.6477 7.71944 11.0674C4.91594 9.96375 4.29606 6.609 4.16219 5.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
