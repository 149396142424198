import React from "react";

export default function ChainIcon({ size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 33}
      height={size || 32}
      viewBox="0 0 33 32"
      className={`${className ? `${className} ` : ""} tbk-shrink-0`}
    >
      <path
        d="M19.1442 13.3541C18.8598 13.0681 18.5445 12.8145 18.2042 12.5981C17.3027 12.0209 16.2547 11.7141 15.1842 11.7141C13.6985 11.7114 12.2731 12.3017 11.2243 13.3541L6.13638 18.446C5.0894 19.495 4.50094 20.9163 4.5 22.3983C4.49803 25.4911 7.00355 27.9999 10.0963 28.0018C11.5798 28.007 13.0039 27.4195 14.0523 26.3699L18.2522 22.1699C18.3285 22.0943 18.3711 21.9911 18.3705 21.8836C18.3692 21.6627 18.1891 21.4847 17.9682 21.4859H17.8082C16.9307 21.489 16.0611 21.3205 15.2483 20.99C15.0985 20.9283 14.9264 20.9631 14.8123 21.0779L11.7923 24.1019C10.8546 25.0397 9.33414 25.0397 8.39637 24.1019C7.45861 23.1641 7.45861 21.6437 8.39637 20.706L13.5043 15.6021C14.4413 14.6662 15.9592 14.6662 16.8963 15.6021C17.5278 16.1964 18.5128 16.1964 19.1442 15.6021C19.4159 15.3301 19.5806 14.9695 19.6082 14.5861C19.6374 14.1279 19.4684 13.6791 19.1442 13.3541Z"
        fill="currentColor"
      />
      <path
        d="M26.86 5.6383C24.673 3.45129 21.1271 3.45129 18.9401 5.6383L14.7442 9.83022C14.629 9.946 14.5957 10.1201 14.6602 10.2702C14.7239 10.4207 14.8727 10.5173 15.0361 10.5142H15.1841C16.0606 10.5126 16.9288 10.6824 17.7401 11.0142C17.8899 11.0759 18.062 11.0411 18.1761 10.9262L21.188 7.91827C22.1258 6.9805 23.6462 6.9805 24.584 7.91827C25.5217 8.85603 25.5217 10.3764 24.584 11.3142L20.832 15.0621L20.8 15.0981L19.488 16.4021C18.551 17.338 17.0331 17.338 16.0961 16.4021C15.4646 15.8078 14.4796 15.8078 13.8481 16.4021C13.5747 16.6761 13.4098 17.0399 13.3841 17.4261C13.3549 17.8843 13.5238 18.333 13.8481 18.6581C14.3111 19.123 14.8528 19.5022 15.4481 19.778C15.5321 19.818 15.6161 19.85 15.7001 19.886C15.7841 19.922 15.8721 19.95 15.9561 19.982C16.0401 20.0141 16.128 20.042 16.212 20.066L16.448 20.13C16.608 20.17 16.7681 20.202 16.932 20.23C17.1295 20.2594 17.3285 20.2781 17.528 20.286H17.808H17.832L18.072 20.258C18.16 20.2541 18.252 20.234 18.356 20.234H18.492L18.768 20.1941L18.896 20.1701L19.128 20.1221H19.172C20.1545 19.8753 21.0517 19.3665 21.7679 18.6501L26.8598 13.5582C29.047 11.3712 29.047 7.82531 26.86 5.6383Z"
        fill="currentColor"
      />
    </svg>
  );
}
