import React from "react";

export default function TwitterIcon({ className, size }) {
  return (
    <svg
      width={size || 33}
      height={size || 32}
      viewBox="0 0 33 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5 7.93837C28.5331 8.3625 27.5028 8.64362 26.4287 8.78012C27.5337 8.12037 28.3771 7.08362 28.7736 5.834C27.7433 6.44825 26.6058 6.88212 25.3936 7.12425C24.4153 6.08262 23.0211 5.4375 21.5001 5.4375C18.5491 5.4375 16.1734 7.83275 16.1734 10.7691C16.1734 11.1916 16.2091 11.5979 16.2969 11.9846C11.8655 11.7685 7.94437 9.64462 5.31025 6.40925C4.85037 7.20712 4.58062 8.12037 4.58062 9.10349C4.58062 10.9495 5.53125 12.5859 6.94824 13.5332C6.09187 13.517 5.25175 13.2684 4.54 12.8767C4.54 12.893 4.54 12.9141 4.54 12.9352C4.54 15.5255 6.38762 17.677 8.81049 18.1726C8.37662 18.2912 7.90374 18.3481 7.41299 18.3481C7.07174 18.3481 6.72725 18.3286 6.40387 18.2571C7.09449 20.368 9.05424 21.9198 11.3845 21.9702C9.57099 23.3888 7.26837 24.2436 4.77562 24.2436C4.3385 24.2436 3.91925 24.2241 3.5 24.1705C5.86112 25.6931 8.65937 26.5625 11.677 26.5625C21.4855 26.5625 26.848 18.4375 26.848 11.3947C26.848 11.1591 26.8398 10.9316 26.8285 10.7057C27.8863 9.95499 28.7752 9.01737 29.5 7.93837Z"
        fill="currentColor"
      />
    </svg>
  );
}
