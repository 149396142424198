import React from "react";

export default function HeartIcon({ className, size }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M58.9771 31.1921C56.6885 35.9195 52.62 40.2307 48.9214 43.7097C46.263 46.2063 40.9346 51.2226 35.1555 54.8288C33.2253 56.0308 30.7749 56.0308 28.8447 54.8288C23.0771 51.2226 17.7372 46.2063 15.0788 43.7097C11.3801 40.2307 7.31164 35.9079 5.0231 31.1921C1.32446 23.5406 4.96531 13.4849 12.5475 9.89026C19.7599 6.48057 26.0707 8.81534 32.0001 13.508C37.9295 8.81534 44.2403 6.48057 51.4411 9.89026C59.0349 13.4849 62.6757 23.5406 58.9771 31.1921Z"
        fill="url(#paint0_linear_13511_19193)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13511_19193"
          x1="3.56543"
          y1="8.37239"
          x2="28.3637"
          y2="73.9238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
