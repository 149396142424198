import React from "react";

export default function WoSpiritualGangsterIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        d="M20.4001 11.3171C20.4001 11.2061 20.2622 11.0952 20.0691 10.9564C19.683 10.7067 19.2417 10.568 18.7728 10.568C18.2487 10.5957 17.7522 10.7622 17.3109 11.0397H17.173V5.57358C17.173 4.68569 16.9799 3.85329 15.6008 3.85329C14.2218 3.85329 14.0287 4.68569 14.0287 5.57358L13.8908 11.4559H13.5874V3.52034C13.5874 2.63245 13.3943 1.80005 12.0152 1.80005C10.6361 1.80005 10.443 2.63245 10.443 3.52034V11.4836H10.1672V11.4004L10.0293 5.62908C10.0293 4.74119 9.83624 3.90879 8.45714 3.90879C7.07805 3.90879 6.88497 4.65795 6.88497 6.04528V11.1506L6.71948 11.0674C6.27817 10.7899 5.75411 10.6512 5.23006 10.6512C4.78875 10.6512 4.31986 10.7622 3.93371 10.9842C3.74064 11.1229 3.63031 11.2339 3.60273 11.3449C3.57514 11.4559 3.76822 11.7056 4.01645 12.0108C5.0094 13.0097 5.45072 14.4525 5.1749 15.8676C5.09215 17.3936 5.56104 18.9197 6.52641 20.1128C7.9055 21.5279 9.80865 22.277 11.767 22.1938H12.2083C14.1666 22.277 16.0697 21.5279 17.4213 20.1128C18.3866 18.9197 18.8831 17.3936 18.7728 15.8676C18.4969 14.4802 18.9107 13.0374 19.9312 12.0108C20.1794 11.7333 20.3449 11.5391 20.3449 11.3449M12.0152 18.9197C5.89202 15.8121 10.25 12.205 12.0152 14.508C13.808 12.205 18.166 15.7843 12.0152 18.9197Z"
        fill="currentColor"
      />
    </svg>
  );
}
