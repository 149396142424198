import React from "react";

export default function PenIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        d="M6 15.5003V18H8.49965L15.872 10.6277L13.3723 8.12804L6 15.5003ZM17.805 8.69463C18.065 8.43466 18.065 8.01472 17.805 7.75476L16.2452 6.19497C15.9853 5.93501 15.5653 5.93501 15.3054 6.19497L14.0855 7.4148L16.5852 9.91446L17.805 8.69463Z"
        fill="currentColor"
      />
    </svg>
  );
}
