import React from "react";

export default function StarHalfIcon({ size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      className={className ? className : ""}
    >
      <path
        d="M18 11.192l-4.314-.36L12 7l-1.686 3.838L6 11.192l3.276 2.738L8.292 18 12 15.84 15.708 18l-.978-4.07L18 11.192zm-6 3.566V9.374l1.026 2.339 2.628.22-1.992 1.667.6 2.478L12 14.758z"
        fill="currentColor"
      />
    </svg>
  );
}
