import { useState, useCallback } from "react";

export default function useGeocoder(geocoder) {
  const [addressInfo, setAddressInfo] = useState();

  const lookupAddress = useCallback(
    (latlng) => {
      if (!geocoder) {
        return;
      }

      return geocoder
        .geocode({ location: latlng })
        .then((response) => {
          if (response.results[0]) {
            const addrInfo = response.results[0];

            setAddressInfo(addrInfo);

            return addrInfo;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },
    [geocoder]
  );

  return [addressInfo, lookupAddress];
}
