import React from "react";

export default function ArrowRightIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-shrink-0${className ? ` ${className}` : ""}`}
    >
      <path
        d="M8 7.5L9.5 6L16 12.5L9.5 19L8 17.5L13 12.5L8 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
