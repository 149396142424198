import React from "react";

export default function FacebookIcon({ className, size }) {
  return (
    <svg
      width={size || 33}
      height={size || 32}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5771 18.743C21.4104 18.743 22.2157 18.7424 23.0209 18.7432C23.653 18.7438 23.7671 18.6367 23.8459 18.0069C23.9333 17.3092 24.0272 16.6122 24.1281 15.9163C24.2172 15.3015 24.0197 15.0594 23.4064 15.0591C22.4748 15.0585 21.5434 15.0589 20.615 15.0589C20.5938 15.0168 20.5682 14.99 20.5684 14.9634C20.5774 14.0426 20.5581 13.1202 20.6093 12.2017C20.6533 11.4108 21.1029 10.9751 21.8941 10.9095C22.4809 10.8609 23.0728 10.8703 23.6625 10.8613C24.1888 10.8532 24.3723 10.6764 24.3739 10.1492C24.3759 9.50725 24.3767 8.86531 24.374 8.22336C24.3718 7.70227 24.1841 7.49343 23.6727 7.48703C22.6703 7.4746 21.6613 7.407 20.6662 7.49181C18.5634 7.67096 16.997 9.24317 16.8184 11.3443C16.7241 12.455 16.7612 13.577 16.7396 14.6938C16.7374 14.8049 16.7392 14.9161 16.7392 15.0605C16.2525 15.0605 15.7997 15.0604 15.347 15.0606C14.9224 15.0607 14.4976 15.0543 14.0731 15.0635C13.7446 15.0706 13.5225 15.2817 13.519 15.6089C13.5097 16.4682 13.509 17.3278 13.519 18.1871C13.5232 18.545 13.7468 18.7398 14.1247 18.742C14.8808 18.7466 15.6368 18.7445 16.3929 18.7451C16.4942 18.7452 16.5955 18.7451 16.7392 18.7451C16.7392 18.8837 16.7392 18.9934 16.7392 19.1032C16.7398 21.8782 16.7404 24.6532 16.7408 27.4283C16.7408 27.5111 16.7421 27.594 16.7387 27.6767C16.7213 28.0954 16.5189 28.3002 16.0953 28.3017C14.8215 28.3065 13.5475 28.3049 12.2736 28.3043C10.72 28.3036 9.16635 28.3168 7.61308 28.2959C5.72469 28.2705 4.19859 26.7154 4.19678 24.8206C4.19125 18.9496 4.19057 13.0785 4.19685 7.20747C4.19896 5.24491 5.74464 3.69905 7.70737 3.69694C13.5798 3.6906 19.4523 3.6911 25.3248 3.69706C27.2302 3.69905 28.797 5.24547 28.8002 7.14701C28.8103 13.0491 28.8103 18.9513 28.8 24.8533C28.7967 26.736 27.2345 28.2789 25.3466 28.2957C23.9899 28.3078 22.633 28.2992 21.2762 28.2985C20.7557 28.2983 20.5635 28.1152 20.5621 27.5875C20.5571 25.8066 20.5592 24.0256 20.5591 22.2447C20.5591 21.1988 20.5597 20.153 20.5614 19.1072C20.5615 18.9965 20.5707 18.8858 20.5771 18.743Z"
        fill="currentColor"
      />
    </svg>
  );
}
