/**
 * Time ZOne Label component for displaying currently set time zone.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/29
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { getTz } from "../../utils/date";

const TimeZoneLabel = ({ region }) => {
  const [utcOffset, setUtcOffset] = useState(null);

  useEffect(() => {
    const timeZone = getTz(region);
    const offset = moment.tz(timeZone).utcOffset();
    setUtcOffset({
      hours: Math.abs(offset / 60),
      sign: offset < 0 ? "-" : "+",
    });
  }, [region]);

  const timeZoneOffset = `${utcOffset ? utcOffset.sign : "+"}${
    utcOffset ? utcOffset.hours : "0"
  }`;

  return (
    <span className="tbk-text-grey-main">
      {region === "GB" ? "London, UK (UTC" : "Los Angeles, CA, US (UTC"}
      {timeZoneOffset})
    </span>
  );
};

export default TimeZoneLabel;
