import React from "react";

export default function CheckOIcon({ className }) {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <path
        d="M24.5 10C16.772 10 10.5 16.272 10.5 24C10.5 31.728 16.772 38 24.5 38C32.228 38 38.5 31.728 38.5 24C38.5 16.272 32.228 10 24.5 10ZM21.7 31L14.7 24L16.674 22.026L21.7 27.038L32.326 16.412L34.3 18.4L21.7 31Z"
        fill="#3BC693"
      />
    </svg>
  );
}
