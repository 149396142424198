import React from "react";

export default function LotusBig(props) {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="126" height="126" rx="16" fill="#FF7557" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123 63C123 96.137 96.137 123 63 123C29.864 123 3 96.137 3 63C3 29.863 29.864 3 63 3C96.137 3 123 29.863 123 63Z"
        fill="#FF7557"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.2537 36.4123C76.2978 43.8085 70.339 49.8418 62.9428 49.8869C55.5424 49.9331 49.5112 43.9712 49.4661 36.575C49.422 29.1767 55.3818 23.1434 62.7801 23.1004C70.1763 23.0542 76.2075 29.014 76.2537 36.4123Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.3941 89.9556C96.3941 89.9556 51.4499 62.9328 32.2832 92.7223C32.2832 92.7223 59.3344 117.04 96.3941 89.9556Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.8162 53.7776C69.8162 53.7776 64.2103 72.8215 52.109 72.8215C41.4316 72.8215 31.1993 49.8632 28.3496 49.6858C28.3496 49.6858 34.5814 42.1205 42.6779 47.3705C57.6835 57.0988 63.8522 56.2703 69.8162 53.7776Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.293 72.2404C86.2552 72.5533 112.897 47.0404 84.4754 36.8523C81.5837 56.629 67.5284 69.0463 65.293 72.2404Z"
        fill="white"
      />
    </svg>
  );
}
