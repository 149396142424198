import React from "react";

export default function WoKickboxingIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2763 4.32671C17.6699 3.9095 18.3238 3.88879 18.741 4.28233C19.1582 4.67586 19.1759 5.32978 18.7883 5.74698L16.1342 8.5609L13.8322 11.0405C13.8203 11.0878 13.8026 11.1351 13.7848 11.1795V18.417C13.7848 18.9881 13.3203 19.4526 12.7492 19.4526C12.1781 19.4526 11.7136 18.9881 11.7136 18.417V12.0021L10.1246 11.9932C10.0093 11.9932 9.89681 11.9784 9.78733 11.9488L7.35807 11.2979C7.32974 11.2908 7.30329 11.2818 7.27572 11.2725C7.26874 11.2701 7.26169 11.2677 7.25451 11.2653L6.76038 12.372L7.65988 13.2123C8.01791 13.5467 8.03566 14.1088 7.70131 14.4669C7.52673 14.6533 7.29002 14.748 7.05331 14.748C6.83435 14.748 6.61835 14.6681 6.44673 14.5083L5.08563 13.236C4.8075 12.9756 4.72761 12.5702 4.88147 12.224L5.83128 10.0995C5.87566 9.99893 5.9378 9.9072 6.01473 9.83323C6.09462 9.8421 6.17747 9.84802 6.25736 9.84802C6.48224 9.84802 6.69824 9.81252 6.90536 9.74446C7.27522 9.62019 7.59774 9.39235 7.867 9.06983C8.08892 8.80057 8.2635 8.47805 8.38481 8.11115L9.51216 7.03411L11.9592 4.73504C12.3172 4.39772 12.8794 4.41548 13.2137 4.7735C13.5511 5.13153 13.5333 5.69372 13.1753 6.02808L10.7312 8.32419L9.81396 9.20002L10.3111 9.33317L12.5569 9.34797H12.5746L14.6222 7.14359L17.2763 4.32671ZM7.71316 6.19673C8.04456 7.18797 7.6599 8.7118 6.66867 9.0432C6.53256 9.08758 6.39349 9.10829 6.25738 9.10829C5.36084 9.10829 4.40807 8.25909 4.12105 7.40101C3.78966 6.40977 4.12697 5.27651 5.27798 4.89186C5.50582 4.81493 5.72182 4.77942 5.92894 4.77942C6.78998 4.77942 7.4439 5.39487 7.71316 6.19673Z"
        fill="currentColor"
      />
    </svg>
  );
}
