import React from "react";

export default function ReNewlyDiscoveredIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 32C64 49.6729 49.6735 64 32 64C14.3273 64 0 49.6729 0 32C0 14.3265 14.3273 0 32 0C49.6735 0 64 14.3265 64 32Z"
        fill="#FFBF44"
      />
      <mask
        id={`${id}_mask0_5436_19023`}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64 32C64 49.6729 49.6735 64 32 64C14.3273 64 0 49.6729 0 32C0 14.3265 14.3273 0 32 0C49.6735 0 64 14.3265 64 32Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id}_mask0_5436_19023)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.0603 15.5298C53.0823 15.1901 52.9583 15.1084 52.6353 15.0769C51.0232 14.9199 49.4089 15.1053 47.7962 15.0108C47.1073 14.9705 46.412 15.0547 45.7195 15.0703C43.0712 15.1306 40.4401 15.371 37.8361 15.882C34.1323 16.6088 31.0035 17.6643 28.4012 20.554C28.0804 20.9103 27.9749 21.4 28.4012 21.5535C29.9994 22.1287 34.2778 24.0518 36.8845 28.5816C38.0115 30.54 50.8147 19.9778 51.1007 17.6637C51.2844 16.1783 53.0361 15.9023 53.0603 15.5298Z"
          fill={`url(#${id}_paint0_linear_5436_19023)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.0057 15.2533C52.9862 15.2049 51.8535 15.9157 51.2993 16.6518C50.045 18.3176 48.3796 21.4828 45.3937 23.7461C41.4195 26.7586 36.4119 27.8878 36.822 28.5713C37.9817 30.5039 38.7692 32.9803 38.9199 36.0202C38.9575 36.7784 45.1301 34.9799 46.7721 33.7714C48.8159 32.2671 50.1096 30.1765 51.0441 27.8302C51.9018 25.6765 52.3719 23.4237 52.6579 21.1096C52.8415 19.6242 53.1638 18.1408 53.2348 16.6518C53.2348 16.2777 53.1659 15.6515 53.0057 15.2533Z"
          fill={`url(#${id}_paint1_linear_5436_19023)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.9243 37.5584C39.27 34.7647 39.1745 31.9924 38.4937 29.2513C37.9508 27.0655 37.007 25.0669 35.572 23.3125C33.6657 20.9819 31.2114 19.4324 28.361 18.4823C25.6642 17.5833 22.8526 17.3165 20.0378 17.1442C17.22 16.9717 14.3966 16.9537 11.5736 17.0917C11.214 17.1094 10.7875 17.3141 11.12 17.5583C13.1647 19.0605 12.5586 18.5041 14.1195 21C15.9859 23.9841 17.1039 25.0163 20.6924 28.0118C22.1008 29.1873 25.6583 29.2835 27.9295 30.0081C30.2006 30.7327 36.4869 31.2083 37.944 39.0292C38.3534 39.2669 38.9194 37.5986 38.9243 37.5584Z"
          fill={`url(#${id}_paint2_linear_5436_19023)`}
        />
        <path
          d="M36.2788 34.934C36.2788 34.1144 39.4491 35.5664 40.1383 37.0296C40.9496 38.7519 41.0768 38.9623 41.0057 41.9593L40.9701 43.3599C40.9649 43.6026 40.9607 43.8344 40.9575 44.0634L40.9505 45.1031V58.6152C40.9505 59.7197 40.0551 60.6152 38.9505 60.6152C37.8962 60.6152 37.0323 59.7993 36.956 58.7644L36.9505 58.6152V45.1031C36.9505 44.37 36.9596 43.7238 36.9784 42.9571L37.0183 41.3375C37.0462 39.7938 36.9926 39.5294 36.7854 39.2109C36.6618 39.0209 36.2788 35.7536 36.2788 34.934Z"
          fill={`url(#${id}_paint3_linear_5436_19023)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.7982 34.4118C35.2666 32.572 33.6505 30.9976 27.8036 29.5255C21.9568 28.0535 17.6856 25.1883 11.9741 17.5583C11.791 17.3137 11.0017 17.0443 11.0002 17.3927C10.9923 19.2107 11.2165 21.185 11.4751 22.9856C11.8879 25.8596 12.6319 28.627 14.1928 31.1228C16.0592 34.1069 18.7341 35.8746 22.2904 36.2037C24.3877 36.3978 26.4946 36.4887 28.5854 36.7616C30.63 37.0285 32.6591 37.3703 34.6491 37.9189C35.68 38.203 36.6835 38.5619 37.7276 39C38.1369 39.2377 38.753 36.7601 36.7982 34.4118Z"
          fill={`url(#${id}_paint4_linear_5436_19023)`}
        />
        <circle
          cx="45"
          cy="81"
          r="30"
          fill={`url(#${id}_paint5_linear_5436_19023)`}
        />
      </g>
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_19023`}
          x1="20.2613"
          y1="20.9089"
          x2="24.1017"
          y2="33.7529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00ACA0" />
          <stop offset="1" stopColor="#007467" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_19023`}
          x1="31.62"
          y1="24.2068"
          x2="41.5217"
          y2="38.6044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00ACA0" />
          <stop offset="1" stopColor="#007467" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_5436_19023`}
          x1="28.1719"
          y1="7.88449"
          x2="19.2811"
          y2="25.0295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E5C6" />
          <stop offset="1" stopColor="#43C694" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint3_linear_5436_19023`}
          x1="33.9069"
          y1="50.0314"
          x2="43.3045"
          y2="51.485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE9556" />
          <stop offset="1" stopColor="#5C3614" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint4_linear_5436_19023`}
          x1="39.0893"
          y1="49.9323"
          x2="52.7388"
          y2="14.3049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#43C694" />
          <stop offset="1" stopColor="#78E5C6" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint5_linear_5436_19023`}
          x1="-15"
          y1="86.499"
          x2="55.998"
          y2="146.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE9556" />
          <stop offset="1" stopColor="#5C3614" />
        </linearGradient>
      </defs>
    </svg>
  );
}
