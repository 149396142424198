import React from "react";

export default function Visa({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <rect width="24" height="24" rx="3" fill="#0054A3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1499 14.7708L10.1021 9.302H11.6249L10.6721 14.7708H9.1499Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.35 9.31462C16.038 9.20295 15.5492 9.08325 14.9387 9.08325C13.3828 9.08325 12.2867 9.83055 12.2774 10.9016C12.2686 11.6933 13.0599 12.135 13.6572 12.3986C14.27 12.6686 14.4762 12.8409 14.4732 13.082C14.4693 13.4514 13.9837 13.6201 13.5311 13.6201C12.9009 13.6201 12.5661 13.5367 12.049 13.3309L11.846 13.2433L11.625 14.4768C11.9929 14.6306 12.673 14.7639 13.3791 14.7708C15.0344 14.7708 16.1089 14.032 16.1211 12.8883C16.1271 12.2615 15.7075 11.7844 14.7991 11.3912C14.2487 11.1363 13.9116 10.9662 13.9151 10.7081C13.9151 10.4791 14.2004 10.2342 14.8169 10.2342C15.3319 10.2266 15.7048 10.3336 15.9954 10.4453L16.1365 10.5088L16.35 9.31462Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.257 9.302H19.0466C18.6716 9.302 18.391 9.39938 18.2263 9.75579L15.8999 14.7708H17.5449C17.5449 14.7708 17.8138 14.0964 17.8747 13.9484C18.0545 13.9484 19.6523 13.9507 19.8807 13.9507C19.9276 14.1422 20.0714 14.7708 20.0714 14.7708H21.5249L20.257 9.302ZM18.3255 12.8291C18.4549 12.5138 18.9496 11.2994 18.9496 11.2994C18.9402 11.3139 19.0781 10.9826 19.1572 10.7771L19.2631 11.2489C19.2631 11.2489 19.5631 12.5552 19.6257 12.8291H18.3255Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75361 9.302L6.25488 13.0373L6.09523 12.2781C5.81627 11.403 4.947 10.455 3.9751 9.98045L5.34548 14.7708L6.96514 14.769L9.3751 9.302H7.75361Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95905 9.08325H2.42004L2.3999 9.20038C4.37531 9.6664 5.68233 10.7926 6.2249 12.1458L5.67281 9.55855C5.57742 9.20199 5.30099 9.09565 4.95905 9.08325Z"
        fill="#ED9F2D"
      />
    </svg>
  );
}
