import React from "react";

export default function ReSelfCareSpecialistIcon({
  id,
  className,
  size,
  style,
}) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <circle
        cx="32"
        cy="32"
        r="32"
        fill={`url(#${id}_paint0_linear_5436_18957)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1456 60.7117C46.0948 60.485 46.0433 60.2546 45.9912 60.0213C45.1739 56.3607 44.1941 51.9721 42.8284 49.6895C41.6367 47.6978 38.4055 46.362 35.6556 45.2253C33.7987 44.4577 32.1613 43.7808 31.5195 43.0539C31.9811 39.2616 33.3776 31.4962 35.2716 30.7735C37.6391 29.8702 44.5895 30.9756 46.7701 31.9517C47.8327 32.4273 47.8793 34.8109 47.9255 37.1717C47.9551 38.6867 47.9846 40.1922 48.2822 41.1781L54.8813 54.3707C52.362 56.9471 49.4111 59.0998 46.1456 60.7117Z"
        fill="#D39370"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0001 48.4325L29.3665 46.0684C20.013 37.7044 13.8379 32.1881 13.8379 25.4181C13.8379 19.9018 18.2331 15.5676 23.8271 15.5676C26.9873 15.5676 30.0204 17.0183 32.0001 19.3108C33.9797 17.0183 37.0128 15.5676 40.173 15.5676C45.767 15.5676 50.1622 19.9018 50.1622 25.4181C50.1622 32.1881 43.9871 37.7044 34.6336 46.0863L32.0001 48.4325Z"
        fill={`url(#${id}_paint1_linear_5436_18957)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.5107 59.4175C47.6444 54.6336 46.1077 51.9579 44.9762 49.9876C43.6071 47.6037 42.831 46.2525 44.5533 43.4494C47.7673 38.2184 46.7816 32.5244 46.0315 28.1908C45.4134 24.6204 44.9552 21.9734 47.1375 21.2699L48.5137 22.4343C51.1091 29.4774 51.8478 34.6125 52.3245 37.926C52.5723 39.6481 52.7492 40.8782 53.0792 41.6283C53.5043 42.5946 54.5079 43.849 55.6487 45.2747C56.6906 46.577 57.8469 48.0222 58.7814 49.5216C56.1467 53.5405 52.6318 56.9304 48.5107 59.4175Z"
        fill="#FFD1B8"
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18957`}
          x1="9.08108"
          y1="51.027"
          x2="52.3243"
          y2="8.64865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E5C6" />
          <stop offset="1" stopColor="#6B7BCC" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18957`}
          x1="25.5136"
          y1="33.7298"
          x2="48.4325"
          y2="21.6217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E44035" />
          <stop offset="1" stopColor="#FF9548" />
        </linearGradient>
      </defs>
    </svg>
  );
}
