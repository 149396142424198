import React from "react";

export default function WhatsAppIcon({ className, size }) {
  return (
    <svg
      width={size || 33}
      height={size || 32}
      viewBox="0 0 33 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.503 4H16.497C9.8805 4 4.5 9.382 4.5 16C4.5 18.625 5.346 21.058 6.7845 23.0335L5.289 27.4915L9.9015 26.017C11.799 27.274 14.0625 28 16.503 28C23.1195 28 28.5 22.6165 28.5 16C28.5 9.3835 23.1195 4 16.503 4ZM23.4855 20.9455C23.196 21.763 22.047 22.441 21.1305 22.639C20.5035 22.7725 19.6845 22.879 16.9275 21.736C13.401 20.275 11.13 16.6915 10.953 16.459C10.7835 16.2265 9.528 14.5615 9.528 12.8395C9.528 11.1175 10.4025 10.279 10.755 9.919C11.0445 9.6235 11.523 9.4885 11.982 9.4885C12.1305 9.4885 12.264 9.496 12.384 9.502C12.7365 9.517 12.9135 9.538 13.146 10.0945C13.4355 10.792 14.1405 12.514 14.2245 12.691C14.31 12.868 14.3955 13.108 14.2755 13.3405C14.163 13.5805 14.064 13.687 13.887 13.891C13.71 14.095 13.542 14.251 13.365 14.47C13.203 14.6605 13.02 14.8645 13.224 15.217C13.428 15.562 14.133 16.7125 15.171 17.6365C16.5105 18.829 17.5965 19.21 17.985 19.372C18.2745 19.492 18.6195 19.4635 18.831 19.2385C19.0995 18.949 19.431 18.469 19.7685 17.9965C20.0085 17.6575 20.3115 17.6155 20.6295 17.7355C20.9535 17.848 22.668 18.6955 23.0205 18.871C23.373 19.048 23.6055 19.132 23.691 19.2805C23.775 19.429 23.775 20.1265 23.4855 20.9455Z"
        fill="currentColor"
      />
    </svg>
  );
}
