import React from "react";

export default function LocationIcon({ className, size }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9333 21.3359C12.9333 10.4926 21.604 2 32 2C42.5134 2 51.0667 10.6741 51.0667 21.3359C51.0667 25.3563 49.8547 29.2203 47.5593 32.5111L33.4591 54.8031C32.7794 55.881 31.222 55.8831 30.5409 54.8031L16.5016 32.5867C14.1557 29.1397 12.9333 25.4612 12.9333 21.3359ZM23.3333 21.3359C23.3333 26.182 27.2214 30.125 32 30.125C36.7786 30.125 40.6667 26.182 40.6667 21.3359C40.6667 16.4899 36.7786 12.5469 32 12.5469C27.2214 12.5469 23.3333 16.4899 23.3333 21.3359ZM36.8225 56.2323L45.5505 42.3939C53.2517 44.1965 58 47.5021 58 51.4531C58 58.3041 44.6039 62 32 62C19.3961 62 6 58.3041 6 51.4531C6 47.505 10.7417 44.2011 18.4335 42.3976L27.1758 56.2357C29.4242 59.7927 34.5633 59.8044 36.8225 56.2323Z"
        fill="url(#paint0_linear_13511_19204)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13511_19204"
          x1="6"
          y1="2.12988"
          x2="44.9043"
          y2="76.5118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
