import React from "react";

const HeadlineWithSeparator = ({
  className,
  component: Component = "h3", // default component is h3
  textAlign = "center",
  titleClass,
  separatorClass = "tbk--mt-1.5 lg:tbk--mt-2",
  children,
}) => {
  return (
    <div
      className={`tbk-relative ${
        textAlign === "center" ? "tbk-text-center" : "tbk-text-left"
      } ${className}`}
    >
      <Component
        className={`tbk-inline-block ${
          textAlign === "center" ? "tbk-px-2" : "tbk-pr-2"
        } ${titleClass}`}
      >
        {children}
      </Component>
      <hr className={`tbk-mb-0 tbk-border-blue-grey-light ${separatorClass}`} />
    </div>
  );
};

export default HeadlineWithSeparator;
