import React, {
  createContext,
  useContext,
  /*useReducer, */ useState,
  useEffect,
} from "react";

export function getPhoneCode() {
  const match = window.location.search.match(/phoneCode=([^&]*)/);
  const phoneNumber = match
    ? decodeURIComponent(match[1])
    : window.sessionStorage.getItem("phoneCode");

  return phoneNumber;
}

export function getPhoneNumber() {
  const match = window.location.search.match(/phoneNumber=([^&]*)/);
  const phoneNumber = match
    ? decodeURIComponent(match[1])
    : window.sessionStorage.getItem("phoneNumber");

  return phoneNumber;
}

export function getEmail() {
  const match = window.location.search.match(/email=([^&]*)/);
  const email = match
    ? decodeURIComponent(match[1])
    : window.sessionStorage.getItem("email");

  return email;
}

export const StateContext = createContext(null);

export default function SignUpStateProvider({ children, ...rest }) {
  const [authState, setAuthState] = useState("");
  const [isAppleSignIn, setIsAppleSignIn] = useState(false);
  const [authVendor, setAuthVendor] = useState();
  const [hasAuthError, setHasAuthError] = useState(false);

  const [userPhoneCode, setUserPhoneCode] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userFamilyName, setUserFamilyName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [identityToken, setIdentityToken] = useState();
  const [authorizationCode, setAuthorizationCode] = useState();
  const [accessToken, setAccessToken] = useState("");
  const [migrationCode, setMigrationCode] = useState("");

  let contextValue = {
    authState,
    setAuthState,
    isAppleSignIn,
    setIsAppleSignIn,
    authVendor,
    setAuthVendor,
    hasAuthError,
    setHasAuthError,
    userPhoneCode,
    setUserPhoneCode,
    userPhoneNumber,
    setUserPhoneNumber,
    userPassword,
    setUserPassword,
    userFirstName,
    setUserFirstName,
    userFamilyName,
    setUserFamilyName,
    userEmail,
    setUserEmail,
    resetCode,
    setResetCode,
    identityToken, // For Apple Sign-in and Google Sign-in
    setIdentityToken,
    authorizationCode, // For Apple Sign-in
    setAuthorizationCode,
    accessToken,
    setAccessToken, // For Facebook login
    migrationCode,
    setMigrationCode,
  };

  useEffect(() => {
    // const userPhoneCode = query.get("authState");
    // const userPhoneNumber = query.get("authState");

    // Will get from `search` automatically
    const phoneCode = getPhoneCode();
    const phoneNumber = getPhoneNumber();
    const email = getEmail();

    if (phoneCode && phoneNumber) {
      setUserPhoneCode(phoneCode);
      setUserPhoneNumber(phoneNumber);
    }

    if (email) {
      setUserEmail(email);
    }

    // From Coach Profile page
    const query = new URLSearchParams(window.location.search);

    const authState = query.get("authState");

    if (authState) {
      setAuthState(authState);
    }
  }, []);

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
      }}
    >
      {React.cloneElement(children, { ...rest })}
    </StateContext.Provider>
  );
}

export function useSignUpState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error(
      "useSignUpState must be used within the SignUpStateProvider"
    );
  }
  return context;
}
