import React from "react";

import MovingBar from "../MovingBar/MovingBar";

import arthaLogo from "./artha-logo.png";
import arthaLogo2x from "./artha-logo@2x.png";
import arthaLogo3x from "./artha-logo@3x.png";
import onLogo from "./on-logo.png";
import onLogo2x from "./on-logo@2x.png";
import onLogo3x from "./on-logo@3x.png";
import pendryLogo from "./pendry-logo.png";
import pendryLogo2x from "./pendry-logo@2x.png";
import pendryLogo3x from "./pendry-logo@3x.png";
import highlightLogo from "./highlight-logo.png";
import highlightLogo2x from "./highlight-logo@2x.png";
import highlightLogo3x from "./highlight-logo@3x.png";
import technogymLogo from "./technogym-logo.png";
import technogymLogo2x from "./technogym-logo@2x.png";
import technogymLogo3x from "./technogym-logo@3x.png";
import neueHouseLogo from "./neue-house-logo.png";
import neueHouseLogo2x from "./neue-house-logo@2x.png";
import neueHouseLogo3x from "./neue-house-logo@3x.png";
import editionLogo from "./edition-logo.png";
import editionLogo2x from "./edition-logo@2x.png";
import editionLogo3x from "./edition-logo@3x.png";
import thePartLogo from "./the-park-logo.png";
import thePartLogo2x from "./the-park-logo@2x.png";
import thePartLogo3x from "./the-park-logo@3x.png";
import platformLogo from "./platform-logo.png";
import platformLogo2x from "./platform-logo@2x.png";
import platformLogo3x from "./platform-logo@3x.png";
import mossLogo from "./moss-logo.png";
import mossLogo2x from "./moss-logo@2x.png";
import mossLogo3x from "./moss-logo@3x.png";
import springPlaceLogo from "./spring-place-logo.png";
import springPlaceLogo2x from "./spring-place-logo@2x.png";
import springPlaceLogo3x from "./spring-place-logo@3x.png";
import oneHotelLogo from "./one-hotel-logo.png";
import oneHotelLogo2x from "./one-hotel-logo@2x.png";
import oneHotelLogo3x from "./one-hotel-logo@3x.png";

const logoImages = [
  {
    url: onLogo3x,
    height: "76px",
  },
  {
    url: pendryLogo3x,
    height: "54px",
  },
  {
    url: highlightLogo3x,
    height: "49px",
  },
  {
    url: technogymLogo3x,
    height: "51px",
  },
  {
    url: neueHouseLogo3x,
    height: "39px",
  },
  {
    url: editionLogo3x,
    height: "55px",
  },
  {
    url: thePartLogo3x,
    height: "52px",
  },
  {
    url: arthaLogo3x,
    height: "47px",
  },
  {
    url: platformLogo3x,
    height: "44px",
  },
  {
    url: mossLogo3x,
    height: "29px",
  },
  {
    url: springPlaceLogo3x,
    height: "99px",
  },
  {
    url: oneHotelLogo3x,
    height: "80px",
  },
];

const LogoBar = () => {
  return (
    <div className="user-select-none tb-moving-bar tb-moving-logobar tbk-mb-3 tbk-flex tbk-w-full tbk-overflow-hidden">
      <MovingBar
        images={logoImages}
        className="tb-moving-logobar tbk-h-8"
        imgClassName="tbk-max-h-full tbk-h-8_ tbk-min-w-0"
      />
      <MovingBar
        images={logoImages}
        className="tb-moving-logobar tbk-h-8"
        imgClassName="tbk-max-h-full tbk-h-8_ tbk-min-w-0"
      />
    </div>
  );
};

export default LogoBar;
