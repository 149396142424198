import React from "react";

export default function Pound(props) {
  return (
    <svg
      width="30"
      height="40"
      viewBox="0 0 30 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.0352 28.0269C28.8662 27.8467 28.65 27.7568 28.3871 27.7568H23.8206C23.5571 27.7568 23.3409 27.8467 23.1719 28.0269C23.003 28.2071 22.9186 28.4198 22.9186 28.666V33.808H11.2492V23.0406H19.846C20.0904 23.0406 20.3018 22.9507 20.4803 22.7709C20.6589 22.5911 20.7481 22.3779 20.7481 22.1317V18.4097C20.7481 18.1444 20.6589 17.9268 20.4803 17.7563C20.3018 17.5862 20.0904 17.5008 19.846 17.5008H11.2492V11.3926C11.2492 9.95356 11.7376 8.78894 12.7147 7.89837C13.6921 7.00819 14.9791 6.5632 16.5762 6.5632C17.4219 6.5632 18.2959 6.73385 19.1977 7.07486C20.0999 7.41556 20.7481 7.71846 21.1427 7.98394C21.5373 8.24882 21.7821 8.42923 21.8756 8.52366C22.0634 8.69391 22.2794 8.76018 22.5239 8.72257C22.7683 8.70337 22.9746 8.58993 23.1438 8.38147L26.0471 4.77309C26.198 4.58373 26.2632 4.37059 26.2443 4.13396C26.2255 3.89714 26.1315 3.7033 25.9626 3.55166C23.1445 1.18412 19.9967 0 16.5201 0C12.9312 0 9.9759 1.05138 7.65522 3.15363C5.33444 5.25619 4.1741 7.92653 4.1741 11.1653V17.5005H1.49633C1.23332 17.5005 1.01711 17.5859 0.84799 17.756C0.678673 17.9264 0.594262 18.144 0.594262 18.4095V22.1311C0.594262 22.3769 0.678673 22.5897 0.84799 22.7699C1.01711 22.95 1.23332 23.0399 1.49633 23.0399H4.1741V33.9204H1.43996C1.19571 33.9204 0.984135 34.0106 0.805834 34.1904C0.627237 34.3706 0.538086 34.5837 0.538086 34.8296V39.0909C0.538086 39.3565 0.622399 39.5738 0.791617 39.7447C0.960736 39.9146 1.17695 40 1.43996 40H28.3871C28.6503 40 28.8665 39.9146 29.0352 39.7447C29.2041 39.5737 29.2881 39.3565 29.2881 39.0909V28.665C29.2886 28.4194 29.2041 28.2057 29.0352 28.0269Z"
        fill="url(#paint0_linear_318_4343)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4343"
          x1="0.538086"
          y1="0.0865855"
          x2="29.0334"
          y2="45.2689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
