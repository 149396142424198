/* global google */
import React from "react";
import PropTypes from "prop-types";

import { contextTypes as googleMapsContextTypes } from "../GoogleMaps";

import { isFunction, has, lowerFirst } from "../../../utils/_";

const propTypes = {
  /**
   * For the 2nd argument of `MarkerCluster#addMarker`
   * @see https://github.com/mikesaidani/marker-clusterer-plus
   */
  noRedraw: PropTypes.bool,

  /**
   * @type Animation
   */
  defaultAnimation: PropTypes.any,

  /**
   * @type boolean
   */
  defaultClickable: PropTypes.bool,

  /**
   * @type string
   */
  defaultCursor: PropTypes.string,

  /**
   * @type boolean
   */
  defaultDraggable: PropTypes.bool,

  /**
   * @type string|Icon|Symbol
   */
  defaultIcon: PropTypes.any,

  /**
   * @type string|MarkerLabel
   */
  defaultLabel: PropTypes.any,

  /**
   * @type number
   */
  defaultOpacity: PropTypes.number,

  /**
   * @type MarkerOptions
   */
  defaultOptions: PropTypes.any,

  /**
   * @type MarkerPlace
   */
  defaultPlace: PropTypes.any,

  /**
   * @type LatLng|LatLngLiteral
   */
  defaultPosition: PropTypes.any,

  /**
   * @type MarkerShape
   */
  defaultShape: PropTypes.any,

  /**
   * @type string
   */
  defaultTitle: PropTypes.string,

  /**
   * @type boolean
   */
  defaultVisible: PropTypes.bool,

  /**
   * @type number
   */
  defaultZIndex: PropTypes.number,

  /**
   * @type Animation
   */
  animation: PropTypes.any,

  /**
   * @type boolean
   */
  clickable: PropTypes.bool,

  /**
   * @type string
   */
  cursor: PropTypes.string,

  /**
   * @type boolean
   */
  draggable: PropTypes.bool,

  /**
   * @type string|Icon|Symbol
   */
  icon: PropTypes.any,

  /**
   * @type string|MarkerLabel
   */
  label: PropTypes.any,

  /**
   * @type number
   */
  opacity: PropTypes.number,

  /**
   * @type MarkerOptions
   */
  options: PropTypes.any,

  /**
   * @type MarkerPlace
   */
  place: PropTypes.any,

  /**
   * @type LatLng|LatLngLiteral
   */
  position: PropTypes.any,

  /**
   * @type MarkerShape
   */
  shape: PropTypes.any,

  /**
   * @type string
   */
  title: PropTypes.string,

  /**
   * @type boolean
   */
  visible: PropTypes.bool,

  /**
   * @type number
   */
  zIndex: PropTypes.number,

  /**
   * function
   */
  onDblClick: PropTypes.func,

  /**
   * function
   */
  onDragEnd: PropTypes.func,

  /**
   * function
   */
  onDragStart: PropTypes.func,

  /**
   * function
   */
  onMouseDown: PropTypes.func,

  /**
   * function
   */
  onMouseOut: PropTypes.func,

  /**
   * function
   */
  onMouseOver: PropTypes.func,

  /**
   * function
   */
  onMouseUp: PropTypes.func,

  /**
   * function
   */
  onRightClick: PropTypes.func,

  /**
   * function
   */
  onAnimationChanged: PropTypes.func,

  /**
   * function
   */
  onClick: PropTypes.func,

  /**
   * function
   */
  onClickableChanged: PropTypes.func,

  /**
   * function
   */
  onCursorChanged: PropTypes.func,

  /**
   * function
   */
  onDrag: PropTypes.func,

  /**
   * function
   */
  onDraggableChanged: PropTypes.func,

  /**
   * function
   */
  onFlatChanged: PropTypes.func,

  /**
   * function
   */
  onIconChanged: PropTypes.func,

  /**
   * function
   */
  onPositionChanged: PropTypes.func,

  /**
   * function
   */
  onShapeChanged: PropTypes.func,

  /**
   * function
   */
  onTitleChanged: PropTypes.func,

  /**
   * function
   */
  onVisibleChanged: PropTypes.func,

  /**
   * function
   */
  onZindexChanged: PropTypes.func,
};

/**
 * A wrapper around `google.maps.Marker`
 *
 * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Marker
 */
export class Marker extends React.PureComponent {
  static propTypes = propTypes;

  static contextTypes = {
    ...googleMapsContextTypes,
    // map: PropTypes.object,
  };

  static childContextTypes = {
    anchor: PropTypes.object,
  };

  applyUpdaterToNextProps(prevProps, nextProps, instance) {
    Object.keys(updaterMap).forEach((key) => {
      const fn = updaterMap[key];
      const nextValue = nextProps[key];

      if (nextValue !== prevProps[key]) {
        fn(instance, nextValue);
      }
    });
  }

  /*
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Marker
   */
  constructor(props, context) {
    super(props, context);

    const marker = new google.maps.Marker();

    // eslint-disable-next-line
    const { nextProps } = Object.keys(propTypes).reduce(
      (acc, value /*, key*/) => {
        const key = value;

        if (has(acc.prevProps, key)) {
          const match = key.match(/^default(\S+)/);

          if (match) {
            const unprefixedKey = lowerFirst(match[1]);

            if (!has(acc.nextProps, unprefixedKey)) {
              acc.nextProps[unprefixedKey] = acc.prevProps[key];
            }
          } else {
            acc.nextProps[key] = acc.prevProps[key];
          }
        }

        return acc;
      },
      {
        nextProps: {},
        prevProps: this.props,
      }
    );

    this.applyUpdaterToNextProps(
      {
        /* empty prevProps for construct */
      },
      nextProps,
      marker
    );

    this.registerEvents = this.registerEvents.bind(this);

    marker.setMap(this.context.map);

    this.state = {
      marker: marker,
    };
  }

  getChildContext() {
    return {
      anchor: this.context.anchor || this.state.marker,
    };
  }

  componentDidMount() {
    this.registerEvents();
  }

  componentDidUpdate(prevProps) {
    this.unregisterAllEvents();

    this.applyUpdaterToNextProps(prevProps, this.props, this.state.marker);

    this.registerEvents();
  }

  componentWillUnmount() {
    const { marker } = this.state;

    this.unregisterAllEvents();

    if (marker) {
      marker.setMap(null);
    }
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }

  registerEvents() {
    const registeredList = Object.keys(eventMap).reduce((acc, value) => {
      const googleEventName = eventMap[value];
      const onEventName = value;

      if (isFunction(this.props[onEventName])) {
        acc.push(
          google.maps.event.addListener(
            this.state.marker,
            googleEventName,
            this.props[onEventName]
          )
        );
      }
      return acc;
    }, []);

    this.unregisterAllEvents = () => {
      registeredList.forEach(unregisterEvent);
    };
  }

  /**
   *
   * @type Animation
   * @public
   */
  getAnimation() {
    return this.state.marker.getAnimation();
  }

  /**
   *
   * @type boolean
   * @public
   */
  getClickable() {
    return this.state.marker.getClickable();
  }

  /**
   *
   * @type string
   * @public
   */
  getCursor() {
    return this.state.marker.getCursor();
  }

  /**
   *
   * @type boolean
   * @public
   */
  getDraggable() {
    return this.state.marker.getDraggable();
  }

  /**
   *
   * @type string|Icon|Symbol
   * @public
   */
  getIcon() {
    return this.state.marker.getIcon();
  }

  /**
   *
   * @type MarkerLabel
   * @public
   */
  getLabel() {
    return this.state.marker.getLabel();
  }

  /**
   *
   * @type number
   * @public
   */
  getOpacity() {
    return this.state.marker.getOpacity();
  }

  /**
   *
   * @type MarkerPlace
   * @public
   */
  getPlace() {
    return this.state.marker.getPlace();
  }

  /**
   *
   * @type LatLng
   * @public
   */
  getPosition() {
    return this.state.marker.getPosition();
  }

  /**
   *
   * @type MarkerShape
   * @public
   */
  getShape() {
    return this.state.marker.getShape();
  }

  /**
   *
   * @type string
   * @public
   */
  getTitle() {
    return this.state.marker.getTitle();
  }

  /**
   *
   * @type boolean
   * @public
   */
  getVisible() {
    return this.state.marker.getVisible();
  }

  /**
   *
   * @type number
   * @public
   */
  getZIndex() {
    return this.state.marker.getZIndex();
  }
}

export default Marker;

const eventMap = {
  onDblClick: "dblclick",
  onDragEnd: "dragend",
  onDragStart: "dragstart",
  onMouseDown: "mousedown",
  onMouseOut: "mouseout",
  onMouseOver: "mouseover",
  onMouseUp: "mouseup",
  onRightClick: "rightclick",
  onAnimationChanged: "animation_changed",
  onClick: "click",
  onClickableChanged: "clickable_changed",
  onCursorChanged: "cursor_changed",
  onDrag: "drag",
  onDraggableChanged: "draggable_changed",
  onFlatChanged: "flat_changed",
  onIconChanged: "icon_changed",
  onPositionChanged: "position_changed",
  onShapeChanged: "shape_changed",
  onTitleChanged: "title_changed",
  onVisibleChanged: "visible_changed",
  onZindexChanged: "zindex_changed",
};

const updaterMap = {
  animation(instance, animation) {
    instance.setAnimation(animation);
  },

  clickable(instance, clickable) {
    instance.setClickable(clickable);
  },

  cursor(instance, cursor) {
    instance.setCursor(cursor);
  },

  draggable(instance, draggable) {
    instance.setDraggable(draggable);
  },

  icon(instance, icon) {
    instance.setIcon(icon);
  },

  label(instance, label) {
    instance.setLabel(label);
  },

  opacity(instance, opacity) {
    instance.setOpacity(opacity);
  },

  options(instance, options) {
    instance.setOptions(options);
  },

  place(instance, place) {
    instance.setPlace(place);
  },

  position(instance, position) {
    instance.setPosition(position);
  },

  shape(instance, shape) {
    instance.setShape(shape);
  },

  title(instance, title) {
    instance.setTitle(title);
  },

  visible(instance, visible) {
    instance.setVisible(visible);
  },

  zIndex(instance, zIndex) {
    instance.setZIndex(zIndex);
  },
};

function unregisterEvent(registered) {
  google.maps.event.removeListener(registered);
}
