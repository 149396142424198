import React from "react";

export default function CheckboxOIcon({ width, height, className }) {
  return (
    <svg
      width={width || 33}
      height={height || 32}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="0.5" width="32" height="32" rx="16" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3 10.6667L24.5 12.9167L14.9667 22.6667L9.83333 17.4167L12.0333 15.1667L14.9667 18.1667L22.3 10.6667Z"
        fill="white"
      />
    </svg>
  );
}
