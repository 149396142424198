import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppState } from "../state";

// Hooks
import useUserLocation from "../hooks/useUserLocation/useUserLocation";

// Utils
import { selectRegionBasedOnUserLanguages } from "../utils/locale";

const RegionContext = createContext();

export function useRegion() {
  return useContext(RegionContext);
}

const initialRegion = window.location.pathname.startsWith("/us") ? "US" : "GB";

export function RegionProvider({ children }) {
  const { user, hasTriedToAutoLogin } = useAppState();
  const { userLocation } = useUserLocation();

  const [region, setRegion] = useState(initialRegion);

  // Determine the initial region based on user status and userRegion
  const userRegion = useMemo(() => {
    if (user && hasTriedToAutoLogin) {
      return user.region;
    }
    if (userLocation) {
      return userLocation;
    }
    return window.location.pathname.startsWith("/us") ? "US" : "GB";
  }, [user, hasTriedToAutoLogin, userLocation]);

  // Update region only after hasTriedToAutoLogin is true
  useEffect(() => {
    setRegion(userRegion /*user.region*/);
    localStorage.setItem("userRegion", userRegion /*user.region*/);
  }, [userRegion]);
  //}, [/*userRegion, */ hasTriedToAutoLogin]);

  const value = {
    region,
    setRegion,
  };

  return (
    <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
  );
}
