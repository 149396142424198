/**
 * Country Flag component.
 * For mobile devices shows native icons, on other devices SVG icons.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/06/21
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useMemo } from "react";

import { isAndroidDevice, isIosDevice, isMac } from "../../utils";
import { COUNTRY_PHONE_CODES_WITH_FLAGS } from "../../constants";

export const CountryFlag = ({ region }) => {
  const countryInfo = useMemo(
    () => COUNTRY_PHONE_CODES_WITH_FLAGS.find((v) => v.code === region),
    [region]
  );

  return (
    <>
      {isAndroidDevice || isMac || isIosDevice ? (
        countryInfo.emoji
      ) : (
        <img src={countryInfo.image} className="tbk-w-4" />
      )}
    </>
  );
};
