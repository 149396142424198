import React from "react";

export default function EmailIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#303740"
        d="M35.2 14H12.8c-1.54 0-2.786 1.238-2.786 2.75L10 33.25c0 1.513 1.26 2.75 2.8 2.75h22.4c1.54 0 2.8-1.237 2.8-2.75v-16.5c0-1.512-1.26-2.75-2.8-2.75zm0 19.25H12.8V19.5L24 26.375 35.2 19.5v13.75zM24 23.625L12.8 16.75h22.4L24 23.625z"
      />
    </svg>
  );
}
