import React from "react";

// Icons
import TimeIcon from "../../../icons/TimeIcon";

export const MigrationBox = () => (
  <div className="tbk-mb-2 tbk-flex tbk-flex-col tbk-rounded-2xl tbk-bg-basic-white tbk-p-2 tbk-shadow-trube md:tbk-p-3 lg:tbk-mb-0">
    <div className="tbk-mb-2 tbk-flex tbk-items-center">
      <TimeIcon
        size={32}
        className="tbk-mr-3 tbk-shrink-0 tbk-text-blue-grey"
      />
      <div className="tbk-text-h3-subtitle md:tbk-text-title-bold tbk-text-primary">
        Migrating Your Account ...
      </div>
    </div>
    <div className="tbk-text-main tbk-mb-5 tbk-pb-1 tbk-text-primary lg:tbk-mb-1">
      We updated TruBe services and currently migrating your bookings and
      personal information from the old account. Please, revisit the page in
      5-10 minutes.
    </div>
  </div>
);

export default MigrationBox;
