import React from "react";

export default function CloseIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-inline-block tbk-align-text-bottom tbk-shrink-0${
        className ? " " + className : ""
      }`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 13.75L6.5 19L5 17.5L10 12.5L5 7.5L6.5 6L11.75 11.25L17 6L18.5 7.5L13.5 12.5L18.5 17.5L17 19L11.75 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
