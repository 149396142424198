import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAppState } from "../state";

const PrevStateContext = createContext();

export function usePrevState() {
  return useContext(PrevStateContext);
}

export function PrevStateProvider({ children }) {
  const history = useHistory();

  const [prevState, setPrevState] = useState([]);
  const [prevLocation, setPrevLocation] = useState(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH") {
        setPrevState((prev) => {
          const newPrevState = [prevLocation, ...prev];
          return newPrevState.slice(0, 2);
        });
        setPrevLocation(location.pathname);
      } else if (action === "POP") {
        setPrevState((prev) => {
          if (prev[0] === prevLocation) {
            return prev.slice(1);
          } else {
            return [prevLocation, ...prev].slice(0, 2);
          }
        });
        setPrevLocation(location.pathname);
      }
    });

    return unlisten;
  }, [history, prevLocation]);

  const value = {
    prevState,
  };

  return (
    <PrevStateContext.Provider value={value}>
      {children}
    </PrevStateContext.Provider>
  );
}
