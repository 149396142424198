import React from "react";

export default function DefaultProfilePic({ size, className }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <circle cx="16" cy="16" r="16" fill="#72879A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6409 27.9487C23.8133 30.4687 20.0854 32 15.9998 32C11.9142 32 8.18631 30.4687 5.35869 27.9488C7.30956 25.4325 12.9111 24.1667 15.9998 24.1667C19.0886 24.1667 24.69 25.4325 26.6409 27.9487ZM21.6665 15.6667C21.6665 18.7975 19.1307 21.3333 15.9998 21.3333C12.869 21.3333 10.3332 18.7975 10.3332 15.6667C10.3332 12.5358 12.869 10 15.9998 10C19.1307 10 21.6665 12.5358 21.6665 15.6667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30.6667C24.1002 30.6667 30.6667 24.1002 30.6667 16C30.6667 7.89982 24.1002 1.33333 16 1.33333C7.89982 1.33333 1.33333 7.89982 1.33333 16C1.33333 24.1002 7.89982 30.6667 16 30.6667ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#72879A"
      />
    </svg>
  );
}
