import React, { useCallback } from "react";

const BarImg = ({ className, style, imgUrl, index, onClick }) => {
  const handleClick = useCallback(() => {
    onClick && onClick(index);
  }, [index]);

  return (
    <img
      src={imgUrl}
      className={`${className} tbk-cursor-pointer tbk-object-cover`}
      style={style}
      onClick={handleClick}
    />
  );
};

const MovingBar = ({ className, imgClassName, images, onClick }) => (
  <div
    className={`${
      className ? className : ""
    } tb-moving-bar tbk-justify-space-around tbk-flex tbk-min-w-full tbk-shrink-0 tbk-animate-move-left tbk-items-center`}
  >
    {images.map(({ url, height }, index) => (
      <BarImg
        className={imgClassName}
        style={{ height }}
        imgUrl={url}
        index={index}
        onClick={onClick}
      />
    ))}
  </div>
);

export default MovingBar;
