import React from "react";

export default function CaretUpIcon({ size, className }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? `${className} ` : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3335 21.3334L25.3335 19.3334L16.6668 10.6667L8.00016 19.3334L10.0002 21.3334L16.6668 14.6667L23.3335 21.3334Z"
        fill="currentColor"
      />
    </svg>
  );
}
