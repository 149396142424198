import React from "react";

export default function Apple({ className }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        d="M15.6358 2C15.6751 2 15.7144 2 15.7559 2C15.8523 3.19123 15.3977 4.08131 14.8451 4.72587C14.3029 5.36599 13.5604 5.98682 12.3595 5.89262C12.2794 4.71846 12.7348 3.89439 13.2867 3.25131C13.7985 2.65198 14.7368 2.11868 15.6358 2Z"
        fill="white"
      />
      <path
        d="M19.271 14.3988C19.271 14.4107 19.271 14.4211 19.271 14.4322C18.9336 15.4543 18.4522 16.3303 17.8647 17.1433C17.3284 17.8813 16.6713 18.8745 15.4978 18.8745C14.4839 18.8745 13.8104 18.2225 12.7712 18.2047C11.672 18.1869 11.0675 18.7499 10.0624 18.8915C9.94744 18.8915 9.83247 18.8915 9.71973 18.8915C8.9817 18.7847 8.38609 18.2002 7.95217 17.6736C6.67268 16.1174 5.68395 14.1073 5.5 11.535C5.5 11.2828 5.5 11.0314 5.5 10.7792C5.57788 8.93819 6.47241 7.44137 7.66141 6.71596C8.28892 6.33025 9.15156 6.00167 10.1121 6.14853C10.5238 6.21232 10.9443 6.35325 11.313 6.49269C11.6623 6.62695 12.0992 6.86505 12.5131 6.85244C12.7935 6.84428 13.0724 6.69816 13.355 6.59505C14.1827 6.29614 14.9942 5.95345 16.0638 6.11441C17.3492 6.30874 18.2615 6.87988 18.8253 7.76106C17.7379 8.4531 16.8782 9.49598 17.0251 11.2769C17.1556 12.8946 18.0961 13.8411 19.271 14.3988Z"
        fill="white"
      />
    </svg>
  );
}
