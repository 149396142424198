import React from "react";

export default function CopyIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.727 6.462h-1.272V5.23c0-.677-.573-1.231-1.273-1.231h-8.91C5.573 4 5 4.554 5 5.23v11.078c0 .677.573 1.23 1.273 1.23h1.272v1.231c0 .677.573 1.231 1.273 1.231h8.91c.7 0 1.272-.554 1.272-1.23V7.691c0-.677-.573-1.23-1.273-1.23zM7.545 7.692v8.616H6.273V5.23h8.909v1.23H8.818c-.7 0-1.273.554-1.273 1.231zM17.727 18.77H8.818V7.692h8.91V18.77z"
        fill="currentColor"
      />
    </svg>
  );
}
