import React from "react";

export default function MobilePhoneIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        d="M18.3212 15.3501L16.2886 13.93C16.0228 13.7446 15.7122 13.6469 15.39 13.6469C14.8777 13.6469 14.3968 13.8981 14.1039 14.3185L13.6321 14.9947C12.8413 14.4644 11.9589 13.7238 11.1177 12.8828C10.2766 12.0417 9.53623 11.1593 9.00602 10.3685L9.68191 9.89668C10.0265 9.65678 10.2566 9.29738 10.3296 8.88498C10.4025 8.47288 10.3106 8.05649 10.0704 7.7118L8.6506 5.6792C8.35371 5.25455 7.87467 5.00085 7.36897 5.00085C7.19371 5.00085 7.02183 5.0318 6.85821 5.09216C6.67239 5.16079 6.49928 5.24505 6.32954 5.35167L6.04888 5.54929C5.97872 5.60383 5.91346 5.66358 5.85095 5.72608C5.50856 6.06832 5.26559 6.50156 5.12848 7.01384C4.54343 9.20699 5.99281 12.5234 8.73501 15.2656C11.0378 17.5684 13.8044 18.999 15.9548 18.9993H15.955C16.3233 18.9993 16.6704 18.9564 16.9869 18.8718C17.4992 18.7348 17.9324 18.4919 18.275 18.1493C18.3372 18.0871 18.3966 18.0219 18.4603 17.9394L18.6581 17.6573C18.7548 17.5028 18.8389 17.3297 18.9084 17.1425C19.1476 16.496 18.9061 15.7589 18.3212 15.3501Z"
        fill="currentColor"
      />
    </svg>
  );
}
