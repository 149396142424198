import { useState, useEffect, useMemo } from "react";

// Used to memoize the ref provided
export default function useForwardRef(ref, initialRef) {
  const [_ref, setRef] = useState(initialRef);

  function attachRef(ref, value) {
    if (value === undefined) {
      return;
    }

    if (typeof ref == "function") {
      ref(value);
    } else {
      if (ref) {
        ref.current = value; // value equals _ref
      }
    }
  }

  useEffect(() => {
    setRef(initialRef);
  }, [initialRef, setRef]);

  useEffect(() => {
    /*!*/ attachRef(ref, _ref);
  }, [_ref, ref]);

  return useMemo(
    () => (initialRef !== undefined ? [undefined, undefined] : [_ref, setRef]),
    [initialRef, _ref]
  );
}
