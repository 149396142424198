// __d((function(g, r, i, a, m, e, d) {
//     "use strict";
//     Object.defineProperty(e, "__esModule", {
//         value: !0
//     }),

export default function getScrollOffset(
  scrollingContainer_l,
  targetElement_s,
  direction_c,
  spaceBetweenItems_f = 0
) {
  if (direction_c === "rtl" /*t.Direction.RTL*/) {
    const func_n_res_t = func_n();

    if (func_n_res_t === globalScrollType_o.POSITIVE) {
      return (
        scrollingContainer_l.scrollWidth -
        scrollingContainer_l.clientWidth +
        targetElement_s.offsetLeft
      );
    }

    if (func_n_res_t === globalScrollType_o.REVERSE) {
      return -1 * targetElement_s.offsetLeft - spaceBetweenItems_f;
    }
  }

  return targetElement_s.offsetLeft + spaceBetweenItems_f;
}

// getScrollOffset.ScrollType = undefined;

// var t = r(d[0]);
let globalScrollType_o = {},
  global_l;

function func_n() {
  if (global_l !== undefined) {
    return global_l;
  }

  const el_t = document.createElement("div");

  el_t.appendChild(document.createTextNode("test"));
  el_t.dir = "rtl";
  el_t.classList.add("notranslate");
  el_t.style.fontSize = "14px";
  el_t.style.height = "1px";
  el_t.style.overflow = "scroll";
  el_t.style.position = "absolute";
  el_t.style.top = "-1000px";
  el_t.style.width = "4px";

  document.body.appendChild(el_t);

  global_l = globalScrollType_o.REVERSE;

  if (el_t.scrollLeft > 0) {
    global_l = globalScrollType_o.POSITIVE;
  } else {
    el_t.scrollLeft = 3;

    if (el_t.scrollLeft !== 3) {
      global_l = globalScrollType_o.NEGATIVE;
    }
  }

  document.body.removeChild(el_t);

  return global_l;
}

getScrollOffset.ScrollType = globalScrollType_o;

(function (t) {
  t.POSITIVE = 0;
  t.NEGATIVE = 1;
  t.REVERSE = 2;

  t[0] = "POSITIVE";
  t[1] = "NEGATIVE";
  t[2] = "REVERSE";
})(globalScrollType_o || getScrollOffset.ScrollType);

// ), "792c84", ["8aeb78"]);
