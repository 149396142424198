import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types"; // use directly
import Lottie from "react-lottie";
import { FormattedMessage } from "react-intl";

import { useAppState } from "../../state";

// Components
import LocationPicker from "../LocationPicker/LocationPicker";
// import ExclamationMarkOIcon from "../../icons/ExclamationMarkOIcon";
import GymBadge from "./GymBadge/GymBadge";
import Button from "../Button/Button";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Icons
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import BlockerIcon from "../../icons/BlockerIcon";

// Utils
import {
  clientWidth,
  getLastUsedLocation,
  getLastUsedLocationStorageKey,
  getPartnerPictureUrl,
  isMobile,
} from "../../utils";
import {
  // LAST_USED_LOCATION_STORAGE_KEY,
  LOCATION_PICKER_RECENT_LOCATIONS,
} from "../../constants";

// Images
import noResultsIcon from "../../images/no-results-icon.png";
import noResultsIcon2x from "../../images/no-results-icon@2x.png";

import animationData from "../../lotties/loading-spinner";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const HOUR_IN_MS = 3600000;
const DEFAULT_LOCATION_SELECTOR_HEADER_HEIGHT = 70;
const DEFAULT_LOCATION_SELECTOR_HEADER_HEIGHT_DESKTOP = 36; //62;

const getTopRecentItems = (items) => {
  if (!items) {
    return [];
  }

  const locationItemsCount = isMobile
    ? LOCATION_PICKER_RECENT_LOCATIONS.LIST_COUNT_MOBILE
    : LOCATION_PICKER_RECENT_LOCATIONS.LIST_COUNT_DESKTOP;

  const locationItems = items.filter(
    (item) =>
      item.frequency >= LOCATION_PICKER_RECENT_LOCATIONS.ELIGIBLE_FREQUENCY
  );

  if (!locationItems || locationItems.length === 0) {
    return [];
  }

  locationItems.sort((itemA, itemB) => {
    // Sort all frequent items in descending order of frequency
    // and then by lastAccessedOn with recent most first
    if (itemA.frequency !== itemB.frequency) {
      return itemB.frequency - itemA.frequency;
    } else if (itemA.lastAccessedOn !== itemB.lastAccessedOn) {
      return itemB.lastAccessedOn - itemA.lastAccessedOn;
    }

    return 0;
  });

  return locationItems.slice(0, locationItemsCount); // take(locationItems, locationItemsCount);
};

// Get recent location from localstorage
const getRecentItems = (storageKey) => (dispatch) => {
  const storedRecentItems = JSON.parse(localStorage.getItem(storageKey));

  if (storedRecentItems) {
    return getTopRecentItems(storedRecentItems);
  }

  return [];
};

const CoachesFound = ({ availablePartners, onCloseClick }) => (
  <div className="tbk-p-2 tbk-text-center sm:tbk-mt-[10px]">
    <div className="_sm:tbk-py-0.5 tbk-mb-2 tbk-inline-flex tbk-w-68 tbk-justify-end tbk-py-1 sm:tbk-mb-1.5">
      <div className="tbk-mr-2 tbk-flex tbk-w-8 tbk-items-center tbk-justify-end">
        {availablePartners.slice(0, 4).map((partner, index) => (
          <div
            key={partner.id}
            className={`tbk-relative tbk-min-w-0 tbk-max-w-[40px] tbk-shrink-0 tbk-grow tbk-pt-5`}
          >
            <img
              className="tbk-absolute tbk-top-0 tbk-right-0 tbk-h-5 tbk-w-5 tbk-max-w-none tbk-overflow-hidden tbk-rounded-full tbk-border tbk-border-solid tbk-border-basic-white tbk-bg-grey-light tbk-object-cover tbk-shadow-trube"
              src={getPartnerPictureUrl(partner)}
              alt={partner.firstName}
              width="40px"
              height="40px"
            />
          </div>
        ))}
      </div>
      <div className="tbk-text-left">
        <div className="tbk-text-main tbk-text-blue">
          {availablePartners.length}{" "}
          <FormattedMessage id="trainer" defaultMessage="trainer" />
          {availablePartners.length > 1 || availablePartners.length === 0
            ? "es"
            : ""}{" "}
          found
        </div>
        <div className="tbk-text-main tbk-text-blue">in this location</div>
      </div>
    </div>
    <Button
      className="tbk-mx-auto tbk-mb-2 md:tbk-w-1/2"
      onClick={onCloseClick}
    >
      <span className="tbk-whitespace-nowrap">
        Show <FormattedMessage id="Trainers" defaultMessage="Trainers" />
      </span>
    </Button>
  </div>
);

const ApplyLocationButton = ({ disabled, onApplyLocationClick }) => (
  <Button
    size="md"
    className="tbk--mt-1 tbk-mb-2 tbk-w-full"
    disabled={disabled}
    onClick={onApplyLocationClick}
  >
    Apply
  </Button>
);

const NoCoachesFound = () => (
  <div className="tbk-mx-auto tbk-py-[5px] tbk-text-center  sm:tbk-mt-[10px] md:tbk-py-2">
    <img
      src={noResultsIcon}
      srcSet={`${noResultsIcon2x} 1x, ${noResultsIcon2x} 2x`}
      alt="Disappointment"
      width="64"
      height="64"
      className="tbk-mt-2 tbk-inline tbk-hidden"
    />
    <div className="tbk-mb-2 tbk-inline-block tbk-rounded-full tbk-bg-grey-light tbk-p-[10px]">
      <BlockerIcon size={48} className="tbk-inline" />
    </div>
    <div className="tbk-text-h3-subtitle tbk-mb-1 tbk-text-grey-main">
      Sorry, we don't have{" "}
      <FormattedMessage id="trainers" defaultMessage="trainers" />
      <br className="md:tbk-hidden" /> available in this area.
    </div>
    <div className="tbk-text-main tbk-mb-2 tbk-text-grey-main">
      Please, try another location.
    </div>
  </div>
);

const BackToSearch = ({ bookedPartner, onBackToSearchClick }) => (
  <div className="tbk-mt-1.5 tbk-flex tbk-flex-col tbk-items-center tbk-py-2 lg:tbk--mt-0.5">
    <div className="tbk-text-h3-subtitle tbk-mb-1 tbk-text-center tbk-text-grey-main lg:tbk-text-left">
      {" "}
      Sorry, {bookedPartner.firstName} {bookedPartner.lastName} isn’t
      <br className="lg:tbk-hidden" /> available in this area.
    </div>
    <button
      className="tbk-text-main tbk-mb-2 tbk-flex tbk-items-center tbk-text-coral tbk-underline"
      onClick={onBackToSearchClick}
    >
      <ArrowLeftIcon className="tbk-ml-0.5" />
      Back to search
    </button>
  </div>
);

export default function LocationSelector({
  preserve = true,
  gymsListRef,
  showSpecifyLocationInfo,
  bookedPartner,
  region = "GB",
  gyms,
  mapHeight,
  closeTimeoutMS,
  selected,
  onSelect,
  onCloseClick,
  onBackToSearchClick,
  onApplyLocationClick,
  onShowAllGymsClick,
}) {
  const { theme } = useTheme();

  const { user, partners: availablePartners, isFetching } = useAppState();

  const [recentLocations, setRecentLocations] = useState([]);
  const [locationSelectorHeaderHeight, setLocationSelectorHeaderHeight] =
    useState(DEFAULT_LOCATION_SELECTOR_HEADER_HEIGHT);
  const [selectedGym, setSelectedGym] = useState(null);
  const [isShowAllGyms, setIsShowAllGyms] = useState(false);

  const mapRef = React.useRef(null);
  const locationSelectorHeaderRef = React.useRef(null);
  const closeGymsListTimerRef = React.useRef(null);

  useEffect(() => {
    let lastUsedLocation = getLastUsedLocation(user);

    const storageKey = `${"default"}/${
      LOCATION_PICKER_RECENT_LOCATIONS["STORAGE_KEY"]
    }`;

    if (lastUsedLocation) {
      let recentLocations = getRecentItems(storageKey)();

      if (
        !recentLocations.find((val) => val.label === lastUsedLocation.label)
      ) {
        logItemAccess(lastUsedLocation);
      } else {
        setRecentLocations(getRecentItems(storageKey));
      }
    } else {
      setRecentLocations(getRecentItems(storageKey));
    }
  }, [user]);

  const updateExistingLocationItem = (locationItem, item) => {
    // `locationItem` comes from localStorage and it's possible it doesn't have a `lastAccessedOn`
    const neverAccessed = !locationItem.lastAccessedOn;
    const shouldUpdate =
      neverAccessed ||
      Math.abs(
        Date.now() /*item.lastAccessedOn*/ - locationItem.lastAccessedOn
      ) /
        HOUR_IN_MS >
        0.0000001;

    return {
      ...item,
      frequency: shouldUpdate
        ? locationItem.frequency + 1
        : locationItem.frequency,
      lastAccessedOn: shouldUpdate ? Date.now() : locationItem.lastAccessedOn,
    };
  };

  const logItemAccess = useCallback(
    (unsanitizedItem) => {
      // TODO: add sanitize
      const item = unsanitizedItem; // sanitizeItem(unsanitizedItem);

      // When we click Clear for example
      if (!unsanitizedItem) {
        return;
      }

      const storageKey = `${user ? user.firstName : "default"}/${
        LOCATION_PICKER_RECENT_LOCATIONS["STORAGE_KEY"]
      }`;

      // Check if there's any frequent items list set
      const storedRawItems = localStorage.getItem(storageKey);
      const storedLocationItems = storedRawItems
        ? JSON.parse(storedRawItems)
        : [{ ...item, frequency: 1 }]; // No frequent items list set, set one up.

      // Check if item already exists in list
      const itemMatchIndex = storedLocationItems.findIndex(
        (locationItem) => locationItem.label === item.label
      );

      if (itemMatchIndex > -1) {
        storedLocationItems[itemMatchIndex] = updateExistingLocationItem(
          storedLocationItems[itemMatchIndex],
          item
        );
      } else {
        if (
          storedLocationItems.length ===
          LOCATION_PICKER_RECENT_LOCATIONS.MAX_COUNT
        ) {
          storedLocationItems.shift();
        }

        storedLocationItems.push({ ...item, frequency: 1 });
      }

      setRecentLocations(getRecentItems(storageKey));

      return localStorage.setItem(
        storageKey,
        JSON.stringify(storedLocationItems)
      );
    },
    [user]
  );

  const handleLocationItemSelect = useCallback(
    (locationItem) => {
      logItemAccess(locationItem);

      // TODO should it be here and not in OurTrainers?
      const storageKey = getLastUsedLocationStorageKey();

      if (preserve) {
        localStorage.setItem(storageKey, JSON.stringify(locationItem));
      }

      onSelect(locationItem);
    },
    [user, logItemAccess]
  );

  const handleLocationClear = useCallback(() => {
    // TODO should it be here and not in OurTrainers?
    const storageKey = getLastUsedLocationStorageKey();

    localStorage.removeItem(storageKey);
  }, []);

  const noPostalCode = !selected || !selected.postalCode;
  const noPlaceId = !selected || !selected.placeId;

  let showCoachesFound = false,
    showNoCoachesFound = false,
    showApplyLocationButton = false,
    showBackToSearch = false;

  if (bookedPartner) {
    if (partnerAvailable(bookedPartner.id, availablePartners)) {
      showApplyLocationButton = !isFetching && (!noPostalCode || !noPlaceId);
    } else {
      showBackToSearch = !isFetching && showSpecifyLocationInfo; // && !noPostalCode;
    }
  } else {
    if (availablePartners && availablePartners.length) {
      showCoachesFound = !isFetching && selected && selected.value;
    } else {
      showNoCoachesFound = !isFetching && selected && selected.label;
    }
  }

  useEffect(() => {
    setLocationSelectorHeaderHeight(
      locationSelectorHeaderRef.current.clientHeight
    );
  });

  const handleGoogleMapsLoaded = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleGymSelect = useCallback(
    (val) => {
      if (val && (!selectedGym || selectedGym.id !== val.value)) {
        setSelectedGym(val);

        mapRef.current &&
          mapRef.current.setCenter({ lat: val.latitude, lng: val.longitude });

        const newLocationValue = {
          label: val.address,
          value: {
            place: val.value,
            name: val.name,
            detail: val.detail,
          },
          lat: val.latitude,
          lng: val.longitude,
          // postalCode: postalCode && postalCode.short_name,
          placeId: val.placeId,
        };

        handleLocationItemSelect(newLocationValue);
      } else if (selectedGym && selectedGym.name === val.value) {
        setSelectedGym(null);
      }
    },
    [selectedGym, handleLocationItemSelect]
  );

  const showAllGyms = useCallback(() => {
    clearTimeout(closeGymsListTimerRef.current);

    setIsShowAllGyms(true);

    onShowAllGymsClick(true);
  }, [onShowAllGymsClick]);

  const closeGymsListWithoutTimeout = useCallback(() => {
    setIsShowAllGyms(false);
  }, []);

  const closeGymsListWithTimeout = useCallback(() => {
    onShowAllGymsClick(false);

    closeGymsListTimerRef.current = setTimeout(
      closeGymsListWithoutTimeout,
      closeTimeoutMS
    );
  }, [closeTimeoutMS]);

  const closeGymsList = useCallback(() => {
    if (closeTimeoutMS > 0) {
      closeGymsListWithTimeout();
    } else {
      closeGymsListWithoutTimeout();
    }
  }, [closeTimeoutMS, closeGymsListWithTimeout]);

  const handleShowOnTheMapClick = useCallback(() => {
    closeGymsList();
  }, [closeGymsList]);

  var _mapHeight =
    clientWidth() < theme.responsive.breakpoints.sm
      ? showNoCoachesFound
        ? 246
        : showCoachesFound || isFetching
        ? 268
        : showApplyLocationButton
        ? 414
        : showBackToSearch
        ? 326
        : 464
      : showCoachesFound || showNoCoachesFound || isFetching
      ? 160
      : showApplyLocationButton
      ? 270 + 26
      : showBackToSearch
      ? 216 + 26
      : 320 + 26;

  var heightFix =
    locationSelectorHeaderHeight -
    (clientWidth() < theme.responsive.breakpoints.sm
      ? DEFAULT_LOCATION_SELECTOR_HEADER_HEIGHT
      : DEFAULT_LOCATION_SELECTOR_HEADER_HEIGHT_DESKTOP);

  return (
    <div className="tbk-relative tbk-flex tbk-h-full tbk-max-h-full tbk-grow tbk-flex-col tbk-bg-basic-white">
      <div ref={locationSelectorHeaderRef}>
        {bookedPartner ? (
          <div className="tbk-mb-2 tbk-flex tbk-items-center">
            <img
              className="tbk-mr-2 tbk-h-4 tbk-w-4 tbk-rounded-lg tbk-object-cover"
              src={getPartnerPictureUrl(bookedPartner)}
              alt={bookedPartner.firstName}
              width={32}
              height={32}
            />
            <div className="tbk-text-main tbk-text-blue-grey">
              Training with {bookedPartner.firstName} {bookedPartner.lastName}
            </div>
          </div>
        ) : null}
        <div className="tbk-text-h3-subtitle md:tbk-text-title-bold tbk-mb-1 tbk-pr-3 tbk-text-grey-main">
          {bookedPartner ? (
            "Please, Specify Your Location"
          ) : (
            <FormattedMessage
              id="locationSelectorSubtitle"
              defaultMessage="Where Would You Like to Meet Your Coach?"
            />
          )}
        </div>
        {!selectedGym && (
          <div className="tbk-text-small tbk-mb-2 tbk-flex tbk-text-blue-grey">
            {/*<ExclamationMarkOIcon className="tbk-mr-1 tbk-shrink-0 tbk-text-blue-grey" />*/}
            {bookedPartner ? (
              <FormattedMessage
                id="locationSelectorChooseExactPlace"
                defaultMessage="Please, choose exact place where you plan to meet your coach."
              />
            ) : (
              <FormattedMessage
                id="locationSelectorPickAnyPlace"
                defaultMessage="Pick any place you want. Our coaches will come to your home, work, park, or even a hotel."
              />
            )}
          </div>
        )}
      </div>

      <LocationPicker
        className={isShowAllGyms ? "tbk-invisible" : ""}
        inner={
          <>
            {showCoachesFound ? (
              <CoachesFound
                availablePartners={availablePartners}
                onCloseClick={onCloseClick}
              />
            ) : showNoCoachesFound ? (
              <NoCoachesFound />
            ) : showApplyLocationButton ? (
              <ApplyLocationButton
                disabled={
                  !selected || !(selected.postalCode || selected.placeId)
                }
                onApplyLocationClick={onApplyLocationClick}
              />
            ) : showBackToSearch ? (
              <BackToSearch
                bookedPartner={bookedPartner}
                onBackToSearchClick={onBackToSearchClick}
              />
            ) : (
              isFetching && (
                <div className="tbk-py-5 sm:tbk-mt-[10px]">
                  <Lottie
                    options={defaultOptions}
                    height={
                      clientWidth() < theme.responsive.breakpoints.sm
                        ? 116
                        : 112
                    }
                    width={
                      clientWidth() < theme.responsive.breakpoints.sm
                        ? 116
                        : 112
                    }
                  />
                </div>
              )
            )}
          </>
        }
        // bookedPartner={bookedPartner}
        // showSpecifyLocationInfo={showSpecifyLocationInfo}
        mapHeight={_mapHeight - heightFix}
        selected={selected}
        region={region}
        gyms={gyms}
        recentLocations={recentLocations}
        onGoogleMapsLoaded={handleGoogleMapsLoaded}
        onSelect={handleLocationItemSelect}
        onGymSelect={handleGymSelect}
        onShowAllGymsClick={showAllGyms}
        onClear={handleLocationClear}
        onCloseClick={onCloseClick}
        onBackToSearchClick={onBackToSearchClick}
        onApplyLocationClick={onApplyLocationClick}
      />
      {isShowAllGyms ? (
        <div className="tbk-absolute tbk-left-0 tbk-top-0 tbk-right-0 tbk-bottom-0 tbk-z-1 tbk-bg-basic-white">
          <div className="tbk-flex tbk-h-full tbk-max-h-full tbk-flex-col">
            <div className="tbk-flex">
              <div
                className="tbk-cursor-pointer"
                onClick={handleShowOnTheMapClick}
              >
                <ArrowLeftIcon className="tbk-mr-1 tbk-text-coral" />
              </div>
              <div className="tbk-text-h3-subtitle tbk-mb-4 tbk-text-primary">
                Training in a Gym or a Studio:
              </div>
            </div>
            <div className="tbk-mb-4 tbk-min-h-0 tbk-grow">
              <div
                ref={gymsListRef}
                className="tbk-flex tbk-max-h-full tbk-min-h-0 tbk-flex-col tbk-gap-x-7 tbk-overflow-auto lg:tbk-grid lg:tbk-grid-cols-2 lg:tbk-flex-wrap"
              >
                {gyms &&
                  gyms.length &&
                  gyms.map((gym) => (
                    <div className="tbk-min-w-0">
                      <GymBadge
                        key={gym.value}
                        className="tbk-mr-1 tbk-mb-1 tbk-max-w-full tbk-overflow-hidden tbk-text-ellipsis"
                        element={"button"}
                        active={selectedGym && selectedGym.name === gym.name}
                        gym={gym}
                        onClick={(val) => {
                          handleGymSelect(val);
                          closeGymsList();
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <button
                className="tbk-mb-1 tbk-flex tbk-items-center tbk-whitespace-nowrap tbk-rounded-lg tbk-border tbk-border-solid tbk-border-blue-grey-light tbk-bg-blue-grey-light tbk-px-1 tbk-py-1 tbk-text-primary hover:tbk-bg-grey-main hover:tbk-text-basic-white"
                onClick={handleShowOnTheMapClick}
              >
                <span className="tbk-text-small-bold tbk-uppercase">
                  Show on the map
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

LocationSelector.propTypes = {
  gymsListRef: PropTypes.object,
  showSpecifyLocationInfo: PropTypes.bool,
  bookedPartner: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }),
  gyms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeId: PropTypes.string,
      address: PropTypes.string.isRequired,
      detail: PropTypes.string,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    })
  ),
  mapHeight: PropTypes.number,
  closeTimeoutMS: PropTypes.number,
  selected: PropTypes.shape({
    postalCode: PropTypes.string.isRequired,
    placeId: PropTypes.string.isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onBackToSearchClick: PropTypes.func.isRequired,
  onApplyLocationClick: PropTypes.func.isRequired,
  onShowAllGymsClick: PropTypes.func.isRequired,
};

const partnerAvailable = (partnerId, availablePartners) => {
  return !!(
    availablePartners &&
    availablePartners.length &&
    availablePartners.find((val) => val.id === partnerId)
  );
};
