import React from "react";

export default function LogoutIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3L14 3V5L5 5L5 19L14 19V21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3ZM16 17L14.59 15.59L17.17 13H7.5V11L17.17 11L14.59 8.41L16 7L21 12L16 17Z"
        fill="currentColor"
      />
    </svg>
  );
}
