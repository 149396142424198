import React from "react";

export default function HowItWorks1({ className }) {
  return (
    <svg
      width="173"
      height="173"
      viewBox="0 0 173 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5903 46.3681V11.8401H110.774V156H72.5983V46.3681H53.5903ZM74.5983 44.3681V154H108.774V13.8401H55.5903V44.3681H74.5983Z"
        fill="url(#paint0_linear_19165_7114)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_19165_7114"
          x1="86.5001"
          y1="9.18802e-05"
          x2="86.5001"
          y2="173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8DBB4" />
          <stop offset="0.520833" stopColor="#C8DBB4" stopOpacity="0" />
          <stop offset="1" stopColor="#C8DBB4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
