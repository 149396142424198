import React from "react";

export default function ReLifestyleIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8367 32 32 24.8365 32 16H16V32Z"
        fill="#FFB571"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0V16H32C32 7.16326 24.8367 0 16 0Z"
        fill="#FFA08B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16366 0 0 7.16326 0 16H16V0Z"
        fill="#FFE382"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16H16V32C7.16367 32 0 24.8365 0 16Z"
        fill="#FFCEA0"
      />
      <g clipPath={`url(#${id}_clip0_12687_15088)`}>
        <circle cx="16" cy="16" r="8" fill="white" />
        <path
          d="M15.654 10.5621C16.7575 10.5621 17.6522 9.66746 17.6522 8.56388C17.6522 7.4603 16.7575 6.56567 15.654 6.56567C14.5504 6.56567 13.6558 7.4603 13.6558 8.56388C13.6558 9.66746 14.5504 10.5621 15.654 10.5621Z"
          fill="#543328"
        />
        <path
          d="M23.3428 21.0173C20.377 17.3025 20.5922 17.5553 20.4564 17.4443L19.0864 11.8735L23.018 6.53598C23.3338 6.10726 23.2422 5.50369 22.8135 5.18792C22.3848 4.87215 21.7813 4.96364 21.4654 5.39241L17.4914 10.7875C17.435 10.7988 15.0673 11.3744 14.9282 11.4282L10.0567 6.46903C9.68363 6.08918 9.07313 6.08373 8.69328 6.45689C8.31342 6.83004 8.30797 7.44049 8.68113 7.82035L14.2163 13.4551L14.9426 16.4085L15.7473 19.6805L15.301 21.2606L11.4057 20.5712C10.777 20.4601 10.1761 20.8796 10.0648 21.5088C9.95344 22.138 10.3732 22.7383 11.0024 22.8497C16.3167 23.7903 15.9851 23.7394 16.1308 23.7394C16.6398 23.7394 17.1005 23.4022 17.2432 22.8968C18.3476 18.9871 18.2787 19.2578 18.295 19.1103L18.7713 18.9932C18.8196 19.0637 18.6215 18.8124 20.9359 21.7113L18.0284 25.0881C17.6115 25.5724 17.6661 26.3029 18.1503 26.7198C18.6343 27.1365 19.3649 27.0824 19.7819 26.5979L23.3154 22.4941C23.6785 22.0725 23.6899 21.4521 23.3428 21.0173Z"
          fill="#543328"
        />
      </g>
      <defs>
        <clipPath id={`${id}_clip0_12687_15088`}>
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
