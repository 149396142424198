import { useEffect, useState, useCallback } from "react";
import getAddressComponent from "../../utils/getAddressComponent";

const GEOCODE_BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export default function useCountryName(latlng) {
  const [shortName, setShortName] = useState("US");
  const [longName, setLongName] = useState("United States");

  const lookupAddress = useCallback(
    (latlng) => {
      return fetch(
        `${GEOCODE_BASE_URL}?latlng=${latlng}&key=${process.env.REACT_APP_MAPS_API_KEY}`
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.results) {
            response.results.forEach((res) => {
              const addrComp = getAddressComponent(
                res.address_components,
                "country"
              );

              if (addrComp) {
                setLongName(addrComp.long_name);
                setShortName(addrComp.short_name);
                return addrComp;
              }
            });
          }

          return null;
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },
    [
      /*geocoder*/
    ]
  );

  useEffect(() => {
    if (latlng) {
      // lookupAddress(latlng);
    }
  }, [lookupAddress, latlng]);

  return [longName, shortName, lookupAddress];
}
