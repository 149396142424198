import React from "react";

export default function ReConditioningIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 64C14.3268 64 0 49.6735 0 32.0003C0 14.3271 14.3268 0 32 0C49.6732 0 64 14.3271 64 32.0003C64 49.6735 49.6732 64 32 64Z"
        fill="#E64F3A"
      />
      <mask
        id={`${id}_mask0_5436_18949`}
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 64C14.3268 64 0 49.6735 0 32.0003C0 14.3271 14.3268 0 32 0C49.6732 0 64 14.3271 64 32.0003C64 49.6735 49.6732 64 32 64Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#${id}_mask0_5436_18949)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.2098 35.968C62.4124 34.6152 51.086 30.8702 45.0136 38.9472C45.0136 38.9472 42.4983 34.9425 35.0377 35.2313C30.7159 35.3985 27.3685 39.8927 27.3685 39.8927C27.3685 39.8927 26.8261 34.0372 26.0119 30.4024C25.1983 26.7684 24.4528 17.2776 26.0119 15.5944C27.5722 13.9115 30.746 14.6956 33.0134 16.8597C35.2809 19.0245 38.5708 16.3024 40.1443 15.373C41.7178 14.4428 38.9364 9.91438 37.6755 8.66227C36.4141 7.41044 35.9799 6.97966 33.1317 7.04672C30.2841 7.11404 22.0797 9.9412 22.0797 9.9412C22.0797 9.9412 11.2308 34.8445 12.9935 48.9114C12.9935 48.9114 29.0862 62.1954 47.0772 54.0314C47.0772 54.0314 52.8258 54.0882 56.6246 53.4469C60.7705 48.7015 63.5542 42.7527 64.3755 36.199C64.332 36.0988 64.2779 36.0192 64.2098 35.968Z"
          fill={`url(#${id}_paint0_linear_5436_18949)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.0304 12.6478C33.2616 11.0572 29.6189 10.6236 27.87 11.3467C26.9585 11.7238 25.7724 13.4787 25.5449 16.5251C25.6701 16.1173 25.8242 15.7985 26.0123 15.5946C27.5723 13.9117 30.7463 14.6955 33.0138 16.8598C35.281 19.0244 38.5711 16.3026 40.1446 15.3729C40.149 15.3701 40.1531 15.3663 40.1581 15.3628C38.9698 14.5094 37.4266 13.4505 36.0304 12.6478Z"
          fill={`url(#${id}_paint1_linear_5436_18949)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7998 24.8746C35.7998 25.2106 36.0821 25.4829 36.4296 25.4829H38.0617V29.5982C38.0617 30.3946 38.7305 31.04 39.5553 31.04H42.0443C42.8693 31.04 43.5381 30.3946 43.5381 29.5982V25.4829H45.17C45.5175 25.4829 45.7998 25.2106 45.7998 24.8746C45.7998 24.7012 45.7241 24.5453 45.6035 24.4344L42.7115 20.9858C41.6542 19.7248 39.94 19.7248 38.8831 20.9858L35.9782 24.4493L35.9787 24.4502C35.868 24.56 35.7998 24.7094 35.7998 24.8746Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18949`}
          x1="9.34626"
          y1="30.9535"
          x2="28.0294"
          y2="57.8841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE17A" />
          <stop offset="1" stopColor="#FFBF44" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18949`}
          x1="25.5449"
          y1="11.0077"
          x2="25.5449"
          y2="17.676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FACA45" />
          <stop offset="1" stopColor="#F39A21" />
        </linearGradient>
      </defs>
    </svg>
  );
}
