import React, { useRef, useState } from "react";

// Components
import Button from "../../Button/Button";

// Icons
import CheckNiceIcon from "../../../icons/CheckNiceIcon";
import CloseIcon from "../../../icons/CloseIcon";
import LoaderSpinnerIcon from "../../../icons/LoaderSpinnerIcon";

const PromoCodeInput = ({
  disabled,
  isBusy,
  hasError,
  applied,
  promoCode,
  discountPromoCode,
  inputRef,
  onChange,
  onApply,
  onClear,
}) => {
  // const promoCodeInputRef = useRef(null);

  // const [promoCode, setPromoCode] = useState('');

  const handlePromoCodeChange = (e) => {
    const value = e.target.value;

    // setPromoCode(value);
    onChange && onChange(value);
  };

  const checkDiscount = () => {
    // Apply discount logic here
    onApply && onApply();
  };

  const isPromoCodeValid =
    discountPromoCode && discountPromoCode.discount !== null;

  return (
    <>
      <div className="tbk-text-small tbk-mb-0.5 tbk-text-primary">
        Promo code{" "}
        {isPromoCodeValid ? (
          <span className="tbk-text-green">applied</span>
        ) : discountPromoCode && promoCode && applied ? (
          <span className="tbk-text-coral">incorrect</span>
        ) : (
          "(if applicable)"
        )}
        :
      </div>
      <div className="tbk-flex md:tbk-mb-5">
        <div className="tbk-relative tbk-mr-1 tbk-grow">
          <input
            name="promocode"
            ref={inputRef}
            className={`tbk-input-field tbk-text-main tbk-mb-0 tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap tbk-text-grey-main ${
              hasError && promoCode ? " tbk-border-coral" : ""
            }`}
            placeholder=""
            value={promoCode}
            onChange={handlePromoCodeChange}
          />
          {isBusy && promoCode ? (
            <span
              className="tbk-absolute tbk-top-1.5 tbk-right-2 tbk-animate-spin tbk-text-blue-grey"
              // style={{ right: "32px" }}
            >
              <LoaderSpinnerIcon />
            </span>
          ) : isPromoCodeValid ? (
            <CheckNiceIcon className="tbk-absolute tbk-right-2 tbk-top-1.5 tbk-text-green" />
          ) : promoCode && applied ? (
            <button onClick={onClear}>
              <CloseIcon className="tbk-absolute tbk-right-2 tbk-top-1.5 tbk-text-coral" />
            </button>
          ) : null}
        </div>
        {false && promoCode ? (
          <Button
            // variant="secondary"
            size="md"
            className="tbk-grow"
            disabled={disabled}
            // showLoader={isBusy}
            onClick={checkDiscount}
          >
            Apply
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default PromoCodeInput;
