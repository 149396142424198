import React from "react";

export default function HomeIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5971 10.5243C18.5967 10.5239 18.5964 10.5236 18.596 10.5232L12.4766 4.40466C12.2158 4.14374 11.869 4 11.5001 4C11.1312 4 10.7844 4.14362 10.5235 4.40455L4.40724 10.52C4.40518 10.5221 4.40312 10.5243 4.40106 10.5263C3.86542 11.065 3.86633 11.939 4.40369 12.4763C4.64919 12.7219 4.97343 12.8641 5.32011 12.879C5.33419 12.8804 5.34838 12.8811 5.36269 12.8811H5.60659V17.384C5.60659 18.275 6.33164 19 7.223 19H9.61712C9.85976 19 10.0566 18.8033 10.0566 18.5605V15.0303C10.0566 14.6237 10.3874 14.2929 10.794 14.2929H12.2062C12.6128 14.2929 12.9436 14.6237 12.9436 15.0303V18.5605C12.9436 18.8033 13.1403 19 13.3831 19H15.7772C16.6685 19 17.3936 18.275 17.3936 17.384V12.8811H17.6198C17.9885 12.8811 18.3353 12.7374 18.5964 12.4765C19.1343 11.9383 19.1345 11.0628 18.5971 10.5243Z"
        fill="currentColor"
      />
    </svg>
  );
}
