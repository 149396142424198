import { useState, useCallback, useRef } from "react";

import { makeCancelable, toQueryString } from "../../utils";
import { getAccessToken } from "../useAuth/useAuth";
import { PAGE_SIZE, PARTNERS_PAGE_SIZE } from "../../constants";

const MAX_COACHES_TO_SHOW = 16;

export default function usePartner() {
  const [partnerSummary, setPartnerSummary] = useState(null);
  const [partnerAvailability, setPartnerAvailability] = useState([]);
  const [partners, setPartners] = useState([]); // From Location Picker for now
  const [availablePartners, setAvailablePartners] = useState([]); // All available with all filters
  const [availablePartnersLoaded, setAvailablePartnersLoaded] = useState(false);
  const [partnerLoaded, setPartnerLoaded] = useState(false);

  const [partnerAvailabilityLoaded, setPartnerAvailabilityLoaded] =
    useState(false);
  const [partnerReviews, setPartnerReviews] = useState([]);
  const [partnerReviewsPage, setPartnerReviewsPage] = useState(null);
  const [partnerReviewsNumber, setPartnerReviewsNumber] = useState(0);
  const [partnerReviewsLoaded, setPartnerReviewsLoaded] = useState(false);
  const [hasMoreReviews, setHasMoreReviews] = useState();
  const [specialisations, setSpecialisations] = useState([]);
  const [partnersBySpecialisation, setPartnersBySpecialisation] = useState([]);
  const [gyms, setGyms] = useState([]);
  const [partnerGyms, setPartnerGyms] = useState([]);
  const [availablePartnersPageLoaded, setAvailablePartnersPageLoaded] =
    useState(-1);
  const [hasMorePartners, setHasMorePartners] = useState();
  const [numberOfAvailablePartners, setNumberOfAvailablePartners] = useState(0);

  const availablePartnersRef = useRef([]);
  const availablePartnersPageLoadedRef = useRef(-1);

  /**
   * Prevent partner summary caching in Booking Flow
   */
  const clearPartnerSummaryReceive = useCallback(() => {
    setPartnerLoaded(false);
  }, []);

  /**
   * Prevent partner summary caching in Booking Flow
   */
  const clearAvailablePartnersLoaded = useCallback(() => {
    setAvailablePartnersLoaded(false);
  }, []);

  /**
   * Prevent partner summary caching in Booking Flow
   */
  const clearPartnerAvailabilityLoaded = useCallback(() => {
    setPartnerAvailabilityLoaded(false);
  }, []);

  /**
   * Customer gets partner time slots
   */
  const loadPartnerAvailability = useCallback((partnerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/calendars/v1/timeslots?partnerId=${partnerId}`;

    const request = new Request(endpointUrl, {
      headers: {
        "content-type": "application/json",
      },
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            `There was an error loading partner time slots`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.data) {
        setPartnerAvailability(jsonResponse.data);
        setPartnerAvailabilityLoaded(true);
      }
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer gets partner profile
   */
  const loadPartnerProfile = useCallback((id) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/partners/${id}`;

    const request = new Request(endpointUrl, {
      headers: {
        "content-type": "application/json",
      },
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || `There was an error loading partner profile`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.data) {
        setPartnerSummary({
          ...jsonResponse.data,
        });
        setPartnerLoaded(true);

        return jsonResponse.data;
      }
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer gets partners availability
   * Takes into account the filters
   */
  const loadAvailablePartners = useCallback((params, region = "US") => {
    let qs;

    if (params && params.locationCode && Array.isArray(params.locationCode)) {
      const locationCodes = params.locationCode
        .map((val) => `locationCode=${val}`)
        .join("&");

      delete params.locationCode;

      if (!params.page) {
        params.page = 0;
      }

      if (!params.size) {
        params.size = PARTNERS_PAGE_SIZE;
      }

      qs = toQueryString({ region, ...params });

      qs = `${qs}${qs && locationCodes ? "&" : ""}${locationCodes}`;
    } else {
      if (!params.page) {
        params.page = 0;
      }

      if (!params.size) {
        params.size = PARTNERS_PAGE_SIZE;
      }

      qs = toQueryString({ region, ...params });
    }

    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/calendars/v1/partners/pages?${qs}`;

    const request = new Request(endpointUrl, {
      headers: {
        "content-type": "application/json",
      },
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            `There was an error loading partners availability`
        );

        return Promise.reject(error);
      }

      let page = params.page || 0;
      let partners = page <= 0 ? [] : availablePartnersRef.current;

      let partnersOffset = partners.length || 0;
      let newPartnersOffset = partnersOffset + jsonResponse.data.content.length;

      if (partnersOffset < newPartnersOffset) {
        // new items
        partners = [
          ...partners,
          ...jsonResponse.data.content.slice(
            -(newPartnersOffset - partnersOffset)
          ),
        ];
      }

      let hasMore = newPartnersOffset < jsonResponse.data.totalElements;
      page = hasMore ? page : availablePartnersPageLoadedRef.current;

      availablePartnersRef.current = partners;

      // return Object.assign({}, state, { isCompletedFetching: false, isCompletedReceived: true }, { completed: { content: bookings, hasMore, page } });
      setHasMorePartners(hasMore);
      setAvailablePartnersPageLoaded(page);
      availablePartnersPageLoadedRef.current = page;
      setNumberOfAvailablePartners(jsonResponse.data.totalElements);

      if (partners) {
        setAvailablePartners(partners);
        setAvailablePartnersLoaded(true);
      }

      return jsonResponse.data;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer gets partners in location
   */
  const loadPartnersInLocation = useCallback((params, region = "US") => {
    let qs;

    if (params && params.locationCode && Array.isArray(params.locationCode)) {
      const locationCodes = params.locationCode
        .map((val) => `locationCode=${val}`)
        .join("&");

      delete params.locationCode;

      qs = toQueryString({ region, ...params });

      qs = `${qs}${qs && locationCodes ? "&" : ""}${locationCodes}`;
    } else {
      qs = toQueryString({ region, ...params });
    }

    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/calendars/v1/partners?${qs}`;

    const request = new Request(endpointUrl, {
      headers: {
        "content-type": "application/json",
      },
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            `There was an error loading partners availability`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.data) {
        setPartners(jsonResponse.data);
      }

      return jsonResponse.data;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer loads reviews
   */
  const loadReviews = useCallback(
    async (partnerId, page) => {
      const endpointUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/profiles/v1/partners/${partnerId}/reviews?${toQueryString({
        page,
        size: PAGE_SIZE,
        sort: "filled,date,desc",
      })}`;

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message || "There was an error loading partner reviews"
          );

          return Promise.reject(error);
        }

        let lastReviews = [...partnerReviews];
        let hasMore = false;

        let reviewsOffset = partnerReviews.length;
        let newReviewsOffset = reviewsOffset + jsonResponse.data.content.length;

        if (reviewsOffset < newReviewsOffset && partnerReviewsPage !== page) {
          // new items
          lastReviews = [
            ...lastReviews,
            ...jsonResponse.data.content.slice(
              -(newReviewsOffset - reviewsOffset)
            ),
          ];
          hasMore = true;
        }

        page = hasMore ? page : partnerReviewsPage;

        setPartnerReviews(lastReviews);
        setHasMoreReviews(hasMore);
        setPartnerReviewsPage(page);
        setPartnerReviewsNumber(jsonResponse.data.totalElements);

        return jsonResponse;
      });
    },
    [partnerReviews, partnerReviewsPage]
  );

  /**
   * Customer sends review
   */
  const sendReview = useCallback(
    async (userId, rating, review, partnerId, bookingId) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/partners/${partnerId}/reviews`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          rating,
          review,
          customerId: userId,
          bookingId,
        }),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message || "There was an error sending review"
          );

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    []
  );

  /**
   * Customer gets specialisations
   */
  const loadSpecialisations = useCallback(async (region = "US") => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/partners/specialisations?region=${region}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading specialisations"
        );

        return Promise.reject(error);
      }

      setSpecialisations(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * Customer gets available partners by specialisation
   */
  const loadAvailablePartnersBySpecialisation = useCallback(
    (specialisation = "ENDURANCE", region = process.env.REACT_APP_REGION) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/calendars/v1/partners/specialisations/search?specialisation=${specialisation}&size=${MAX_COACHES_TO_SHOW}&region=${region}`;

      const request = new Request(endpointUrl, {
        headers: {
          "content-type": "application/json",
        },
      });

      const cancelablePromise = makeCancelable(fetch(request));

      const requestPromise = cancelablePromise.promise.then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error loading coaches by specialisation"
          );

          return Promise.reject(error);
        }

        if (jsonResponse.data) {
          setPartnersBySpecialisation(
            jsonResponse.data.slice(0, MAX_COACHES_TO_SHOW)
          );
        }

        return jsonResponse;
      });

      return {
        ...cancelablePromise,
        promise: requestPromise,
      };
    },
    []
  );

  /**
   * Customer gets specialisations
   */
  const loadPartnerGyms = useCallback(async (partnerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/locations/v1/places?partnerId=${partnerId}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error loading places for partner"
        );

        return Promise.reject(error);
      }

      setPartnerGyms(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * Customer gets specialisations
   */
  const loadGyms = useCallback(async (params) => {
    const endpointUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/locations/v1/places/list?${toQueryString(params)}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading places"
        );

        return Promise.reject(error);
      }

      setGyms(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  const clearPartnerReviews = () => {
    setPartnerReviews([]);
    setPartnerReviewsPage(undefined);
  };

  const clearPartnerReviewsLoaded = () => {
    setPartnerReviewsLoaded(false);
  };

  return {
    partnerSummary,
    partnerAvailability,
    partnerLoaded,
    availablePartnersLoaded,
    partnerAvailabilityLoaded,
    partners,
    availablePartners,
    specialisations,
    partnersBySpecialisation,
    partnerReviews,
    partnerReviewsPage,
    hasMoreReviews,
    partnerReviewsNumber,
    // partnerReviewsLoaded,
    gyms,
    partnerGyms,
    numberOfAvailablePartners,
    availablePartnersPageLoaded,
    hasMorePartners,
    loadPartnerProfile,
    loadPartnerAvailability,
    loadPartnersInLocation,
    loadAvailablePartners,
    loadAvailablePartnersBySpecialisation,
    loadSpecialisations,
    loadReviews,
    sendReview,
    loadPartnerGyms,
    loadGyms,
    clearPartnerSummaryReceive,
    clearAvailablePartnersLoaded,
    clearPartnerAvailabilityLoaded,
    clearPartnerReviews,
    clearPartnerReviewsLoaded,
  };
}
