import React from "react";

export default function LoaderSpinnerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        d="M11.202 21a8.5 8.5 0 1 0-6.91-13.45L4 7.955l1.462 1.049.292-.406A6.7 6.7 0 1 1 11.202 19.2h-.5V21h.5z"
        fill="currentColor"
      />
    </svg>
  );
}
