export const areas = [
  {
    zipCode: "94501",
    city: "Alameda",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJlRXP8tiAj4ARFG8BYM-Z_2Y",
  },
  {
    zipCode: "94502",
    city: "Alameda",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJlRXP8tiAj4ARFG8BYM-Z_2Y",
  },
  {
    zipCode: "94706",
    city: "Albany",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJPQVb0tJ4hYARLxEHP9E4sNU",
  },
  {
    zipCode: "94701",
    city: "Berkeley",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94702",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94703",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94704",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94705",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94707",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94708",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94709",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94710",
    city: "Berkeley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94712",
    city: "Berkeley",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94720",
    city: "Berkeley",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJ00mFOjZ5hYARk-l1ppUV6pQ",
  },
  {
    zipCode: "94546",
    city: "Castro Valley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJM8gFqMSRj4ARJ5xu_Pkuy54",
  },
  {
    zipCode: "94552",
    city: "Castro Valley",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJM8gFqMSRj4ARJ5xu_Pkuy54",
  },
  {
    zipCode: "94568",
    city: "Dublin",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJMSKJ1lzmj4ARV0D2joR8Mjs",
  },
  {
    zipCode: "94608",
    city: "Emeryville",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJs44f6hR-hYARB3GwqDEYHUg",
  },
  {
    zipCode: "94662",
    city: "Emeryville",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJs44f6hR-hYARB3GwqDEYHUg",
  },
  {
    zipCode: "94536",
    city: "Fremont",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ98rot0a_j4AR1IjYiTsx2oo",
  },
  {
    zipCode: "94537",
    city: "Fremont",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJ98rot0a_j4AR1IjYiTsx2oo",
  },
  {
    zipCode: "94538",
    city: "Fremont",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ98rot0a_j4AR1IjYiTsx2oo",
  },
  {
    zipCode: "94539",
    city: "Fremont",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ98rot0a_j4AR1IjYiTsx2oo",
  },
  {
    zipCode: "94555",
    city: "Fremont",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ98rot0a_j4AR1IjYiTsx2oo",
  },
  {
    zipCode: "94540",
    city: "Hayward",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94541",
    city: "Hayward",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94542",
    city: "Hayward",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94543",
    city: "Hayward",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94544",
    city: "Hayward",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94545",
    city: "Hayward",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94557",
    city: "Hayward",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJfVkBtjGUj4ARwDlpwt50BAc",
  },
  {
    zipCode: "94550",
    city: "Livermore",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJcSBaOIblj4ARcdhryzEi05g",
  },
  {
    zipCode: "94551",
    city: "Livermore",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJcSBaOIblj4ARcdhryzEi05g",
  },
  {
    zipCode: "94560",
    city: "Newark",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJk2UuWyS_j4ARNNuZMBq3-sc",
  },
  {
    zipCode: "94601",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94602",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94603",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94604",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94605",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94606",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94607",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94609",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94610",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94611",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94612",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94613",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94614",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94615",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94617",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94618",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94619",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94621",
    city: "Oakland",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94622",
    city: "Oakland",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94623",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94624",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94649",
    city: "Oakland",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94659",
    city: "Oakland",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94660",
    city: "Oakland",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94661",
    city: "Oakland",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94666",
    city: "Oakland",
    county: "Alameda",
    type: "Unique",
    placeId: "ChIJA-2qKIt9hYARZ5N1NdUVtHE",
  },
  {
    zipCode: "94620",
    city: "Piedmont",
    county: "Alameda",
    type: "P.O.Box",
    placeId: "ChIJ9cUZf399hYARP70kjNztjQs",
  },
  {
    zipCode: "94566",
    city: "Pleasanton",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJX3W6YaLpj4ARFn3q4Udoq7M",
  },
  {
    zipCode: "94588",
    city: "Pleasanton",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJX3W6YaLpj4ARFn3q4Udoq7M",
  },
  {
    zipCode: "94577",
    city: "San Leandro",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ3WY6BH-Fj4ARslfX2g1JquU",
  },
  {
    zipCode: "94578",
    city: "San Leandro",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ3WY6BH-Fj4ARslfX2g1JquU",
  },
  {
    zipCode: "94579",
    city: "San Leandro",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ3WY6BH-Fj4ARslfX2g1JquU",
  },
  {
    zipCode: "94580",
    city: "San Lorenzo",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJ4eEHIkyQj4ARLANTEJpkvQw",
  },
  {
    zipCode: "94586",
    city: "Sunol",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJAVvXmDPCj4ARl0rmlQPLlEQ",
  },
  {
    zipCode: "94587",
    city: "Union City",
    county: "Alameda",
    type: "Standard",
    placeId: "ChIJk-qHUbyRj4AR7opkvGzerFs",
  },
  {
    zipCode: "95646",
    city: "Kirkwood",
    county: "Alpine",
    type: "P.O.Box",
    placeId: "ChIJz_RJ-QX0mYARlwhM1Pw5VWg",
  },
  {
    zipCode: "96120",
    city: "Markleeville",
    county: "Alpine",
    type: "Standard",
    placeId: "ChIJPyeDJFLdmYARmdI0LgwniuA",
  },
  {
    zipCode: "95601",
    city: "Amador City",
    county: "Amador",
    type: "P.O.Box",
    placeId: "ChIJW1qda0BjmoARhwdprWUeoO8",
  },
  {
    zipCode: "95699",
    city: "Drytown",
    county: "Amador",
    type: "P.O.Box",
    placeId: "ChIJGfyp801imoARoqtvu49lciQ",
  },
  {
    zipCode: "95629",
    city: "Fiddletown",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJr2rpnolmmoARXFxc3qp4ks4",
  },
  {
    zipCode: "95640",
    city: "Ione",
    county: "Amador",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95642",
    city: "Jackson",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJp99yHsN7moARRvcJ__qXZrY",
  },
  {
    zipCode: "95644",
    city: "Kit Carson",
    county: "Amador",
    type: "P.O.Box",
    placeId: "ChIJySSxkGL2mYARbsNA1s-KkxU",
  },
  {
    zipCode: "95654",
    city: "Martell",
    county: "Amador",
    type: "P.O.Box",
    placeId: "ChIJE_P9h4N7moARBZSK1EwWSgo",
  },
  {
    zipCode: "95665",
    city: "Pine Grove",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJJ5kr_ANxmoAR2Nji5N-eL7Y",
  },
  {
    zipCode: "95666",
    city: "Pioneer",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJkWjmzQBzmoARyZJ6MZMf4N4",
  },
  {
    zipCode: "95669",
    city: "Plymouth",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJQ80ztXT0moARlA4CpAX0At0",
  },
  {
    zipCode: "95675",
    city: "River Pines",
    county: "Amador",
    type: "P.O.Box",
    placeId: "ChIJ7aY5rLFnmoARFecMIzmsJP4",
  },
  {
    zipCode: "95685",
    city: "Sutter Creek",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJwZ-zg197moARmJUZX9S2Usc",
  },
  {
    zipCode: "95689",
    city: "Volcano",
    county: "Amador",
    type: "Standard",
    placeId: "ChIJZ2RSG_JxmoARdvE9uhzWzc8",
  },
  {
    zipCode: "95914",
    city: "Bangor",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJqxPCirSlnIARVYR8Y7hSwiM",
  },
  {
    zipCode: "95916",
    city: "Berry Creek",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJgZcsUF7GnIARfclHfxlpx5g",
  },
  {
    zipCode: "95917",
    city: "Biggs",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJQWfZKCNJg4ARz5smb0rzZDw",
  },
  {
    zipCode: "95926",
    city: "Chico",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95927",
    city: "Chico",
    county: "Butte",
    type: "P.O.Box",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95928",
    city: "Chico",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95929",
    city: "Chico",
    county: "Butte",
    type: "Unique",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95973",
    city: "Chico",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95976",
    city: "Chico",
    county: "Butte",
    type: "Unique",
    placeId: "ChIJh2Qj_I0fg4AROEU6eY_rjjA",
  },
  {
    zipCode: "95930",
    city: "Clipper Mills",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJAdj7b7iQnIARbjojFvBAdP4",
  },
  {
    zipCode: "95938",
    city: "Durham",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJhzRuR44vg4ARqCrSpZuF9N0",
  },
  {
    zipCode: "95940",
    city: "Feather Falls",
    county: "Butte",
    type: "P.O.Box",
    placeId: "ChIJaSH5-oOVnIARusDEyyisEDg",
  },
  {
    zipCode: "95941",
    city: "Forbestown",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJAyIS99-VnIARGCmk8qgmDsI",
  },
  {
    zipCode: "95942",
    city: "Forest Ranch",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJUaXA-oDUgoARJqAu7Uk_E5M",
  },
  {
    zipCode: "95948",
    city: "Gridley",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJcTnICO1Ng4AR1LxgYnea5gw",
  },
  {
    zipCode: "95954",
    city: "Magalia",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJA2GjtqAqnYARam-gDge1rW0",
  },
  {
    zipCode: "95958",
    city: "Nelson",
    county: "Butte",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "95965",
    city: "Oroville",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJES6ESgO2nIARR4PiDdYZabU",
  },
  {
    zipCode: "95966",
    city: "Oroville",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJES6ESgO2nIARR4PiDdYZabU",
  },
  {
    zipCode: "95968",
    city: "Palermo",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJYRuUvoCxnIARME2TqWMGBQc",
  },
  {
    zipCode: "95967",
    city: "Paradise",
    county: "Butte",
    type: "P.O.Box",
    placeId: "ChIJnzJCr1qeKIcREDcrEiGiKwE",
  },
  {
    zipCode: "95969",
    city: "Paradise",
    county: "Butte",
    type: "Standard",
    placeId: "ChIJnzJCr1qeKIcREDcrEiGiKwE",
  },
  {
    zipCode: "95974",
    city: "Richvale",
    county: "Butte",
    type: "P.O.Box",
    placeId: "ChIJefWDMxY2g4ARyB9qt5pipx4",
  },
  {
    zipCode: "95978",
    city: "Stirling City",
    county: "Butte",
    type: "P.O.Box",
    placeId: "ChIJacf3e10pnYARV_4m8ibacis",
  },
  {
    zipCode: "95221",
    city: "Altaville",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJw2vKH8SVkIAROywPzvaE2Sc",
  },
  {
    zipCode: "95222",
    city: "Angels Camp",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJrWd0BMKVkIARuTzs9zMcNyc",
  },
  {
    zipCode: "95223",
    city: "Arnold",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJb7ed9TalkIARsHXtmqn-tFo",
  },
  {
    zipCode: "95224",
    city: "Avery",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJWRByUuq6kIARkplmX4VHrkg",
  },
  {
    zipCode: "95225",
    city: "Burson",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJ_woleYOAkIAR2Qy6uSpyghw",
  },
  {
    zipCode: "95226",
    city: "Campo Seco",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJKyB7ojOAkIARtt-r4lFCg-Y",
  },
  {
    zipCode: "95228",
    city: "Copperopolis",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJ_8EeNrnskIARzJjbzvl4vhI",
  },
  {
    zipCode: "95229",
    city: "Douglas Flat",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJy7MNxLy-kIAR4tjzh39V1R0",
  },
  {
    zipCode: "95232",
    city: "Glencoe",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJF021e611moARf0U7JT3nTC0",
  },
  {
    zipCode: "95233",
    city: "Hathaway Pines",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJCSqyFoq6kIARfUjlzXLw6xs",
  },
  {
    zipCode: "95245",
    city: "Mokelumne Hill",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJRWmlyqqdkIAR4FOQmuXqMA4",
  },
  {
    zipCode: "95246",
    city: "Mountain Ranch",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJM4KV2ZCYkIARe5s9SGRTX1U",
  },
  {
    zipCode: "95247",
    city: "Murphys",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJV2pT4HWXkIARV-u7FjlPD5E",
  },
  {
    zipCode: "95248",
    city: "Rail Road Flat",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJc_uvHIZ1moAReRR1IiYoRtU",
  },
  {
    zipCode: "95249",
    city: "San Andreas",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJ7aAYL4-ZkIAR1jx_DbNNuSo",
  },
  {
    zipCode: "95251",
    city: "Vallecito",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJU5N66be9kIARjUCkAQw53bE",
  },
  {
    zipCode: "95252",
    city: "Valley Springs",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJ9bp8sRSBkIARTRKMvcr0fCo",
  },
  {
    zipCode: "95254",
    city: "Wallace",
    county: "Calaveras",
    type: "P.O.Box",
    placeId: "ChIJyWOC7k9_kIAR_EGAsgXL-eA",
  },
  {
    zipCode: "95255",
    city: "West Point",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJzdw2Bs-rkIAR81F9DPLVO1Q",
  },
  {
    zipCode: "95257",
    city: "Wilseyville",
    county: "Calaveras",
    type: "Standard",
    placeId: "ChIJhfE6SSYLmoARs3e9muqA1fY",
  },
  {
    zipCode: "95912",
    city: "Arbuckle",
    county: "Colusa",
    type: "Standard",
    placeId: "ChIJl3OTbTeZhIARNA7QMFDZDM0",
  },
  {
    zipCode: "95932",
    city: "Colusa",
    county: "Colusa",
    type: "Standard",
    placeId: "ChIJK1_3pGlng4AR63EOxQUkCaQ",
  },
  {
    zipCode: "95950",
    city: "Grimes",
    county: "Colusa",
    type: "P.O.Box",
    placeId: "ChIJ32C75a6mhIARALdZdhDm-Ow",
  },
  {
    zipCode: "95955",
    city: "Maxwell",
    county: "Colusa",
    type: "P.O.Box",
    placeId: "ChIJX3guCwxyg4ARa5Iq9UHbP28",
  },
  {
    zipCode: "95970",
    city: "Princeton",
    county: "Colusa",
    type: "Standard",
    placeId: "ChIJx7Q-CrRqg4AR6FE10b_js8M",
  },
  {
    zipCode: "95979",
    city: "Stonyford",
    county: "Colusa",
    type: "Standard",
    placeId: "ChIJX9RCr8m6g4AReciL880TFRI",
  },
  {
    zipCode: "95987",
    city: "Williams",
    county: "Colusa",
    type: "Standard",
    placeId: "ChIJ9UR1KZhkg4ARN5bCPnSdQDI",
  },
  {
    zipCode: "94507",
    city: "Alamo",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ83S0PC2Lj4ARoDyC4N90BAc",
  },
  {
    zipCode: "94509",
    city: "Antioch",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ1aCvlWNXhYARWXWULDxGagU",
  },
  {
    zipCode: "94531",
    city: "Antioch",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ1aCvlWNXhYARWXWULDxGagU",
  },
  {
    zipCode: "94511",
    city: "Bethel Island",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJFU0g8i9VhYARfyj1c2cEzzc",
  },
  {
    zipCode: "94513",
    city: "Brentwood",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJzciH8Jn5j4ARAprSVluDGX4",
  },
  {
    zipCode: "94514",
    city: "Byron",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJv2Zzk7vij4ARNuo-nSqyb08",
  },
  {
    zipCode: "94516",
    city: "Canyon",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJ6dTKnO-Hj4ARfd9_pAPYq0E",
  },
  {
    zipCode: "94517",
    city: "Clayton",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJzxZ2thRfhYARBM-0isDLlQI",
  },
  {
    zipCode: "94518",
    city: "Concord",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94519",
    city: "Concord",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94520",
    city: "Concord",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94521",
    city: "Concord",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94522",
    city: "Concord",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94524",
    city: "Concord",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94527",
    city: "Concord",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94529",
    city: "Concord",
    county: "Contra Costa",
    type: "Unique",
    placeId: "ChIJhczEaaxghYAR7_8flgRgQQ4",
  },
  {
    zipCode: "94525",
    city: "Crockett",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJtX67G6JxhYARayJyhINFINI",
  },
  {
    zipCode: "94506",
    city: "Danville",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhwVQCRLzj4AR1S-D85d7Wxg",
  },
  {
    zipCode: "94526",
    city: "Danville",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhwVQCRLzj4AR1S-D85d7Wxg",
  },
  {
    zipCode: "94528",
    city: "Diablo",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJ60tQF33zj4ARpyXhxAb_cgQ",
  },
  {
    zipCode: "94505",
    city: "Discovery Bay",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJdb2ZOVgCkIARJC6La-rAjnE",
  },
  {
    zipCode: "94530",
    city: "El Cerrito",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJw7t-MwR5hYARQgh_35VN4Ps",
  },
  {
    zipCode: "94803",
    city: "El Sobrante",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJc8fDjMF5hYARghPSciqQHgQ",
  },
  {
    zipCode: "94820",
    city: "El Sobrante",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJc8fDjMF5hYARghPSciqQHgQ",
  },
  {
    zipCode: "94547",
    city: "Hercules",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJreeBgipnhYAROboW0_Uk_Vg",
  },
  {
    zipCode: "94548",
    city: "Knightsen",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJN7xS3K7_j4ARmDVeNs0Cl-A",
  },
  {
    zipCode: "94549",
    city: "Lafayette",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJhY_6o3xghYARJ2nkA11YKPk",
  },
  {
    zipCode: "94553",
    city: "Martinez",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJK3ldFnxhhYARgklERLLugGg",
  },
  {
    zipCode: "94556",
    city: "Moraga",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJjbmZDbaJj4ARbgp2zPCitaI",
  },
  {
    zipCode: "94570",
    city: "Moraga",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJjbmZDbaJj4ARbgp2zPCitaI",
  },
  {
    zipCode: "94575",
    city: "Moraga",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJjbmZDbaJj4ARbgp2zPCitaI",
  },
  {
    zipCode: "94561",
    city: "Oakley",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJGWVgnPBVhYARqMFDEL11yXc",
  },
  {
    zipCode: "94563",
    city: "Orinda",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ_yPIXb18hYARiWcSAjfA2ok",
  },
  {
    zipCode: "94564",
    city: "Pinole",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJXzXpbF52hYARdocdHwfYRJw",
  },
  {
    zipCode: "94565",
    city: "Pittsburg",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJsfacOOVbhYARI38AIpLCewA",
  },
  {
    zipCode: "94523",
    city: "Pleasant Hill",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJEzRzzFJhhYARGTDJkLuzUEI",
  },
  {
    zipCode: "94569",
    city: "Port Costa",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJa5tKyMZxhYARgvB8_qgfO0g",
  },
  {
    zipCode: "94801",
    city: "Richmond",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94802",
    city: "Richmond",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94804",
    city: "Richmond",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94805",
    city: "Richmond",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94807",
    city: "Richmond",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94808",
    city: "Richmond",
    county: "Contra Costa",
    type: "P.O.Box",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94850",
    city: "Richmond",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJKYxc8eBlhYAR9L8V2jMWELM",
  },
  {
    zipCode: "94572",
    city: "Rodeo",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJC4tBQR9xhYAR55ZGLtKwXig",
  },
  {
    zipCode: "94806",
    city: "San Pablo",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJcU50YSpnhYARea-g6bdYEAc",
  },
  {
    zipCode: "94582",
    city: "San Ramon",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJT6zq_3ztj4ARrT4l-bvuYvU",
  },
  {
    zipCode: "94583",
    city: "San Ramon",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJT6zq_3ztj4ARrT4l-bvuYvU",
  },
  {
    zipCode: "94595",
    city: "Walnut Creek",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ86V7GXT0j4ARbMMDIJdJbuA",
  },
  {
    zipCode: "94596",
    city: "Walnut Creek",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ86V7GXT0j4ARbMMDIJdJbuA",
  },
  {
    zipCode: "94597",
    city: "Walnut Creek",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ86V7GXT0j4ARbMMDIJdJbuA",
  },
  {
    zipCode: "94598",
    city: "Walnut Creek",
    county: "Contra Costa",
    type: "Standard",
    placeId: "ChIJ86V7GXT0j4ARbMMDIJdJbuA",
  },
  {
    zipCode: "95531",
    city: "Crescent City",
    county: "Del Norte",
    type: "Standard",
    placeId: "ChIJ24hiXDdm0FQRLuZ1c7Ca6HY",
  },
  {
    zipCode: "95532",
    city: "Crescent City",
    county: "Del Norte",
    type: "Unique",
    placeId: "ChIJ24hiXDdm0FQRLuZ1c7Ca6HY",
  },
  {
    zipCode: "95538",
    city: "Fort Dick",
    county: "Del Norte",
    type: "P.O.Box",
    placeId: "ChIJJenYz5Jt0FQRo73lCuMwd1c",
  },
  {
    zipCode: "95543",
    city: "Gasquet",
    county: "Del Norte",
    type: "Standard",
    placeId: "ChIJ5R3jWmcU0FQRwCBYr3smjX8",
  },
  {
    zipCode: "95548",
    city: "Klamath",
    county: "Del Norte",
    type: "Standard",
    placeId: "ChIJjbhS6GZQ0FQRh8U1Qql4gF4",
  },
  {
    zipCode: "95567",
    city: "Smith River",
    county: "Del Norte",
    type: "Standard",
    placeId: "ChIJL9Q5WiVz0FQRpkCCEb880LA",
  },
  {
    zipCode: "95709",
    city: "Camino",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJeV6PeXMM3IARy0x1Nhtx0kQ",
  },
  {
    zipCode: "95613",
    city: "Coloma",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJN8UuEWFVmoARUA15MtYQtjI",
  },
  {
    zipCode: "95614",
    city: "Cool",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJjx5r_W0Em4ARqVpr8G1dVfc",
  },
  {
    zipCode: "95619",
    city: "Diamond Springs",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJqW7b67JRmoARz31-sft3j78",
  },
  {
    zipCode: "95721",
    city: "Echo Lake",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ7xR9PkCMmYAR89V-IMeQnRI",
  },
  {
    zipCode: "95623",
    city: "El Dorado",
    county: "El Dorado",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95762",
    city: "El Dorado Hills",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJtThU5z1WmoARgNjYfDngCPA",
  },
  {
    zipCode: "95633",
    city: "Garden Valley",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJf3zRl9Gqm4ARnssszGy0Q5c",
  },
  {
    zipCode: "95634",
    city: "Georgetown",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJX6zZ9rmrm4ARqP17sUqPwcQ",
  },
  {
    zipCode: "95635",
    city: "Greenwood",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJ6wZTK7oAm4ARLJwUBlyG214",
  },
  {
    zipCode: "95636",
    city: "Grizzly Flats",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJPd7otlQVmoARJopbtbDEuGo",
  },
  {
    zipCode: "95720",
    city: "Kyburz",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJV0UrhYIjmoARtsVBmcZCQ-0",
  },
  {
    zipCode: "95651",
    city: "Lotus",
    county: "El Dorado",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95656",
    city: "Mount Aukum",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJRX8OarpnmoARTinYVx9sGdo",
  },
  {
    zipCode: "95664",
    city: "Pilot Hill",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJGzxBoEkCm4AR_h6c31hB6qQ",
  },
  {
    zipCode: "95667",
    city: "Placerville",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJ7VilO0VPmoARNupnM-0sA7U",
  },
  {
    zipCode: "95726",
    city: "Pollock Pines",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJCUV99HM7moARMPc-eQurlpo",
  },
  {
    zipCode: "95672",
    city: "Rescue",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJ81wk9h1amoARPcfUV230YWI",
  },
  {
    zipCode: "95682",
    city: "Shingle Springs",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJRw_u3idXmoARaWN0ee0cepA",
  },
  {
    zipCode: "95684",
    city: "Somerset",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJiwlVMRRDmoARWP269tNPXTQ",
  },
  {
    zipCode: "96150",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96151",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96152",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96154",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96155",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96156",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96157",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96158",
    city: "South Lake Tahoe",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJ4U-l7omFmYARjpEj-0YvG90",
  },
  {
    zipCode: "96142",
    city: "Tahoma",
    county: "El Dorado",
    type: "P.O.Box",
    placeId: "ChIJweGGO45_mYAR7rp5gDZDifs",
  },
  {
    zipCode: "95735",
    city: "Twin Bridges",
    county: "El Dorado",
    type: "Standard",
    placeId: "ChIJAY9X4EeKmYARJl9Swojb5nI",
  },
  {
    zipCode: "93602",
    city: "Auberry",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJRRkKZIw1lIARW6G9UzeGaPw",
  },
  {
    zipCode: "93605",
    city: "Big Creek",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJp6dli7PIlYARI80n3nJiFqI",
  },
  {
    zipCode: "93606",
    city: "Biola",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJW3c081BwlIARPqqjdBnZOaY",
  },
  {
    zipCode: "93607",
    city: "Burrel",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnxTmLmaFlIARmF1Sh3JE_Hk",
  },
  {
    zipCode: "93608",
    city: "Cantua Creek",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJu6H4o0h4k4AR5-bPyg2O2Bg",
  },
  {
    zipCode: "93609",
    city: "Caruthers",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJuYmnfKyNlIARNtW2m6-vCA8",
  },
  {
    zipCode: "93611",
    city: "Clovis",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ5c_kAJRQlIARnZxkQGEKPU4",
  },
  {
    zipCode: "93612",
    city: "Clovis",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ5c_kAJRQlIARnZxkQGEKPU4",
  },
  {
    zipCode: "93613",
    city: "Clovis",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJ5c_kAJRQlIARnZxkQGEKPU4",
  },
  {
    zipCode: "93619",
    city: "Clovis",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ5c_kAJRQlIARnZxkQGEKPU4",
  },
  {
    zipCode: "93210",
    city: "Coalinga",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJSU6D1v9Bk4AR0H0-JxSL69A",
  },
  {
    zipCode: "93616",
    city: "Del Rey",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJn9eVxvj5lIARevN5fZoBi2M",
  },
  {
    zipCode: "93621",
    city: "Dunlap",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ5aoxZssNlYARAeazcmI3IiI",
  },
  {
    zipCode: "93622",
    city: "Firebaugh",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJmUwSE5Drk4AR8mF6ngD-zEE",
  },
  {
    zipCode: "93624",
    city: "Five Points",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJu4cn7E1jk4ARbtOgKJ9am3o",
  },
  {
    zipCode: "93625",
    city: "Fowler",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ6zXSPpP0lIARkIztPL9v13c",
  },
  {
    zipCode: "93650",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93701",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93702",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93703",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93704",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93705",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93706",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93707",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93708",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93709",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93710",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93711",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93712",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93714",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93715",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93716",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93717",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93718",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93720",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93721",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93722",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93723",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93724",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93725",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93726",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93727",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93728",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93729",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93730",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93737",
    city: "Fresno",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93740",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93741",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93744",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93745",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93747",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93750",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93755",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93760",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93761",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93764",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93765",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93771",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93772",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93773",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93774",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93775",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93776",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93777",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93778",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93779",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93786",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93790",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93791",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93792",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93793",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93794",
    city: "Fresno",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93844",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93888",
    city: "Fresno",
    county: "Fresno",
    type: "Unique",
    placeId: "ChIJnU6eVOFdlIAREbijSWRAEns",
  },
  {
    zipCode: "93626",
    city: "Friant",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJVfuk1aw4lIARe7FvCEO_XG0",
  },
  {
    zipCode: "93627",
    city: "Helm",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJBYdiHihdlIAR_MFc6opISB0",
  },
  {
    zipCode: "93628",
    city: "Hume",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJeyt5IK5xlYAR7oLRr-nW8gk",
  },
  {
    zipCode: "93234",
    city: "Huron",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJHQ4GBTChlIARf_AQbqBx0eg",
  },
  {
    zipCode: "93630",
    city: "Kerman",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJO6-7XiqGk4ARvgGj0e_gdaw",
  },
  {
    zipCode: "93631",
    city: "Kingsburg",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJi-PQKTLklIARl3aZWnFcdWI",
  },
  {
    zipCode: "93634",
    city: "Lakeshore",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJ6bctaDfElYARCdTGq-0lNcc",
  },
  {
    zipCode: "93242",
    city: "Laton",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJP37MWHnplIARXbQITOuyzbQ",
  },
  {
    zipCode: "93640",
    city: "Mendota",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJmWR6DA6Pk4AR62x05-YQ970",
  },
  {
    zipCode: "93641",
    city: "Miramonte",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJDWfzmM4SlYARD_YVKCiBoWg",
  },
  {
    zipCode: "93642",
    city: "Mono Hot Springs",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJL4thz0jolYARj89rs8VdH1k",
  },
  {
    zipCode: "93646",
    city: "Orange Cove",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJqaJH0W4blYARCuxFOSsE2_E",
  },
  {
    zipCode: "93648",
    city: "Parlier",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJe7KLYRf7lIARkmVu6dafSg0",
  },
  {
    zipCode: "93649",
    city: "Piedra",
    county: "Fresno",
    type: "P.O.Box",
    placeId: "ChIJ2_k6YvKqlYARSnEOKvMiMIU",
  },
  {
    zipCode: "93651",
    city: "Prather",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJDVSIgYhJlIARAH1KCKtkEho",
  },
  {
    zipCode: "93652",
    city: "Raisin City",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJCR-f8gyJlIARnS8WsCmPE0M",
  },
  {
    zipCode: "93654",
    city: "Reedley",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJoWedRMLilIARDr0VtAyxHDs",
  },
  {
    zipCode: "93656",
    city: "Riverdale",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJrcaBbc6FlIAR4Lt6pkHmflw",
  },
  {
    zipCode: "93660",
    city: "San Joaquin",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJJ6kWMl-Ak4AR9fnmX0dK9uI",
  },
  {
    zipCode: "93657",
    city: "Sanger",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJa43vyLXwlIARgjfD2nur4eE",
  },
  {
    zipCode: "93662",
    city: "Selma",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJ5fCkoP_vlIARlaioM4pZy7E",
  },
  {
    zipCode: "93664",
    city: "Shaver Lake",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJr3kc0w22lYARfSrrBe3mohA",
  },
  {
    zipCode: "93675",
    city: "Squaw Valley",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJDb_Ox0wOlYAR8LnkxM0IzqM",
  },
  {
    zipCode: "93667",
    city: "Tollhouse",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJyTgnfb1dlIARJREtRuEwGHI",
  },
  {
    zipCode: "93668",
    city: "Tranquillity",
    county: "Fresno",
    type: "Standard",
    placeId: "ChIJQ_WOcyCEk4ARWIzXDGA107I",
  },
  {
    zipCode: "95913",
    city: "Artois",
    county: "Glenn",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "95920",
    city: "Butte City",
    county: "Glenn",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95939",
    city: "Elk Creek",
    county: "Glenn",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95943",
    city: "Glenn",
    county: "Glenn",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95951",
    city: "Hamilton City",
    county: "Glenn",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "95963",
    city: "Orland",
    county: "Glenn",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95988",
    city: "Willows",
    county: "Glenn",
    type: "Standard",
    placeId: "ChIJb7v6OvG_PYgRksJHVI9x1cc",
  },
  {
    zipCode: "95511",
    city: "Alderpoint",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJDzy4VlSE1FQRLb1O38-dyJ4",
  },
  {
    zipCode: "95518",
    city: "Arcata",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJAei5Zw5X0VQRhoeFoY_Xin0",
  },
  {
    zipCode: "95521",
    city: "Arcata",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJAei5Zw5X0VQRhoeFoY_Xin0",
  },
  {
    zipCode: "95524",
    city: "Bayside",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJh-Wtd81X0VQRhhabZKskxac",
  },
  {
    zipCode: "95514",
    city: "Blocksburg",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ88UEYXSE1FQRJkscm73xeVM",
  },
  {
    zipCode: "95525",
    city: "Blue Lake",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJrZn48FhY0VQRfnKD2pgWxCQ",
  },
  {
    zipCode: "95526",
    city: "Bridgeville",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJO3FPMYp31FQRTNDqQ1PXL7g",
  },
  {
    zipCode: "95528",
    city: "Carlotta",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ35ql0SgN1FQR4BwRodtxP1Y",
  },
  {
    zipCode: "95534",
    city: "Cutten",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJlwkiDVv_01QRet-BFdMnWMM",
  },
  {
    zipCode: "95501",
    city: "Eureka",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJp07_5oP_01QRlQYpuGYHhio",
  },
  {
    zipCode: "95502",
    city: "Eureka",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJp07_5oP_01QRlQYpuGYHhio",
  },
  {
    zipCode: "95503",
    city: "Eureka",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJp07_5oP_01QRlQYpuGYHhio",
  },
  {
    zipCode: "95536",
    city: "Ferndale",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ3QrRGeMQ1FQRSfJoiguXZFo",
  },
  {
    zipCode: "95537",
    city: "Fields Landing",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJ5TvzD2oB1FQRBZuhDxIy954",
  },
  {
    zipCode: "95540",
    city: "Fortuna",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJz2yxfYsK1FQRbt_j9Gh3lDM",
  },
  {
    zipCode: "95542",
    city: "Garberville",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJqwoWE6js1FQRvB4_shlMlp8",
  },
  {
    zipCode: "95545",
    city: "Honeydew",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJQe53wtZY1FQRHot9Ho7qCVo",
  },
  {
    zipCode: "95546",
    city: "Hoopa",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJu71m3nZ20VQRGdF5Jjdw_zI",
  },
  {
    zipCode: "95547",
    city: "Hydesville",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ7evOyVUM1FQRvaTzQSRdNX0",
  },
  {
    zipCode: "95549",
    city: "Kneeland",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJyYk-8-r701QRiTivSWHTFCw",
  },
  {
    zipCode: "95550",
    city: "Korbel",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJCzbJQINY0VQRxO1OW7M5jN0",
  },
  {
    zipCode: "95551",
    city: "Loleta",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJFRWu-34E1FQRYqg6Ckf7Ruw",
  },
  {
    zipCode: "95519",
    city: "Mckinleyville",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJyUJ54cZa0VQRdm8Sxi4JNGA",
  },
  {
    zipCode: "95553",
    city: "Miranda",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJJX282IaL1FQRlvGhu3Bipj8",
  },
  {
    zipCode: "95554",
    city: "Myers Flat",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJBavjuIiJ1FQRTxSsxeUMmwQ",
  },
  {
    zipCode: "95555",
    city: "Orick",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ01obAVYF0VQR3YH_-3gZPdg",
  },
  {
    zipCode: "95556",
    city: "Orleans",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJm-F0t9-i0VQR9Wtb24PEzII",
  },
  {
    zipCode: "95558",
    city: "Petrolia",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJZQ87T-BE1FQRboUvxXISqWI",
  },
  {
    zipCode: "95559",
    city: "Phillipsville",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJl4H4pNiN1FQRov0Oatbk23E",
  },
  {
    zipCode: "95569",
    city: "Redcrest",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ1bczEzhl1FQR3iS7PkomcLE",
  },
  {
    zipCode: "95560",
    city: "Redway",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJxTG8erfy1FQR3uM5B8EqL5A",
  },
  {
    zipCode: "95562",
    city: "Rio Dell",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJO6WeWjxt1FQRedkFXncrOAo",
  },
  {
    zipCode: "95564",
    city: "Samoa",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ39LA8w9V0VQRhAsAxMVwDfE",
  },
  {
    zipCode: "95565",
    city: "Scotia",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJDdz6JQFt1FQRVgNAIv83SzA",
  },
  {
    zipCode: "95570",
    city: "Trinidad",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJkR8FdQNB0VQRm64T_lv1g1g",
  },
  {
    zipCode: "95571",
    city: "Weott",
    county: "Humboldt",
    type: "P.O.Box",
    placeId: "ChIJGf2CBJlj1FQRYNxY0TkmLsY",
  },
  {
    zipCode: "95589",
    city: "Whitethorn",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJ7Xppbifm1FQRz8IqmNmzBq4",
  },
  {
    zipCode: "95573",
    city: "Willow Creek",
    county: "Humboldt",
    type: "Standard",
    placeId: "ChIJw2eiW7t90VQRynkQcuwrpa4",
  },
  {
    zipCode: "92222",
    city: "Bard",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJA57FiGPz1oARK0Nnk7Kx2RA",
  },
  {
    zipCode: "92227",
    city: "Brawley",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJdZIog5RP14AR8djOTTicqrE",
  },
  {
    zipCode: "92231",
    city: "Calexico",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJBZvPqtJl14ARl7hvYSwwTl4",
  },
  {
    zipCode: "92232",
    city: "Calexico",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJBZvPqtJl14ARl7hvYSwwTl4",
  },
  {
    zipCode: "92233",
    city: "Calipatria",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJXXQBVldS14ARUH1FVhQbprM",
  },
  {
    zipCode: "92243",
    city: "El Centro",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJvbtCJpxf14ARr_2fJLhIBMs",
  },
  {
    zipCode: "92244",
    city: "El Centro",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJvbtCJpxf14ARr_2fJLhIBMs",
  },
  {
    zipCode: "92249",
    city: "Heber",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJm1tsq8tl14AR4Dk_21njAwc",
  },
  {
    zipCode: "92250",
    city: "Holtville",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJ5YhQmhkU14ARTAUs-nOdytw",
  },
  {
    zipCode: "92251",
    city: "Imperial",
    county: "Imperial",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92257",
    city: "Niland",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJZ21wN8mt0IAR2Gy8Yp2buOA",
  },
  {
    zipCode: "92259",
    city: "Ocotillo",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92266",
    city: "Palo Verde",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJpX7dJNld14ARVqDe6SEXZO8",
  },
  {
    zipCode: "92275",
    city: "Salton City",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJzWNywMgV2oARcXL5fsKTUjI",
  },
  {
    zipCode: "92273",
    city: "Seeley",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92281",
    city: "Westmorland",
    county: "Imperial",
    type: "P.O.Box",
    placeId: "ChIJxbbW61hW14ARpxmQQdXIhBE",
  },
  {
    zipCode: "92283",
    city: "Winterhaven",
    county: "Imperial",
    type: "Standard",
    placeId: "ChIJvRcnFTbf1oARWr7b7cdXIAs",
  },
  {
    zipCode: "93513",
    city: "Big Pine",
    county: "Inyo",
    type: "Standard",
    placeId: "ChIJzbYFPuNJvoARk60krWVy870",
  },
  {
    zipCode: "93514",
    city: "Bishop",
    county: "Inyo",
    type: "Standard",
    placeId: "ChIJB2SmngEWvoARFonplU0ezRM",
  },
  {
    zipCode: "93515",
    city: "Bishop",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJB2SmngEWvoARFonplU0ezRM",
  },
  {
    zipCode: "93522",
    city: "Darwin",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJtc-H-PrqwIARuWuYh5wF5FM",
  },
  {
    zipCode: "92328",
    city: "Death Valley",
    county: "Inyo",
    type: "Standard",
    placeId: "ChIJsf-PHqI5x4ARJd0j14NziRw",
  },
  {
    zipCode: "93526",
    city: "Independence",
    county: "Inyo",
    type: "Standard",
    placeId: "ChIJJ0MQzNy-v4ARkwgd0Yqjfos",
  },
  {
    zipCode: "93530",
    city: "Keeler",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJx4SWNCKBwIARed2M7rfxdww",
  },
  {
    zipCode: "93542",
    city: "Little Lake",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJ9Sdze57_wIARocI1Nr_Vl6I",
  },
  {
    zipCode: "93545",
    city: "Lone Pine",
    county: "Inyo",
    type: "Standard",
    placeId: "ChIJUwbyE5wtvoAReI8CYGNh-AQ",
  },
  {
    zipCode: "93549",
    city: "Olancha",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJKSTjV1lhwIAROYzDwMSuu0o",
  },
  {
    zipCode: "92384",
    city: "Shoshone",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJnxZz869XxoARlaPYlCr5LwY",
  },
  {
    zipCode: "92389",
    city: "Tecopa",
    county: "Inyo",
    type: "P.O.Box",
    placeId: "ChIJW5qMgYtvxoARFF32FwwjoRU",
  },
  {
    zipCode: "93203",
    city: "Arvin",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJtblJXl4O6oARLd_3Pvsxn-s",
  },
  {
    zipCode: "93301",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93302",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93303",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93304",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93305",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93306",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93307",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93308",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93309",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93311",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93312",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93313",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93314",
    city: "Bakersfield",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93380",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93383",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93384",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93385",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93386",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93387",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93388",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93389",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93390",
    city: "Bakersfield",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcuUuchr6oARyHT-mAkMNlk",
  },
  {
    zipCode: "93205",
    city: "Bodfish",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJY-rmREDDwYARvO58V9JF-6c",
  },
  {
    zipCode: "93516",
    city: "Boron",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJYzDgw9XGw4ARwof6aLjokNQ",
  },
  {
    zipCode: "93596",
    city: "Boron",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJYzDgw9XGw4ARwof6aLjokNQ",
  },
  {
    zipCode: "93206",
    city: "Buttonwillow",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJZ2Z0uKOr64ARcLsKoc3PAdM",
  },
  {
    zipCode: "93518",
    city: "Caliente",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJPx0UvkriwYAR_lgT2uvY8gc",
  },
  {
    zipCode: "93504",
    city: "California City",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJQwmmE9IpwoARMgv1O7TeWDQ",
  },
  {
    zipCode: "93505",
    city: "California City",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJQwmmE9IpwoARMgv1O7TeWDQ",
  },
  {
    zipCode: "93519",
    city: "Cantil",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJDcA9JIOBwYARnz4d4W0ywBk",
  },
  {
    zipCode: "93215",
    city: "Delano",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJI6LNNo3l6oARPHFY6zcggz0",
  },
  {
    zipCode: "93216",
    city: "Delano",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJI6LNNo3l6oARPHFY6zcggz0",
  },
  {
    zipCode: "93220",
    city: "Edison",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJV4Cbz0Jt6oARJmkvDj49VeU",
  },
  {
    zipCode: "93523",
    city: "Edwards",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJ68MpOrBKwoAR6EYT2bsjcN8",
  },
  {
    zipCode: "93524",
    city: "Edwards",
    county: "Kern",
    type: "Unique",
    placeId: "ChIJ68MpOrBKwoAR6EYT2bsjcN8",
  },
  {
    zipCode: "93224",
    city: "Fellows",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJ86u0UQq364ARmrzZPRu0tQA",
  },
  {
    zipCode: "93225",
    city: "Frazier Park",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJyZ5YCG2L6YARkM0DUEs5saY",
  },
  {
    zipCode: "93226",
    city: "Glennville",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJD_fDv06A6oAR-cS27R0cLU0",
  },
  {
    zipCode: "93527",
    city: "Inyokern",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJBQ7T404IwYARfCtEsc4n9Eg",
  },
  {
    zipCode: "93528",
    city: "Johannesburg",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJbykc03FhwYAR_iUHl6RWzZ8",
  },
  {
    zipCode: "93531",
    city: "Keene",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJFYyLxQr7wYARr9p2Ljo1kQ8",
  },
  {
    zipCode: "93238",
    city: "Kernville",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJS6Qg2hbGwYARJOCOWxCgNMw",
  },
  {
    zipCode: "93240",
    city: "Lake Isabella",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJZdO78K_PwYAR4VG6myhWz5c",
  },
  {
    zipCode: "93241",
    city: "Lamont",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJ0dcDJdYI6oARvixVkgcO9W8",
  },
  {
    zipCode: "93243",
    city: "Lebec",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJcSndKPHv6YARbAXZyi33nM8",
  },
  {
    zipCode: "93249",
    city: "Lost Hills",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJo_jq2owR64AR2i-Ghk8hpZk",
  },
  {
    zipCode: "93252",
    city: "Maricopa",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJrz_nRMMy6oAR6Iig0KIs4-U",
  },
  {
    zipCode: "93250",
    city: "Mc Farland",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJodIaD2v66oAR3CE5Tymivck",
  },
  {
    zipCode: "93251",
    city: "Mc Kittrick",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJ74tcX62g64ARN0Q8FnUiA7w",
  },
  {
    zipCode: "93501",
    city: "Mojave",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJR_9x9lwhwoARoLz_vXOA39Y",
  },
  {
    zipCode: "93502",
    city: "Mojave",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJR_9x9lwhwoARoLz_vXOA39Y",
  },
  {
    zipCode: "93255",
    city: "Onyx",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJk0qghCiwwYARR2-3Q-bW4rw",
  },
  {
    zipCode: "93222",
    city: "Pine Mountain Club",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJPQZOV7GO6YAR-Rhi9iPsVfI",
  },
  {
    zipCode: "93554",
    city: "Randsburg",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJ2UbrKK1kwYARE-1bTFCVSHA",
  },
  {
    zipCode: "93558",
    city: "Red Mountain",
    county: "Kern",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "93555",
    city: "Ridgecrest",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJpYoMALxswYARuq4QZwNzstw",
  },
  {
    zipCode: "93556",
    city: "Ridgecrest",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJpYoMALxswYARuq4QZwNzstw",
  },
  {
    zipCode: "93560",
    city: "Rosamond",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJOw9A7Dc_woARcFM_21njAwc",
  },
  {
    zipCode: "93263",
    city: "Shafter",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJJyzQN2FF6oARc3yj99la-30",
  },
  {
    zipCode: "93268",
    city: "Taft",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJbXV0To826oARE6j5ba_oxxI",
  },
  {
    zipCode: "93561",
    city: "Tehachapi",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJEUbfWTT2wYAR1JxKuNZ0qU8",
  },
  {
    zipCode: "93581",
    city: "Tehachapi",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJEUbfWTT2wYAR1JxKuNZ0qU8",
  },
  {
    zipCode: "93276",
    city: "Tupman",
    county: "Kern",
    type: "P.O.Box",
    placeId: "ChIJFYshIFBK6oARoq7Uorm0mE4",
  },
  {
    zipCode: "93280",
    city: "Wasco",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJkwGqB2L-6oARpRr5lK_2yAM",
  },
  {
    zipCode: "93283",
    city: "Weldon",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJ8wU4Y3bGwYARcjT7eMPSvjM",
  },
  {
    zipCode: "93285",
    city: "Wofford Heights",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJt3vkEJ3NwYAR_rNFjQRQzeY",
  },
  {
    zipCode: "93287",
    city: "Woody",
    county: "Kern",
    type: "Standard",
    placeId: "ChIJH4i5WkqG6oAR_tbOop9LEn8",
  },
  {
    zipCode: "93202",
    city: "Armona",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJrw8vxFDBlIAR2OsvEY8INJ8",
  },
  {
    zipCode: "93204",
    city: "Avenal",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJPcFHhkaqlIARZCfZoG8eu6U",
  },
  {
    zipCode: "93212",
    city: "Corcoran",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJKRQAexUt64AR5sLkLUyy3s0",
  },
  {
    zipCode: "93230",
    city: "Hanford",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJU3qvGXvDlIARkPRjxeSnFhs",
  },
  {
    zipCode: "93232",
    city: "Hanford",
    county: "Kings",
    type: "P.O.Box",
    placeId: "ChIJU3qvGXvDlIARkPRjxeSnFhs",
  },
  {
    zipCode: "93239",
    city: "Kettleman City",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJgxEPBLqylIAR5HTouxBJWDk",
  },
  {
    zipCode: "93245",
    city: "Lemoore",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJ5RX-fkS_lIAR72ebKlYc9TY",
  },
  {
    zipCode: "93246",
    city: "Lemoore",
    county: "Kings",
    type: "Unique",
    placeId: "ChIJ5RX-fkS_lIAR72ebKlYc9TY",
  },
  {
    zipCode: "93266",
    city: "Stratford",
    county: "Kings",
    type: "Standard",
    placeId: "ChIJH171wTq2lIARslwIfe3Utyw",
  },
  {
    zipCode: "95422",
    city: "Clearlake",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJlcFgQJWLg4ARtum188bqasE",
  },
  {
    zipCode: "95423",
    city: "Clearlake Oaks",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJSVSZAaiNg4AR-Qcrr5U0nIk",
  },
  {
    zipCode: "95424",
    city: "Clearlake Park",
    county: "Lake",
    type: "P.O.Box",
    placeId: "ChIJo0BOeojtg4ARIxa_m15L_1A",
  },
  {
    zipCode: "95426",
    city: "Cobb",
    county: "Lake",
    type: "P.O.Box",
    placeId: "ChIJidNqLjcyGIgR6dJWmvTIvww",
  },
  {
    zipCode: "95435",
    city: "Finley",
    county: "Lake",
    type: "P.O.Box",
    placeId: "ChIJAVQWbtpfLYgRhisCi5LEPZw",
  },
  {
    zipCode: "95443",
    city: "Glenhaven",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJF7nqWaTyg4ARRbrOaxzu5k4",
  },
  {
    zipCode: "95467",
    city: "Hidden Valley Lake",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJ9Smo3A1whIARwbp6ePa9-k4",
  },
  {
    zipCode: "95451",
    city: "Kelseyville",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJ2bdyaB7ug4ARwEVe6KAIiPM",
  },
  {
    zipCode: "95453",
    city: "Lakeport",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJv_wo3d3lg4AR8KIqvZJFP6w",
  },
  {
    zipCode: "95457",
    city: "Lower Lake",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJwRCoMhR2hIARH6UfSHe2AtI",
  },
  {
    zipCode: "95458",
    city: "Lucerne",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJzwVhkyNYhUcRcBULwbU0C6Y",
  },
  {
    zipCode: "95461",
    city: "Middletown",
    county: "Lake",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95464",
    city: "Nice",
    county: "Lake",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95485",
    city: "Upper Lake",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJ29ORjjPDg4ARSqbcWOK9c6c",
  },
  {
    zipCode: "95493",
    city: "Witter Springs",
    county: "Lake",
    type: "Standard",
    placeId: "ChIJm04I19nbg4AR7Qzhji_4Chc",
  },
  {
    zipCode: "96009",
    city: "Bieber",
    county: "Lassen",
    type: "P.O.Box",
    placeId: "ChIJlX8M-132zFQRh_DIZQUr42E",
  },
  {
    zipCode: "96109",
    city: "Doyle",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJD2Q_Jo2enoARBJ-VFG-tpMM",
  },
  {
    zipCode: "96113",
    city: "Herlong",
    county: "Lassen",
    type: "P.O.Box",
    placeId: "ChIJV95C5emBnoAR4H67i9YHAhE",
  },
  {
    zipCode: "96114",
    city: "Janesville",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJyQ7wzOK3nYARBFqcG5LFQ5E",
  },
  {
    zipCode: "96117",
    city: "Litchfield",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJUaWGSfjEnYARXP7XN0SA4vs",
  },
  {
    zipCode: "96119",
    city: "Madeline",
    county: "Lassen",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "96121",
    city: "Milford",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJayv4KMcynIARbpi51_CVJjs",
  },
  {
    zipCode: "96068",
    city: "Nubieber",
    county: "Lassen",
    type: "P.O.Box",
    placeId: "ChIJKwWj5lz3zFQRQ6NIrWbofXo",
  },
  {
    zipCode: "96123",
    city: "Ravendale",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJYY76CWCtzFQRonvwkTmhG3Q",
  },
  {
    zipCode: "96128",
    city: "Standish",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJAfNBxTLEnYARADEUJeOc6OU",
  },
  {
    zipCode: "96127",
    city: "Susanville",
    county: "Lassen",
    type: "P.O.Box",
    placeId: "ChIJ47422upznYARbugPIslMB0k",
  },
  {
    zipCode: "96130",
    city: "Susanville",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJ47422upznYARbugPIslMB0k",
  },
  {
    zipCode: "96132",
    city: "Termo",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJZwM-hc6xzFQROODRZ0gG_yk",
  },
  {
    zipCode: "96136",
    city: "Wendel",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJPX2aSSHXnYAR1P5AaMfH1aw",
  },
  {
    zipCode: "96137",
    city: "Westwood",
    county: "Lassen",
    type: "Standard",
    placeId: "ChIJYXG4gERwnYAR2YUUxks-QW4",
  },
  {
    zipCode: "93510",
    city: "Acton",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ72TQn532woARzGcGB6mge5g",
  },
  {
    zipCode: "91301",
    city: "Agoura Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlRyqIx4h6IAR1v3fLa72Dqw",
  },
  {
    zipCode: "91376",
    city: "Agoura Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJlRyqIx4h6IAR1v3fLa72Dqw",
  },
  {
    zipCode: "91801",
    city: "Alhambra",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91802",
    city: "Alhambra",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91803",
    city: "Alhambra",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91804",
    city: "Alhambra",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91896",
    city: "Alhambra",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91899",
    city: "Alhambra",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIbZmgz3FwoARZkZyerE4-Ns",
  },
  {
    zipCode: "91001",
    city: "Altadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJRUXtQLvCwoARhX06ubgzUIs",
  },
  {
    zipCode: "91003",
    city: "Altadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJRUXtQLvCwoARhX06ubgzUIs",
  },
  {
    zipCode: "91006",
    city: "Arcadia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJK8q408XbwoARiymu0ttQqEw",
  },
  {
    zipCode: "91007",
    city: "Arcadia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJK8q408XbwoARiymu0ttQqEw",
  },
  {
    zipCode: "91066",
    city: "Arcadia",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJK8q408XbwoARiymu0ttQqEw",
  },
  {
    zipCode: "91077",
    city: "Arcadia",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJK8q408XbwoARiymu0ttQqEw",
  },
  {
    zipCode: "90701",
    city: "Artesia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJhe3MBVQs3YARQyZP6i6i5Zs",
  },
  {
    zipCode: "90702",
    city: "Artesia",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJhe3MBVQs3YARQyZP6i6i5Zs",
  },
  {
    zipCode: "90704",
    city: "Avalon",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJrw4SODm_woAR362Sui6yl9o",
  },
  {
    zipCode: "91702",
    city: "Azusa",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ40sVQULpwoARW3UvffMbmiU",
  },
  {
    zipCode: "91706",
    city: "Baldwin Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJuawuuoDXwoAR_xcaQMKbVgo",
  },
  {
    zipCode: "90202",
    city: "Bell",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJkW_58pTOwoARGiQ2VqD0rhA",
  },
  {
    zipCode: "90201",
    city: "Bell Gardens",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJFZG_A9bNwoARm9_9Cfsrat4",
  },
  {
    zipCode: "90706",
    city: "Bellflower",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJc0O-9bAy3YARCZtI9k9VBrY",
  },
  {
    zipCode: "90707",
    city: "Bellflower",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJc0O-9bAy3YARCZtI9k9VBrY",
  },
  {
    zipCode: "90209",
    city: "Beverly Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJq0fR1gS8woAR0R4I_XnDx9Y",
  },
  {
    zipCode: "90210",
    city: "Beverly Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq0fR1gS8woAR0R4I_XnDx9Y",
  },
  {
    zipCode: "90211",
    city: "Beverly Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq0fR1gS8woAR0R4I_XnDx9Y",
  },
  {
    zipCode: "90212",
    city: "Beverly Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq0fR1gS8woAR0R4I_XnDx9Y",
  },
  {
    zipCode: "90213",
    city: "Beverly Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJq0fR1gS8woAR0R4I_XnDx9Y",
  },
  {
    zipCode: "91501",
    city: "Burbank",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91502",
    city: "Burbank",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91503",
    city: "Burbank",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91504",
    city: "Burbank",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91505",
    city: "Burbank",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91506",
    city: "Burbank",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91507",
    city: "Burbank",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91508",
    city: "Burbank",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91510",
    city: "Burbank",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91521",
    city: "Burbank",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91522",
    city: "Burbank",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91523",
    city: "Burbank",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91526",
    city: "Burbank",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJlcUYKBWVwoAR1IofkK-RdzA",
  },
  {
    zipCode: "91302",
    city: "Calabasas",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJwdPvFyOewoARrhXmb8LiomE",
  },
  {
    zipCode: "91372",
    city: "Calabasas",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJwdPvFyOewoARrhXmb8LiomE",
  },
  {
    zipCode: "91303",
    city: "Canoga Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQ_UeYxScwoARAZGazbRh1_A",
  },
  {
    zipCode: "91304",
    city: "Canoga Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQ_UeYxScwoARAZGazbRh1_A",
  },
  {
    zipCode: "91305",
    city: "Canoga Park",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQ_UeYxScwoARAZGazbRh1_A",
  },
  {
    zipCode: "91309",
    city: "Canoga Park",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQ_UeYxScwoARAZGazbRh1_A",
  },
  {
    zipCode: "91351",
    city: "Canyon Country",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJyZnwdGOIwoARfm4SJWgftaA",
  },
  {
    zipCode: "91386",
    city: "Canyon Country",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJyZnwdGOIwoARfm4SJWgftaA",
  },
  {
    zipCode: "91387",
    city: "Canyon Country",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJyZnwdGOIwoARfm4SJWgftaA",
  },
  {
    zipCode: "90745",
    city: "Carson",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJfcS6fx7LwoARZYDiqXgXL6E",
  },
  {
    zipCode: "90746",
    city: "Carson",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJfcS6fx7LwoARZYDiqXgXL6E",
  },
  {
    zipCode: "90747",
    city: "Carson",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJfcS6fx7LwoARZYDiqXgXL6E",
  },
  {
    zipCode: "90749",
    city: "Carson",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJfcS6fx7LwoARZYDiqXgXL6E",
  },
  {
    zipCode: "90895",
    city: "Carson",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJfcS6fx7LwoARZYDiqXgXL6E",
  },
  {
    zipCode: "91310",
    city: "Castaic",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJL_Cs7iF_woARPg7H6-JPhGQ",
  },
  {
    zipCode: "91384",
    city: "Castaic",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJL_Cs7iF_woARPg7H6-JPhGQ",
  },
  {
    zipCode: "90703",
    city: "Cerritos",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJSzhb9wAt3YARAT6T3UiVSQ4",
  },
  {
    zipCode: "91311",
    city: "Chatsworth",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJAU5R0MacwoARrI_xnCmhhfk",
  },
  {
    zipCode: "91313",
    city: "Chatsworth",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJAU5R0MacwoARrI_xnCmhhfk",
  },
  {
    zipCode: "91714",
    city: "City Of Industry",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJpTpWIA_WwoARYnTqyCoWLIs",
  },
  {
    zipCode: "91715",
    city: "City Of Industry",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJpTpWIA_WwoARYnTqyCoWLIs",
  },
  {
    zipCode: "91716",
    city: "City Of Industry",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJpTpWIA_WwoARYnTqyCoWLIs",
  },
  {
    zipCode: "91711",
    city: "Claremont",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJX_ane5Q6w4ARTTe8KondetU",
  },
  {
    zipCode: "90220",
    city: "Compton",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJTVsdf2vLwoARQstbkZrOawU",
  },
  {
    zipCode: "90221",
    city: "Compton",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJTVsdf2vLwoARQstbkZrOawU",
  },
  {
    zipCode: "90222",
    city: "Compton",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJTVsdf2vLwoARQstbkZrOawU",
  },
  {
    zipCode: "90223",
    city: "Compton",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJTVsdf2vLwoARQstbkZrOawU",
  },
  {
    zipCode: "90224",
    city: "Compton",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJTVsdf2vLwoARQstbkZrOawU",
  },
  {
    zipCode: "91722",
    city: "Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWeZXWVEow4AR7oAZ5JcrAJE",
  },
  {
    zipCode: "91723",
    city: "Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWeZXWVEow4AR7oAZ5JcrAJE",
  },
  {
    zipCode: "91724",
    city: "Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWeZXWVEow4AR7oAZ5JcrAJE",
  },
  {
    zipCode: "90230",
    city: "Culver City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJnXN32x66woAR_lnrS9HphTE",
  },
  {
    zipCode: "90231",
    city: "Culver City",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJnXN32x66woAR_lnrS9HphTE",
  },
  {
    zipCode: "90232",
    city: "Culver City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJnXN32x66woAR_lnrS9HphTE",
  },
  {
    zipCode: "91765",
    city: "Diamond Bar",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqSn3Qo4sw4ARqdd90SjAiOo",
  },
  {
    zipCode: "90239",
    city: "Downey",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJkSIIHkzMwoARCp14FianQJE",
  },
  {
    zipCode: "90240",
    city: "Downey",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJkSIIHkzMwoARCp14FianQJE",
  },
  {
    zipCode: "90241",
    city: "Downey",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJkSIIHkzMwoARCp14FianQJE",
  },
  {
    zipCode: "90242",
    city: "Downey",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJkSIIHkzMwoARCp14FianQJE",
  },
  {
    zipCode: "91008",
    city: "Duarte",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqe6-YuDYwoAR7XKVWP58PtI",
  },
  {
    zipCode: "91009",
    city: "Duarte",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqe6-YuDYwoAR7XKVWP58PtI",
  },
  {
    zipCode: "91010",
    city: "Duarte",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqe6-YuDYwoAR7XKVWP58PtI",
  },
  {
    zipCode: "91731",
    city: "El Monte",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ79D2Y6TQwoARTTewpY4BD2s",
  },
  {
    zipCode: "91732",
    city: "El Monte",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ79D2Y6TQwoARTTewpY4BD2s",
  },
  {
    zipCode: "91734",
    city: "El Monte",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ79D2Y6TQwoARTTewpY4BD2s",
  },
  {
    zipCode: "91735",
    city: "El Monte",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJ79D2Y6TQwoARTTewpY4BD2s",
  },
  {
    zipCode: "90245",
    city: "El Segundo",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJrTzSGUexwoARttmLvMdiBYw",
  },
  {
    zipCode: "91316",
    city: "Encino",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq-KwCjeYwoARLkJopGarvlY",
  },
  {
    zipCode: "91416",
    city: "Encino",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJq-KwCjeYwoARLkJopGarvlY",
  },
  {
    zipCode: "91426",
    city: "Encino",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJq-KwCjeYwoARLkJopGarvlY",
  },
  {
    zipCode: "91436",
    city: "Encino",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq-KwCjeYwoARLkJopGarvlY",
  },
  {
    zipCode: "90247",
    city: "Gardena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJCd2bU4G1woARkcpitRo3GcY",
  },
  {
    zipCode: "90248",
    city: "Gardena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJCd2bU4G1woARkcpitRo3GcY",
  },
  {
    zipCode: "90249",
    city: "Gardena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJCd2bU4G1woARkcpitRo3GcY",
  },
  {
    zipCode: "91201",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91202",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91203",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91204",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91205",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91206",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91207",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91208",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91209",
    city: "Glendale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91210",
    city: "Glendale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91221",
    city: "Glendale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91222",
    city: "Glendale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91225",
    city: "Glendale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91226",
    city: "Glendale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqYPwyDLAwoARpibJ_p6hP-U",
  },
  {
    zipCode: "91740",
    city: "Glendora",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJX7Ts8w0mw4AR7I7B-b7UyPA",
  },
  {
    zipCode: "91741",
    city: "Glendora",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJX7Ts8w0mw4AR7I7B-b7UyPA",
  },
  {
    zipCode: "91344",
    city: "Granada Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJZzVyNM2awoARI0eqJutpXFc",
  },
  {
    zipCode: "91394",
    city: "Granada Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJZzVyNM2awoARI0eqJutpXFc",
  },
  {
    zipCode: "91745",
    city: "Hacienda Heights",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJiXfDKNTVwoARKFWiwN8i3-M",
  },
  {
    zipCode: "90710",
    city: "Harbor City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ8-IhVXRK3YARxXpGvakgYRs",
  },
  {
    zipCode: "90716",
    city: "Hawaiian Gardens",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJY5BQNNEt3YAR9h3oVjh0FXs",
  },
  {
    zipCode: "90250",
    city: "Hawthorne",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ8S7MWiu0woARAbM4zB1xwWI",
  },
  {
    zipCode: "90251",
    city: "Hawthorne",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ8S7MWiu0woARAbM4zB1xwWI",
  },
  {
    zipCode: "90254",
    city: "Hermosa Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJrTm74X6zwoARQM6_cTJOEis",
  },
  {
    zipCode: "90255",
    city: "Huntington Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJhcU5VjDJwoARDNU_kUzPP0w",
  },
  {
    zipCode: "90301",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90302",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90303",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90304",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90305",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90306",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90307",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90308",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90309",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90310",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90311",
    city: "Inglewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "90312",
    city: "Inglewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJjd1LJ1a2woARcDGu3A-zJwc",
  },
  {
    zipCode: "91011",
    city: "La Canada Flintridge",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJEcDTpOHpwoAR1lGHwDbx4_U",
  },
  {
    zipCode: "91012",
    city: "La Canada Flintridge",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJEcDTpOHpwoAR1lGHwDbx4_U",
  },
  {
    zipCode: "91214",
    city: "La Crescenta",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJoYuZImrqwoARtbfURGU0BUo",
  },
  {
    zipCode: "91224",
    city: "La Crescenta",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJoYuZImrqwoARtbfURGU0BUo",
  },
  {
    zipCode: "90637",
    city: "La Mirada",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ45-rnVK_woAR-0xoHiQu1sw",
  },
  {
    zipCode: "90638",
    city: "La Mirada",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ45-rnVK_woAR-0xoHiQu1sw",
  },
  {
    zipCode: "90639",
    city: "La Mirada",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJ45-rnVK_woAR-0xoHiQu1sw",
  },
  {
    zipCode: "91744",
    city: "La Puente",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGbd3VkbWwoARlwhuaE5h9TM",
  },
  {
    zipCode: "91746",
    city: "La Puente",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGbd3VkbWwoARlwhuaE5h9TM",
  },
  {
    zipCode: "91747",
    city: "La Puente",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGbd3VkbWwoARlwhuaE5h9TM",
  },
  {
    zipCode: "91749",
    city: "La Puente",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGbd3VkbWwoARlwhuaE5h9TM",
  },
  {
    zipCode: "91750",
    city: "La Verne",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJTZXDcFwlw4ARTFswFnNibZs",
  },
  {
    zipCode: "93532",
    city: "Lake Hughes",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJvxD8ylZuwoARDsEWzTLKEHM",
  },
  {
    zipCode: "90711",
    city: "Lakewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJHYGo5F8y3YARxXjJKWCoW8c",
  },
  {
    zipCode: "90712",
    city: "Lakewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJHYGo5F8y3YARxXjJKWCoW8c",
  },
  {
    zipCode: "90713",
    city: "Lakewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJHYGo5F8y3YARxXjJKWCoW8c",
  },
  {
    zipCode: "90714",
    city: "Lakewood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJHYGo5F8y3YARxXjJKWCoW8c",
  },
  {
    zipCode: "90715",
    city: "Lakewood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJHYGo5F8y3YARxXjJKWCoW8c",
  },
  {
    zipCode: "93534",
    city: "Lancaster",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "93535",
    city: "Lancaster",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "93536",
    city: "Lancaster",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "93539",
    city: "Lancaster",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "93584",
    city: "Lancaster",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "93586",
    city: "Lancaster",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQYDoaLFawoARBz4Lfc9yxFg",
  },
  {
    zipCode: "90260",
    city: "Lawndale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJh9_pck60woAR9vxrmYOvDqo",
  },
  {
    zipCode: "90261",
    city: "Lawndale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJh9_pck60woAR9vxrmYOvDqo",
  },
  {
    zipCode: "93543",
    city: "Littlerock",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJz6iAYNb9woARZYmrxkZpUxs",
  },
  {
    zipCode: "93544",
    city: "Llano",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJkZhw1uyewoARKBy9-s9vo2U",
  },
  {
    zipCode: "90717",
    city: "Lomita",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ11yAWGVK3YARkdngmEUwcS8",
  },
  {
    zipCode: "90801",
    city: "Long Beach",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90802",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90803",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90804",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90805",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90806",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90807",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90808",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90809",
    city: "Long Beach",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90810",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90813",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90814",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90815",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90822",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90831",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90832",
    city: "Long Beach",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90833",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90840",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90842",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90844",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90846",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90847",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90848",
    city: "Long Beach",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90853",
    city: "Long Beach",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJWdeZQOjKwoARqo8qxPo6AKE",
  },
  {
    zipCode: "90001",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90002",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90003",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90004",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90005",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90006",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90007",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90008",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90009",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90010",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90011",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90012",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90013",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90014",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90015",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90016",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90017",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90018",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90019",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90020",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90021",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90022",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90023",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90024",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90025",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90026",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90027",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90028",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90029",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90030",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90031",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90032",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90033",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90034",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90035",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90036",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90037",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90038",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90039",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90040",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90041",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90042",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90043",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90044",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90045",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90046",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90047",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90048",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90049",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90050",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90051",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90052",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90053",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90054",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90055",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90056",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90057",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90058",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90059",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90060",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90061",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90062",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90063",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90064",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90065",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90066",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90067",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90068",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90070",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90071",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90072",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90073",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90074",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90075",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90076",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90077",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90078",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90079",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90080",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90081",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90082",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90083",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90084",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90086",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90087",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90088",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90089",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90091",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90093",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90095",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90096",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90099",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90134",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90189",
    city: "Los Angeles",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
  },
  {
    zipCode: "90262",
    city: "Lynwood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJZ6ONppfLwoARqwb6abQj9ug",
  },
  {
    zipCode: "90263",
    city: "Malibu",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJP9YI-akd6IARWozqsnEtt5M",
  },
  {
    zipCode: "90264",
    city: "Malibu",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJP9YI-akd6IARWozqsnEtt5M",
  },
  {
    zipCode: "90265",
    city: "Malibu",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJP9YI-akd6IARWozqsnEtt5M",
  },
  {
    zipCode: "90266",
    city: "Manhattan Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJL2Ow4sWzwoARIEUV9NRRAwc",
  },
  {
    zipCode: "90267",
    city: "Manhattan Beach",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJL2Ow4sWzwoARIEUV9NRRAwc",
  },
  {
    zipCode: "90292",
    city: "Marina Del Rey",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJLbMF8IqwwoARns4Lm7HaVpc",
  },
  {
    zipCode: "90295",
    city: "Marina Del Rey",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJLbMF8IqwwoARns4Lm7HaVpc",
  },
  {
    zipCode: "90270",
    city: "Maywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJFa5PUMPOwoARN3_-Bdevm8M",
  },
  {
    zipCode: "91345",
    city: "Mission Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIZlxE2qQwoARnDat1UQFME8",
  },
  {
    zipCode: "91346",
    city: "Mission Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIZlxE2qQwoARnDat1UQFME8",
  },
  {
    zipCode: "91395",
    city: "Mission Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIZlxE2qQwoARnDat1UQFME8",
  },
  {
    zipCode: "91016",
    city: "Monrovia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ2eJeOzfZwoARhfe1CC46JE8",
  },
  {
    zipCode: "91017",
    city: "Monrovia",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ2eJeOzfZwoARhfe1CC46JE8",
  },
  {
    zipCode: "90640",
    city: "Montebello",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJhV4fRirOwoAR2prNNJkxiH4",
  },
  {
    zipCode: "91754",
    city: "Monterey Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJD8jobpnFwoARZZBZzayvgTw",
  },
  {
    zipCode: "91755",
    city: "Monterey Park",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJD8jobpnFwoARZZBZzayvgTw",
  },
  {
    zipCode: "91756",
    city: "Monterey Park",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJD8jobpnFwoARZZBZzayvgTw",
  },
  {
    zipCode: "91020",
    city: "Montrose",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJoYuZImrqwoARtbfURGU0BUo",
  },
  {
    zipCode: "91021",
    city: "Montrose",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJoYuZImrqwoARtbfURGU0BUo",
  },
  {
    zipCode: "91023",
    city: "Mount Wilson",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJDZ0dbnjdwoARb3ULkyHuA9Y",
  },
  {
    zipCode: "91321",
    city: "Newhall",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJU8LvmtOGwoARFioS3vhtBxQ",
  },
  {
    zipCode: "91322",
    city: "Newhall",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJU8LvmtOGwoARFioS3vhtBxQ",
  },
  {
    zipCode: "91343",
    city: "North Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJa4rfFieUwoARTXj3kUbSO04",
  },
  {
    zipCode: "91393",
    city: "North Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa4rfFieUwoARTXj3kUbSO04",
  },
  {
    zipCode: "91601",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91602",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91603",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91605",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91606",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91609",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91611",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91612",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91615",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91616",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91618",
    city: "North Hollywood",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0TEA7NOVwoARtPDh3nGzOC0",
  },
  {
    zipCode: "91324",
    city: "Northridge",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "91325",
    city: "Northridge",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "91327",
    city: "Northridge",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "91328",
    city: "Northridge",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "91329",
    city: "Northridge",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "91330",
    city: "Northridge",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJiUtZIleawoARUmFdWw-rsjM",
  },
  {
    zipCode: "90650",
    city: "Norwalk",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0_EvDMzSwoARhERzb-kP0qc",
  },
  {
    zipCode: "90651",
    city: "Norwalk",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0_EvDMzSwoARhERzb-kP0qc",
  },
  {
    zipCode: "90652",
    city: "Norwalk",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0_EvDMzSwoARhERzb-kP0qc",
  },
  {
    zipCode: "90272",
    city: "Pacific Palisades",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJiXqJCHqjwoARIzLoRT5rfE8",
  },
  {
    zipCode: "91331",
    city: "Pacoima",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQT0LyXSRwoARIOuZNIcitn8",
  },
  {
    zipCode: "91333",
    city: "Pacoima",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQT0LyXSRwoARIOuZNIcitn8",
  },
  {
    zipCode: "91334",
    city: "Pacoima",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQT0LyXSRwoARIOuZNIcitn8",
  },
  {
    zipCode: "93550",
    city: "Palmdale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "93551",
    city: "Palmdale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "93552",
    city: "Palmdale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "93590",
    city: "Palmdale",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "93591",
    city: "Palmdale",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "93599",
    city: "Palmdale",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJtd1w7IRXwoAROuASrS15bGo",
  },
  {
    zipCode: "90274",
    city: "Palos Verdes Peninsula",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE4olypVL3YARyoyx8BwufxQ",
  },
  {
    zipCode: "91402",
    city: "Panorama City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGeY-0CyRwoARPGGaUmRaCXI",
  },
  {
    zipCode: "91412",
    city: "Panorama City",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGeY-0CyRwoARPGGaUmRaCXI",
  },
  {
    zipCode: "90723",
    city: "Paramount",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJMzLLtN3MwoARVy-F8pmbodM",
  },
  {
    zipCode: "91101",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91102",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91103",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91104",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91105",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91106",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91107",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91109",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91110",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91114",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91115",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91116",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91117",
    city: "Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91121",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91123",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91124",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91125",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91126",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91129",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91182",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91184",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91185",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91188",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91189",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "91199",
    city: "Pasadena",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJUQszONzCwoARSo_RGhZBKwU",
  },
  {
    zipCode: "93553",
    city: "Pearblossom",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJpRwVHxECw4ARZtI_k6EisX0",
  },
  {
    zipCode: "90660",
    city: "Pico Rivera",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIe29AovRwoARkA7EgmjDsFc",
  },
  {
    zipCode: "90661",
    city: "Pico Rivera",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIe29AovRwoARkA7EgmjDsFc",
  },
  {
    zipCode: "90662",
    city: "Pico Rivera",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIe29AovRwoARkA7EgmjDsFc",
  },
  {
    zipCode: "90293",
    city: "Playa Del Rey",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJq21cfPSwwoARogpss4dx60s",
  },
  {
    zipCode: "90296",
    city: "Playa Del Rey",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJq21cfPSwwoARogpss4dx60s",
  },
  {
    zipCode: "90094",
    city: "Playa Vista",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJZ8sJQpqwwoAR2nfkAOI8LbQ",
  },
  {
    zipCode: "91766",
    city: "Pomona",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIaGfee8tw4ARM6nKAHCUCVI",
  },
  {
    zipCode: "91767",
    city: "Pomona",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIaGfee8tw4ARM6nKAHCUCVI",
  },
  {
    zipCode: "91768",
    city: "Pomona",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJIaGfee8tw4ARM6nKAHCUCVI",
  },
  {
    zipCode: "91769",
    city: "Pomona",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJIaGfee8tw4ARM6nKAHCUCVI",
  },
  {
    zipCode: "91326",
    city: "Porter Ranch",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJscK9TM-EwoAREDM5A4HhBv4",
  },
  {
    zipCode: "90275",
    city: "Rancho Palos Verdes",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJn41UcNpL3YARtJSWblt_RME",
  },
  {
    zipCode: "90277",
    city: "Redondo Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE7lJQ5G0woARZfEcVNv4z_8",
  },
  {
    zipCode: "90278",
    city: "Redondo Beach",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJE7lJQ5G0woARZfEcVNv4z_8",
  },
  {
    zipCode: "91335",
    city: "Reseda",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJPZSimNWbwoAR4i0HIrDBH2k",
  },
  {
    zipCode: "91337",
    city: "Reseda",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJPZSimNWbwoAR4i0HIrDBH2k",
  },
  {
    zipCode: "91770",
    city: "Rosemead",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJsXdsdnTQwoARfIhDlkgdNaA",
  },
  {
    zipCode: "91771",
    city: "Rosemead",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJsXdsdnTQwoARfIhDlkgdNaA",
  },
  {
    zipCode: "91772",
    city: "Rosemead",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJsXdsdnTQwoARfIhDlkgdNaA",
  },
  {
    zipCode: "91748",
    city: "Rowland Heights",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJp_QOnV0qw4ARxwbfYWDJuIA",
  },
  {
    zipCode: "91773",
    city: "San Dimas",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ39G4_KIWw4AREBzIr5r1mWI",
  },
  {
    zipCode: "91340",
    city: "San Fernando",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ70i_U1GQwoAR17cofW3ioUs",
  },
  {
    zipCode: "91341",
    city: "San Fernando",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ70i_U1GQwoAR17cofW3ioUs",
  },
  {
    zipCode: "91775",
    city: "San Gabriel",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJK7PxJ9vawoAR7OFLP8rH-bE",
  },
  {
    zipCode: "91776",
    city: "San Gabriel",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJK7PxJ9vawoAR7OFLP8rH-bE",
  },
  {
    zipCode: "91778",
    city: "San Gabriel",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJK7PxJ9vawoAR7OFLP8rH-bE",
  },
  {
    zipCode: "91108",
    city: "San Marino",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ5fMpnLXEwoARyPk4Uf3afqo",
  },
  {
    zipCode: "91118",
    city: "San Marino",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ5fMpnLXEwoARyPk4Uf3afqo",
  },
  {
    zipCode: "90731",
    city: "San Pedro",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJpYrtFq413YARX4eG8Fd9FAQ",
  },
  {
    zipCode: "90732",
    city: "San Pedro",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJpYrtFq413YARX4eG8Fd9FAQ",
  },
  {
    zipCode: "90733",
    city: "San Pedro",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJpYrtFq413YARX4eG8Fd9FAQ",
  },
  {
    zipCode: "90734",
    city: "San Pedro",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJpYrtFq413YARX4eG8Fd9FAQ",
  },
  {
    zipCode: "91350",
    city: "Santa Clarita",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJxjVrDOGwoARSNYFc-CPSlY",
  },
  {
    zipCode: "91380",
    city: "Santa Clarita",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJJxjVrDOGwoARSNYFc-CPSlY",
  },
  {
    zipCode: "91382",
    city: "Santa Clarita",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJxjVrDOGwoARSNYFc-CPSlY",
  },
  {
    zipCode: "91383",
    city: "Santa Clarita",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJxjVrDOGwoARSNYFc-CPSlY",
  },
  {
    zipCode: "91390",
    city: "Santa Clarita",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJxjVrDOGwoARSNYFc-CPSlY",
  },
  {
    zipCode: "90670",
    city: "Santa Fe Springs",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJh2x1kwLTwoAR4zWXGfiJrzU",
  },
  {
    zipCode: "90671",
    city: "Santa Fe Springs",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJh2x1kwLTwoAR4zWXGfiJrzU",
  },
  {
    zipCode: "90401",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90402",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90403",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90404",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90405",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90406",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90407",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90408",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90409",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90410",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "90411",
    city: "Santa Monica",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJGQCRws6kwoARq_Uj_7UKF7Q",
  },
  {
    zipCode: "91403",
    city: "Sherman Oaks",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ87ycp9uXwoARFv9xgD9OmwM",
  },
  {
    zipCode: "91413",
    city: "Sherman Oaks",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ87ycp9uXwoARFv9xgD9OmwM",
  },
  {
    zipCode: "91423",
    city: "Sherman Oaks",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ87ycp9uXwoARFv9xgD9OmwM",
  },
  {
    zipCode: "91495",
    city: "Sherman Oaks",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJ87ycp9uXwoARFv9xgD9OmwM",
  },
  {
    zipCode: "91024",
    city: "Sierra Madre",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ2TsjdInbwoARBt8-8jRVXPg",
  },
  {
    zipCode: "91025",
    city: "Sierra Madre",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ2TsjdInbwoARBt8-8jRVXPg",
  },
  {
    zipCode: "90755",
    city: "Signal Hill",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ5YZ9qM8z3YARyXxmX3G13es",
  },
  {
    zipCode: "91733",
    city: "South El Monte",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJryT2NenQwoARtVqLSgSvZv0",
  },
  {
    zipCode: "90280",
    city: "South Gate",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJH7dFoxHMwoAR0mrfGYQGQ5E",
  },
  {
    zipCode: "91030",
    city: "South Pasadena",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJn1E36IrEwoARX8XYgRvgJ1I",
  },
  {
    zipCode: "91031",
    city: "South Pasadena",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJn1E36IrEwoARX8XYgRvgJ1I",
  },
  {
    zipCode: "91381",
    city: "Stevenson Ranch",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJk9gjzVeAwoAReHogmOjXLgU",
  },
  {
    zipCode: "91604",
    city: "Studio City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ9QK_aQm-woARDtBe_FLmNZA",
  },
  {
    zipCode: "91614",
    city: "Studio City",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ9QK_aQm-woARDtBe_FLmNZA",
  },
  {
    zipCode: "91352",
    city: "Sun Valley",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ41W8cD-UwoARa9yZhNFXXYg",
  },
  {
    zipCode: "91353",
    city: "Sun Valley",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ41W8cD-UwoARa9yZhNFXXYg",
  },
  {
    zipCode: "91040",
    city: "Sunland",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJ6DaMKuUwoARyppiMp9zu2E",
  },
  {
    zipCode: "91041",
    city: "Sunland",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJJ6DaMKuUwoARyppiMp9zu2E",
  },
  {
    zipCode: "91342",
    city: "Sylmar",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJVdlNNCaQwoARpmARhUYseDY",
  },
  {
    zipCode: "91392",
    city: "Sylmar",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJVdlNNCaQwoARpmARhUYseDY",
  },
  {
    zipCode: "91356",
    city: "Tarzana",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJSzfwWiCZwoARmo8uvoxfa18",
  },
  {
    zipCode: "91357",
    city: "Tarzana",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJSzfwWiCZwoARmo8uvoxfa18",
  },
  {
    zipCode: "91780",
    city: "Temple City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ5S-vG_3QwoAR3tuU4KHWU1Y",
  },
  {
    zipCode: "91610",
    city: "Toluca Lake",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJHeib5jO-woARekf-TO20oyE",
  },
  {
    zipCode: "90290",
    city: "Topanga",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ4d7gcdyhwoARTf9E9wDtgdU",
  },
  {
    zipCode: "90501",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90502",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90503",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90504",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90505",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90506",
    city: "Torrance",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90507",
    city: "Torrance",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90508",
    city: "Torrance",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90509",
    city: "Torrance",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "90510",
    city: "Torrance",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ096zWNFK3YARHmYKTm7TS60",
  },
  {
    zipCode: "91042",
    city: "Tujunga",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJJ6DaMKuUwoARyppiMp9zu2E",
  },
  {
    zipCode: "91043",
    city: "Tujunga",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJJ6DaMKuUwoARyppiMp9zu2E",
  },
  {
    zipCode: "91608",
    city: "Universal City",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJgbqKQEm-woARJM6ebUSUUVk",
  },
  {
    zipCode: "91354",
    city: "Valencia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ3VMEvDSHwoAR3kFVT3-gtxc",
  },
  {
    zipCode: "91355",
    city: "Valencia",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ3VMEvDSHwoAR3kFVT3-gtxc",
  },
  {
    zipCode: "91385",
    city: "Valencia",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ3VMEvDSHwoAR3kFVT3-gtxc",
  },
  {
    zipCode: "91607",
    city: "Valley Village",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJRVAs-vuVwoARZib9HQ1JZsM",
  },
  {
    zipCode: "91617",
    city: "Valley Village",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJRVAs-vuVwoARZib9HQ1JZsM",
  },
  {
    zipCode: "93563",
    city: "Valyermo",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJxf88erYcw4ARRrskC-v2qbI",
  },
  {
    zipCode: "91401",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91404",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91405",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91406",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91407",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91408",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91409",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91410",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91411",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91470",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91482",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91496",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "91499",
    city: "Van Nuys",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJa-i6-hqXwoARSIjZDHu_cMg",
  },
  {
    zipCode: "90291",
    city: "Venice",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJXWhSMMC6woARPI1cDbQBEY8",
  },
  {
    zipCode: "90294",
    city: "Venice",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJXWhSMMC6woARPI1cDbQBEY8",
  },
  {
    zipCode: "91046",
    city: "Verdugo City",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJB9IcFmjqwoARh8aRp1t77BQ",
  },
  {
    zipCode: "91788",
    city: "Walnut",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJMyel7Zcrw4AReYg0hj99Lq8",
  },
  {
    zipCode: "91789",
    city: "Walnut",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJMyel7Zcrw4AReYg0hj99Lq8",
  },
  {
    zipCode: "91790",
    city: "West Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqQ7XZsQpw4AR6oEJqkv0XuQ",
  },
  {
    zipCode: "91791",
    city: "West Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqQ7XZsQpw4AR6oEJqkv0XuQ",
  },
  {
    zipCode: "91792",
    city: "West Covina",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJqQ7XZsQpw4AR6oEJqkv0XuQ",
  },
  {
    zipCode: "91793",
    city: "West Covina",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJqQ7XZsQpw4AR6oEJqkv0XuQ",
  },
  {
    zipCode: "91307",
    city: "West Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJo1cio4OdwoAR6edV3EL6zz4",
  },
  {
    zipCode: "91308",
    city: "West Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJo1cio4OdwoAR6edV3EL6zz4",
  },
  {
    zipCode: "90069",
    city: "West Hollywood",
    county: "Los Angeles",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "90601",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90602",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90603",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90604",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90605",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90606",
    city: "Whittier",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90607",
    city: "Whittier",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90608",
    city: "Whittier",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90609",
    city: "Whittier",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90610",
    city: "Whittier",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ94d6VYbTwoARWiRAwUd9qCc",
  },
  {
    zipCode: "90744",
    city: "Wilmington",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJQbN0A3823YARlkDKfJIWAik",
  },
  {
    zipCode: "90748",
    city: "Wilmington",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJQbN0A3823YARlkDKfJIWAik",
  },
  {
    zipCode: "91306",
    city: "Winnetka",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJ0fd4S_KbwoAR2hRDrsr3HmQ",
  },
  {
    zipCode: "91396",
    city: "Winnetka",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJ0fd4S_KbwoAR2hRDrsr3HmQ",
  },
  {
    zipCode: "91364",
    city: "Woodland Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJVfY28eyewoARiBIkRG5Qhvo",
  },
  {
    zipCode: "91365",
    city: "Woodland Hills",
    county: "Los Angeles",
    type: "P.O.Box",
    placeId: "ChIJVfY28eyewoARiBIkRG5Qhvo",
  },
  {
    zipCode: "91367",
    city: "Woodland Hills",
    county: "Los Angeles",
    type: "Standard",
    placeId: "ChIJVfY28eyewoARiBIkRG5Qhvo",
  },
  {
    zipCode: "91371",
    city: "Woodland Hills",
    county: "Los Angeles",
    type: "Unique",
    placeId: "ChIJVfY28eyewoARiBIkRG5Qhvo",
  },
  {
    zipCode: "93601",
    city: "Ahwahnee",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJcU3NqfaeloARWtznxrFHtjY",
  },
  {
    zipCode: "93604",
    city: "Bass Lake",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJ3XApUfgolIARZXnG8qUEerw",
  },
  {
    zipCode: "93610",
    city: "Chowchilla",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJlX1AXvlXkYARjTPuHPbM2hM",
  },
  {
    zipCode: "93614",
    city: "Coarsegold",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJu-GfQa0mlIAR7TSDsJtwTm4",
  },
  {
    zipCode: "93636",
    city: "Madera",
    county: "Madera",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "93637",
    city: "Madera",
    county: "Madera",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "93638",
    city: "Madera",
    county: "Madera",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "93639",
    city: "Madera",
    county: "Madera",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "93643",
    city: "North Fork",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJO-RRBv8tlIARarijrkHBR8Q",
  },
  {
    zipCode: "93645",
    city: "O Neals",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJBenbAng8lIARYQkPIB0t-Ow",
  },
  {
    zipCode: "93644",
    city: "Oakhurst",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJZ7xdf6InlIARgY19u9c-aNA",
  },
  {
    zipCode: "93653",
    city: "Raymond",
    county: "Madera",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "93669",
    city: "Wishon",
    county: "Madera",
    type: "Standard",
    placeId: "ChIJoRqPiMkulIAR82iR9BIqu5Q",
  },
  {
    zipCode: "94920",
    city: "Belvedere Tiburon",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJkb7kqJSEhYARxwQKiT4Blfc",
  },
  {
    zipCode: "94924",
    city: "Bolinas",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJJT1aDFWVhYARha3Yj26i_LI",
  },
  {
    zipCode: "94925",
    city: "Corte Madera",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJK4ajZAmWhYAREnDJlAElSAU",
  },
  {
    zipCode: "94976",
    city: "Corte Madera",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJK4ajZAmWhYAREnDJlAElSAU",
  },
  {
    zipCode: "94929",
    city: "Dillon Beach",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJN9UlZl4shIARq4Oz4Y25sEs",
  },
  {
    zipCode: "94930",
    city: "Fairfax",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJgfgHWFeWhYARUBSCSoS-4DM",
  },
  {
    zipCode: "94978",
    city: "Fairfax",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJgfgHWFeWhYARUBSCSoS-4DM",
  },
  {
    zipCode: "94933",
    city: "Forest Knolls",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJB4ZzDXW_hYARd-EmK3PDmY8",
  },
  {
    zipCode: "94904",
    city: "Greenbrae",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJ9_QBUw2ahYAR94Gk5mjshN0",
  },
  {
    zipCode: "94937",
    city: "Inverness",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJnwOX1inEhYARGePUYzw6xq0",
  },
  {
    zipCode: "94914",
    city: "Kentfield",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJVy2TQKWQhYARS9U8BT0fGyo",
  },
  {
    zipCode: "94938",
    city: "Lagunitas",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJB4ZzDXW_hYARd-EmK3PDmY8",
  },
  {
    zipCode: "94939",
    city: "Larkspur",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJRf47R3CahYARV2ndbPAFwMk",
  },
  {
    zipCode: "94977",
    city: "Larkspur",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJRf47R3CahYARV2ndbPAFwMk",
  },
  {
    zipCode: "94940",
    city: "Marshall",
    county: "Marin",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "94941",
    city: "Mill Valley",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJkzxaPHOQhYARhtX5z92kf9I",
  },
  {
    zipCode: "94942",
    city: "Mill Valley",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJkzxaPHOQhYARhtX5z92kf9I",
  },
  {
    zipCode: "94946",
    city: "Nicasio",
    county: "Marin",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "94945",
    city: "Novato",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJjZ6kWd-7hYARSbhzxmcUtwU",
  },
  {
    zipCode: "94947",
    city: "Novato",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJjZ6kWd-7hYARSbhzxmcUtwU",
  },
  {
    zipCode: "94948",
    city: "Novato",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJjZ6kWd-7hYARSbhzxmcUtwU",
  },
  {
    zipCode: "94949",
    city: "Novato",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJjZ6kWd-7hYARSbhzxmcUtwU",
  },
  {
    zipCode: "94998",
    city: "Novato",
    county: "Marin",
    type: "Unique",
    placeId: "ChIJjZ6kWd-7hYARSbhzxmcUtwU",
  },
  {
    zipCode: "94950",
    city: "Olema",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJbyKeBrXGhYARy1nKQANAt8A",
  },
  {
    zipCode: "94956",
    city: "Point Reyes Station",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJK5G0qojPhYARqKBsK0IBFYw",
  },
  {
    zipCode: "94957",
    city: "Ross",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJ3fGoCDaXhYARzZTRTv0R9VE",
  },
  {
    zipCode: "94960",
    city: "San Anselmo",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJ2cX8c6yXhYARECyyKE9Ek1Q",
  },
  {
    zipCode: "94979",
    city: "San Anselmo",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJ2cX8c6yXhYARECyyKE9Ek1Q",
  },
  {
    zipCode: "94963",
    city: "San Geronimo",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJfwdm7C--hYARzqS5AukKOq0",
  },
  {
    zipCode: "94964",
    city: "San Quentin",
    county: "Marin",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "94974",
    city: "San Quentin",
    county: "Marin",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "94901",
    city: "San Rafael",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJL3oJNmeXhYARVCbi5iBNAb4",
  },
  {
    zipCode: "94903",
    city: "San Rafael",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJL3oJNmeXhYARVCbi5iBNAb4",
  },
  {
    zipCode: "94912",
    city: "San Rafael",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJL3oJNmeXhYARVCbi5iBNAb4",
  },
  {
    zipCode: "94913",
    city: "San Rafael",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJL3oJNmeXhYARVCbi5iBNAb4",
  },
  {
    zipCode: "94915",
    city: "San Rafael",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJL3oJNmeXhYARVCbi5iBNAb4",
  },
  {
    zipCode: "94965",
    city: "Sausalito",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJdSbJMVOEhYAR7lGPgm2qA7k",
  },
  {
    zipCode: "94966",
    city: "Sausalito",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJdSbJMVOEhYAR7lGPgm2qA7k",
  },
  {
    zipCode: "94970",
    city: "Stinson Beach",
    county: "Marin",
    type: "Standard",
    placeId: "ChIJLZ_NrZKThYARujlCxy63w78",
  },
  {
    zipCode: "94971",
    city: "Tomales",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJLzB_mV0thIARvNXImrE81tY",
  },
  {
    zipCode: "94973",
    city: "Woodacre",
    county: "Marin",
    type: "P.O.Box",
    placeId: "ChIJMw3-iveVhYARTGebjHzkIxE",
  },
  {
    zipCode: "95306",
    city: "Catheys Valley",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJqQVUg72zloARUBKj9HpZI9Y",
  },
  {
    zipCode: "95311",
    city: "Coulterville",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJkSzDbvQskYARY8B5ouI2S20",
  },
  {
    zipCode: "95318",
    city: "El Portal",
    county: "Mariposa",
    type: "P.O.Box",
    placeId: "ChIJcRrS6g3BloAR5BdriFqEUWY",
  },
  {
    zipCode: "93623",
    city: "Fish Camp",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJRbyVkO2aloARbFIKlkcRT-s",
  },
  {
    zipCode: "95325",
    city: "Hornitos",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJgTeQve81kYAROin4XwU5_J0",
  },
  {
    zipCode: "95338",
    city: "Mariposa",
    county: "Mariposa",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95345",
    city: "Midpines",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJadZYQPy4loARwnYVdVoquqE",
  },
  {
    zipCode: "95389",
    city: "Yosemite National Park",
    county: "Mariposa",
    type: "Standard",
    placeId: "ChIJxeyK9Z3wloAR_gOA7SycJC0",
  },
  {
    zipCode: "95410",
    city: "Albion",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJZT2OtQqpgYAROdq-2E9_KLc",
  },
  {
    zipCode: "95415",
    city: "Boonville",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJAU3EOElqgYARrKJz09g912I",
  },
  {
    zipCode: "95417",
    city: "Branscomb",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJnbQ65wzQgYARyPbF4icUH0g",
  },
  {
    zipCode: "95418",
    city: "Calpella",
    county: "Mendocino",
    type: "P.O.Box",
    placeId: "ChIJRwXFQLd7gYAR_dgE51IM9H8",
  },
  {
    zipCode: "95420",
    city: "Caspar",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJff718lNLgIARd6xaVWjjNgY",
  },
  {
    zipCode: "95427",
    city: "Comptche",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJ3Qxg9piVgYARzhjrisEnmL4",
  },
  {
    zipCode: "95428",
    city: "Covelo",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJQ-D9EsKMgYARE3N3CXHY9SU",
  },
  {
    zipCode: "95429",
    city: "Dos Rios",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJa6k5l63ogYARF9ro0Yug9lo",
  },
  {
    zipCode: "95432",
    city: "Elk",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJayTkZdoAgYAR8lKJEb-hwQs",
  },
  {
    zipCode: "95437",
    city: "Fort Bragg",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJb0gCxVM1gIAR35ysN4qEhdI",
  },
  {
    zipCode: "95445",
    city: "Gualala",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJi5yrPUo3gYAR5eixEy_UWuk",
  },
  {
    zipCode: "95449",
    city: "Hopland",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJ3UJP4NxYgYARAi0857G_4Y0",
  },
  {
    zipCode: "95454",
    city: "Laytonville",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJFVdKBNbcgYARgAePAhZH30M",
  },
  {
    zipCode: "95585",
    city: "Leggett",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJ-Sij_x_M1FQRLlnhoPz63yg",
  },
  {
    zipCode: "95456",
    city: "Little River",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJk2e312WsgYAR6hqkgZJ6-NA",
  },
  {
    zipCode: "95459",
    city: "Manchester",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJK9_Y_pAbgYAR2-uUHraTwmA",
  },
  {
    zipCode: "95460",
    city: "Mendocino",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJM-tWPGuygYARO7PAQfKXD9Y",
  },
  {
    zipCode: "95463",
    city: "Navarro",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJffhofU4JgYARL5nLnvoJGMk",
  },
  {
    zipCode: "95466",
    city: "Philo",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJtWZLxCkTgYARZOUq8TTCVXc",
  },
  {
    zipCode: "95587",
    city: "Piercy",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJPXCz8p_D1FQRHczbGxE2dQE",
  },
  {
    zipCode: "95468",
    city: "Point Arena",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJfXdI1tcYgYARKUgy8DDmXHU",
  },
  {
    zipCode: "95469",
    city: "Potter Valley",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJE73iaKRwgYARCU5su-oXmZc",
  },
  {
    zipCode: "95470",
    city: "Redwood Valley",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJK-4fNG55gYARh9uaMCHzpOA",
  },
  {
    zipCode: "95481",
    city: "Talmage",
    county: "Mendocino",
    type: "P.O.Box",
    placeId: "ChIJVa5tK-NggYARakgC3IivQkw",
  },
  {
    zipCode: "95482",
    city: "Ukiah",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJ43UBnP5kgYAR5sBNg5e83HM",
  },
  {
    zipCode: "95488",
    city: "Westport",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJQyWVtTnTgYARdA8Hl0VIggE",
  },
  {
    zipCode: "95490",
    city: "Willits",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJsxuSKQuLgYARCg93VBtxXcg",
  },
  {
    zipCode: "95494",
    city: "Yorkville",
    county: "Mendocino",
    type: "Standard",
    placeId: "ChIJ7ZPooopagYAR9n4U4o9Jyqk",
  },
  {
    zipCode: "95301",
    city: "Atwater",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJW7LUgrtlkYARbVRrrvNkXdA",
  },
  {
    zipCode: "95303",
    city: "Ballico",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJzVGw4OcRkYARVa04nQpYswI",
  },
  {
    zipCode: "95312",
    city: "Cressey",
    county: "Merced",
    type: "P.O.Box",
    placeId: "ChIJsQPOUVASkYARo2YfSqXiEO4",
  },
  {
    zipCode: "95315",
    city: "Delhi",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJeWtf7IQOkYARqIGKfhVoSaU",
  },
  {
    zipCode: "93620",
    city: "Dos Palos",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJM7OOW0V9kYARZPjEf6GDZCM",
  },
  {
    zipCode: "95317",
    city: "El Nido",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJG1GfAqVfkYARru2dWcI3eTI",
  },
  {
    zipCode: "95322",
    city: "Gustine",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJ637fn7WYkYARXry-kNqsoRI",
  },
  {
    zipCode: "95324",
    city: "Hilmar",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJkdB_93cJkYARTJYTK-KL1YQ",
  },
  {
    zipCode: "95333",
    city: "Le Grand",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJaZHVpC5UkYARFimh16cgtqQ",
  },
  {
    zipCode: "95334",
    city: "Livingston",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJx7DjUXgNkYARk7W_ypxvqvU",
  },
  {
    zipCode: "93635",
    city: "Los Banos",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJa-jV7OR-kYAR4jLdmWXZOHQ",
  },
  {
    zipCode: "95340",
    city: "Merced",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJmbYb_YQ_kYARGIKGvCCqL-0",
  },
  {
    zipCode: "95341",
    city: "Merced",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJmbYb_YQ_kYARGIKGvCCqL-0",
  },
  {
    zipCode: "95343",
    city: "Merced",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJmbYb_YQ_kYARGIKGvCCqL-0",
  },
  {
    zipCode: "95344",
    city: "Merced",
    county: "Merced",
    type: "P.O.Box",
    placeId: "ChIJmbYb_YQ_kYARGIKGvCCqL-0",
  },
  {
    zipCode: "95348",
    city: "Merced",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJmbYb_YQ_kYARGIKGvCCqL-0",
  },
  {
    zipCode: "95365",
    city: "Planada",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJs5HnNGpQkYARKpAWUZ-yDlE",
  },
  {
    zipCode: "93661",
    city: "Santa Rita Park",
    county: "Merced",
    type: "P.O.Box",
    placeId: "ChIJhcN_XBjfk4ARO9xVAtX_Mhk",
  },
  {
    zipCode: "95369",
    city: "Snelling",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJWzbHhgg8kYARr_hCJLwm7h8",
  },
  {
    zipCode: "93665",
    city: "South Dos Palos",
    county: "Merced",
    type: "P.O.Box",
    placeId: "ChIJ84zAdrjbk4ARULZ_iPs0cks",
  },
  {
    zipCode: "95374",
    city: "Stevinson",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJndkRydcKkYAR6wvx-5sNETM",
  },
  {
    zipCode: "95388",
    city: "Winton",
    county: "Merced",
    type: "Standard",
    placeId: "ChIJO5CRD6sUkYARnTkY8DfcKOk",
  },
  {
    zipCode: "96006",
    city: "Adin",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJAXxazpqJzFQRLtILZsRZT-g",
  },
  {
    zipCode: "96101",
    city: "Alturas",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJ8wCVaa2Qy1QROXFgPHKKB30",
  },
  {
    zipCode: "96015",
    city: "Canby",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJvRvTsB92zFQRbHZg4nwZyY0",
  },
  {
    zipCode: "96104",
    city: "Cedarville",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJQwE01D-vy1QRR7exfh1Iml4",
  },
  {
    zipCode: "96108",
    city: "Davis Creek",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJlTAcnhDJy1QRFXlyto2j8YI",
  },
  {
    zipCode: "96110",
    city: "Eagleville",
    county: "Modoc",
    type: "P.O.Box",
    placeId: "ChIJ39CNcj8Ty1QR8_8f5L_KaH4",
  },
  {
    zipCode: "96112",
    city: "Fort Bidwell",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJe4L2qP8wylQReonejnMxFPw",
  },
  {
    zipCode: "96115",
    city: "Lake City",
    county: "Modoc",
    type: "Standard",
    placeId: "ChIJTxu1suKzy1QR8VI_968yd4I",
  },
  {
    zipCode: "96116",
    city: "Likely",
    county: "Modoc",
    type: "P.O.Box",
    placeId: "ChIJlwiZIYh8y1QRwOzc0hcP-Pc",
  },
  {
    zipCode: "96054",
    city: "Lookout",
    county: "Modoc",
    type: "P.O.Box",
    placeId: "ChIJXw2WlopezFQRvCWWEX6NCOc",
  },
  {
    zipCode: "93512",
    city: "Benton",
    county: "Mono",
    type: "Standard",
    placeId: "ChIJAaYjivnvvYARQKKrQiyTHs4",
  },
  {
    zipCode: "93517",
    city: "Bridgeport",
    county: "Mono",
    type: "Standard",
    placeId: "ChIJqYhvmrW6l4ARAa9b0OT1eJ4",
  },
  {
    zipCode: "96107",
    city: "Coleville",
    county: "Mono",
    type: "Standard",
    placeId: "ChIJE7EaBNZ9l4ARvc7igKGfrcY",
  },
  {
    zipCode: "93529",
    city: "June Lake",
    county: "Mono",
    type: "P.O.Box",
    placeId: "ChIJq9BN6-ZqloARtP4_vnmMZGU",
  },
  {
    zipCode: "93541",
    city: "Lee Vining",
    county: "Mono",
    type: "P.O.Box",
    placeId: "ChIJ2Uc73FY2loAR6xtXkWBXM9A",
  },
  {
    zipCode: "93546",
    city: "Mammoth Lakes",
    county: "Mono",
    type: "Standard",
    placeId: "ChIJnxezeV8MloARbY3uD9fZFPE",
  },
  {
    zipCode: "96133",
    city: "Topaz",
    county: "Mono",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95004",
    city: "Aromas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJGbMy6m0CjoARED-C4N90BAc",
  },
  {
    zipCode: "93920",
    city: "Big Sur",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJd00OeKfojYARYazvylnY6Dc",
  },
  {
    zipCode: "93426",
    city: "Bradley",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJAZ7betsok4ARLl53dKMPOis",
  },
  {
    zipCode: "93922",
    city: "Carmel",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJU-wXtxXmjYARHFRRyqYrwtI",
  },
  {
    zipCode: "93923",
    city: "Carmel",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJU-wXtxXmjYARHFRRyqYrwtI",
  },
  {
    zipCode: "93921",
    city: "Carmel By The Sea",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJU-wXtxXmjYARHFRRyqYrwtI",
  },
  {
    zipCode: "93924",
    city: "Carmel Valley",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJvUku7X3zjYARtkSqSWQJ2qA",
  },
  {
    zipCode: "95012",
    city: "Castroville",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJra6wkd8GjoAR4-Zoeje1LuI",
  },
  {
    zipCode: "93925",
    city: "Chualar",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJZ6dFrhkMkoARURByUgRQOe0",
  },
  {
    zipCode: "93926",
    city: "Gonzales",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJayZagFltkoARMvH25r6awPI",
  },
  {
    zipCode: "93927",
    city: "Greenfield",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJs4tEpzhekoARKPzNuR_jg_c",
  },
  {
    zipCode: "93928",
    city: "Jolon",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJP7z-b_7BkoARSjEU5t2DMEM",
  },
  {
    zipCode: "93930",
    city: "King City",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJA5nI19fwkoARrh3vxQ2WWYU",
  },
  {
    zipCode: "93932",
    city: "Lockwood",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJNZ0Na0vDkoARlck0y9WFvIs",
  },
  {
    zipCode: "93933",
    city: "Marina",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJ4RtxuqDkjYAR1gn5XbknKMI",
  },
  {
    zipCode: "93940",
    city: "Monterey",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJkfu1cFLkjYARXj1K2AlJSO4",
  },
  {
    zipCode: "93942",
    city: "Monterey",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJkfu1cFLkjYARXj1K2AlJSO4",
  },
  {
    zipCode: "93943",
    city: "Monterey",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJkfu1cFLkjYARXj1K2AlJSO4",
  },
  {
    zipCode: "93944",
    city: "Monterey",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJkfu1cFLkjYARXj1K2AlJSO4",
  },
  {
    zipCode: "95039",
    city: "Moss Landing",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJX3eoItUFjoAROQIfWAKzpuA",
  },
  {
    zipCode: "93950",
    city: "Pacific Grove",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJ4VDR-zfhjYARVWSjTPHATFE",
  },
  {
    zipCode: "93953",
    city: "Pebble Beach",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJrWvQdVXmjYARJSGHmQE965w",
  },
  {
    zipCode: "93901",
    city: "Salinas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93902",
    city: "Salinas",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93905",
    city: "Salinas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93906",
    city: "Salinas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93907",
    city: "Salinas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93908",
    city: "Salinas",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93912",
    city: "Salinas",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93915",
    city: "Salinas",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJL3JhTpb4jYARIYHtuhG7EaU",
  },
  {
    zipCode: "93450",
    city: "San Ardo",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJ4Tlky4_fkoARt0gRfehCyFc",
  },
  {
    zipCode: "93954",
    city: "San Lucas",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJtXpUTlrkkoARkL87wzEp1P8",
  },
  {
    zipCode: "93955",
    city: "Seaside",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJJwX_Zb7kjYARgDmC4N90BAc",
  },
  {
    zipCode: "93960",
    city: "Soledad",
    county: "Monterey",
    type: "Standard",
    placeId: "ChIJlX40ht9okoAR0DmC4N90BAc",
  },
  {
    zipCode: "93962",
    city: "Spreckels",
    county: "Monterey",
    type: "P.O.Box",
    placeId: "ChIJPf1gaRn4jYAR8xmNd69LynQ",
  },
  {
    zipCode: "94503",
    city: "American Canyon",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJW99ma3IMhYARTBzSkDk67vo",
  },
  {
    zipCode: "94508",
    city: "Angwin",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJGVh_JORbhIARqVNYSbkwM8Y",
  },
  {
    zipCode: "94515",
    city: "Calistoga",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJkTJxhwlDhIARQO6YM2Hiiwc",
  },
  {
    zipCode: "94576",
    city: "Deer Park",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJv608OBVahIARrU6svcahZCc",
  },
  {
    zipCode: "94558",
    city: "Napa",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJ797y-Of_hIARJceEMzqfYtY",
  },
  {
    zipCode: "94559",
    city: "Napa",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJ797y-Of_hIARJceEMzqfYtY",
  },
  {
    zipCode: "94581",
    city: "Napa",
    county: "Napa",
    type: "P.O.Box",
    placeId: "ChIJ797y-Of_hIARJceEMzqfYtY",
  },
  {
    zipCode: "94562",
    city: "Oakville",
    county: "Napa",
    type: "P.O.Box",
    placeId: "ChIJeRPJWUtUhIARTIPJaLgJm4M",
  },
  {
    zipCode: "94567",
    city: "Pope Valley",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJfQLlCLlehIAR3Ou8y2JKPTs",
  },
  {
    zipCode: "94573",
    city: "Rutherford",
    county: "Napa",
    type: "P.O.Box",
    placeId: "ChIJUShLNJ9WhIARcJ9vA9ml70s",
  },
  {
    zipCode: "94574",
    city: "Saint Helena",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJocM09pdQhIARl3sXLMqvaRU",
  },
  {
    zipCode: "94599",
    city: "Yountville",
    county: "Napa",
    type: "Standard",
    placeId: "ChIJfRoJQE1VhIARTB6YA4IIzN0",
  },
  {
    zipCode: "95924",
    city: "Cedar Ridge",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJOaxAvBJxm4ARJlcqF1bcgck",
  },
  {
    zipCode: "95712",
    city: "Chicago Park",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJsb5kBJN0m4ARkvYb0MildNk",
  },
  {
    zipCode: "96111",
    city: "Floriston",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJnxk3Mn5dmYARPgB_pYpE-08",
  },
  {
    zipCode: "95945",
    city: "Grass Valley",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJEZ3Eo6YEoYARHW94Mj7DXC0",
  },
  {
    zipCode: "95949",
    city: "Grass Valley",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJEZ3Eo6YEoYARHW94Mj7DXC0",
  },
  {
    zipCode: "95959",
    city: "Nevada City",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJSYtANHF6m4ARzcPgXJqqvzc",
  },
  {
    zipCode: "95724",
    city: "Norden",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJ6_padm3om4ARMI9YsHzq8-0",
  },
  {
    zipCode: "95960",
    city: "North San Juan",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJjbOukZlim4ARSEYJLsT6EoM",
  },
  {
    zipCode: "95946",
    city: "Penn Valley",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJOyH2FaNom4ARZfnyZo9DtgE",
  },
  {
    zipCode: "95975",
    city: "Rough And Ready",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJfaugNoVlm4ARDMibUiWa_UA",
  },
  {
    zipCode: "95977",
    city: "Smartsville",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJcbTe-KEKmYARs5X8qooDR88",
  },
  {
    zipCode: "95728",
    city: "Soda Springs",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJ_WUws_bpm4ARovc0QTmOJNU",
  },
  {
    zipCode: "96160",
    city: "Truckee",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJ141OPOzfm4ARyfw0wYgvF20",
  },
  {
    zipCode: "96161",
    city: "Truckee",
    county: "Nevada",
    type: "Standard",
    placeId: "ChIJ141OPOzfm4ARyfw0wYgvF20",
  },
  {
    zipCode: "96162",
    city: "Truckee",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJ141OPOzfm4ARyfw0wYgvF20",
  },
  {
    zipCode: "95986",
    city: "Washington",
    county: "Nevada",
    type: "P.O.Box",
    placeId: "ChIJ96WKHsWrvIARVvH_HkIfpuk",
  },
  {
    zipCode: "92656",
    city: "Orange County",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJz_fVVFyS3IARB9bwj2HDpt4",
  },
  {
    zipCode: "92656",
    city: "Aliso Viejo",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJxc_RcRnp3IARS-eEWffxiVI",
  },
  {
    zipCode: "92698",
    city: "Aliso Viejo",
    county: "Orange",
    type: "Unique",
    placeId: "ChIJxc_RcRnp3IARS-eEWffxiVI",
  },
  {
    zipCode: "92801",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92802",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92803",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92804",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92805",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92806",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92807",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92808",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92809",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92812",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92814",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92815",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92816",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92817",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92825",
    city: "Anaheim",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92850",
    city: "Anaheim",
    county: "Orange",
    type: "Unique",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92899",
    city: "Anaheim",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJZ-hVgPnW3IARYLErmquJqwE",
  },
  {
    zipCode: "92811",
    city: "Atwood",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92821",
    city: "Brea",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJF4yeaIbU3IAR5EoElre1GBU",
  },
  {
    zipCode: "92822",
    city: "Brea",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJF4yeaIbU3IAR5EoElre1GBU",
  },
  {
    zipCode: "92823",
    city: "Brea",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJF4yeaIbU3IAR5EoElre1GBU",
  },
  {
    zipCode: "90620",
    city: "Buena Park",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJK11gE_Qr3YARJf7ozeXun80",
  },
  {
    zipCode: "90621",
    city: "Buena Park",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJK11gE_Qr3YARJf7ozeXun80",
  },
  {
    zipCode: "90622",
    city: "Buena Park",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJK11gE_Qr3YARJf7ozeXun80",
  },
  {
    zipCode: "90624",
    city: "Buena Park",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJK11gE_Qr3YARJf7ozeXun80",
  },
  {
    zipCode: "92624",
    city: "Capistrano Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJaXfi7Qvx3IAR1LkvzejT2aI",
  },
  {
    zipCode: "92625",
    city: "Corona Del Mar",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJWcGzje3g3IAReKxmGAmgbUE",
  },
  {
    zipCode: "92626",
    city: "Costa Mesa",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJeW9X_G_f3IAR2sAD2BKEAe8",
  },
  {
    zipCode: "92627",
    city: "Costa Mesa",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJeW9X_G_f3IAR2sAD2BKEAe8",
  },
  {
    zipCode: "92628",
    city: "Costa Mesa",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJeW9X_G_f3IAR2sAD2BKEAe8",
  },
  {
    zipCode: "90630",
    city: "Cypress",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ7_ZrWZIu3YAREFTB1a3XLNQ",
  },
  {
    zipCode: "92629",
    city: "Dana Point",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJif1kuo_w3IARFfoBcLy0LuQ",
  },
  {
    zipCode: "92650",
    city: "East Irvine",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJaU7gMz7d3IARd-au3HkyqzQ",
  },
  {
    zipCode: "92609",
    city: "El Toro",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJT4TjKRno3IAR5Q82kaAzSdw",
  },
  {
    zipCode: "92610",
    city: "Foothill Ranch",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ09vZigzC3IARJqS55bq8K4s",
  },
  {
    zipCode: "92708",
    city: "Fountain Valley",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJn9Z-GhUn3YARyejhu-WepGg",
  },
  {
    zipCode: "92728",
    city: "Fountain Valley",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJn9Z-GhUn3YARyejhu-WepGg",
  },
  {
    zipCode: "92831",
    city: "Fullerton",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92832",
    city: "Fullerton",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92833",
    city: "Fullerton",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92834",
    city: "Fullerton",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92835",
    city: "Fullerton",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92836",
    city: "Fullerton",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92837",
    city: "Fullerton",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92838",
    city: "Fullerton",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJY8uBw3Qq3YARSupgHoFTnPU",
  },
  {
    zipCode: "92840",
    city: "Garden Grove",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92841",
    city: "Garden Grove",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92842",
    city: "Garden Grove",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92843",
    city: "Garden Grove",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92844",
    city: "Garden Grove",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92845",
    city: "Garden Grove",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92846",
    city: "Garden Grove",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJjc0dnT8o3YAROosPvSKWkAM",
  },
  {
    zipCode: "92605",
    city: "Huntington Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92615",
    city: "Huntington Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92646",
    city: "Huntington Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92647",
    city: "Huntington Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92648",
    city: "Huntington Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92649",
    city: "Huntington Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJtSrI-SIk3YARATtmpMmF7UU",
  },
  {
    zipCode: "92602",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92603",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92604",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92606",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92612",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92614",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92616",
    city: "Irvine",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92617",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92618",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92619",
    city: "Irvine",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92620",
    city: "Irvine",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92623",
    city: "Irvine",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "92697",
    city: "Irvine",
    county: "Orange",
    type: "Unique",
    placeId: "ChIJ40CRaA7d3IAROZpKYHW1eqc",
  },
  {
    zipCode: "90631",
    city: "La Habra",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJgz2LOjHVwoAR3SYwhaFIH6g",
  },
  {
    zipCode: "90632",
    city: "La Habra",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJgz2LOjHVwoAR3SYwhaFIH6g",
  },
  {
    zipCode: "90633",
    city: "La Habra",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJgz2LOjHVwoAR3SYwhaFIH6g",
  },
  {
    zipCode: "90623",
    city: "La Palma",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJIQ8j3BQs3YARkZEfWW27HtU",
  },
  {
    zipCode: "92694",
    city: "Ladera Ranch",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJN6h92Cfe3IAR9dKClyUYCQU",
  },
  {
    zipCode: "92651",
    city: "Laguna Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJwSRbj8jg3IAR9tp5qDW05w0",
  },
  {
    zipCode: "92652",
    city: "Laguna Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJwSRbj8jg3IAR9tp5qDW05w0",
  },
  {
    zipCode: "92653",
    city: "Laguna Hills",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ_fXW8sbo3IARHMFirCl_Qu8",
  },
  {
    zipCode: "92654",
    city: "Laguna Hills",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ_fXW8sbo3IARHMFirCl_Qu8",
  },
  {
    zipCode: "92607",
    city: "Laguna Niguel",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJE-QK6V3v3IARksS97RctAKQ",
  },
  {
    zipCode: "92677",
    city: "Laguna Niguel",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJE-QK6V3v3IARksS97RctAKQ",
  },
  {
    zipCode: "92637",
    city: "Laguna Woods",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJU3THRMfo3IARF9_nAQgDng0",
  },
  {
    zipCode: "92630",
    city: "Lake Forest",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJT4TjKRno3IAR5Q82kaAzSdw",
  },
  {
    zipCode: "90720",
    city: "Los Alamitos",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJJQtQH_Eu3YAR--qhgEZhhdQ",
  },
  {
    zipCode: "90721",
    city: "Los Alamitos",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJJQtQH_Eu3YAR--qhgEZhhdQ",
  },
  {
    zipCode: "92655",
    city: "Midway City",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhQZnRzkm3YARu1j9KDJ2dQM",
  },
  {
    zipCode: "92690",
    city: "Mission Viejo",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJm329k6_C3IARNEgxTpKFYic",
  },
  {
    zipCode: "92691",
    city: "Mission Viejo",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJm329k6_C3IARNEgxTpKFYic",
  },
  {
    zipCode: "92692",
    city: "Mission Viejo",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJm329k6_C3IARNEgxTpKFYic",
  },
  {
    zipCode: "92658",
    city: "Newport Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92659",
    city: "Newport Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92660",
    city: "Newport Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92661",
    city: "Newport Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92662",
    city: "Newport Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92663",
    city: "Newport Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ3bO_xLbo3IARvVmLZFSVVPE",
  },
  {
    zipCode: "92657",
    city: "Newport Coast",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJNV42Xivn3IAR-6gm-H4rLXk",
  },
  {
    zipCode: "92856",
    city: "Orange",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92857",
    city: "Orange",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92859",
    city: "Orange",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92862",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92863",
    city: "Orange",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92864",
    city: "Orange",
    county: "Orange",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "92865",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92866",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92867",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92868",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92869",
    city: "Orange",
    county: "Orange",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "92870",
    city: "Placentia",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJmUH7xR7U3IARWBeNFCur1A0",
  },
  {
    zipCode: "92871",
    city: "Placentia",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJmUH7xR7U3IARWBeNFCur1A0",
  },
  {
    zipCode: "92688",
    city: "Rancho Santa Margarita",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJbTmTWJzr3IARlqst5hfsH7A",
  },
  {
    zipCode: "92672",
    city: "San Clemente",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJn1YpWVv03IARv3vMFEgFJjo",
  },
  {
    zipCode: "92673",
    city: "San Clemente",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJn1YpWVv03IARv3vMFEgFJjo",
  },
  {
    zipCode: "92674",
    city: "San Clemente",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJn1YpWVv03IARv3vMFEgFJjo",
  },
  {
    zipCode: "92675",
    city: "San Juan Capistrano",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ9wxgAUTt3IARk42-Je8q6BY",
  },
  {
    zipCode: "92693",
    city: "San Juan Capistrano",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ9wxgAUTt3IARk42-Je8q6BY",
  },
  {
    zipCode: "92701",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92702",
    city: "Santa Ana",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92703",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92704",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92705",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92706",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92707",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92711",
    city: "Santa Ana",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92712",
    city: "Santa Ana",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92735",
    city: "Santa Ana",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "92799",
    city: "Santa Ana",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJhTCCbJXb3IARvP0eHzqET8c",
  },
  {
    zipCode: "90740",
    city: "Seal Beach",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJMWN2ONje3IARFZQnbuxw_D0",
  },
  {
    zipCode: "92676",
    city: "Silverado",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJF5cEuF3B3IARbJU7APoT41Q",
  },
  {
    zipCode: "90680",
    city: "Stanton",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJA5JEvuEo3YARxVzDrBeHKZc",
  },
  {
    zipCode: "90742",
    city: "Sunset Beach",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ3dFO0Asl3YARvKz8zmlFc8w",
  },
  {
    zipCode: "90743",
    city: "Surfside",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJT5wGZ28l3YARgNXE-vRjHV4",
  },
  {
    zipCode: "92678",
    city: "Trabuco Canyon",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJ7YiawPDq3IAR7UuoUKtuIP8",
  },
  {
    zipCode: "92679",
    city: "Trabuco Canyon",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ7YiawPDq3IAR7UuoUKtuIP8",
  },
  {
    zipCode: "92780",
    city: "Tustin",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJSzbuqjfD3IARckzIEB2RVeg",
  },
  {
    zipCode: "92781",
    city: "Tustin",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJSzbuqjfD3IARckzIEB2RVeg",
  },
  {
    zipCode: "92782",
    city: "Tustin",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJSzbuqjfD3IARckzIEB2RVeg",
  },
  {
    zipCode: "92861",
    city: "Villa Park",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJ7dqrGL3Q3IARSBj3eKxeSTE",
  },
  {
    zipCode: "92683",
    city: "Westminster",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJo7W_R2rZ3IARQ9L86fjkp0s",
  },
  {
    zipCode: "92684",
    city: "Westminster",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJo7W_R2rZ3IARQ9L86fjkp0s",
  },
  {
    zipCode: "92685",
    city: "Westminster",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJo7W_R2rZ3IARQ9L86fjkp0s",
  },
  {
    zipCode: "92885",
    city: "Yorba Linda",
    county: "Orange",
    type: "P.O.Box",
    placeId: "ChIJe6lY0Soow4ARSVAD1lXVb_Q",
  },
  {
    zipCode: "92886",
    city: "Yorba Linda",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJe6lY0Soow4ARSVAD1lXVb_Q",
  },
  {
    zipCode: "92887",
    city: "Yorba Linda",
    county: "Orange",
    type: "Standard",
    placeId: "ChIJe6lY0Soow4ARSVAD1lXVb_Q",
  },
  {
    zipCode: "95701",
    city: "Alta",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJG8zTVC2Zm4ARbhXk6fA4LkM",
  },
  {
    zipCode: "95703",
    city: "Applegate",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJh2xSZFAJm4AR6rlqKnF4m-U",
  },
  {
    zipCode: "95602",
    city: "Auburn",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJn6V1VSgFm4ARcHgsMwuxAjk",
  },
  {
    zipCode: "95603",
    city: "Auburn",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJn6V1VSgFm4ARcHgsMwuxAjk",
  },
  {
    zipCode: "95604",
    city: "Auburn",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJn6V1VSgFm4ARcHgsMwuxAjk",
  },
  {
    zipCode: "96140",
    city: "Carnelian Bay",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJq4HXo01jmYARJ9FKCK2pe84",
  },
  {
    zipCode: "95713",
    city: "Colfax",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJAdChMRALm4ARo40iTj9NAk8",
  },
  {
    zipCode: "95714",
    city: "Dutch Flat",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJpZCve--em4ARfM6sxhQ7sA0",
  },
  {
    zipCode: "95715",
    city: "Emigrant Gap",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJt3KRMNyQm4ARQPG9veOxXTw",
  },
  {
    zipCode: "95631",
    city: "Foresthill",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJZSLdvkq7m4ARJD-7_ayuOLU",
  },
  {
    zipCode: "95717",
    city: "Gold Run",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJpzYFU6-fm4ARlkEc6kLODGI",
  },
  {
    zipCode: "95746",
    city: "Granite Bay",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJJ_60znMem4ARuHjcEjRv6QI",
  },
  {
    zipCode: "96141",
    city: "Homewood",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJE3UVCH7Vm4ARvliwkRurZoU",
  },
  {
    zipCode: "96143",
    city: "Kings Beach",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJHzvNO8lkmYARt1XlmHDm9lQ",
  },
  {
    zipCode: "95648",
    city: "Lincoln",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJm2t7nw8Tm4ARFda4oA7CWJA",
  },
  {
    zipCode: "95650",
    city: "Loomis",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJk5E0wWkIm4ARZ58FbiX1FuY",
  },
  {
    zipCode: "95722",
    city: "Meadow Vista",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJrU6GnGAOm4ARmKmHSaGB91g",
  },
  {
    zipCode: "95658",
    city: "Newcastle",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJ3bfs8KAbm4ARZPgE9E9a6Us",
  },
  {
    zipCode: "96146",
    city: "Olympic Valley",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJYY7XjuvZm4ARTzFpkvy0uYQ",
  },
  {
    zipCode: "95663",
    city: "Penryn",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJD0Ba4d8bm4ARsa6dgyBuRiw",
  },
  {
    zipCode: "95677",
    city: "Rocklin",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJteOg_Ucfm4ARRCGNfUcfs0U",
  },
  {
    zipCode: "95765",
    city: "Rocklin",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJteOg_Ucfm4ARRCGNfUcfs0U",
  },
  {
    zipCode: "95661",
    city: "Roseville",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJeRg8YmUfm4ARmTlJulJKryI",
  },
  {
    zipCode: "95678",
    city: "Roseville",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJeRg8YmUfm4ARmTlJulJKryI",
  },
  {
    zipCode: "95747",
    city: "Roseville",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJeRg8YmUfm4ARmTlJulJKryI",
  },
  {
    zipCode: "95681",
    city: "Sheridan",
    county: "Placer",
    type: "Standard",
    placeId: "ChIJ9W5gdcA4m4ARY0NKUn5k6gA",
  },
  {
    zipCode: "96145",
    city: "Tahoe City",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJIacfzy7Wm4AR0Z5sgTCymCo",
  },
  {
    zipCode: "96148",
    city: "Tahoe Vista",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJ__hgcV1jmYAR5P5MAraMdCE",
  },
  {
    zipCode: "95736",
    city: "Weimar",
    county: "Placer",
    type: "P.O.Box",
    placeId: "ChIJRfJN2FsJm4ARmLvcA7DwNkw",
  },
  {
    zipCode: "96129",
    city: "Beckwourth",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJ90EtvDIXnIARDXeh1SLHkZc",
  },
  {
    zipCode: "95915",
    city: "Belden",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJeZztTLEYnYAR04TTzkzM4gY",
  },
  {
    zipCode: "96103",
    city: "Blairsden Graeagle",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJ_eZ1ibxpnIARXRSVnW0x46I",
  },
  {
    zipCode: "95923",
    city: "Canyon Dam",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJQwHde9MPnYAReeIcuAtrs1s",
  },
  {
    zipCode: "96020",
    city: "Chester",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJD7k98hdqnYARHFw7bKlx_60",
  },
  {
    zipCode: "96105",
    city: "Chilcoot",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJ213TlhOonoARPJLvX_3J9Vk",
  },
  {
    zipCode: "96106",
    city: "Clio",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJm3aDyflsnIARnBO7ReRHouM",
  },
  {
    zipCode: "95934",
    city: "Crescent Mills",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJa7KHxWMAnYARoUYb3TYYt30",
  },
  {
    zipCode: "95947",
    city: "Greenville",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJUef8lE0GnYARNW_ACAiTx0A",
  },
  {
    zipCode: "95956",
    city: "Meadow Valley",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJvWed_jPjnIARaVl7N8CGS18",
  },
  {
    zipCode: "96122",
    city: "Portola",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJxZRpXGgUnIARcWl0s2vSUao",
  },
  {
    zipCode: "95971",
    city: "Quincy",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJI-PDCGJcnIARET3hqz3A6wQ",
  },
  {
    zipCode: "95980",
    city: "Storrie",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJiWWVw54gnYARfb-NrGcq2Ag",
  },
  {
    zipCode: "95983",
    city: "Taylorsville",
    county: "Plumas",
    type: "Standard",
    placeId: "ChIJS4MPjnlSnIARRwcbZ655ZJA",
  },
  {
    zipCode: "95984",
    city: "Twain",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJS-N80SQDnYARpLmJkIgL4B8",
  },
  {
    zipCode: "96135",
    city: "Vinton",
    county: "Plumas",
    type: "P.O.Box",
    placeId: "ChIJ7Qv5edEdnIAR8rnJK9wW9Ek",
  },
  {
    zipCode: "92536",
    city: "Aguanga",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJRTjNcjKZ24ARkM8Xw6LM0ZA",
  },
  {
    zipCode: "92539",
    city: "Anza",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJdwgzIv0J24ARNN_hi2NiwME",
  },
  {
    zipCode: "92220",
    city: "Banning",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJxf-hqG1B24ARClmqiPYbHUc",
  },
  {
    zipCode: "92223",
    city: "Beaumont",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJb-EVG65E24ARyxp9OMqnDCM",
  },
  {
    zipCode: "92225",
    city: "Blythe",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ5xLRa0Mh0YARriWyWuzCaiw",
  },
  {
    zipCode: "92226",
    city: "Blythe",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJ5xLRa0Mh0YARriWyWuzCaiw",
  },
  {
    zipCode: "92230",
    city: "Cabazon",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJMwmjQHI_24ARALA11mMohNU",
  },
  {
    zipCode: "92320",
    city: "Calimesa",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJIR9NO5tQ24ARDnPvCrD47Rk",
  },
  {
    zipCode: "92234",
    city: "Cathedral City",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJQ8IyCwEd24ARfZgDp1sNFoM",
  },
  {
    zipCode: "92235",
    city: "Cathedral City",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJQ8IyCwEd24ARfZgDp1sNFoM",
  },
  {
    zipCode: "92236",
    city: "Coachella",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJrzzOh45Y2oAR-LyXe3kcUv0",
  },
  {
    zipCode: "92877",
    city: "Corona",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92878",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92879",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92880",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92881",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92882",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92883",
    city: "Corona",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJbWBd6rmw3IAR8VKS0g_RmIk",
  },
  {
    zipCode: "92239",
    city: "Desert Center",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJW57mrHiO0IARaOF1GIfKFTY",
  },
  {
    zipCode: "92240",
    city: "Desert Hot Springs",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJay-WZeQG24ARnr8wkixlzUU",
  },
  {
    zipCode: "92241",
    city: "Desert Hot Springs",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJay-WZeQG24ARnr8wkixlzUU",
  },
  {
    zipCode: "92543",
    city: "Hemet",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJqRWWhTdk24ARwnC1aPZ2PGk",
  },
  {
    zipCode: "92544",
    city: "Hemet",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJqRWWhTdk24ARwnC1aPZ2PGk",
  },
  {
    zipCode: "92545",
    city: "Hemet",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJqRWWhTdk24ARwnC1aPZ2PGk",
  },
  {
    zipCode: "92546",
    city: "Hemet",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJqRWWhTdk24ARwnC1aPZ2PGk",
  },
  {
    zipCode: "92548",
    city: "Homeland",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJJUingxRh24AR6AEodO7iVLc",
  },
  {
    zipCode: "92549",
    city: "Idyllwild",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJ_-oTUBIO24ARri2-E7V7zq0",
  },
  {
    zipCode: "92210",
    city: "Indian Wells",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJa_pnt8T-2oAReov8KyiucNM",
  },
  {
    zipCode: "92201",
    city: "Indio",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJhdffQzX02oARMVAjp83nbBY",
  },
  {
    zipCode: "92202",
    city: "Indio",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJhdffQzX02oARMVAjp83nbBY",
  },
  {
    zipCode: "92203",
    city: "Indio",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJhdffQzX02oARMVAjp83nbBY",
  },
  {
    zipCode: "92509",
    city: "Jurupa Valley",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJZZjxJ2a03IARZtZxWGvLlZI",
  },
  {
    zipCode: "92247",
    city: "La Quinta",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJX5eQ9TVa2oARudotYG5om8g",
  },
  {
    zipCode: "92248",
    city: "La Quinta",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJX5eQ9TVa2oARudotYG5om8g",
  },
  {
    zipCode: "92253",
    city: "La Quinta",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJX5eQ9TVa2oARudotYG5om8g",
  },
  {
    zipCode: "92530",
    city: "Lake Elsinore",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJMzqZMSaa3IAR2VQIqqhMXXE",
  },
  {
    zipCode: "92531",
    city: "Lake Elsinore",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJMzqZMSaa3IAR2VQIqqhMXXE",
  },
  {
    zipCode: "92532",
    city: "Lake Elsinore",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJMzqZMSaa3IAR2VQIqqhMXXE",
  },
  {
    zipCode: "92518",
    city: "March Air Reserve Base",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJRdX6BYCm3IARWDHTJCknjvc",
  },
  {
    zipCode: "92254",
    city: "Mecca",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJrURIUjxd2oAR2I76G7eKyHQ",
  },
  {
    zipCode: "92584",
    city: "Menifee",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJt55zxAJk24ARD_7tqLOpxXU",
  },
  {
    zipCode: "92585",
    city: "Menifee",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJt55zxAJk24ARD_7tqLOpxXU",
  },
  {
    zipCode: "92586",
    city: "Menifee",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJt55zxAJk24ARD_7tqLOpxXU",
  },
  {
    zipCode: "92587",
    city: "Menifee",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJt55zxAJk24ARD_7tqLOpxXU",
  },
  {
    zipCode: "91752",
    city: "Mira Loma",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ_ToBFp613IAR9n3xtBN_4Sg",
  },
  {
    zipCode: "92551",
    city: "Moreno Valley",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92552",
    city: "Moreno Valley",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92553",
    city: "Moreno Valley",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92554",
    city: "Moreno Valley",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92555",
    city: "Moreno Valley",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92556",
    city: "Moreno Valley",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92557",
    city: "Moreno Valley",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJm-IZ2wmh3IARLD-boBWy-sM",
  },
  {
    zipCode: "92561",
    city: "Mountain Center",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJcTcECMcN24ARTBBKpzgFUzM",
  },
  {
    zipCode: "92562",
    city: "Murrieta",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ8fICxT9924ARj-3Unfku1BI",
  },
  {
    zipCode: "92563",
    city: "Murrieta",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ8fICxT9924ARj-3Unfku1BI",
  },
  {
    zipCode: "92564",
    city: "Murrieta",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJ8fICxT9924ARj-3Unfku1BI",
  },
  {
    zipCode: "92860",
    city: "Norco",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ9YTaON_J3IARlxSrY1lRde8",
  },
  {
    zipCode: "92258",
    city: "North Palm Springs",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJk6zkgJEY24AR6IK78bXjf6A",
  },
  {
    zipCode: "92567",
    city: "Nuevo",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJIyE_zD5e24AR-sizCQjlZh0",
  },
  {
    zipCode: "92211",
    city: "Palm Desert",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJN8Kl8i782oARdUavho9GuS4",
  },
  {
    zipCode: "92255",
    city: "Palm Desert",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJN8Kl8i782oARdUavho9GuS4",
  },
  {
    zipCode: "92260",
    city: "Palm Desert",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJN8Kl8i782oARdUavho9GuS4",
  },
  {
    zipCode: "92261",
    city: "Palm Desert",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJN8Kl8i782oARdUavho9GuS4",
  },
  {
    zipCode: "92262",
    city: "Palm Springs",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJs-Xb_9Qa24ARfHntwodp5aE",
  },
  {
    zipCode: "92263",
    city: "Palm Springs",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJs-Xb_9Qa24ARfHntwodp5aE",
  },
  {
    zipCode: "92264",
    city: "Palm Springs",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJs-Xb_9Qa24ARfHntwodp5aE",
  },
  {
    zipCode: "92570",
    city: "Perris",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ_wHMqtKh3IARKJ-8zFt98zo",
  },
  {
    zipCode: "92571",
    city: "Perris",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ_wHMqtKh3IARKJ-8zFt98zo",
  },
  {
    zipCode: "92572",
    city: "Perris",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJ_wHMqtKh3IARKJ-8zFt98zo",
  },
  {
    zipCode: "92599",
    city: "Perris",
    county: "Riverside",
    type: "Unique",
    placeId: "ChIJ_wHMqtKh3IARKJ-8zFt98zo",
  },
  {
    zipCode: "92270",
    city: "Rancho Mirage",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ5TXJ7ZAC24ARznj3j1L-wu0",
  },
  {
    zipCode: "92501",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92502",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92503",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92504",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92505",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92506",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92507",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92508",
    city: "Riverside",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92513",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92514",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92516",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92517",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92519",
    city: "Riverside",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92521",
    city: "Riverside",
    county: "Riverside",
    type: "Unique",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92522",
    city: "Riverside",
    county: "Riverside",
    type: "Unique",
    placeId: "ChIJu330f9-m3IAR6ApeEwXXofc",
  },
  {
    zipCode: "92581",
    city: "San Jacinto",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJF1mAzHhj24AR5EG1Idxv1JY",
  },
  {
    zipCode: "92582",
    city: "San Jacinto",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJF1mAzHhj24AR5EG1Idxv1JY",
  },
  {
    zipCode: "92583",
    city: "San Jacinto",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJF1mAzHhj24AR5EG1Idxv1JY",
  },
  {
    zipCode: "92589",
    city: "Temecula",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJI74a9VJi24ARqAY4oIgOvGg",
  },
  {
    zipCode: "92590",
    city: "Temecula",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJI74a9VJi24ARqAY4oIgOvGg",
  },
  {
    zipCode: "92591",
    city: "Temecula",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJI74a9VJi24ARqAY4oIgOvGg",
  },
  {
    zipCode: "92592",
    city: "Temecula",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJI74a9VJi24ARqAY4oIgOvGg",
  },
  {
    zipCode: "92593",
    city: "Temecula",
    county: "Riverside",
    type: "P.O.Box",
    placeId: "ChIJI74a9VJi24ARqAY4oIgOvGg",
  },
  {
    zipCode: "92274",
    city: "Thermal",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJT6uQE61e2oARqxh09naWucw",
  },
  {
    zipCode: "92276",
    city: "Thousand Palms",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJ-xYZXbbi2oARgFPIGyJ0fYc",
  },
  {
    zipCode: "92282",
    city: "Whitewater",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJEa8tGGQ924ARkQq6mTuZdi0",
  },
  {
    zipCode: "92595",
    city: "Wildomar",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJW78BBBqZ3IARDOA7LIRkwEU",
  },
  {
    zipCode: "92596",
    city: "Winchester",
    county: "Riverside",
    type: "Standard",
    placeId: "ChIJM_YUmeFh24ARck03y7elYLg",
  },
  {
    zipCode: "95843",
    city: "Antelope",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJHT8Cm0wnm4AR9gNLHjniHSo",
  },
  {
    zipCode: "95608",
    city: "Carmichael",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJCXiGuCTcmoARwGJOdQLzfKU",
  },
  {
    zipCode: "95609",
    city: "Carmichael",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJCXiGuCTcmoARwGJOdQLzfKU",
  },
  {
    zipCode: "95610",
    city: "Citrus Heights",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJk94s2tPcmoARqYZxhBexR1c",
  },
  {
    zipCode: "95611",
    city: "Citrus Heights",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJk94s2tPcmoARqYZxhBexR1c",
  },
  {
    zipCode: "95621",
    city: "Citrus Heights",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJk94s2tPcmoARqYZxhBexR1c",
  },
  {
    zipCode: "95615",
    city: "Courtland",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJE_VVuYi1moARJBEyMEvv0kA",
  },
  {
    zipCode: "95624",
    city: "Elk Grove",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJOfii4Mu4moAR6Agc02DrDMo",
  },
  {
    zipCode: "95757",
    city: "Elk Grove",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJOfii4Mu4moAR6Agc02DrDMo",
  },
  {
    zipCode: "95758",
    city: "Elk Grove",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJOfii4Mu4moAR6Agc02DrDMo",
  },
  {
    zipCode: "95759",
    city: "Elk Grove",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJOfii4Mu4moAR6Agc02DrDMo",
  },
  {
    zipCode: "95626",
    city: "Elverta",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJs2iIG48om4ARnJoCYPMyFM0",
  },
  {
    zipCode: "95628",
    city: "Fair Oaks",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJe4lgGlfYmoARXkx-7yb-nZ4",
  },
  {
    zipCode: "95630",
    city: "Folsom",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJf3rZiRvhmoARKs7hMS5MTtQ",
  },
  {
    zipCode: "95763",
    city: "Folsom",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJf3rZiRvhmoARKs7hMS5MTtQ",
  },
  {
    zipCode: "95632",
    city: "Galt",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ09aqk_egmoAR8Z4So9KHZYw",
  },
  {
    zipCode: "95638",
    city: "Herald",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ0bmlf-mXmoARjtG5JZAhF_c",
  },
  {
    zipCode: "95639",
    city: "Hood",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ91c4JOvJmoARyTeWmx44ho0",
  },
  {
    zipCode: "95641",
    city: "Isleton",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ6w4XXUytmoAR_DRjwedsSag",
  },
  {
    zipCode: "95655",
    city: "Mather",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJBXNLGI7CmoARFHA95H_T6Iw",
  },
  {
    zipCode: "95652",
    city: "Mcclellan",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJswNJhV_YmoARNEAjIg3KSYY",
  },
  {
    zipCode: "95660",
    city: "North Highlands",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJuffV8-jYmoAR350kIPtLYoU",
  },
  {
    zipCode: "95662",
    city: "Orangevale",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ9fQSB97gmoAR2ZjYrqiFer4",
  },
  {
    zipCode: "95670",
    city: "Rancho Cordova",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ7fPl0wTdmoARVCVOx2VOv5Q",
  },
  {
    zipCode: "95741",
    city: "Rancho Cordova",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ7fPl0wTdmoARVCVOx2VOv5Q",
  },
  {
    zipCode: "95742",
    city: "Rancho Cordova",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ7fPl0wTdmoARVCVOx2VOv5Q",
  },
  {
    zipCode: "95671",
    city: "Represa",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJKeVoZ1fgmoARBQ9__1DyrDM",
  },
  {
    zipCode: "95673",
    city: "Rio Linda",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ5WokBxUom4ARDOd02sX2up8",
  },
  {
    zipCode: "95680",
    city: "Ryde",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJFZbaZ7-zmoARRcjIk6Tmhd4",
  },
  {
    zipCode: "94203",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94204",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94205",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94206",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94207",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94208",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94209",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94211",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94229",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94230",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94232",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94234",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94235",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94236",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94237",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94239",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94240",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94244",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94245",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94247",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94248",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94249",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94250",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94252",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94254",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94256",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94257",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94258",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94259",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94261",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94262",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94263",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94267",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94268",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94269",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94271",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94273",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94274",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94277",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94278",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94279",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94280",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94282",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94283",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94284",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94285",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94287",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94288",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94289",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94290",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94291",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94293",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94294",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94295",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94296",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94297",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94298",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "94299",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95811",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95812",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95813",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95814",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95815",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95816",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95817",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95818",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95819",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95820",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95821",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95822",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95823",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95824",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95825",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95826",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95827",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95828",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95829",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95830",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95831",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95832",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95833",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95834",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95835",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95836",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95837",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95838",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95840",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95841",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95842",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95851",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95852",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95853",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95860",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95864",
    city: "Sacramento",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95865",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95866",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95867",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95894",
    city: "Sacramento",
    county: "Sacramento",
    type: "Unique",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95899",
    city: "Sacramento",
    county: "Sacramento",
    type: "P.O.Box",
    placeId: "ChIJ-ZeDsnLGmoAR238ZdKpqH5I",
  },
  {
    zipCode: "95683",
    city: "Sloughhouse",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ3yLEnZfrmoARlvdIXOkmUBY",
  },
  {
    zipCode: "95690",
    city: "Walnut Grove",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJl01mjwuxmoARpWW8uRMVZks",
  },
  {
    zipCode: "95693",
    city: "Wilton",
    county: "Sacramento",
    type: "Standard",
    placeId: "ChIJ4VZ0ZuaUmoAR4hjZ5lmIT1o",
  },
  {
    zipCode: "95023",
    city: "Hollister",
    county: "San Benito",
    type: "Standard",
    placeId: "ChIJa5wY38n3kYARwDppwt50BAc",
  },
  {
    zipCode: "95024",
    city: "Hollister",
    county: "San Benito",
    type: "P.O.Box",
    placeId: "ChIJa5wY38n3kYARwDppwt50BAc",
  },
  {
    zipCode: "95043",
    city: "Paicines",
    county: "San Benito",
    type: "Standard",
    placeId: "ChIJYdTTrR0YkoARQcfiJ6VNmXc",
  },
  {
    zipCode: "95045",
    city: "San Juan Bautista",
    county: "San Benito",
    type: "Standard",
    placeId: "ChIJ6VEnutL4kYARZLv1nc3fb2M",
  },
  {
    zipCode: "95075",
    city: "Tres Pinos",
    county: "San Benito",
    type: "Standard",
    placeId: "ChIJdeRMkjIZkoAR04-8qLU4W0c",
  },
  {
    zipCode: "92301",
    city: "Adelanto",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJO52bmEpjw4ARnMKlg5bPvhE",
  },
  {
    zipCode: "92304",
    city: "Amboy",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJO8JPCrhWxYARlTvlMdw9JqY",
  },
  {
    zipCode: "92305",
    city: "Angelus Oaks",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJH8MnVGBN24ARK7432lyJNyE",
  },
  {
    zipCode: "92307",
    city: "Apple Valley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJl0Ki0z5hw4ARen1rug7lBSk",
  },
  {
    zipCode: "92308",
    city: "Apple Valley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJl0Ki0z5hw4ARen1rug7lBSk",
  },
  {
    zipCode: "92309",
    city: "Baker",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJTcVkQ7-9xYARYyCLNZVzd6k",
  },
  {
    zipCode: "92311",
    city: "Barstow",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJVZRUTENwxIARd-LvKjMsQRs",
  },
  {
    zipCode: "92312",
    city: "Barstow",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJVZRUTENwxIARd-LvKjMsQRs",
  },
  {
    zipCode: "92314",
    city: "Big Bear City",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ3yNiUlOxxIARil2OT3SLF5Q",
  },
  {
    zipCode: "92315",
    city: "Big Bear Lake",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJZYdwAomzxIARv1O7X3ZFbfQ",
  },
  {
    zipCode: "92316",
    city: "Bloomington",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ5d2ibvRWw4ARgR959ImFaHk",
  },
  {
    zipCode: "92317",
    city: "Blue Jay",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92318",
    city: "Bryn Mawr",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ68iA57er3IARfPQjEQ7eeQ8",
  },
  {
    zipCode: "92321",
    city: "Cedar Glen",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJuYehXCJXw4ARtT9513AA0Ag",
  },
  {
    zipCode: "92322",
    city: "Cedarpines Park",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJt7aesJ5aw4ARGtUnZK0xoZY",
  },
  {
    zipCode: "91708",
    city: "Chino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJJ4l8PSkzw4ARhWfdoJje2j8",
  },
  {
    zipCode: "91710",
    city: "Chino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJJ4l8PSkzw4ARhWfdoJje2j8",
  },
  {
    zipCode: "91709",
    city: "Chino Hills",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJKRLZ1FE2w4ARbgC1lBaCozQ",
  },
  {
    zipCode: "92323",
    city: "Cima",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJjVq8bZN5z4ARGwy0SlGqwV0",
  },
  {
    zipCode: "92324",
    city: "Colton",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ7Yxx34WmxIARPx7pEHVWSmc",
  },
  {
    zipCode: "92325",
    city: "Crestline",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJI2bbPJdQw4ARDDba8iKtntg",
  },
  {
    zipCode: "92327",
    city: "Daggett",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJtdF3YvZhxIARIOFgd8RslKQ",
  },
  {
    zipCode: "92242",
    city: "Earp",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ7Z10LG2D0YARWbaIbUr1x5A",
  },
  {
    zipCode: "92332",
    city: "Essex",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJMdHYA9PCz4ARtM_KEXylNPk",
  },
  {
    zipCode: "92333",
    city: "Fawnskin",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJd5kg-lmxxIARNrLCceucR7E",
  },
  {
    zipCode: "92331",
    city: "Fontana",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJwwlEIo9Lw4ARm1QpGMHzy3g",
  },
  {
    zipCode: "92334",
    city: "Fontana",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJwwlEIo9Lw4ARm1QpGMHzy3g",
  },
  {
    zipCode: "92335",
    city: "Fontana",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJwwlEIo9Lw4ARm1QpGMHzy3g",
  },
  {
    zipCode: "92336",
    city: "Fontana",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJwwlEIo9Lw4ARm1QpGMHzy3g",
  },
  {
    zipCode: "92337",
    city: "Fontana",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJwwlEIo9Lw4ARm1QpGMHzy3g",
  },
  {
    zipCode: "92339",
    city: "Forest Falls",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ8cmHLJdO24AR1JU3lfv_7V0",
  },
  {
    zipCode: "92310",
    city: "Fort Irwin",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJM84jRec4xIARI83SKXkSSK8",
  },
  {
    zipCode: "92313",
    city: "Grand Terrace",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJP-LlAAis3IARnBKELHFdaOg",
  },
  {
    zipCode: "92341",
    city: "Green Valley Lake",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJZ30KvcmuxIARJEv-t4Tu3tY",
  },
  {
    zipCode: "91743",
    city: "Guasti",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJYfh2iwc1w4AR_5wAh17PR04",
  },
  {
    zipCode: "92342",
    city: "Helendale",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJU-nz4X54w4ARS_Hhyj0f6i4",
  },
  {
    zipCode: "92340",
    city: "Hesperia",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJfxBHww5dw4ARIDpY-VrjAwc",
  },
  {
    zipCode: "92344",
    city: "Hesperia",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJfxBHww5dw4ARIDpY-VrjAwc",
  },
  {
    zipCode: "92345",
    city: "Hesperia",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJfxBHww5dw4ARIDpY-VrjAwc",
  },
  {
    zipCode: "92346",
    city: "Highland",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJB-BI9J5M24ARDYYeQ0Bv1NY",
  },
  {
    zipCode: "92347",
    city: "Hinkley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ9drVet6Hw4ARfte6ElJydXw",
  },
  {
    zipCode: "92252",
    city: "Joshua Tree",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJAVDJ-0XQ2oARw2sUAFr-5-Q",
  },
  {
    zipCode: "92352",
    city: "Lake Arrowhead",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJXQEEKYdXw4ARO3aW7d_QsSw",
  },
  {
    zipCode: "92285",
    city: "Landers",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ5fQ6ZQrV2oARbaoZ30UR06I",
  },
  {
    zipCode: "92350",
    city: "Loma Linda",
    county: "San Bernardino",
    type: "Unique",
    placeId: "ChIJc01uIJKr3IARhbLTjqxRH80",
  },
  {
    zipCode: "92354",
    city: "Loma Linda",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJc01uIJKr3IARhbLTjqxRH80",
  },
  {
    zipCode: "92357",
    city: "Loma Linda",
    county: "San Bernardino",
    type: "Unique",
    placeId: "ChIJc01uIJKr3IARhbLTjqxRH80",
  },
  {
    zipCode: "92356",
    city: "Lucerne Valley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJf3oqcY28xIARTeaeIduvJ28",
  },
  {
    zipCode: "92338",
    city: "Ludlow",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJUxBNMEMWxYAR_mUp5n_gftA",
  },
  {
    zipCode: "92358",
    city: "Lytle Creek",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ8ed_G_1Gw4ARuvIJCvcSYO8",
  },
  {
    zipCode: "92359",
    city: "Mentone",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJzXhCtBZT24ARkQcDPEXkn0A",
  },
  {
    zipCode: "91763",
    city: "Montclair",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ8RlonC4yw4AR2_SOX9D1JH4",
  },
  {
    zipCode: "92256",
    city: "Morongo Valley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJM7wiIu8k24ARJYeux20ErsM",
  },
  {
    zipCode: "92366",
    city: "Mountain Pass",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJT8I10kFnz4ARL76ZJ5IE5Ss",
  },
  {
    zipCode: "91759",
    city: "Mt Baldy",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJmWXw1-g7w4ARe_VUr0hIQDk",
  },
  {
    zipCode: "92363",
    city: "Needles",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJM4o14XIjzoAR85QN4ReoXyA",
  },
  {
    zipCode: "92365",
    city: "Newberry Springs",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJSelkA7v2xIAREOoMWkldSV0",
  },
  {
    zipCode: "92364",
    city: "Nipton",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJwclqQFcTz4AR-DHmz151w3s",
  },
  {
    zipCode: "91758",
    city: "Ontario",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJe2Ld6ts0w4ARkDFY-VrjAwc",
  },
  {
    zipCode: "91761",
    city: "Ontario",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJe2Ld6ts0w4ARkDFY-VrjAwc",
  },
  {
    zipCode: "91762",
    city: "Ontario",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJe2Ld6ts0w4ARkDFY-VrjAwc",
  },
  {
    zipCode: "91764",
    city: "Ontario",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJe2Ld6ts0w4ARkDFY-VrjAwc",
  },
  {
    zipCode: "92368",
    city: "Oro Grande",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJNT2j7Op6w4AR8TVMOpR93cE",
  },
  {
    zipCode: "92267",
    city: "Parker Dam",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJqTkaCh4o0oARGc3UXUPSEuY",
  },
  {
    zipCode: "92369",
    city: "Patton",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJVSBQc-FUw4ARuSUszrDO1Os",
  },
  {
    zipCode: "92329",
    city: "Phelan",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJVzYjcL0Uw4ARVxJwhOPGXgc",
  },
  {
    zipCode: "92371",
    city: "Phelan",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJVzYjcL0Uw4ARVxJwhOPGXgc",
  },
  {
    zipCode: "92372",
    city: "Pinon Hills",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJS86fDY1pw4ARhzGQ3kCN4r8",
  },
  {
    zipCode: "92268",
    city: "Pioneertown",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJOcg-Lqcu24ARvMOr7gwKMVE",
  },
  {
    zipCode: "91701",
    city: "Rancho Cucamonga",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJkScROPlbw4ARS_Pygq16aq0",
  },
  {
    zipCode: "91729",
    city: "Rancho Cucamonga",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJkScROPlbw4ARS_Pygq16aq0",
  },
  {
    zipCode: "91730",
    city: "Rancho Cucamonga",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJkScROPlbw4ARS_Pygq16aq0",
  },
  {
    zipCode: "91737",
    city: "Rancho Cucamonga",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJkScROPlbw4ARS_Pygq16aq0",
  },
  {
    zipCode: "91739",
    city: "Rancho Cucamonga",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJkScROPlbw4ARS_Pygq16aq0",
  },
  {
    zipCode: "92373",
    city: "Redlands",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJQ7GWQcZT24ARZ7rbO8rp2XU",
  },
  {
    zipCode: "92374",
    city: "Redlands",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJQ7GWQcZT24ARZ7rbO8rp2XU",
  },
  {
    zipCode: "92375",
    city: "Redlands",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJQ7GWQcZT24ARZ7rbO8rp2XU",
  },
  {
    zipCode: "92376",
    city: "Rialto",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJGddGpQ1Nw4ARoFqQJoSZD4g",
  },
  {
    zipCode: "92377",
    city: "Rialto",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJGddGpQ1Nw4ARoFqQJoSZD4g",
  },
  {
    zipCode: "92378",
    city: "Rimforest",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJOWq20v5Ww4ARuDTtNSdQnko",
  },
  {
    zipCode: "92382",
    city: "Running Springs",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJt46Te4SpxIARg6e2PSQxCeU",
  },
  {
    zipCode: "92401",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92402",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92403",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92404",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92405",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92406",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92407",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92408",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92410",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92411",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92413",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92415",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Unique",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92418",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "Unique",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92423",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92427",
    city: "San Bernardino",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJ87j94n1Tw4ARjcKHjHjGZyg",
  },
  {
    zipCode: "92385",
    city: "Skyforest",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJdyHUASZWw4ARNjA1u3ARk6Y",
  },
  {
    zipCode: "92386",
    city: "Sugarloaf",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJbzZnAQm1xIARjH8BMnTMqCU",
  },
  {
    zipCode: "93562",
    city: "Trona",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJpxZokqc5wYAR_svwEnxv_rk",
  },
  {
    zipCode: "93592",
    city: "Trona",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJpxZokqc5wYAR_svwEnxv_rk",
  },
  {
    zipCode: "92277",
    city: "Twentynine Palms",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJz35ewibH2oAR_KNjyULrN-w",
  },
  {
    zipCode: "92278",
    city: "Twentynine Palms",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJz35ewibH2oAR_KNjyULrN-w",
  },
  {
    zipCode: "92391",
    city: "Twin Peaks",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJH8RDfdFaw4ARSy8dwIvDUd8",
  },
  {
    zipCode: "91784",
    city: "Upland",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJzRiDRSMxw4ARF_BxDdhqqZg",
  },
  {
    zipCode: "91785",
    city: "Upland",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJzRiDRSMxw4ARF_BxDdhqqZg",
  },
  {
    zipCode: "91786",
    city: "Upland",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJzRiDRSMxw4ARF_BxDdhqqZg",
  },
  {
    zipCode: "92392",
    city: "Victorville",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJedLdY1pkw4ARdjT0JVkRlQ0",
  },
  {
    zipCode: "92393",
    city: "Victorville",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJedLdY1pkw4ARdjT0JVkRlQ0",
  },
  {
    zipCode: "92394",
    city: "Victorville",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJedLdY1pkw4ARdjT0JVkRlQ0",
  },
  {
    zipCode: "92395",
    city: "Victorville",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJedLdY1pkw4ARdjT0JVkRlQ0",
  },
  {
    zipCode: "92280",
    city: "Vidal",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJrXY3Rmif0YARi82WmA-q9uc",
  },
  {
    zipCode: "92397",
    city: "Wrightwood",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJFbkj3HsVw4ARlG5rZw4YJKw",
  },
  {
    zipCode: "92398",
    city: "Yermo",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJRWTdCWBgxIARHJ-AYVxyoXU",
  },
  {
    zipCode: "92399",
    city: "Yucaipa",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJP7oo1BxR24AR_qisu9QeKXo",
  },
  {
    zipCode: "92284",
    city: "Yucca Valley",
    county: "San Bernardino",
    type: "Standard",
    placeId: "ChIJGytMIY0n24ARxAeAypzOtGw",
  },
  {
    zipCode: "92286",
    city: "Yucca Valley",
    county: "San Bernardino",
    type: "P.O.Box",
    placeId: "ChIJGytMIY0n24ARxAeAypzOtGw",
  },
  {
    zipCode: "91901",
    city: "Alpine",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJUyG3vO9g2YARsSPBQmgrE-4",
  },
  {
    zipCode: "91903",
    city: "Alpine",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJUyG3vO9g2YARsSPBQmgrE-4",
  },
  {
    zipCode: "91902",
    city: "Bonita",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJS_5JMypQ2YARbtCcmYzBOTA",
  },
  {
    zipCode: "91908",
    city: "Bonita",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJS_5JMypQ2YARbtCcmYzBOTA",
  },
  {
    zipCode: "92003",
    city: "Bonsall",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJyTAxgid43IAROzC9nqhB3Fg",
  },
  {
    zipCode: "92004",
    city: "Borrego Springs",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJcc29juAn2oARklIWnDTUwpQ",
  },
  {
    zipCode: "91905",
    city: "Boulevard",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJqcNZdoCi2YARRm0MuKR3ehE",
  },
  {
    zipCode: "92055",
    city: "Camp Pendleton",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJd_m66aN73IARH8g1oNkLf8w",
  },
  {
    zipCode: "91906",
    city: "Campo",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJKU5fseR02YARH9yr2AelwRQ",
  },
  {
    zipCode: "92007",
    city: "Cardiff By The Sea",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJUYWCHKoO3IARwZMdY93cdYY",
  },
  {
    zipCode: "92008",
    city: "Carlsbad",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "92009",
    city: "Carlsbad",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "92010",
    city: "Carlsbad",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "92011",
    city: "Carlsbad",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "92013",
    city: "Carlsbad",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "92018",
    city: "Carlsbad",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJWe47P0Vz3IARL132_JJVy6Q",
  },
  {
    zipCode: "91909",
    city: "Chula Vista",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91910",
    city: "Chula Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91911",
    city: "Chula Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91912",
    city: "Chula Vista",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91913",
    city: "Chula Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91914",
    city: "Chula Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91915",
    city: "Chula Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "91921",
    city: "Chula Vista",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJz6rDZUVO2YAR0yuPS3O4zEY",
  },
  {
    zipCode: "92118",
    city: "Coronado",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJf9GTj2JN2YARLPuQziFPsTg",
  },
  {
    zipCode: "92178",
    city: "Coronado",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJf9GTj2JN2YARLPuQziFPsTg",
  },
  {
    zipCode: "92014",
    city: "Del Mar",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJIcQpcAz524ARGfKOhhXRoBA",
  },
  {
    zipCode: "91916",
    city: "Descanso",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWZiaQ05j2YARbbbULPD7mGc",
  },
  {
    zipCode: "91917",
    city: "Dulzura",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJE3sUj4dU2YARnHYelXIX2ZU",
  },
  {
    zipCode: "92019",
    city: "El Cajon",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJY4MJe7FZ2YARYycM1mqd9wo",
  },
  {
    zipCode: "92020",
    city: "El Cajon",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJY4MJe7FZ2YARYycM1mqd9wo",
  },
  {
    zipCode: "92021",
    city: "El Cajon",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJY4MJe7FZ2YARYycM1mqd9wo",
  },
  {
    zipCode: "92022",
    city: "El Cajon",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJY4MJe7FZ2YARYycM1mqd9wo",
  },
  {
    zipCode: "92023",
    city: "Encinitas",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJtzLW474L3IARngTAtvs_xow",
  },
  {
    zipCode: "92024",
    city: "Encinitas",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJtzLW474L3IARngTAtvs_xow",
  },
  {
    zipCode: "92025",
    city: "Escondido",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92026",
    city: "Escondido",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92027",
    city: "Escondido",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92029",
    city: "Escondido",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92030",
    city: "Escondido",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92033",
    city: "Escondido",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92046",
    city: "Escondido",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJe0PCgV7z24AR38WWdw_I0QE",
  },
  {
    zipCode: "92028",
    city: "Fallbrook",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJtygSujeP24ARtKEaXNDRwIU",
  },
  {
    zipCode: "92088",
    city: "Fallbrook",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJtygSujeP24ARtKEaXNDRwIU",
  },
  {
    zipCode: "91931",
    city: "Guatay",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSdnCtuJQ2YARH9YrMt-R2A8",
  },
  {
    zipCode: "91932",
    city: "Imperial Beach",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJFY4B7ydB2YARKOqMRyKKnVw",
  },
  {
    zipCode: "91933",
    city: "Imperial Beach",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJFY4B7ydB2YARKOqMRyKKnVw",
  },
  {
    zipCode: "91934",
    city: "Jacumba",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ-9Zu-Fu82YAROmffn6JV3K8",
  },
  {
    zipCode: "91935",
    city: "Jamul",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJdejI15dc2YARFrxhtWKwnbE",
  },
  {
    zipCode: "92036",
    city: "Julian",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJwRQoisos2oARQDH4mmW5fEQ",
  },
  {
    zipCode: "92037",
    city: "La Jolla",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJzQ7MT3bQ24ARlDAdXPQe5fw",
  },
  {
    zipCode: "92038",
    city: "La Jolla",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJzQ7MT3bQ24ARlDAdXPQe5fw",
  },
  {
    zipCode: "92039",
    city: "La Jolla",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJzQ7MT3bQ24ARlDAdXPQe5fw",
  },
  {
    zipCode: "92092",
    city: "La Jolla",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJzQ7MT3bQ24ARlDAdXPQe5fw",
  },
  {
    zipCode: "92093",
    city: "La Jolla",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJzQ7MT3bQ24ARlDAdXPQe5fw",
  },
  {
    zipCode: "91941",
    city: "La Mesa",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJbUyTwxdX2YAR_MhWriLdarU",
  },
  {
    zipCode: "91942",
    city: "La Mesa",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJbUyTwxdX2YAR_MhWriLdarU",
  },
  {
    zipCode: "91943",
    city: "La Mesa",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJbUyTwxdX2YAR_MhWriLdarU",
  },
  {
    zipCode: "91944",
    city: "La Mesa",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJbUyTwxdX2YAR_MhWriLdarU",
  },
  {
    zipCode: "92040",
    city: "Lakeside",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJj2T2nqNY2YAR2YyXv2srfvQ",
  },
  {
    zipCode: "91945",
    city: "Lemon Grove",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ170mWC9R2YARkNb-jQsuYWo",
  },
  {
    zipCode: "91946",
    city: "Lemon Grove",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJ170mWC9R2YARkNb-jQsuYWo",
  },
  {
    zipCode: "91948",
    city: "Mount Laguna",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJy5CwDT6C2YAREJqYWEJUJxM",
  },
  {
    zipCode: "91950",
    city: "National City",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJbREDyAlS2YAR7MAgtFanVIk",
  },
  {
    zipCode: "91951",
    city: "National City",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJbREDyAlS2YAR7MAgtFanVIk",
  },
  {
    zipCode: "92049",
    city: "Oceanside",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92051",
    city: "Oceanside",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92052",
    city: "Oceanside",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92054",
    city: "Oceanside",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92056",
    city: "Oceanside",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92057",
    city: "Oceanside",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92058",
    city: "Oceanside",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJBXdbYF5l3IARh5-POT_LngE",
  },
  {
    zipCode: "92059",
    city: "Pala",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJG61igSmE24AR1X-80fNoIDY",
  },
  {
    zipCode: "92060",
    city: "Palomar Mountain",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJNXOWB9iW24ARaQKPZLuhcqA",
  },
  {
    zipCode: "92061",
    city: "Pauma Valley",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJZ1oaxSuQ24AReRg-o58tpjg",
  },
  {
    zipCode: "91962",
    city: "Pine Valley",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ2TNQZXh32YARoJajoJwAjp8",
  },
  {
    zipCode: "91963",
    city: "Potrero",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ1bF-_ltt2YARbgQhn_kQ5xw",
  },
  {
    zipCode: "92064",
    city: "Poway",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJJ5bxsbTg24ARZwSLTUFKSzI",
  },
  {
    zipCode: "92074",
    city: "Poway",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJJ5bxsbTg24ARZwSLTUFKSzI",
  },
  {
    zipCode: "92065",
    city: "Ramona",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJH9SZeWTo24ARGcKz0MNnvVg",
  },
  {
    zipCode: "92066",
    city: "Ranchita",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJJeHGTFjJ24ARWeUgRHAutLU",
  },
  {
    zipCode: "92067",
    city: "Rancho Santa Fe",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJTWsUz5UJ3IARHucl6eyR16E",
  },
  {
    zipCode: "92091",
    city: "Rancho Santa Fe",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJTWsUz5UJ3IARHucl6eyR16E",
  },
  {
    zipCode: "92101",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92102",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92103",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92104",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92105",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92106",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92107",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92108",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92109",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92110",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92111",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92112",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92113",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92114",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92115",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92116",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92117",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92119",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92120",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92121",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92122",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92123",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92124",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92126",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92127",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92128",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92129",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92130",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92131",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92132",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92134",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92135",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92136",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92137",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92138",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92139",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92140",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92142",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92145",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92147",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92149",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92150",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92152",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92153",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92154",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92155",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92158",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92159",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92160",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92161",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92163",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92165",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92166",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92167",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92168",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92169",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92170",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92171",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92172",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92174",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92175",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92176",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92177",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92179",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92182",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92186",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92187",
    city: "San Diego",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92191",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92192",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92193",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92195",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92196",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92197",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92198",
    city: "San Diego",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92199",
    city: "San Diego",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
  },
  {
    zipCode: "92068",
    city: "San Luis Rey",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJfTCfrhaq3oARcGNbPijEaCU",
  },
  {
    zipCode: "92069",
    city: "San Marcos",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJd_xUiKiK24ARR3u05cNO57A",
  },
  {
    zipCode: "92078",
    city: "San Marcos",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJd_xUiKiK24ARR3u05cNO57A",
  },
  {
    zipCode: "92079",
    city: "San Marcos",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJd_xUiKiK24ARR3u05cNO57A",
  },
  {
    zipCode: "92096",
    city: "San Marcos",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJd_xUiKiK24ARR3u05cNO57A",
  },
  {
    zipCode: "92143",
    city: "San Ysidro",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJJfA4OhhJ2YARwicqTGjUSHs",
  },
  {
    zipCode: "92173",
    city: "San Ysidro",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJJfA4OhhJ2YARwicqTGjUSHs",
  },
  {
    zipCode: "92070",
    city: "Santa Ysabel",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJWdqGF-7E24ARp9N9qQEc0fE",
  },
  {
    zipCode: "92071",
    city: "Santee",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ8ZDpe7Pi24ARyARGTfef5Vk",
  },
  {
    zipCode: "92072",
    city: "Santee",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJ8ZDpe7Pi24ARyARGTfef5Vk",
  },
  {
    zipCode: "92075",
    city: "Solana Beach",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ1W3oXwkJ3IARgiS9qsXdNFc",
  },
  {
    zipCode: "91976",
    city: "Spring Valley",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJk0buB4NQ2YAR2nagpuMrlQ4",
  },
  {
    zipCode: "91977",
    city: "Spring Valley",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJk0buB4NQ2YAR2nagpuMrlQ4",
  },
  {
    zipCode: "91978",
    city: "Spring Valley",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJk0buB4NQ2YAR2nagpuMrlQ4",
  },
  {
    zipCode: "91979",
    city: "Spring Valley",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJk0buB4NQ2YAR2nagpuMrlQ4",
  },
  {
    zipCode: "91980",
    city: "Tecate",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ1SgZeyxt2YARvByQ1XbAZPA",
  },
  {
    zipCode: "91987",
    city: "Tecate",
    county: "San Diego",
    type: "Unique",
    placeId: "ChIJ1SgZeyxt2YARvByQ1XbAZPA",
  },
  {
    zipCode: "92082",
    city: "Valley Center",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJCQH1YbON24ARwbYy2UW1XYg",
  },
  {
    zipCode: "92081",
    city: "Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ53MR5wML3IARjfdL4YkTXHg",
  },
  {
    zipCode: "92083",
    city: "Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ53MR5wML3IARjfdL4YkTXHg",
  },
  {
    zipCode: "92084",
    city: "Vista",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJ53MR5wML3IARjfdL4YkTXHg",
  },
  {
    zipCode: "92085",
    city: "Vista",
    county: "San Diego",
    type: "P.O.Box",
    placeId: "ChIJ53MR5wML3IARjfdL4YkTXHg",
  },
  {
    zipCode: "92086",
    city: "Warner Springs",
    county: "San Diego",
    type: "Standard",
    placeId: "ChIJgdwlaUe424ARqNMrIeEo2VA",
  },
  {
    zipCode: "94102",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94103",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94104",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94105",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94107",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94108",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94109",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94110",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94111",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94112",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94114",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94115",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94116",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94117",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94118",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94119",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94120",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94121",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94122",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94123",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94124",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94125",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94126",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94127",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94129",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94130",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94131",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94132",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94133",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94134",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94137",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94139",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94140",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94141",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94142",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94143",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94144",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94145",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94146",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94147",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94151",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94158",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94159",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94160",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94161",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94163",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94164",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94172",
    city: "San Francisco",
    county: "San Francisco",
    type: "P.O.Box",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94177",
    city: "San Francisco",
    county: "San Francisco",
    type: "Unique",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "94188",
    city: "San Francisco",
    county: "San Francisco",
    type: "Standard",
    placeId: "ChIJIQBpAG2ahYAR_6128GcTUEo",
  },
  {
    zipCode: "95220",
    city: "Acampo",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJF0KIlumhmoAR16ztw9bt68w",
  },
  {
    zipCode: "95227",
    city: "Clements",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJ9bs8KkidmoARdZECJiv43S8",
  },
  {
    zipCode: "95320",
    city: "Escalon",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJE1Yee9tbkIARDqsfI-hc0k0",
  },
  {
    zipCode: "95230",
    city: "Farmington",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJKxq73ilnkIARP9bXDAMPwDQ",
  },
  {
    zipCode: "95231",
    city: "French Camp",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJVxlPxKIUkIARUjzdniFgxmA",
  },
  {
    zipCode: "95234",
    city: "Holt",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJ74s6lesPkIAR2HkRm6hf-vI",
  },
  {
    zipCode: "95330",
    city: "Lathrop",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJQzwTN04VkIARDiyCDB0cBgs",
  },
  {
    zipCode: "95236",
    city: "Linden",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJrZ21yt9kkIAR9d2GwlyC8Jg",
  },
  {
    zipCode: "95237",
    city: "Lockeford",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJrepLjB12kIARm7jUjepR7bI",
  },
  {
    zipCode: "95240",
    city: "Lodi",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJNTjFJtmhmoARRuhsu5jkrY4",
  },
  {
    zipCode: "95241",
    city: "Lodi",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJNTjFJtmhmoARRuhsu5jkrY4",
  },
  {
    zipCode: "95242",
    city: "Lodi",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJNTjFJtmhmoARRuhsu5jkrY4",
  },
  {
    zipCode: "95336",
    city: "Manteca",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJCUWMJENAkIARjMxOe6Wp4p0",
  },
  {
    zipCode: "95337",
    city: "Manteca",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJCUWMJENAkIARjMxOe6Wp4p0",
  },
  {
    zipCode: "95366",
    city: "Ripon",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJyx61djRCkIARN5sY9tzA_AQ",
  },
  {
    zipCode: "95201",
    city: "Stockton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95202",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95203",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95204",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95205",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95206",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95207",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95208",
    city: "Stockton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95209",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95210",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95211",
    city: "Stockton",
    county: "San Joaquin",
    type: "Unique",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95212",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95213",
    city: "Stockton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95214",
    city: "Stockton",
    county: "San Joaquin",
    type: "Unique",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95215",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95219",
    city: "Stockton",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95267",
    city: "Stockton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95269",
    city: "Stockton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95296",
    city: "Stockton",
    county: "San Joaquin",
    type: "Unique",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95297",
    city: "Stockton",
    county: "San Joaquin",
    type: "Unique",
    placeId: "ChIJgSFEe3MNkIAR2MtPBNH1dlg",
  },
  {
    zipCode: "95686",
    city: "Thornton",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJiXu0b7avmoARjbaYuJOTZog",
  },
  {
    zipCode: "95304",
    city: "Tracy",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJTZlVClkUkIARmRSv4HlvRug",
  },
  {
    zipCode: "95376",
    city: "Tracy",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJTZlVClkUkIARmRSv4HlvRug",
  },
  {
    zipCode: "95377",
    city: "Tracy",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJTZlVClkUkIARmRSv4HlvRug",
  },
  {
    zipCode: "95378",
    city: "Tracy",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJTZlVClkUkIARmRSv4HlvRug",
  },
  {
    zipCode: "95391",
    city: "Tracy",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJTZlVClkUkIARmRSv4HlvRug",
  },
  {
    zipCode: "95385",
    city: "Vernalis",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJkRWZDb83kIARHptflcZ3rRM",
  },
  {
    zipCode: "95253",
    city: "Victor",
    county: "San Joaquin",
    type: "P.O.Box",
    placeId: "ChIJ8c4BDop1kIARtldrmqCffsI",
  },
  {
    zipCode: "95258",
    city: "Woodbridge",
    county: "San Joaquin",
    type: "Standard",
    placeId: "ChIJ_2nDcXahmoARI7fU1nmdavA",
  },
  {
    zipCode: "93420",
    city: "Arroyo Grande",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJz8yOQmRe7IAR7WnVaZBqwaQ",
  },
  {
    zipCode: "93421",
    city: "Arroyo Grande",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJz8yOQmRe7IAR7WnVaZBqwaQ",
  },
  {
    zipCode: "93422",
    city: "Atascadero",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJxbld14DC7IARSAd2yRG2iHU",
  },
  {
    zipCode: "93423",
    city: "Atascadero",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJxbld14DC7IARSAd2yRG2iHU",
  },
  {
    zipCode: "93424",
    city: "Avila Beach",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJF1TM6TT47IARtilGP_bGQlw",
  },
  {
    zipCode: "93428",
    city: "Cambria",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJH0hne3rX7IARVok6h8tJ_cQ",
  },
  {
    zipCode: "93430",
    city: "Cayucos",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJCcIK6cbw7IAR8gAodEYgEk0",
  },
  {
    zipCode: "93432",
    city: "Creston",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJhandxVGV7IARA71_x6lO-sc",
  },
  {
    zipCode: "93433",
    city: "Grover Beach",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJr07OGTVZ7IARH3_SCMyTa30",
  },
  {
    zipCode: "93483",
    city: "Grover Beach",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJr07OGTVZ7IARH3_SCMyTa30",
  },
  {
    zipCode: "93435",
    city: "Harmony",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJv85zBa4l7YAR57Vws8yh-Qw",
  },
  {
    zipCode: "93402",
    city: "Los Osos",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJ9VO7mozk7IARfo527tnJZFM",
  },
  {
    zipCode: "93412",
    city: "Los Osos",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJ9VO7mozk7IARfo527tnJZFM",
  },
  {
    zipCode: "93442",
    city: "Morro Bay",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJk6q1uQvh7IARDJeBwRniG6o",
  },
  {
    zipCode: "93443",
    city: "Morro Bay",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJk6q1uQvh7IARDJeBwRniG6o",
  },
  {
    zipCode: "93444",
    city: "Nipomo",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJXVv1LRBo7IARDHTOPwyqlZA",
  },
  {
    zipCode: "93445",
    city: "Oceano",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJ5fC_swJc7IARpY3aVuhsns0",
  },
  {
    zipCode: "93475",
    city: "Oceano",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJ5fC_swJc7IARpY3aVuhsns0",
  },
  {
    zipCode: "93446",
    city: "Paso Robles",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJs8GMR6Hx7IAR2Ze8guhsLzI",
  },
  {
    zipCode: "93447",
    city: "Paso Robles",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJs8GMR6Hx7IAR2Ze8guhsLzI",
  },
  {
    zipCode: "93448",
    city: "Pismo Beach",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJz9j3w31Y7IARpW9C8JhSy7k",
  },
  {
    zipCode: "93449",
    city: "Pismo Beach",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJz9j3w31Y7IARpW9C8JhSy7k",
  },
  {
    zipCode: "93401",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93403",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93405",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93406",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "P.O.Box",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93407",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93408",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Unique",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93409",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Unique",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93410",
    city: "San Luis Obispo",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJJ8Jse77m7IARRg_vzsKQArw",
  },
  {
    zipCode: "93451",
    city: "San Miguel",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJu6gg2aDx7IAR5eo-nP84ElI",
  },
  {
    zipCode: "93452",
    city: "San Simeon",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJaZuO5rE07YARBs_f9Hnoyho",
  },
  {
    zipCode: "93453",
    city: "Santa Margarita",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJccpefUCB7IARCjhw1C4iofA",
  },
  {
    zipCode: "93461",
    city: "Shandon",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJRfOxFHCj7IAR8CMlFnh8zTU",
  },
  {
    zipCode: "93465",
    city: "Templeton",
    county: "San Luis Obispo",
    type: "Standard",
    placeId: "ChIJ995QW6rE7IARnn5hQOho-VM",
  },
  {
    zipCode: "94027",
    city: "Atherton",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJveSHS56kj4ARLc3FF5K_W2U",
  },
  {
    zipCode: "94002",
    city: "Belmont",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJCbXJGG-fj4ARJEXCIy-qHqg",
  },
  {
    zipCode: "94005",
    city: "Brisbane",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJMQWj8th4j4ARMK5Tzi_gVf8",
  },
  {
    zipCode: "94010",
    city: "Burlingame",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJWY6bVCV2j4ART48vUz76vAs",
  },
  {
    zipCode: "94011",
    city: "Burlingame",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJWY6bVCV2j4ART48vUz76vAs",
  },
  {
    zipCode: "94014",
    city: "Daly City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJyWPehPt7j4ARV_oTDtvgtCw",
  },
  {
    zipCode: "94015",
    city: "Daly City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJyWPehPt7j4ARV_oTDtvgtCw",
  },
  {
    zipCode: "94016",
    city: "Daly City",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJyWPehPt7j4ARV_oTDtvgtCw",
  },
  {
    zipCode: "94017",
    city: "Daly City",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJyWPehPt7j4ARV_oTDtvgtCw",
  },
  {
    zipCode: "94018",
    city: "El Granada",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJdUy3rcRzj4ARDfFUKZCwDz8",
  },
  {
    zipCode: "94019",
    city: "Half Moon Bay",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJC8sZCqULj4ARVJvnNcic_V4",
  },
  {
    zipCode: "94020",
    city: "La Honda",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJvWVi3NSuj4ARGXJqMNMJoVE",
  },
  {
    zipCode: "94021",
    city: "Loma Mar",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJF5ZAS2Wpj4ARYU0nyEmV0dg",
  },
  {
    zipCode: "94025",
    city: "Menlo Park",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ_4ByEbGmj4ARq4nyXY6Zv-s",
  },
  {
    zipCode: "94026",
    city: "Menlo Park",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJ_4ByEbGmj4ARq4nyXY6Zv-s",
  },
  {
    zipCode: "94030",
    city: "Millbrae",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJB5B2CJAKj4ARa1qX_PThidM",
  },
  {
    zipCode: "94037",
    city: "Montara",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJ02k1agRxj4ARnV8ADw4ha_Y",
  },
  {
    zipCode: "94038",
    city: "Moss Beach",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJQxpR03Nxj4ARrf-ktWobc6Q",
  },
  {
    zipCode: "94044",
    city: "Pacifica",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJeZZK0S93j4AROMShF38vsEQ",
  },
  {
    zipCode: "94060",
    city: "Pescadero",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJwcQTtrQAj4ARd4uHd5BgPAQ",
  },
  {
    zipCode: "94028",
    city: "Portola Valley",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJqQSBRQ-vj4ARbIrFqIHbrMw",
  },
  {
    zipCode: "94061",
    city: "Redwood City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ4V7zUzmgj4ARbWqqC5t75Lg",
  },
  {
    zipCode: "94062",
    city: "Redwood City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ4V7zUzmgj4ARbWqqC5t75Lg",
  },
  {
    zipCode: "94063",
    city: "Redwood City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ4V7zUzmgj4ARbWqqC5t75Lg",
  },
  {
    zipCode: "94064",
    city: "Redwood City",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJ4V7zUzmgj4ARbWqqC5t75Lg",
  },
  {
    zipCode: "94065",
    city: "Redwood City",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ4V7zUzmgj4ARbWqqC5t75Lg",
  },
  {
    zipCode: "94066",
    city: "San Bruno",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJOSmpLuR5j4AR0u9gOcikHdo",
  },
  {
    zipCode: "94070",
    city: "San Carlos",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJd2j8APShj4ARiGkYJJ0PePo",
  },
  {
    zipCode: "94128",
    city: "San Francisco",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94074",
    city: "San Gregorio",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ54QZvnYIj4ARPLW-g2VSka8",
  },
  {
    zipCode: "94401",
    city: "San Mateo",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94402",
    city: "San Mateo",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94403",
    city: "San Mateo",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94404",
    city: "San Mateo",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94497",
    city: "San Mateo",
    county: "San Mateo",
    type: "Unique",
    placeId: "ChIJRVWp72Cej4ARpxvMLfT8jv0",
  },
  {
    zipCode: "94080",
    city: "South San Francisco",
    county: "San Mateo",
    type: "Standard",
    placeId: "ChIJ7-z-QwV5j4ARXTNW53l1Ovg",
  },
  {
    zipCode: "94083",
    city: "South San Francisco",
    county: "San Mateo",
    type: "P.O.Box",
    placeId: "ChIJ7-z-QwV5j4ARXTNW53l1Ovg",
  },
  {
    zipCode: "93427",
    city: "Buellton",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJeWrlVfyq7oAR3bDqC4mbfY4",
  },
  {
    zipCode: "93013",
    city: "Carpinteria",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJoWIabtQO6YAR67cbe8kjlRY",
  },
  {
    zipCode: "93014",
    city: "Carpinteria",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJoWIabtQO6YAR67cbe8kjlRY",
  },
  {
    zipCode: "93429",
    city: "Casmalia",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJhZdFh3Y_7IARxk62byQIU7k",
  },
  {
    zipCode: "93116",
    city: "Goleta",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ40iJCXlA6YARWE2IAMVEypM",
  },
  {
    zipCode: "93117",
    city: "Goleta",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ40iJCXlA6YARWE2IAMVEypM",
  },
  {
    zipCode: "93118",
    city: "Goleta",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ40iJCXlA6YARWE2IAMVEypM",
  },
  {
    zipCode: "93199",
    city: "Goleta",
    county: "Santa Barbara",
    type: "Unique",
    placeId: "ChIJ40iJCXlA6YARWE2IAMVEypM",
  },
  {
    zipCode: "93434",
    city: "Guadalupe",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ17stdsxD7IARMInQD02x0eo",
  },
  {
    zipCode: "93436",
    city: "Lompoc",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJbUhR_Jce7IARBbAnup-6lOk",
  },
  {
    zipCode: "93437",
    city: "Lompoc",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJbUhR_Jce7IARBbAnup-6lOk",
  },
  {
    zipCode: "93438",
    city: "Lompoc",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJbUhR_Jce7IARBbAnup-6lOk",
  },
  {
    zipCode: "93440",
    city: "Los Alamos",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJT1tnOEgE7IARTjol2pFIL4M",
  },
  {
    zipCode: "93441",
    city: "Los Olivos",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJg5xJBnpV6YARG_SRJXRb-yw",
  },
  {
    zipCode: "93254",
    city: "New Cuyama",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJxaIU-ETb64ARWWFE6tTMWmc",
  },
  {
    zipCode: "93101",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93102",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93103",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93105",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93106",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Unique",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93107",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93108",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93109",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93110",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93111",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93120",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93121",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93130",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93140",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93150",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93160",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93190",
    city: "Santa Barbara",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJ1YMtb8cU6YARSHa612Q60cg",
  },
  {
    zipCode: "93454",
    city: "Santa Maria",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJCcZUDKoP7IARjjRaimVS_Qs",
  },
  {
    zipCode: "93455",
    city: "Santa Maria",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJCcZUDKoP7IARjjRaimVS_Qs",
  },
  {
    zipCode: "93456",
    city: "Santa Maria",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJCcZUDKoP7IARjjRaimVS_Qs",
  },
  {
    zipCode: "93457",
    city: "Santa Maria",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJCcZUDKoP7IARjjRaimVS_Qs",
  },
  {
    zipCode: "93458",
    city: "Santa Maria",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJCcZUDKoP7IARjjRaimVS_Qs",
  },
  {
    zipCode: "93460",
    city: "Santa Ynez",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJKwFrRQ9U6YARLK7T68dzI5s",
  },
  {
    zipCode: "93463",
    city: "Solvang",
    county: "Santa Barbara",
    type: "Standard",
    placeId: "ChIJhSKS_KBU6YARVsELBhsoDi0",
  },
  {
    zipCode: "93464",
    city: "Solvang",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJhSKS_KBU6YARVsELBhsoDi0",
  },
  {
    zipCode: "93067",
    city: "Summerland",
    county: "Santa Barbara",
    type: "P.O.Box",
    placeId: "ChIJW_8r5fYN6YARW5J3jnKpxkM",
  },
  {
    zipCode: "95002",
    city: "Alviso",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ6ZeMLxTIj4ARVMglvFjq5HY",
  },
  {
    zipCode: "95008",
    city: "Campbell",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ6b7YLRk1joARmAd-J9EOXj0",
  },
  {
    zipCode: "95009",
    city: "Campbell",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ6b7YLRk1joARmAd-J9EOXj0",
  },
  {
    zipCode: "95011",
    city: "Campbell",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ6b7YLRk1joARmAd-J9EOXj0",
  },
  {
    zipCode: "95013",
    city: "Coyote",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJrRV5vSXKj4AR_152qZehD6I",
  },
  {
    zipCode: "95014",
    city: "Cupertino",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJq3fTG1e0j4ARtHjho-E_TTk",
  },
  {
    zipCode: "95015",
    city: "Cupertino",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJq3fTG1e0j4ARtHjho-E_TTk",
  },
  {
    zipCode: "95020",
    city: "Gilroy",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJy85TFbnjkYAROygnpRsM_a4",
  },
  {
    zipCode: "95021",
    city: "Gilroy",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJy85TFbnjkYAROygnpRsM_a4",
  },
  {
    zipCode: "95026",
    city: "Holy City",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "94022",
    city: "Los Altos",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJz0bVI-Gwj4AR_KZl1l0YBWU",
  },
  {
    zipCode: "94023",
    city: "Los Altos",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJz0bVI-Gwj4AR_KZl1l0YBWU",
  },
  {
    zipCode: "94024",
    city: "Los Altos",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJz0bVI-Gwj4AR_KZl1l0YBWU",
  },
  {
    zipCode: "95030",
    city: "Los Gatos",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJPwN3UzY0joARpox0rAFTZ-8",
  },
  {
    zipCode: "95031",
    city: "Los Gatos",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJPwN3UzY0joARpox0rAFTZ-8",
  },
  {
    zipCode: "95032",
    city: "Los Gatos",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJPwN3UzY0joARpox0rAFTZ-8",
  },
  {
    zipCode: "95033",
    city: "Los Gatos",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJPwN3UzY0joARpox0rAFTZ-8",
  },
  {
    zipCode: "95035",
    city: "Milpitas",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJXyrmOufRj4ARJ6U6rcDhY3g",
  },
  {
    zipCode: "95036",
    city: "Milpitas",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJXyrmOufRj4ARJ6U6rcDhY3g",
  },
  {
    zipCode: "95037",
    city: "Morgan Hill",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJbZ7QsR8ejoAR1VyJID7pEmg",
  },
  {
    zipCode: "95038",
    city: "Morgan Hill",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJbZ7QsR8ejoAR1VyJID7pEmg",
  },
  {
    zipCode: "95140",
    city: "Mount Hamilton",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ8Z9ljG4qjoARvL9F1p07z6c",
  },
  {
    zipCode: "94035",
    city: "Mountain View",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "94039",
    city: "Mountain View",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "94040",
    city: "Mountain View",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "94041",
    city: "Mountain View",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "94042",
    city: "Mountain View",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "94043",
    city: "Mountain View",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJiQHsW0m3j4ARm69rRkrUF3w",
  },
  {
    zipCode: "95042",
    city: "New Almaden",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJIeBrFrA6joARuJhyOlrVFUM",
  },
  {
    zipCode: "94301",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "94302",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "94303",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "94304",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "94306",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "94309",
    city: "Palo Alto",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJORy6nXuwj4ARz3b1NVL1Hw4",
  },
  {
    zipCode: "95044",
    city: "Redwood Estates",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJQdsN-d03joARXwHM47hI8TM",
  },
  {
    zipCode: "95101",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95103",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95106",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95108",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95109",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95110",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95111",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95112",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95113",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95115",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95116",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95117",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95118",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95119",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95120",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95121",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95122",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95123",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95124",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95125",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95126",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95127",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95128",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95129",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95130",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95131",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95132",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95133",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95134",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95135",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95136",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95138",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95139",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95141",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95148",
    city: "San Jose",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95150",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95151",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95152",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95153",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95154",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95155",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95156",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95157",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95158",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95159",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95160",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95161",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95164",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95170",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95172",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95173",
    city: "San Jose",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95190",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95191",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95192",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95193",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95194",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95196",
    city: "San Jose",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJ9T_5iuTKj4ARe3GfygqMnbk",
  },
  {
    zipCode: "95046",
    city: "San Martin",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJL692uhPgkYARQ9iy45oRcDg",
  },
  {
    zipCode: "95050",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95051",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95052",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95053",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "Unique",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95054",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95055",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95056",
    city: "Santa Clara",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJk8EIXIG3j4ARwL_Ao3ykdeQ",
  },
  {
    zipCode: "95070",
    city: "Saratoga",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJqT9-TMRKjoARzFYL1IfICIM",
  },
  {
    zipCode: "95071",
    city: "Saratoga",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJqT9-TMRKjoARzFYL1IfICIM",
  },
  {
    zipCode: "94305",
    city: "Stanford",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJpxcVNevJj4ARWWPv-sv6_BY",
  },
  {
    zipCode: "94085",
    city: "Sunnyvale",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJO13QqUW2j4ARosN83Sb7jXY",
  },
  {
    zipCode: "94086",
    city: "Sunnyvale",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJO13QqUW2j4ARosN83Sb7jXY",
  },
  {
    zipCode: "94087",
    city: "Sunnyvale",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJO13QqUW2j4ARosN83Sb7jXY",
  },
  {
    zipCode: "94088",
    city: "Sunnyvale",
    county: "Santa Clara",
    type: "P.O.Box",
    placeId: "ChIJO13QqUW2j4ARosN83Sb7jXY",
  },
  {
    zipCode: "94089",
    city: "Sunnyvale",
    county: "Santa Clara",
    type: "Standard",
    placeId: "ChIJO13QqUW2j4ARosN83Sb7jXY",
  },
  {
    zipCode: "95001",
    city: "Aptos",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJq2dOVwoWjoARIwcAER-fOwI",
  },
  {
    zipCode: "95003",
    city: "Aptos",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJq2dOVwoWjoARIwcAER-fOwI",
  },
  {
    zipCode: "95005",
    city: "Ben Lomond",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJzw6YpOBFjoAR4q4D90Blp7o",
  },
  {
    zipCode: "95006",
    city: "Boulder Creek",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJ_VjbzBFPjoARBQsEKYNJMQQ",
  },
  {
    zipCode: "95007",
    city: "Brookdale",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJseiw8F1PjoARRknd0mNBG-g",
  },
  {
    zipCode: "95010",
    city: "Capitola",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJ066GeHQVjoAR58t1aK7zMNw",
  },
  {
    zipCode: "95017",
    city: "Davenport",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJbWtVV3RQjoAREgB5St1W1JQ",
  },
  {
    zipCode: "95018",
    city: "Felton",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJzzvzj_c-joARLOTrZl4hCSI",
  },
  {
    zipCode: "95019",
    city: "Freedom",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJI3Stn1sajoARGW3cBotkxzE",
  },
  {
    zipCode: "95041",
    city: "Mount Hermon",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJe6ln5cZGjoARCPb_VYXZuwc",
  },
  {
    zipCode: "95060",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95061",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95062",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95063",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95064",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95065",
    city: "Santa Cruz",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJYxrApxzo8ZMRG2FnpFMKi1w",
  },
  {
    zipCode: "95066",
    city: "Scotts Valley",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJRR4-TUpHjoARb_UobpJG34Q",
  },
  {
    zipCode: "95067",
    city: "Scotts Valley",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJRR4-TUpHjoARb_UobpJG34Q",
  },
  {
    zipCode: "95073",
    city: "Soquel",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJpZIyBYAVjoARPZaw2rHx3dI",
  },
  {
    zipCode: "95076",
    city: "Watsonville",
    county: "Santa Cruz",
    type: "Standard",
    placeId: "ChIJ45W--bgIjoARpaFiI4bORds",
  },
  {
    zipCode: "95077",
    city: "Watsonville",
    county: "Santa Cruz",
    type: "P.O.Box",
    placeId: "ChIJ45W--bgIjoARpaFiI4bORds",
  },
  {
    zipCode: "96007",
    city: "Anderson",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJAWIWgEHB0lQRO5PPJH7oA8s",
  },
  {
    zipCode: "96008",
    city: "Bella Vista",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJiyE_fCqb0lQRLgTvC3X6SD0",
  },
  {
    zipCode: "96011",
    city: "Big Bend",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJz1hqhpqYzVQR0pUFFBgrK6w",
  },
  {
    zipCode: "96013",
    city: "Burney",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJicOKMc8JzVQRbzAJZ7yjm0s",
  },
  {
    zipCode: "96016",
    city: "Cassel",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJEy1k2x4PzVQRj6uVeaKV4yk",
  },
  {
    zipCode: "96017",
    city: "Castella",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJG_0ya2fyzVQRzr0fAQOiD0M",
  },
  {
    zipCode: "96022",
    city: "Cottonwood",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJ-WTpnJjD0lQRXJjzwVxM31c",
  },
  {
    zipCode: "96028",
    city: "Fall River Mills",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJ291O_msBzVQRnusem4FPQxM",
  },
  {
    zipCode: "96033",
    city: "French Gulch",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJgddM0Ehf0lQRVRb6g3RD_Xo",
  },
  {
    zipCode: "96040",
    city: "Hat Creek",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJx4nZjVgRzVQR2Zpzdt0nAio",
  },
  {
    zipCode: "96047",
    city: "Igo",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJ5Tp_ReXl0lQRCXKuaSVeqAw",
  },
  {
    zipCode: "96051",
    city: "Lakehead",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJqfZ6EyB60lQRYNBDVcmGe_M",
  },
  {
    zipCode: "96056",
    city: "Mcarthur",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJO-obK9v_zFQRgvtgqTIiS_0",
  },
  {
    zipCode: "96062",
    city: "Millville",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJY5xEDOyX0lQR8NB3rNzdmg0",
  },
  {
    zipCode: "96065",
    city: "Montgomery Creek",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJOyBYrx53zVQRfS4UbAq8bmY",
  },
  {
    zipCode: "96069",
    city: "Oak Run",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJSaltU7Gd0lQRwrRVpO-rgFs",
  },
  {
    zipCode: "96070",
    city: "Obrien",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "96071",
    city: "Old Station",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJsZ3D95Q4zVQRgspzq4o-X_4",
  },
  {
    zipCode: "96073",
    city: "Palo Cedro",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJC6k1I0mU0lQRw7RSBEXMiew",
  },
  {
    zipCode: "96076",
    city: "Platina",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJKepQwgIX01QRJzYwEkOKlTg",
  },
  {
    zipCode: "96001",
    city: "Redding",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJLyBKO9aR0lQRVw82e-xYMx8",
  },
  {
    zipCode: "96002",
    city: "Redding",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJLyBKO9aR0lQRVw82e-xYMx8",
  },
  {
    zipCode: "96003",
    city: "Redding",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJLyBKO9aR0lQRVw82e-xYMx8",
  },
  {
    zipCode: "96049",
    city: "Redding",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJLyBKO9aR0lQRVw82e-xYMx8",
  },
  {
    zipCode: "96099",
    city: "Redding",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJLyBKO9aR0lQRVw82e-xYMx8",
  },
  {
    zipCode: "96084",
    city: "Round Mountain",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJkZoSPQd7zVQRdKuedr_XRC4",
  },
  {
    zipCode: "96087",
    city: "Shasta",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "96019",
    city: "Shasta Lake",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJcdXrbgGR0lQRsST2E7SUoFE",
  },
  {
    zipCode: "96079",
    city: "Shasta Lake",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJcdXrbgGR0lQRsST2E7SUoFE",
  },
  {
    zipCode: "96089",
    city: "Shasta Lake",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJcdXrbgGR0lQRsST2E7SUoFE",
  },
  {
    zipCode: "96088",
    city: "Shingletown",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJ189MxO2l0lQRHaKjQ-LQg2s",
  },
  {
    zipCode: "96095",
    city: "Whiskeytown",
    county: "Shasta",
    type: "P.O.Box",
    placeId: "ChIJX07tVFHx0lQRmlSj2qEqUBU",
  },
  {
    zipCode: "96096",
    city: "Whitmore",
    county: "Shasta",
    type: "Standard",
    placeId: "ChIJF_GNFvCf0lQRxn8CAJh3EMc",
  },
  {
    zipCode: "95910",
    city: "Alleghany",
    county: "Sierra",
    type: "Standard",
    placeId: "ChIJ2xragoNaTIgRkAHAfPi83yM",
  },
  {
    zipCode: "96124",
    city: "Calpine",
    county: "Sierra",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95936",
    city: "Downieville",
    county: "Sierra",
    type: "P.O.Box",
    placeId: "ChIJvXmuzUF8nIARhmkkO7g1AQQ",
  },
  {
    zipCode: "95944",
    city: "Goodyears Bar",
    county: "Sierra",
    type: "P.O.Box",
    placeId: "ChIJ92hwX419nIARtJEfBgol3b8",
  },
  {
    zipCode: "96118",
    city: "Loyalton",
    county: "Sierra",
    type: "Standard",
    placeId: "ChIJP3YhX98AnIARUmU6OQ80kN0",
  },
  {
    zipCode: "96125",
    city: "Sierra City",
    county: "Sierra",
    type: "Standard",
    placeId: "ChIJ428U_492nIARrPPh_ZY9HWw",
  },
  {
    zipCode: "96126",
    city: "Sierraville",
    county: "Sierra",
    type: "P.O.Box",
    placeId: "ChIJd0pD6Of3m4ARZGxBUlqJdRg",
  },
  {
    zipCode: "96014",
    city: "Callahan",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJtTppyhL-0VQRFjQLVsuPZbQ",
  },
  {
    zipCode: "96023",
    city: "Dorris",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJ9wpKn_zBzlQR0AEtxeDTnu0",
  },
  {
    zipCode: "96025",
    city: "Dunsmuir",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJuei5VVvvzVQRNvB3eqhP37U",
  },
  {
    zipCode: "96027",
    city: "Etna",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJJ7M9wSLh0VQREoKVNViGsIk",
  },
  {
    zipCode: "96031",
    city: "Forks Of Salmon",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJEwvuvUiS0VQRYtM0KWzdUZE",
  },
  {
    zipCode: "96032",
    city: "Fort Jones",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJgzrS0CMUzlQRGA6jzVCG1Yk",
  },
  {
    zipCode: "96034",
    city: "Gazelle",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJ6QNqw9kPzlQR23PWFdeKImE",
  },
  {
    zipCode: "96037",
    city: "Greenview",
    county: "Siskiyou",
    type: "P.O.Box",
    placeId: "ChIJgcOMglUizlQRroKAtWqm_SY",
  },
  {
    zipCode: "96038",
    city: "Grenada",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJj3HwuA1CzlQR_icLz6wdapQ",
  },
  {
    zipCode: "96039",
    city: "Happy Camp",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJTwW4bYzXz1QR4LQQumgYG2E",
  },
  {
    zipCode: "96044",
    city: "Hornbrook",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJYa-ypZJVzlQRY6L14RBE0Q4",
  },
  {
    zipCode: "96050",
    city: "Klamath River",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJCa6D-URNzlQRW0Xteeuay9g",
  },
  {
    zipCode: "96058",
    city: "Macdoel",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJH8Q4Jb-NzlQRbRZC0fyfMfE",
  },
  {
    zipCode: "96057",
    city: "Mccloud",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJb47FgOAszFQR08FSa_-NZSI",
  },
  {
    zipCode: "96064",
    city: "Montague",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJgzrS0CMUzlQRNNOvzT_e_DQ",
  },
  {
    zipCode: "96067",
    city: "Mount Shasta",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJQQgCJdPgzVQRB4ZLyAEtao4",
  },
  {
    zipCode: "96085",
    city: "Scott Bar",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJ9e4nCU0yzlQRgcs0kl_Em1Q",
  },
  {
    zipCode: "96086",
    city: "Seiad Valley",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJPRyRCinOz1QRmMrijq-35zc",
  },
  {
    zipCode: "95568",
    city: "Somes Bar",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJyzz9n_G-0VQReIDVpeN91XQ",
  },
  {
    zipCode: "96134",
    city: "Tulelake",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJnzrBVqVKyVQROvji4sc9JAc",
  },
  {
    zipCode: "96094",
    city: "Weed",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJWU8Qh5d0zlQRbvpUQNTvvts",
  },
  {
    zipCode: "96097",
    city: "Yreka",
    county: "Siskiyou",
    type: "Standard",
    placeId: "ChIJgzrS0CMUzlQRXUAsM2ZAu8Q",
  },
  {
    zipCode: "94510",
    city: "Benicia",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJM2Ay-7VthYARXaGvVQcLLpw",
  },
  {
    zipCode: "94512",
    city: "Birds Landing",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ41r2sVlEhYARzwhOH2iOijU",
  },
  {
    zipCode: "95620",
    city: "Dixon",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ-SScZZ4lhYARpo54GqppgpQ",
  },
  {
    zipCode: "95625",
    city: "Elmira",
    county: "Solano",
    type: "P.O.Box",
    placeId: "ChIJNyMPJ4E8hYAR_c8E2X4KPPY",
  },
  {
    zipCode: "94533",
    city: "Fairfield",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ28gj8dAUhYARaPOb0hZWM1w",
  },
  {
    zipCode: "94534",
    city: "Fairfield",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ28gj8dAUhYARaPOb0hZWM1w",
  },
  {
    zipCode: "94571",
    city: "Rio Vista",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ81PH6Kw3hYARqfmp8MV8-mk",
  },
  {
    zipCode: "94585",
    city: "Suisun City",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJgTP535sVhYARgPQ5w8ms6QM",
  },
  {
    zipCode: "94535",
    city: "Travis AFB",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJ0-HJfeI-hYARnMjBBYF4HA4",
  },
  {
    zipCode: "95687",
    city: "Vacaville",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJB_R9n88XhYARHGObY9_IquQ",
  },
  {
    zipCode: "95688",
    city: "Vacaville",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJB_R9n88XhYARHGObY9_IquQ",
  },
  {
    zipCode: "95696",
    city: "Vacaville",
    county: "Solano",
    type: "P.O.Box",
    placeId: "ChIJB_R9n88XhYARHGObY9_IquQ",
  },
  {
    zipCode: "94589",
    city: "Vallejo",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJCQfOMpcRhYARORbGqAI_k5U",
  },
  {
    zipCode: "94590",
    city: "Vallejo",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJCQfOMpcRhYARORbGqAI_k5U",
  },
  {
    zipCode: "94591",
    city: "Vallejo",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJCQfOMpcRhYARORbGqAI_k5U",
  },
  {
    zipCode: "94592",
    city: "Vallejo",
    county: "Solano",
    type: "Standard",
    placeId: "ChIJCQfOMpcRhYARORbGqAI_k5U",
  },
  {
    zipCode: "95412",
    city: "Annapolis",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJTf5Aa59LgYARqUGCYV4U2Z4",
  },
  {
    zipCode: "94922",
    city: "Bodega",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJu5JUWEQvhIARl1KGzNVjzg0",
  },
  {
    zipCode: "94923",
    city: "Bodega Bay",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJXTyyuk0nhIARCEl4F1o56uQ",
  },
  {
    zipCode: "95416",
    city: "Boyes Hot Springs",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJE09-W1GshYAR7XvjmpGgnqw",
  },
  {
    zipCode: "95419",
    city: "Camp Meeker",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJLR_VYW4khIARVeJHq2oH0HI",
  },
  {
    zipCode: "95421",
    city: "Cazadero",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJG2qoMb-ghoAR_kJfafV3_t0",
  },
  {
    zipCode: "95425",
    city: "Cloverdale",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJAQP4gGT_g4ARGP-jNofjT9w",
  },
  {
    zipCode: "94931",
    city: "Cotati",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJpzrOEH1KhIARip-5V8UZBQs",
  },
  {
    zipCode: "95430",
    city: "Duncans Mills",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJ7UJhtUIghIARHW7QHLaN1HE",
  },
  {
    zipCode: "95433",
    city: "El Verano",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJfQc39DSshYARUhWGde4Xk8s",
  },
  {
    zipCode: "95431",
    city: "Eldridge",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJHW-tuj2thYAR3PNeJ59N4r4",
  },
  {
    zipCode: "95436",
    city: "Forestville",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ-TZhZSk9hIAR2iRKUMiIVU4",
  },
  {
    zipCode: "95439",
    city: "Fulton",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJaZE4fys5hIARKUvMLsBlV7Q",
  },
  {
    zipCode: "95441",
    city: "Geyserville",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ1Qs1uHkFhIAR41AiDPrFq8U",
  },
  {
    zipCode: "95442",
    city: "Glen Ellen",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJJWJwBR8_hIARLEb-cgBlYM0",
  },
  {
    zipCode: "95444",
    city: "Graton",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJybIoWAU7hIAREDKC4N90BAc",
  },
  {
    zipCode: "95446",
    city: "Guerneville",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJVdD5-zkfhIARsvq13Q13wyw",
  },
  {
    zipCode: "95448",
    city: "Healdsburg",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJs-FOdNIQhIARuVw3tunU7X8",
  },
  {
    zipCode: "95450",
    city: "Jenner",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJiU3MgpyfhoAR3q_mOsFp97g",
  },
  {
    zipCode: "95452",
    city: "Kenwood",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJMf5QVA9PhIARwYbgs2mJsMU",
  },
  {
    zipCode: "95462",
    city: "Monte Rio",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJjb9CsaEhhIARxcWfSerKJh0",
  },
  {
    zipCode: "95465",
    city: "Occidental",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJmV2lnzUkhIARvbxyEQbQu8Q",
  },
  {
    zipCode: "94951",
    city: "Penngrove",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ3YauvtO0hYAR_KVGDrVKo3g",
  },
  {
    zipCode: "94952",
    city: "Petaluma",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "94953",
    city: "Petaluma",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "94954",
    city: "Petaluma",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "94955",
    city: "Petaluma",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "94975",
    city: "Petaluma",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "94999",
    city: "Petaluma",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJTQx0woxKhIAR8mItZDW4_6s",
  },
  {
    zipCode: "95471",
    city: "Rio Nido",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJF6rp_bYYhIARbb9WNUp6W-8",
  },
  {
    zipCode: "94926",
    city: "Rohnert Park",
    county: "Sonoma",
    type: "Unique",
    placeId: "ChIJd_ag-mpKhIARx1I-A6I4iCQ",
  },
  {
    zipCode: "94927",
    city: "Rohnert Park",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJd_ag-mpKhIARx1I-A6I4iCQ",
  },
  {
    zipCode: "94928",
    city: "Rohnert Park",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJd_ag-mpKhIARx1I-A6I4iCQ",
  },
  {
    zipCode: "95401",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95402",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95403",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95404",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95405",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95406",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95407",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95409",
    city: "Santa Rosa",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJ10fmNsk7hIARSUhPxWtlzVw",
  },
  {
    zipCode: "95472",
    city: "Sebastopol",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJb2jjds0khIARCJLGdBHzeys",
  },
  {
    zipCode: "95473",
    city: "Sebastopol",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJb2jjds0khIARCJLGdBHzeys",
  },
  {
    zipCode: "95476",
    city: "Sonoma",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJd517gUOshYARReDmDiHtgAM",
  },
  {
    zipCode: "95480",
    city: "Stewarts Point",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJj8y8yhi1hoARDvJ4dkOLoT8",
  },
  {
    zipCode: "95497",
    city: "The Sea Ranch",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJm52TzIU1gYARNcjkvbCJ2QI",
  },
  {
    zipCode: "94972",
    city: "Valley Ford",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJg9oBu0EuhIARmuZtova_CNU",
  },
  {
    zipCode: "95486",
    city: "Villa Grande",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJHSiS0skhhIARxrj59-MF_2Y",
  },
  {
    zipCode: "95487",
    city: "Vineburg",
    county: "Sonoma",
    type: "P.O.Box",
    placeId: "ChIJH5WpBDGphYARLsOdtIW649M",
  },
  {
    zipCode: "95492",
    city: "Windsor",
    county: "Sonoma",
    type: "Standard",
    placeId: "ChIJiSEmjAAXhIARwpUhUn9m_lg",
  },
  {
    zipCode: "95307",
    city: "Ceres",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJmercvi2rkYAR29l_FnDmR3o",
  },
  {
    zipCode: "95313",
    city: "Crows Landing",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJo6E2kq26kYAR3hZ9dBTIgDc",
  },
  {
    zipCode: "95316",
    city: "Denair",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJ6x6c--UakYARUy-OzFqiBKk",
  },
  {
    zipCode: "95319",
    city: "Empire",
    county: "Stanislaus",
    type: "P.O.Box",
    placeId: "ChIJN2aie2NVkIARyeLPlHklhS8",
  },
  {
    zipCode: "95323",
    city: "Hickman",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJy2tfjvkCkYARCCeK4wmqAtM",
  },
  {
    zipCode: "95326",
    city: "Hughson",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJ29bsFdYGkYARRw4oYuDvtwE",
  },
  {
    zipCode: "95328",
    city: "Keyes",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJZZBDv2CqkYARIfF-iDQQ3s0",
  },
  {
    zipCode: "95329",
    city: "La Grange",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJ9Q76Q2EhkYARZaxaHNdqNOk",
  },
  {
    zipCode: "95350",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95351",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95352",
    city: "Modesto",
    county: "Stanislaus",
    type: "P.O.Box",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95353",
    city: "Modesto",
    county: "Stanislaus",
    type: "P.O.Box",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95354",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95355",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95356",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95357",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95358",
    city: "Modesto",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95397",
    city: "Modesto",
    county: "Stanislaus",
    type: "Unique",
    placeId: "ChIJUV989PZTkIARK2M3xDK6lL8",
  },
  {
    zipCode: "95360",
    city: "Newman",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJ_1AgAte-kYARTwruZEBJ1xk",
  },
  {
    zipCode: "95361",
    city: "Oakdale",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJ720urh74kIARmIYugpkqfzo",
  },
  {
    zipCode: "95363",
    city: "Patterson",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJtQfmi_OzkYARoOnR-6OeHhY",
  },
  {
    zipCode: "95367",
    city: "Riverbank",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJHUvNegJRkIARZEeJB5Wg1bk",
  },
  {
    zipCode: "95368",
    city: "Salida",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJDZoEsvhPkIARqZ1J9IO8aWI",
  },
  {
    zipCode: "95380",
    city: "Turlock",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJyTIabUwGkYARkOZ2pVznH4E",
  },
  {
    zipCode: "95381",
    city: "Turlock",
    county: "Stanislaus",
    type: "P.O.Box",
    placeId: "ChIJyTIabUwGkYARkOZ2pVznH4E",
  },
  {
    zipCode: "95382",
    city: "Turlock",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJyTIabUwGkYARkOZ2pVznH4E",
  },
  {
    zipCode: "95386",
    city: "Waterford",
    county: "Stanislaus",
    type: "Standard",
    placeId: "ChIJAz39WesckYARNkJHMVoNV_I",
  },
  {
    zipCode: "95387",
    city: "Westley",
    county: "Stanislaus",
    type: "P.O.Box",
    placeId: "ChIJr08SnhFLkIARYTgFNRqvyE4",
  },
  {
    zipCode: "95953",
    city: "Live Oak",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJG-6SxI1Ug4ARvZV-VwL2FR4",
  },
  {
    zipCode: "95957",
    city: "Meridian",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJFT-CklBfg4ARu05CkwoH2eI",
  },
  {
    zipCode: "95659",
    city: "Nicolaus",
    county: "Sutter",
    type: "Standard",
    placeId: "",
  },
  {
    zipCode: "95668",
    city: "Pleasant Grove",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJG7U5SC4wm4ARQa1E7dVfd-w",
  },
  {
    zipCode: "95674",
    city: "Rio Oso",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJDb5UfBtKm4ARfAj82ajjfLA",
  },
  {
    zipCode: "95676",
    city: "Robbins",
    county: "Sutter",
    type: "P.O.Box",
    placeId: "ChIJY_sth_7JhIARPF2-G_pCLsw",
  },
  {
    zipCode: "95982",
    city: "Sutter",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJy4fFVMExm0cRX_TLGref-Kk",
  },
  {
    zipCode: "95991",
    city: "Yuba City",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJN7FwxXGrhIARAiXwBR1wP00",
  },
  {
    zipCode: "95992",
    city: "Yuba City",
    county: "Sutter",
    type: "P.O.Box",
    placeId: "ChIJN7FwxXGrhIARAiXwBR1wP00",
  },
  {
    zipCode: "95993",
    city: "Yuba City",
    county: "Sutter",
    type: "Standard",
    placeId: "ChIJN7FwxXGrhIARAiXwBR1wP00",
  },
  {
    zipCode: "96021",
    city: "Corning",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJ9eJqE5zygoAR88MlfcxCMxA",
  },
  {
    zipCode: "96029",
    city: "Flournoy",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJOV7fTzdhgoARhP-A1Y6S9Bo",
  },
  {
    zipCode: "96035",
    city: "Gerber",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJu1tc6riRgoARHIUGmIPZ-Jc",
  },
  {
    zipCode: "96055",
    city: "Los Molinos",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJV2M4fC58goARBna7oiIJz1Y",
  },
  {
    zipCode: "96059",
    city: "Manton",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJH0uPltmt0lQRtE8cAKqP4WM",
  },
  {
    zipCode: "96061",
    city: "Mill Creek",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJsca7KqBRnYARkHkKIxMoijk",
  },
  {
    zipCode: "96063",
    city: "Mineral",
    county: "Tehama",
    type: "P.O.Box",
    placeId: "ChIJofXGtP9ZnYAR9FOIWct9fUE",
  },
  {
    zipCode: "96074",
    city: "Paskenta",
    county: "Tehama",
    type: "P.O.Box",
    placeId: "ChIJXfgGXkRogoAR-hUXD9eU0lw",
  },
  {
    zipCode: "96075",
    city: "Paynes Creek",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJP-ikSl-z0lQRkvq4ZnHLgQA",
  },
  {
    zipCode: "96078",
    city: "Proberta",
    county: "Tehama",
    type: "P.O.Box",
    placeId: "ChIJtXU2Zh-QgoARRPC23br84dI",
  },
  {
    zipCode: "96080",
    city: "Red Bluff",
    county: "Tehama",
    type: "Standard",
    placeId: "ChIJS3W5ELCDgoARWnY2h74ECik",
  },
  {
    zipCode: "96090",
    city: "Tehama",
    county: "Tehama",
    type: "P.O.Box",
    placeId: "ChIJKcwMWcqTgoARnd_H3a3dFmY",
  },
  {
    zipCode: "96092",
    city: "Vina",
    county: "Tehama",
    type: "P.O.Box",
    placeId: "ChIJJZtA8sbrgoARbTC_Z9ol9BE",
  },
  {
    zipCode: "96010",
    city: "Big Bar",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJd5F5nZe201QRsuRDUudfZ_E",
  },
  {
    zipCode: "95527",
    city: "Burnt Ranch",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJuVy8tdrF01QRxsbp3Gw9-to",
  },
  {
    zipCode: "96024",
    city: "Douglas City",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJPYKIsJRU0lQRTd1Uf7nqp7c",
  },
  {
    zipCode: "96041",
    city: "Hayfork",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJs_s8BnSg01QR2JAV0nz8So0",
  },
  {
    zipCode: "96046",
    city: "Hyampom",
    county: "Trinity",
    type: "P.O.Box",
    placeId: "ChIJ68CME82X01QRBFA0Tbhf1E4",
  },
  {
    zipCode: "96048",
    city: "Junction City",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJzRy59jNN0lQRgjP0aqPQftg",
  },
  {
    zipCode: "96052",
    city: "Lewiston",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJGWbJgaRD0lQRzk1SBj-dRFk",
  },
  {
    zipCode: "95552",
    city: "Mad River",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJPRNoOy6L01QRUM_AwwS0P9Y",
  },
  {
    zipCode: "95563",
    city: "Salyer",
    county: "Trinity",
    type: "P.O.Box",
    placeId: "ChIJQfgeyUXa01QRHEc_EcT6JxA",
  },
  {
    zipCode: "96091",
    city: "Trinity Center",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJCXncKo0S0lQRhzMsXyLTbJI",
  },
  {
    zipCode: "96093",
    city: "Weaverville",
    county: "Trinity",
    type: "P.O.Box",
    placeId: "ChIJc2pG21JQ0lQR7L51_KWY4fU",
  },
  {
    zipCode: "95595",
    city: "Zenia",
    county: "Trinity",
    type: "Standard",
    placeId: "ChIJHWnkNjuc1FQRR9yhNG2uYbE",
  },
  {
    zipCode: "93201",
    city: "Alpaugh",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJ73LO1-Ag64ARLLSOUB_6TAI",
  },
  {
    zipCode: "93603",
    city: "Badger",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJ75e9F21rlYARgwKq_mcwXhM",
  },
  {
    zipCode: "93207",
    city: "California Hot Springs",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJ2xUrel-d6oAR75bzKqpy1cA",
  },
  {
    zipCode: "93208",
    city: "Camp Nelson",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJOVk40kYcwIARY1ZCb_AMjhA",
  },
  {
    zipCode: "93615",
    city: "Cutler",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJC2fWooUYlYARKPuTFeskeeU",
  },
  {
    zipCode: "93618",
    city: "Dinuba",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJ6RD-YfcXlYARmUy1HjYL25s",
  },
  {
    zipCode: "93218",
    city: "Ducor",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJVaqvryuV6oARdiRSIwutpsU",
  },
  {
    zipCode: "93219",
    city: "Earlimart",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJPbswZWzd6oAR47ShFzS_v8I",
  },
  {
    zipCode: "93221",
    city: "Exeter",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJN8Nu_74zlYARIqSc-5jbc-E",
  },
  {
    zipCode: "93223",
    city: "Farmersville",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJ1XmUkZMxlYARxwsJVf-iM5g",
  },
  {
    zipCode: "93227",
    city: "Goshen",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJwwOtjYLYlIARx5rAr4hWYJQ",
  },
  {
    zipCode: "93235",
    city: "Ivanhoe",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJXz7ynMQ6lYAR4JQeVChHiCI",
  },
  {
    zipCode: "93237",
    city: "Kaweah",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJuytqYngslYARxaxIOUQ1UfM",
  },
  {
    zipCode: "93633",
    city: "Kings Canyon National Pk",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJt8TyvUIrlYART-SdytxFQdE",
  },
  {
    zipCode: "93244",
    city: "Lemon Cove",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJuRSoAyRIlYARieRsnxuga6A",
  },
  {
    zipCode: "93247",
    city: "Lindsay",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJl98bxFc0lYARfL9OSLaui5Y",
  },
  {
    zipCode: "93647",
    city: "Orosi",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJlfr9wzwXlYARKOqXDS9BXO0",
  },
  {
    zipCode: "93256",
    city: "Pixley",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJLVgxHTTX6oARD0hDNuKzzMs",
  },
  {
    zipCode: "93257",
    city: "Porterville",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJzwlZG36n6oARBhoriicGQZg",
  },
  {
    zipCode: "93258",
    city: "Porterville",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJzwlZG36n6oARBhoriicGQZg",
  },
  {
    zipCode: "93260",
    city: "Posey",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJY-XEO3KC6oARcLP-YQdLf2g",
  },
  {
    zipCode: "93261",
    city: "Richgrove",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJ5-6qBlDs6oAR_DW3NW8h0l8",
  },
  {
    zipCode: "93262",
    city: "Sequoia National Park",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJeWUZLX37v4ARZPQen_nfCkQ",
  },
  {
    zipCode: "93265",
    city: "Springville",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJw0aGW4QdwIARzI4l6pueL3k",
  },
  {
    zipCode: "93267",
    city: "Strathmore",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJxRZ0KIXJ6oARMWtuCk2AzMY",
  },
  {
    zipCode: "93666",
    city: "Sultana",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJCSuQusgelYARu4UJ6UmkIQ0",
  },
  {
    zipCode: "93270",
    city: "Terra Bella",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJ0xy2dIG_6oARdNzudQXJK2c",
  },
  {
    zipCode: "93271",
    city: "Three Rivers",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJObTlVoBPlYAR-0rOmqnypg4",
  },
  {
    zipCode: "93272",
    city: "Tipton",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJs2nBaibR6oAR_ppYY4GOioU",
  },
  {
    zipCode: "93673",
    city: "Traver",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJD2k9bpfglIAROjGF0Fv9I4Q",
  },
  {
    zipCode: "93274",
    city: "Tulare",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJt8TyvUIrlYART-SdytxFQdE",
  },
  {
    zipCode: "93275",
    city: "Tulare",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJt8TyvUIrlYART-SdytxFQdE",
  },
  {
    zipCode: "93277",
    city: "Visalia",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93278",
    city: "Visalia",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93279",
    city: "Visalia",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93290",
    city: "Visalia",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93291",
    city: "Visalia",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93292",
    city: "Visalia",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJKYfr04LYlIARDkgLAe7jklU",
  },
  {
    zipCode: "93282",
    city: "Waukena",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJR9mDWqDUlIAROvAEmFyVTpc",
  },
  {
    zipCode: "93286",
    city: "Woodlake",
    county: "Tulare",
    type: "Standard",
    placeId: "ChIJS02jCnQ3lYARSUVQrEfpbvY",
  },
  {
    zipCode: "93670",
    city: "Yettem",
    county: "Tulare",
    type: "P.O.Box",
    placeId: "ChIJu5edVZUilYAR5w_UVkvDNEQ",
  },
  {
    zipCode: "95305",
    city: "Big Oak Flat",
    county: "Tuolumne",
    type: "P.O.Box",
    placeId: "ChIJt6xbg3DWkIARTgq50H77elk",
  },
  {
    zipCode: "95309",
    city: "Chinese Camp",
    county: "Tuolumne",
    type: "P.O.Box",
    placeId: "ChIJ--okymLbkIARdPSgs619FgE",
  },
  {
    zipCode: "95310",
    city: "Columbia",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJExPBKB3HkIARQUd4587z9oE",
  },
  {
    zipCode: "95321",
    city: "Groveland",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJYTl0AcrVkIARukpIlQ8518s",
  },
  {
    zipCode: "95327",
    city: "Jamestown",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJMfq3QcfEkIARnc0J7rEzeA8",
  },
  {
    zipCode: "95335",
    city: "Long Barn",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJ8e0Y8Nw0l4ARZEFbAH5zcXI",
  },
  {
    zipCode: "95346",
    city: "Mi Wuk Village",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJ29srW0PLkIARYEeTqWMGBQc",
  },
  {
    zipCode: "95347",
    city: "Moccasin",
    county: "Tuolumne",
    type: "P.O.Box",
    placeId: "ChIJ0cPw57LXkIARX7PLWCOQVsM",
  },
  {
    zipCode: "95364",
    city: "Pinecrest",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJ-9UpiRZIl4ARyXfqeAQqiBg",
  },
  {
    zipCode: "95370",
    city: "Sonora",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJ-QviC9DFkIARd0cldvYH2BY",
  },
  {
    zipCode: "95372",
    city: "Soulsbyville",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJpbYcK4XOkIARdw07PKHNlGk",
  },
  {
    zipCode: "95373",
    city: "Standard",
    county: "Tuolumne",
    type: "P.O.Box",
    placeId: "ChIJLxoqTaTPkIARhTe5xb9HtJE",
  },
  {
    zipCode: "95375",
    city: "Strawberry",
    county: "Tuolumne",
    type: "P.O.Box",
    placeId: "ChIJVUNfUk9Il4AR6FG7-D66jVU",
  },
  {
    zipCode: "95379",
    city: "Tuolumne",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJv25amfzNkIARDtM0paHU2aU",
  },
  {
    zipCode: "95383",
    city: "Twain Harte",
    county: "Tuolumne",
    type: "Standard",
    placeId: "ChIJuYKrBXfKkIARaRPV5wX9FDQ",
  },
  {
    zipCode: "93064",
    city: "Brandeis",
    county: "Ventura",
    type: "Unique",
    placeId: "ChIJCcmhmEIo6IARTi_XGLlvRdY",
  },
  {
    zipCode: "93010",
    city: "Camarillo",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJu5REjxA26IAR52xlig9BWW4",
  },
  {
    zipCode: "93011",
    city: "Camarillo",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJu5REjxA26IAR52xlig9BWW4",
  },
  {
    zipCode: "93012",
    city: "Camarillo",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJu5REjxA26IAR52xlig9BWW4",
  },
  {
    zipCode: "93015",
    city: "Fillmore",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJ4Y_AB3vJ6YARa3-hHmvlV5o",
  },
  {
    zipCode: "93016",
    city: "Fillmore",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJ4Y_AB3vJ6YARa3-hHmvlV5o",
  },
  {
    zipCode: "93020",
    city: "Moorpark",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJ7X5H3AQy6IARI7lFDpnB_7A",
  },
  {
    zipCode: "93021",
    city: "Moorpark",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJ7X5H3AQy6IARI7lFDpnB_7A",
  },
  {
    zipCode: "91319",
    city: "Newbury Park",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJRTSG_5U56IARJr5LIHuBHBE",
  },
  {
    zipCode: "91320",
    city: "Newbury Park",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJRTSG_5U56IARJr5LIHuBHBE",
  },
  {
    zipCode: "91377",
    city: "Oak Park",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJs0GNNH8m6IARzR0pbCWcYIU",
  },
  {
    zipCode: "93022",
    city: "Oak View",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJu2_-qEKv6YARU37Sfkq3i_U",
  },
  {
    zipCode: "93023",
    city: "Ojai",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJe5jVXh646YARXyW-mTcvQWQ",
  },
  {
    zipCode: "93024",
    city: "Ojai",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJe5jVXh646YARXyW-mTcvQWQ",
  },
  {
    zipCode: "93030",
    city: "Oxnard",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93031",
    city: "Oxnard",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93032",
    city: "Oxnard",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93033",
    city: "Oxnard",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93034",
    city: "Oxnard",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93035",
    city: "Oxnard",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93036",
    city: "Oxnard",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJn2clE-ZN6IARRbZe-sBJgFk",
  },
  {
    zipCode: "93040",
    city: "Piru",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJR_izKxDT6YARmup1By4r6rE",
  },
  {
    zipCode: "93042",
    city: "Point Mugu Nawc",
    county: "Ventura",
    type: "Unique",
    placeId: "ChIJcZuOkhpG6IARYxrW0S4oK20",
  },
  {
    zipCode: "93041",
    city: "Port Hueneme",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJKcrafwBL6IARVHqFVcI6M0g",
  },
  {
    zipCode: "93044",
    city: "Port Hueneme",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJKcrafwBL6IARVHqFVcI6M0g",
  },
  {
    zipCode: "93043",
    city: "Port Hueneme Cbc Base",
    county: "Ventura",
    type: "Unique",
    placeId: "ChIJ9VZ7FkxO6IARVKSVXAMZiQM",
  },
  {
    zipCode: "93060",
    city: "Santa Paula",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJm0pej9W16YARIq9ZUPI4O-E",
  },
  {
    zipCode: "93061",
    city: "Santa Paula",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJm0pej9W16YARIq9ZUPI4O-E",
  },
  {
    zipCode: "93062",
    city: "Simi Valley",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJATNWW2wu6IARTUPTlI5Z4PQ",
  },
  {
    zipCode: "93063",
    city: "Simi Valley",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJATNWW2wu6IARTUPTlI5Z4PQ",
  },
  {
    zipCode: "93065",
    city: "Simi Valley",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJATNWW2wu6IARTUPTlI5Z4PQ",
  },
  {
    zipCode: "93094",
    city: "Simi Valley",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJATNWW2wu6IARTUPTlI5Z4PQ",
  },
  {
    zipCode: "93099",
    city: "Simi Valley",
    county: "Ventura",
    type: "Unique",
    placeId: "ChIJATNWW2wu6IARTUPTlI5Z4PQ",
  },
  {
    zipCode: "93066",
    city: "Somis",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJQzEp5zM06IARZjB4-cZwrJs",
  },
  {
    zipCode: "91358",
    city: "Thousand Oaks",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJkYgocFYl6IARJi7MRwF6Lo0",
  },
  {
    zipCode: "91360",
    city: "Thousand Oaks",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJkYgocFYl6IARJi7MRwF6Lo0",
  },
  {
    zipCode: "91362",
    city: "Thousand Oaks",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJkYgocFYl6IARJi7MRwF6Lo0",
  },
  {
    zipCode: "93001",
    city: "Ventura",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93002",
    city: "Ventura",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93003",
    city: "Ventura",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93004",
    city: "Ventura",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93005",
    city: "Ventura",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93006",
    city: "Ventura",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93007",
    city: "Ventura",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "93009",
    city: "Ventura",
    county: "Ventura",
    type: "Unique",
    placeId: "ChIJTTmXUBWt6YAR6Op6Nnz17yQ",
  },
  {
    zipCode: "91359",
    city: "Westlake Village",
    county: "Ventura",
    type: "P.O.Box",
    placeId: "ChIJCesyTno66IARBNfilAxLV9s",
  },
  {
    zipCode: "91361",
    city: "Westlake Village",
    county: "Ventura",
    type: "Standard",
    placeId: "ChIJCesyTno66IARBNfilAxLV9s",
  },
  {
    zipCode: "95606",
    city: "Brooks",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJv1xQcRTthIARtXRmNWNt4BU",
  },
  {
    zipCode: "95607",
    city: "Capay",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJKVIv0d2ShIAR-cqSjGmJo7w",
  },
  {
    zipCode: "95612",
    city: "Clarksburg",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJi2-5R__LmoARDEXl_PTPvpg",
  },
  {
    zipCode: "95616",
    city: "Davis",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJP1SVlJkphYAR_4_ibhs_AcM",
  },
  {
    zipCode: "95617",
    city: "Davis",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJP1SVlJkphYAR_4_ibhs_AcM",
  },
  {
    zipCode: "95618",
    city: "Davis",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJP1SVlJkphYAR_4_ibhs_AcM",
  },
  {
    zipCode: "95937",
    city: "Dunnigan",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJlRTIWHeVhIARrdwui6i1ljs",
  },
  {
    zipCode: "95627",
    city: "Esparto",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJ3xU3ZcrphIAR4D4HoeuOIvE",
  },
  {
    zipCode: "95637",
    city: "Guinda",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJ13aG-QmOhIARwY66aVaLf7c",
  },
  {
    zipCode: "95645",
    city: "Knights Landing",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJBdh4xSDMhIARsCN7U8xIY7U",
  },
  {
    zipCode: "95653",
    city: "Madison",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "95679",
    city: "Rumsey",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJ2bnABIqFhIARKTgpaKzsnEM",
  },
  {
    zipCode: "95605",
    city: "West Sacramento",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJ8TmG1MrTmoARQEDZVqVAgBE",
  },
  {
    zipCode: "95691",
    city: "West Sacramento",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJ8TmG1MrTmoARQEDZVqVAgBE",
  },
  {
    zipCode: "95798",
    city: "West Sacramento",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJ8TmG1MrTmoARQEDZVqVAgBE",
  },
  {
    zipCode: "95799",
    city: "West Sacramento",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJ8TmG1MrTmoARQEDZVqVAgBE",
  },
  {
    zipCode: "95694",
    city: "Winters",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJNfHrsovfhIARhWO3qELgaUA",
  },
  {
    zipCode: "95695",
    city: "Woodland",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJkcx2gl3OhIARAsbaRo3WG9w",
  },
  {
    zipCode: "95776",
    city: "Woodland",
    county: "Yolo",
    type: "Standard",
    placeId: "ChIJkcx2gl3OhIARAsbaRo3WG9w",
  },
  {
    zipCode: "95697",
    city: "Yolo",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "",
  },
  {
    zipCode: "95698",
    city: "Zamora",
    county: "Yolo",
    type: "P.O.Box",
    placeId: "ChIJuymoqsPGhIARbUMVjcH6Ew8",
  },
  {
    zipCode: "95903",
    city: "Beale AFB",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJOan9tX1Em4ARDsjLjLqqX-o",
  },
  {
    zipCode: "95918",
    city: "Browns Valley",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ1wwne6VZm4AR8MqwN5ZIVl4",
  },
  {
    zipCode: "95919",
    city: "Brownsville",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJG_M8OK-inIARGa9m22ntyBI",
  },
  {
    zipCode: "95922",
    city: "Camptonville",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJQ84BkhKDnIARryWR9Q0haoU",
  },
  {
    zipCode: "95925",
    city: "Challenge",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJmW6lO6-inIARhv3N5XGepnI",
  },
  {
    zipCode: "95935",
    city: "Dobbins",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ97n14keenIAR3hA6Cmh97X4",
  },
  {
    zipCode: "95901",
    city: "Marysville",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ9a12L0BTm4ARhTmraYXebSI",
  },
  {
    zipCode: "95961",
    city: "Olivehurst",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJF0zt-t1Nm4AREODPx2QGBQc",
  },
  {
    zipCode: "95962",
    city: "Oregon House",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ6d5wgTCgnIARE_ApEJYTR3A",
  },
  {
    zipCode: "95972",
    city: "Rackerby",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ9_caamijnIAReHYGYVnUoNM",
  },
  {
    zipCode: "95981",
    city: "Strawberry Valley",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJ0_dZl0uQnIARcMx1cX2IA5k",
  },
  {
    zipCode: "95692",
    city: "Wheatland",
    county: "Yuba",
    type: "Standard",
    placeId: "ChIJsfXXccdHm4ARg8zAXCyuuaE",
  },
];
