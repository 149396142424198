import React from "react";

export default function HeartIcon({ className, width, height }) {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M29.4885 15.5961C28.3443 17.9598 26.31 20.1154 24.4607 21.8549C23.1315 23.1032 20.4673 25.6114 17.5777 27.4144C16.6126 28.0155 15.3875 28.0155 14.4223 27.4144C11.5386 25.6114 8.86859 23.1032 7.53939 21.8549C5.69007 20.1154 3.65582 17.954 2.51155 15.5961C0.662229 11.7703 2.48266 6.7425 6.27377 4.94519C9.87994 3.24035 13.0353 4.40773 16 6.75406C18.9647 4.40773 22.1201 3.24035 25.7205 4.94519C29.5174 6.7425 31.3379 11.7703 29.4885 15.5961Z"
        fill="currentColor"
      />
    </svg>
  );
}
