import React from "react";

export default function EnvelopeIcon({ className, size, style }) {
  return (
    <svg
      width={size || 25}
      height={size || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block`}
      style={style}
    >
      <g id="mail">
        <path
          d="M4.5 4.00012H20.5C21.6 4.00012 22.5 4.90012 22.5 6.00012V18.0001C22.5 19.1001 21.6 20.0001 20.5 20.0001H4.5C3.4 20.0001 2.5 19.1001 2.5 18.0001V6.00012C2.5 4.90012 3.4 4.00012 4.5 4.00012Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5 6.00012L12.5 13.0001L2.5 6.00012"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
