import React from "react";

export default function HelpIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C7.0372 3 3 7.0372 3 12C3 16.9628 7.0372 21 12 21C16.9628 21 21 16.9628 21 12C21 7.0372 16.9628 3 12 3ZM12 17.25C11.586 17.25 11.25 16.914 11.25 16.5C11.25 16.086 11.586 15.75 12 15.75C12.414 15.75 12.75 16.086 12.75 16.5C12.75 16.914 12.414 17.25 12 17.25ZM13.1872 12.4815C12.9218 12.6037 12.75 12.8715 12.75 13.1633V13.5C12.75 13.914 12.4147 14.25 12 14.25C11.5853 14.25 11.25 13.914 11.25 13.5V13.1633C11.25 12.288 11.7645 11.4856 12.5588 11.1188C13.323 10.7671 13.875 9.83322 13.875 9.37495C13.875 8.34155 13.0342 7.5 12 7.5C10.9658 7.5 10.125 8.34155 10.125 9.37495C10.125 9.789 9.78969 10.125 9.37495 10.125C8.96022 10.125 8.625 9.789 8.625 9.37495C8.625 7.51428 10.1392 5.99995 12 5.99995C13.8608 5.99995 15.375 7.51428 15.375 9.37495C15.375 10.3883 14.496 11.8778 13.1872 12.4815Z"
        fill="currentColor"
      />
    </svg>
  );
}
