import React from "react";

export default function HowItWorks4({ className }) {
  return (
    <svg
      width="173"
      height="173"
      viewBox="0 0 173 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4395 131.04V101.914L91.0694 13.76H130.783V98.624H146.527V131.04H130.783V156H93.9515V131.04H29.4395ZM95.9515 129.04V154H128.783V129.04H144.527V100.624H128.783V15.76H92.1115L31.4395 102.544V129.04H95.9515ZM65.9995 100.624H98.2555V51.856L65.9995 100.624ZM69.7202 98.624H96.2555V58.5052L69.7202 98.624Z"
        fill="url(#paint0_linear_13741_16652)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13741_16652"
          x1="87.0002"
          y1="1.48888e-05"
          x2="87.0002"
          y2="173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCEC4" />
          <stop offset="0.520833" stopColor="#FFCEC4" stopOpacity="0" />
          <stop offset="1" stopColor="#FFCEC4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
