import React from "react";
import isMobile from "../../utils";

export default function UnsupportedBrowserWarning({ children }) {
  if (false && !isMobile) {
    return (
      <div>
        If you are using a supported browser, please ensure that this app is
        served over a{" "}
      </div>
    );
  }

  return children;
}
