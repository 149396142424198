import React from "react";

export default function ReHolistincHeroIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 32C64 49.6729 49.6735 64 32 64C14.3273 64 0 49.6729 0 32C0 14.3265 14.3273 0 32 0C49.6735 0 64 14.3265 64 32Z"
        fill="#FFBF44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.045 17.5564C40.5032 17.5564 38.8569 18.2892 37.9292 19.52C36.7798 21.0444 37.0092 22.9678 38.5714 24.9362C40.0113 26.7509 41.9966 27.7227 44.2546 27.75C44.9707 26.6858 45.5238 25.499 45.7633 24.0351C46.2832 20.8752 45.5492 18.6896 43.6965 17.8815C43.1919 17.6607 42.6261 17.5564 42.045 17.5564ZM37.7814 44.0806C37.3558 44.0806 36.9684 43.7891 36.8614 43.3528C35.6967 38.6479 38.0719 35.3088 39.2111 33.704C40.019 32.571 40.9262 31.5995 41.8029 30.6608C42.147 30.2922 42.4885 29.9273 42.8172 29.5585C40.5695 29.2028 38.5969 28.0384 37.0882 26.1387C34.0606 22.3257 35.61 19.4255 36.4179 18.3551C38.2121 15.9725 41.8182 14.9683 44.4508 16.118C45.7251 16.6738 48.5845 18.5884 47.639 24.3498C47.4504 25.5052 47.1038 26.5106 46.6578 27.4126C48.6763 26.7409 49.8282 24.9921 50.4398 21.7715C51.0693 18.4398 50.3226 15.2472 48.3348 12.7812C46.3699 10.3414 43.4646 8.97154 40.1566 8.92286C40.0903 8.92157 40.0266 8.92132 39.9629 8.92132C33.0947 8.92132 31.0268 14.3641 29.8196 20.2568C28.9284 24.6073 25.6331 30.8686 20.151 30.2796C19.6288 30.2235 19.2506 29.7503 19.3062 29.2226C19.362 28.6954 19.8276 28.3173 20.3524 28.3686C24.5691 28.8268 27.2066 23.5323 27.9576 19.8669C29.263 13.4959 31.7131 7 39.9502 7C40.0292 7 40.1056 7 40.1846 7.00129C44.0635 7.05846 47.481 8.67948 49.8104 11.5671C52.1244 14.4388 53.0342 18.2899 52.3079 22.1323C51.7676 24.9762 50.5239 28.8391 45.9672 29.5448C45.7251 29.5827 45.483 29.612 45.246 29.6334C44.5986 30.4676 43.8876 31.2284 43.1842 31.9799C42.3509 32.8731 41.4869 33.7967 40.7555 34.8253C39.1984 37.0171 37.8706 39.5109 38.7065 42.8864C38.8339 43.4015 38.523 43.9233 38.0133 44.0518C37.9368 44.0714 37.8578 44.0806 37.7814 44.0806Z"
        fill={`url(#${id}_paint0_linear_5436_18973)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21292 35.3543C7.66273 35.7561 6.08455 34.8124 5.68841 33.2459C5.292 31.6796 6.22697 30.0843 7.77742 29.6823L16.6161 27.3913C18.1663 26.9895 19.7447 27.9335 20.1412 29.4997C20.5376 31.0662 19.6023 32.6613 18.0524 33.0635L9.21292 35.3543Z"
        fill={`url(#${id}_paint1_linear_5436_18973)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4532 53.0642C42.723 54.6583 41.6641 56.1702 40.0858 56.4409C38.5101 56.7118 37.012 55.639 36.7422 54.0446L35.2042 44.9555C34.9343 43.3611 35.9933 41.8492 37.5715 41.5782C39.1498 41.3072 40.6454 42.3804 40.9152 43.9743L42.4532 53.0642Z"
        fill={`url(#${id}_paint2_linear_5436_18973)`}
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18973`}
          x1="19.3008"
          y1="7"
          x2="19.3008"
          y2="44.0806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#425162" />
          <stop offset="1" stopColor="#1F2833" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18973`}
          x1="5.79199"
          y1="26.8938"
          x2="5.4969"
          y2="35.344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5A623" />
          <stop offset="1" stopColor="#E64F3A" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_5436_18973`}
          x1="35.0132"
          y1="41.6921"
          x2="35.799"
          y2="56.6857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5A623" />
          <stop offset="1" stopColor="#E64F3A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
