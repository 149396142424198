/**
 * Cancel Membership Modal
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/10/09
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";

// Components
import Button from "../Button/Button";

// Utils
import { modalStyles } from "../../constants";

export const CancelMembershipModal = ({
  isFetching,
  open,
  isTrial,
  region,
  onClose,
  onMembershipCancel,
}) => {
  const defaultCustomStyle = Object.assign(
    {
      content: {
        ...modalStyles.default.content,
        width: "358px",
      },
    },
    { overlay: { ...modalStyles.default.overlay, zIndex: 1060 } }
  );

  const closeModal = useCallback(() => {
    onClose && onClose();
  }, []);

  return (
    <Modal
      appElement={/*__CLIENT__ && */ document.getElementById("#app")}
      isOpen={open}
      onRequestClose={closeModal}
      style={defaultCustomStyle}
      contentLabel="Cancel RSVP"
    >
      <div className="modal-cancel-rsvp tbk-rounded-2xl tbk-bg-basic-white">
        <h3
          className={`tbk-text-h3-subtitle tbk-mt-0 tbk-mb-2 tbk-text-center tbk-text-blue-dark`}
        >
          Thinking of hitting pause? Let's chat. Text us at{" "}
          <a
            href={`${region === "US" ? "sms:+13233872101" : "sms:07459783573"}`}
            className="tbk-text-blue-dark"
          >
            {region === "US" ? "(323) 387 2101" : "07459783573"}
          </a>
        </h3>
        <p className="tbk-text-main tbk-mb-4 tbk-text-center tbk-text-blue-grey">
          {isTrial ? (
            <span>
              If you decide to cancel, your membership will close and you’ll no
              longer have access to any pre-booked events. You can pause your
              membership for 30 days by speaking to the team on{" "}
              <a
                href={`${
                  region === "US" ? "tel:+13233872101" : "tel:07459783573"
                }`}
                className="tbk-text-blue-grey"
              >
                {region === "US" ? "(323) 387 2101" : "07459783573"}
              </a>
            </span>
          ) : (
            "Upon cancellation, your membership will remain active for the full 30-day period. You can renew it at any time thereafter."
          )}
        </p>
        <Button
          disabled={isFetching}
          showLoader={isFetching}
          className="tbk-mb-2 tbk-w-full"
          onClick={closeModal}
        >
          Keep Wellbeing Your Priority
        </Button>
        <Link to={`/contact`}>
          <Button
            variant="secondary"
            disabled={isFetching}
            showLoader={isFetching}
            className="tbk-mb-2 tbk-w-full"
          >
            Contact Support
          </Button>
        </Link>
        <button
          className="tbk-mt-1.5 tbk-mb-1.5 tbk-w-full tbk-text-primary tbk-underline hover:tbk-text-grey-inactive"
          disabled={isFetching}
          onClick={onMembershipCancel}
        >
          Cancel Subscription
        </button>
      </div>
    </Modal>
  );
};

CancelMembershipModal.displayName = "CancelMembershipModal";
CancelMembershipModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool,
  region: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onMembershipCancel: PropTypes.func.isRequired,
};
CancelMembershipModal.defaultProps = {
  // open: false,
  // isTrial: false,
  region: "US",
};

export default CancelMembershipModal;
