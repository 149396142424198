import React from "react";

export default function MiddayIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.9288" cy="11.2143" r="5.78571" fill="#FFA200" />
      <circle cx="7.5" cy="15.0714" r="4.5" fill="#C0DFFF" />
      <circle cx="13.2859" cy="15.7143" r="3.85714" fill="#C0DFFF" />
      <circle cx="17.7856" cy="16.3571" r="3.21429" fill="#C0DFFF" />
      <rect
        x="6.85693"
        y="18.2858"
        width="11.5714"
        height="1.28571"
        rx="0.5"
        fill="#C0DFFF"
      />
    </svg>
  );
}
