import React from "react";

export default function SpeechBalloonIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9688 4C7.57469 4 4 7.154 4 11.0312C4 12.6691 4.64819 14.2562 5.82784 15.5141C6.06131 16.4768 5.77794 17.4967 5.07481 18.1998C4.78081 18.4938 4.98872 19 5.40625 19C6.742 19 8.02969 18.4768 8.98641 17.5539C9.93306 17.8918 10.9347 18.0625 11.9688 18.0625C16.3628 18.0625 20 14.9085 20 11.0312C20 7.154 16.3628 4 11.9688 4ZM8.28125 12.4375C7.50581 12.4375 6.875 11.8067 6.875 11.0312C6.875 10.2558 7.50581 9.625 8.28125 9.625C9.05669 9.625 9.6875 10.2558 9.6875 11.0312C9.6875 11.8067 9.05669 12.4375 8.28125 12.4375ZM12.0312 12.4375C11.2558 12.4375 10.625 11.8067 10.625 11.0312C10.625 10.2558 11.2558 9.625 12.0312 9.625C12.8067 9.625 13.4375 10.2558 13.4375 11.0312C13.4375 11.8067 12.8067 12.4375 12.0312 12.4375ZM15.7812 12.4375C15.0058 12.4375 14.375 11.8067 14.375 11.0312C14.375 10.2558 15.0058 9.625 15.7812 9.625C16.5567 9.625 17.1875 10.2558 17.1875 11.0312C17.1875 11.8067 16.5567 12.4375 15.7812 12.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
