// __d((function(g, r, i, a, m, e, d) {
//     "use strict";
//     Object.defineProperty(e, "__esModule", {
//         value: !0
//     }),
//     e.getScrollTarget = void 0;
export const getScrollTarget = ({
  direction: direction_t,
  totalCount: totalCount_n,
  visibleItems: visibleItems_o,
}) => {
  if (totalCount_n == null || visibleItems_o == null) {
    return 0;
  }

  if (visibleItems_o === 0) {
    return direction_t === "forward" ? 1 : totalCount_n - 1;
  }

  const visibleItemsLength_l = visibleItems_o.length;
  // u = Math.round(.66 * visibleItemsLength_l);
  let maybeItemOffset_u = Math.round(0.06 * visibleItemsLength_l);

  if (direction_t === "forward") {
    const maxVisibleItem_t = Math.max(...visibleItems_o);

    return Math.min(maxVisibleItem_t + maybeItemOffset_u, totalCount_n - 1);
  }

  maybeItemOffset_u = visibleItemsLength_l;

  const minVisibleItem_c = Math.min(...visibleItems_o),
    scrollToIndex_s =
      minVisibleItem_c - maybeItemOffset_u <= 1
        ? 0
        : minVisibleItem_c - maybeItemOffset_u;

  return Math.max(scrollToIndex_s, 0);
};
// }
// ), "256d02", []);
