import React from "react";

export default function WoLifestyleCoachingIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        d="M11.6774 8.35972C11.672 8.32239 11.5313 7.43463 11.9801 6.34959C12.4819 5.13391 13.5945 4.87531 13.6396 4.86465C13.6396 4.86465 14.0857 4.82466 14.1415 5.22189C14.1946 5.59512 13.8282 5.72309 13.8282 5.72309H13.8308C13.8016 5.73108 13.1086 5.90704 12.7846 6.68816C12.4368 7.5306 12.5377 8.21309 12.5377 8.22109C12.5377 8.22109 12.5722 8.58099 12.1739 8.63431C11.749 8.6903 11.6774 8.35972 11.6774 8.35972Z"
        fill="currentColor"
      />
      <path
        d="M11.6879 8.86359C9.02194 7.65324 6.48877 9.34879 6.48877 12.2973C6.48877 15.3765 8.84935 20.0553 12.041 18.6423C15.4027 20.1033 17.5933 15.3765 17.5933 12.2973C17.5933 9.32213 15.315 7.5786 12.3544 8.89025C12.3544 8.89025 12.2296 8.98089 12.0171 8.98089C11.8313 8.98089 11.6879 8.86359 11.6879 8.86359Z"
        fill="currentColor"
      />
      <path
        d="M10.91 7.09341C11.064 7.08008 11.1994 6.94412 11.2127 6.78949C11.2127 6.78949 11.3109 5.58448 10.4745 4.74471C9.63541 3.90493 8.43786 4.00357 8.43786 4.00357C8.28386 4.0169 8.14844 4.15286 8.13516 4.30749C8.13516 4.30749 8.03426 5.5125 8.87068 6.35228C9.70976 7.19205 10.91 7.09341 10.91 7.09341Z"
        fill="currentColor"
      />
    </svg>
  );
}
