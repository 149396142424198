import React from "react";

export default function ReLifestyleSpecialistIcon({
  id,
  className,
  size,
  style,
}) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 32C64 49.6729 49.6735 64 32 64C14.3273 64 0 49.6729 0 32C0 14.3265 14.3273 0 32 0C49.6735 0 64 14.3265 64 32Z"
        fill="#FFCD77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5903 33.4487C13.536 33.2516 11.1528 31.6102 11.1528 29.6142C11.1528 27.4854 13.8644 25.7599 17.2095 25.7599C17.834 25.7599 18.4367 25.8201 19.0037 25.9319L16.5903 33.4487Z"
        fill="#885493"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2523 32.8643C33.3397 32.8643 32.5996 32.122 32.5996 31.2064V22.9387C32.5996 18.0018 36.6037 13.9856 41.5259 13.9856C42.4387 13.9856 43.1788 14.7279 43.1788 15.6435C43.1788 16.5592 42.4387 17.3015 41.5259 17.3015C38.4268 17.3015 35.9055 19.8301 35.9055 22.9387V31.2064C35.9055 32.122 35.1654 32.8643 34.2523 32.8643Z"
        fill={`url(#${id}_paint0_linear_5436_18990)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.1671 21.7067C41.0481 21.378 38.9391 21.8093 37.0108 22.8327C36.2495 23.4073 35.2939 23.7528 34.2525 23.7528C33.1191 23.7528 32.0871 23.3442 31.2978 22.6751C29.4537 21.7655 27.4555 21.3951 25.4487 21.7067C18.99 22.709 14.895 30.3666 16.303 38.8101C17.7109 47.254 24.0883 53.2866 30.547 52.2843C31.9177 52.0715 33.1802 51.5566 34.308 50.8006C35.4356 51.5566 36.6981 52.0715 38.0688 52.2843C44.5275 53.2866 50.9049 47.254 52.3127 38.8101C53.7205 30.3666 49.626 22.709 43.1671 21.7067Z"
        fill={`url(#${id}_paint1_linear_5436_18990)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9377 18.8298C32.3519 21.1808 28.3062 21.2141 24.9007 18.9037C21.4958 16.5933 19.9624 9.34767 19.9624 9.34767C19.9624 9.34767 27.2379 8.07885 30.643 10.389C34.0483 12.6995 35.5233 16.4785 33.9377 18.8298Z"
        fill={`url(#${id}_paint2_linear_5436_18990)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 28.6957C11 28.6957 11 34.9484 11 37.0774C11 39.073 13.1978 40.7143 16.0146 40.9115C16.2024 40.9247 34.951 41.0213 34.951 41.0213V32.6398C34.951 32.6398 16.2024 32.5434 16.0146 32.5302C13.1978 32.3328 11 30.6915 11 28.6957Z"
        fill={`url(#${id}_paint3_linear_5436_18990)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3521 36.962V37.0742C31.3521 38.7735 32.2936 40.2518 33.6817 41.0145C34.4773 41.0188 34.951 41.0212 34.951 41.0212V32.6398C34.951 32.6398 34.8391 32.6393 34.6336 32.6382C32.7422 33.1634 31.3521 34.8976 31.3521 36.962Z"
        fill="#885493"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7485 35.5025C25.2429 35.5025 25.6439 35.1003 25.6439 34.6044V32.5903C25.0446 32.5872 24.4445 32.5838 23.853 32.5804V34.6044C23.853 35.1003 24.254 35.5025 24.7485 35.5025Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8236 35.5024C20.3181 35.5024 20.7191 35.1003 20.7191 34.6043V32.5627C20.0766 32.5591 19.474 32.5555 18.9282 32.5521V34.6043C18.9282 35.1003 19.3291 35.5024 19.8236 35.5024Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7778 32.6074V34.6044C28.7778 35.1004 29.1788 35.5025 29.6733 35.5025C30.1678 35.5025 30.5687 35.1004 30.5687 34.6044V32.6169C30.0021 32.6139 29.4006 32.6108 28.7778 32.6074Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0034 32.1136V34.6044C14.0034 35.1003 14.4043 35.5025 14.8988 35.5025C15.3933 35.5025 15.7943 35.1003 15.7943 34.6044V32.5111C15.1561 32.4486 14.553 32.3119 14.0034 32.1136Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18990`}
          x1="40.106"
          y1="4.53781"
          x2="28.6925"
          y2="8.96603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58E5C5" />
          <stop offset="1" stopColor="#40AF8D" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18990`}
          x1="43.4488"
          y1="13.8994"
          x2="28.2756"
          y2="31.9151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7857" />
          <stop offset="1" stopColor="#E64F3A" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_5436_18990`}
          x1="28.3389"
          y1="4.8061"
          x2="24.2042"
          y2="13.3191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E5C6" />
          <stop offset="1" stopColor="#43C694" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint3_linear_5436_18990`}
          x1="-1.09526"
          y1="34.9817"
          x2="9.19397"
          y2="54.7797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3023AE" />
          <stop offset="1" stopColor="#C86DD7" />
        </linearGradient>
      </defs>
    </svg>
  );
}
