import React from "react";

export default function Facebook({ className, size }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={
        className
          ? className + " tbk-min-w-0 tbk-shrink-0"
          : "tbk-min-w-0 tbk-shrink-0"
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0771 18.7429C20.9104 18.7429 21.7157 18.7423 22.5209 18.7431C23.153 18.7437 23.2671 18.6366 23.3459 18.0068C23.4333 17.3091 23.5272 16.6121 23.6281 15.9163C23.7172 15.3015 23.5197 15.0594 22.9064 15.059C21.9748 15.0584 21.0434 15.0588 20.115 15.0588C20.0938 15.0168 20.0682 14.9899 20.0684 14.9633C20.0774 14.0426 20.0581 13.1201 20.1093 12.2016C20.1533 11.4108 20.6029 10.975 21.3941 10.9095C21.9809 10.8608 22.5728 10.8702 23.1625 10.8612C23.6888 10.8532 23.8723 10.6764 23.8739 10.1491C23.8759 9.50719 23.8767 8.86524 23.874 8.2233C23.8718 7.70221 23.6841 7.49337 23.1727 7.48697C22.1703 7.47454 21.1613 7.40693 20.1662 7.49175C18.0634 7.6709 16.497 9.2431 16.3184 11.3442C16.2241 12.4549 16.2612 13.5769 16.2396 14.6938C16.2374 14.8049 16.2392 14.916 16.2392 15.0604C15.7525 15.0604 15.2997 15.0604 14.847 15.0605C14.4224 15.0606 13.9976 15.0543 13.5731 15.0634C13.2446 15.0705 13.0225 15.2816 13.019 15.6088C13.0097 16.4681 13.009 17.3277 13.019 18.187C13.0232 18.5449 13.2468 18.7397 13.6247 18.742C14.3808 18.7466 15.1368 18.7444 15.8929 18.745C15.9942 18.7451 16.0955 18.7451 16.2392 18.7451C16.2392 18.8836 16.2392 18.9934 16.2392 19.1031C16.2398 21.8781 16.2404 24.6531 16.2408 27.4282C16.2408 27.511 16.2421 27.5939 16.2387 27.6766C16.2213 28.0953 16.0189 28.3002 15.5953 28.3017C14.3215 28.3064 13.0475 28.3048 11.7736 28.3042C10.22 28.3035 8.66635 28.3167 7.11308 28.2958C5.22469 28.2705 3.69859 26.7153 3.69678 24.8205C3.69125 18.9495 3.69057 13.0784 3.69685 7.20741C3.69896 5.24485 5.24464 3.69899 7.20737 3.69688C13.0798 3.69054 18.9523 3.69104 24.8248 3.697C26.7302 3.69899 28.297 5.24541 28.3002 7.14695C28.3103 13.049 28.3103 18.9512 28.3 24.8533C28.2967 26.7359 26.7345 28.2788 24.8466 28.2956C23.4899 28.3078 22.133 28.2991 20.7762 28.2984C20.2557 28.2982 20.0635 28.1151 20.0621 27.5875C20.0571 25.8065 20.0592 24.0255 20.0591 22.2446C20.0591 21.1988 20.0597 20.1529 20.0614 19.1072C20.0615 18.9964 20.0707 18.8857 20.0771 18.7429Z"
        fill="currentColor"
      />
    </svg>
  );
}
