import React from "react";

export default function Visa() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3" fill="#0054A3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15039 14.7708L10.1026 9.30206H11.6254L10.6726 14.7708H9.15039Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.35 9.31468C16.038 9.20301 15.5492 9.08331 14.9387 9.08331C13.3828 9.08331 12.2867 9.83061 12.2774 10.9016C12.2686 11.6934 13.0599 12.1351 13.6572 12.3987C14.27 12.6687 14.4762 12.8409 14.4732 13.0821C14.4693 13.4514 13.9837 13.6202 13.5311 13.6202C12.9009 13.6202 12.5661 13.5367 12.049 13.331L11.846 13.2434L11.625 14.4769C11.9929 14.6306 12.673 14.764 13.3791 14.7708C15.0344 14.7708 16.1089 14.0321 16.1211 12.8883C16.1271 12.2616 15.7075 11.7845 14.7991 11.3913C14.2487 11.1364 13.9116 10.9662 13.9151 10.7082C13.9151 10.4791 14.2004 10.2342 14.8169 10.2342C15.3319 10.2266 15.7048 10.3337 15.9954 10.4453L16.1365 10.5089L16.35 9.31468Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2575 9.30206H19.0471C18.6721 9.30206 18.3915 9.39945 18.2268 9.75585L15.9004 14.7708H17.5453C17.5453 14.7708 17.8143 14.0965 17.8752 13.9485C18.0549 13.9485 19.6528 13.9508 19.8812 13.9508C19.9281 14.1423 20.0719 14.7708 20.0719 14.7708H21.5254L20.2575 9.30206ZM18.326 12.8291C18.4554 12.5138 18.95 11.2994 18.95 11.2994C18.9407 11.314 19.0786 10.9826 19.1577 10.7772L19.2636 11.249C19.2636 11.249 19.5636 12.5552 19.6262 12.8291H18.326Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7541 9.30206L6.25537 13.0373L6.09572 12.2782C5.81675 11.4031 4.94749 10.4551 3.97559 9.98052L5.34597 14.7708L6.96563 14.769L9.37559 9.30206H7.7541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95954 9.08331H2.42053L2.40039 9.20044C4.3758 9.66646 5.68282 10.7926 6.22539 12.1458L5.6733 9.55861C5.57791 9.20206 5.30148 9.09572 4.95954 9.08331Z"
        fill="#ED9F2D"
      />
    </svg>
  );
}
