/**
 * Slider Prev Button component for navigating to the previous slide.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/08/06
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";

// Icons
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";

export const SliderPrevButton = ({
  className,
  buttonClassName,
  iconClassName,
  style,
  onClick,
}) => (
  <button
    className={`${className ? `${className} ` : ""}${
      buttonClassName ? `${buttonClassName} ` : ""
    }`}
    style={style}
    onClick={onClick}
  >
    <ArrowLeftIcon
      className={`${iconClassName ? `${iconClassName} ` : ""}tbk-inline`}
    />
  </button>
);

export default SliderPrevButton;
