import React from "react";

export default function SkyGarden(props) {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4968 56.998C12.764 56.998 -0.00341797 44.2307 -0.00341797 28.4981C-0.00341797 12.8066 12.764 -0.00195312 28.4968 -0.00195312C44.1884 -0.00195312 56.997 12.7654 56.997 28.4981C56.997 44.1895 44.2295 56.998 28.4968 56.998ZM28.4968 0.286342C12.9288 0.286342 0.284879 12.9301 0.284879 28.4981C0.284879 44.066 12.9288 56.7098 28.4968 56.7098C44.0648 56.7098 56.7087 44.066 56.7087 28.4981C56.7087 12.9301 44.0648 0.286342 28.4968 0.286342Z"
        fill="white"
      />
      <path
        d="M28.4558 11.9417H28.1675V-0.00195312H28.4558V11.9417Z"
        fill="white"
      />
      <path
        d="M28.4558 56.9569H28.1675V45.0544H28.4558V56.9569Z"
        fill="white"
      />
      <path
        d="M56.9558 28.6631H45.0532V28.3745H56.9558V28.6631Z"
        fill="white"
      />
      <path
        d="M11.8577 28.6631H0.161133V28.3745H11.8577V28.6631Z"
        fill="white"
      />
      <path
        d="M48.6364 20.2198H48.3481V8.31738H48.6364V20.2198Z"
        fill="white"
      />
      <path
        d="M8.60372 20.2198H8.31543V8.56445H8.60372V20.2198Z"
        fill="white"
      />
      <path
        d="M48.5949 48.4729H48.3066V36.7764H48.5949V48.4729Z"
        fill="white"
      />
      <path d="M8.5217 48.4729H8.2334V36.7764H8.5217V48.4729Z" fill="white" />
      <path
        d="M19.9709 8.68826H8.31543V8.39966H19.9709V8.68826Z"
        fill="white"
      />
      <path
        d="M48.7597 8.77072H36.8159V8.48218H48.7597V8.77072Z"
        fill="white"
      />
      <path d="M20.1774 48.5967H8.2749V48.3081H20.1774V48.5967Z" fill="white" />
      <path
        d="M48.7186 48.5967H36.7749V48.3081H48.7186V48.5967Z"
        fill="white"
      />
      <path
        d="M28.4969 56.8745L19.8892 48.5963L20.0951 48.3904L28.6616 56.6686L28.4969 56.8745Z"
        fill="white"
      />
      <path
        d="M28.4559 56.7921L28.25 56.5862L36.8577 48.2668L37.0636 48.4728L28.4559 56.7921Z"
        fill="white"
      />
      <path
        d="M20.0949 8.60519L19.9302 8.39926L28.4967 0.121094L28.7026 0.327018L20.0949 8.60519Z"
        fill="white"
      />
      <path
        d="M36.8571 8.68749L28.2905 0.409294L28.4965 0.203369L37.063 8.48156L36.8571 8.68749Z"
        fill="white"
      />
      <path
        d="M0.28454 28.8275L0.0786133 28.6215L8.35685 20.0139L8.56278 20.2198L0.28454 28.8275Z"
        fill="white"
      />
      <path
        d="M8.27463 36.9822L0.161133 28.5805L0.367059 28.4158L8.48056 36.8175L8.27463 36.9822Z"
        fill="white"
      />
      <path
        d="M56.6264 28.8273L48.3481 20.2197L48.5541 20.0549L56.8323 28.6214L56.6264 28.8273Z"
        fill="white"
      />
      <path
        d="M48.7186 36.8999L48.5127 36.6939L56.5438 28.4158L56.7497 28.6217L48.7186 36.8999Z"
        fill="white"
      />
      <path
        d="M36.7334 48.7198L8.15088 36.8585L20.0122 8.27612L48.5948 20.1374L36.7334 48.7198ZM8.52155 36.735L36.5687 48.3491L48.1829 20.3021L20.1358 8.68797L8.52155 36.735Z"
        fill="white"
      />
      <path
        d="M20.0951 48.6788L8.2749 20.0964L36.8575 8.23511L48.7188 36.8175L20.0951 48.6788ZM8.64557 20.2611L20.2598 48.3081L48.307 36.6939L36.6927 8.64696L8.64557 20.2611Z"
        fill="white"
      />
      <path
        d="M19.8891 8.60573L17.1709 2.55153L17.418 2.42798L20.1362 8.48218L19.8891 8.60573Z"
        fill="white"
      />
      <path
        d="M39.2462 54.6917L36.6104 48.8023L36.8987 48.6787L39.5345 54.5682L39.2462 54.6917Z"
        fill="white"
      />
      <path
        d="M8.39867 20.261L2.17969 17.8723L2.26206 17.584L8.48104 20.0139L8.39867 20.261Z"
        fill="white"
      />
      <path
        d="M54.5254 39.3297L48.4712 36.941L48.5536 36.6938L54.649 39.0826L54.5254 39.3297Z"
        fill="white"
      />
      <path
        d="M2.63235 39.5768L2.50879 39.3297L8.39827 36.6938L8.52183 36.941L2.63235 39.5768Z"
        fill="white"
      />
      <path
        d="M48.5947 20.3433L48.4712 20.055L54.649 17.2957L54.7314 17.5428L48.5947 20.3433Z"
        fill="white"
      />
      <path
        d="M17.8301 54.8153L17.583 54.6918L20.0541 48.3081L20.3424 48.4317L17.8301 54.8153Z"
        fill="white"
      />
      <path
        d="M36.9817 8.44109L36.6934 8.35873L39.0821 2.3457L39.3292 2.46926L36.9817 8.44109Z"
        fill="white"
      />
      <path
        d="M16.5121 16.8426L8.35742 8.77038L8.56335 8.56445L16.718 16.6367L16.5121 16.8426Z"
        fill="white"
      />
      <path
        d="M48.3479 48.6374L39.9873 40.3592L40.1932 40.1533L48.5538 48.4315L48.3479 48.6374Z"
        fill="white"
      />
      <path
        d="M8.76891 48.4728L8.56299 48.2669L16.6353 40.1946L16.8412 40.4005L8.76891 48.4728Z"
        fill="white"
      />
      <path
        d="M40.3998 16.925L40.1938 16.719L48.4721 8.39966L48.678 8.60558L40.3998 16.925Z"
        fill="white"
      />
      <path
        d="M24.7898 23.8852C24.7898 24.297 24.6663 24.6677 24.378 24.9148C24.1309 25.1619 23.7602 25.3267 23.3483 25.3267C23.266 25.3267 23.2248 25.3267 23.1836 25.2855V24.7912C23.2248 24.8324 23.3071 24.8324 23.3483 24.8324C23.6366 24.8324 23.8426 24.7501 24.0485 24.5441C24.2132 24.3794 24.3368 24.1323 24.3368 23.844C24.3368 23.6381 24.2544 23.391 24.0897 23.1438C23.9249 22.9379 23.7602 22.732 23.5954 22.5261C23.4307 22.279 23.3483 21.9907 23.3483 21.7024C23.3483 21.2905 23.4719 20.961 23.7602 20.7139C24.0073 20.4668 24.378 20.3433 24.7898 20.3433V20.7963C24.5015 20.7963 24.2956 20.8787 24.1309 21.0434C23.9661 21.2081 23.8426 21.4141 23.8426 21.7024C23.8426 21.9083 23.9249 22.1554 24.0897 22.3613C24.2544 22.5673 24.4191 22.7732 24.5839 22.9791C24.7074 23.3086 24.7898 23.5969 24.7898 23.8852Z"
        fill="white"
      />
      <path
        d="M29.1969 25.2856H28.6203L27.3435 23.144L27.1788 23.4323V25.2856H26.6846V20.4258H27.1788V22.5262L28.4144 20.4258H28.9498L27.6318 22.6498L29.1969 25.2856Z"
        fill="white"
      />
      <path
        d="M33.7687 20.4258L32.1625 23.144V25.2856H31.6682V23.144L30.062 20.4258H30.5974L31.9153 22.6086L33.2333 20.4258H33.7687Z"
        fill="white"
      />
      <path
        d="M19.8067 31.7927C19.6831 32.3693 19.3948 32.8635 18.9418 33.2754C18.4887 33.646 17.9533 33.852 17.3355 33.852C16.6354 33.852 16.0588 33.6049 15.5646 33.1106C15.0704 32.6164 14.8232 32.0398 14.8232 31.3397C14.8232 30.6395 15.0704 30.0629 15.5646 29.5687C16.0588 29.0745 16.6354 28.8274 17.3355 28.8274C18.0357 28.8274 18.6123 29.0745 19.1065 29.5687L18.777 29.8982C18.3652 29.4864 17.9121 29.3216 17.3355 29.3216C16.8001 29.3216 16.3059 29.5275 15.8941 29.8982C15.4822 30.3101 15.3175 30.7631 15.3175 31.3397C15.3175 31.8751 15.5234 32.3693 15.8941 32.7812C16.3059 33.193 16.7589 33.3577 17.3355 33.3577C17.7062 33.3577 18.0357 33.2754 18.3652 33.0694C18.6947 32.8635 18.9418 32.6164 19.1065 32.2869H17.2944V31.7927H19.8067Z"
        fill="white"
      />
      <path
        d="M24.3374 33.7285H23.8432L23.596 33.0284H21.3309L21.0426 33.7285H20.5483L22.4429 28.7451L24.3374 33.7285ZM23.3901 32.6577L22.4017 30.063L21.4132 32.6577H23.3901Z"
        fill="white"
      />
      <path
        d="M28.2084 33.7283H27.5495L26.2315 32.2457V33.7283H25.7373V28.9097H26.4786C26.9317 28.9097 27.3435 29.0744 27.673 29.4039C28.0025 29.7334 28.1672 30.1452 28.1672 30.5983C28.1672 31.0101 28.0437 31.3396 27.7966 31.6279C27.5495 31.9162 27.22 32.1221 26.8493 32.2045L28.2084 33.7283ZM27.7142 30.5983C27.7142 30.2688 27.5906 29.9805 27.3435 29.7746C27.0964 29.5274 26.8081 29.4451 26.4786 29.4451H26.1903V31.8338H26.4375C26.7669 31.8338 27.0552 31.7103 27.3023 31.4631C27.5906 31.1749 27.7142 30.9277 27.7142 30.5983Z"
        fill="white"
      />
      <path
        d="M33.2331 31.3396C33.2331 31.9986 32.986 32.5751 32.533 33.0282C32.0799 33.4812 31.5033 33.7283 30.8444 33.7283H30.103V28.9097H30.8444C31.5033 28.9097 32.0799 29.1568 32.533 29.6098C32.986 30.104 33.2331 30.6806 33.2331 31.3396ZM32.7389 31.3396C32.7389 30.8042 32.533 30.3511 32.1623 29.9805C31.7916 29.6098 31.3386 29.4039 30.8032 29.4039H30.5149V33.2753H30.8032C31.3386 33.2753 31.7916 33.0694 32.1623 32.6987C32.5741 32.328 32.7389 31.875 32.7389 31.3396Z"
        fill="white"
      />
      <path
        d="M37.0633 33.7283H34.9629V28.9097H37.0633V29.4039H35.4571V31.0925H37.0633V31.5455H35.4571V33.2753H37.0633V33.7283Z"
        fill="white"
      />
      <path
        d="M41.4293 33.8933L39.37 30.475V33.7286H38.917V28.7864L40.9763 32.2047V28.9099H41.4293V33.8933Z"
        fill="white"
      />
    </svg>
  );
}
