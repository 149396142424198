import React from "react";

export default function TruBeIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-inline-block tbk-align-text-bottom${
        className ? " " + className : ""
      }`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.3728 24 0 18.6274 0 12C0 5.37261 5.3728 0 12 0C18.6274 0 24 5.37261 24 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5244 6.93564C14.5328 8.34444 13.3978 9.49365 11.989 9.50224C10.5794 9.51104 9.43064 8.37545 9.42204 6.96665C9.41364 5.55744 10.5488 4.40824 11.958 4.40004C13.3668 4.39124 14.5156 5.52644 14.5244 6.93564Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3608 17.1344C18.3608 17.1344 9.79997 11.9872 6.14917 17.6614C6.14917 17.6614 11.3018 22.2934 18.3608 17.1344Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2984 10.2434C13.2984 10.2434 12.2306 13.8708 9.92562 13.8708C7.89182 13.8708 5.94282 9.49776 5.40002 9.46396C5.40002 9.46396 6.58702 8.02296 8.12922 9.02296C10.9874 10.876 12.1624 10.7182 13.2984 10.2434Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4366 13.7601C16.4294 13.8197 21.504 8.96007 16.0904 7.01947C15.5396 10.7865 12.8624 13.1517 12.4366 13.7601Z"
        fill="white"
      />
    </svg>
  );
}
