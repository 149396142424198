/**
 * WhatsApp link to contact Support
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/10/21
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";
import PropTypes from "prop-types";

const WhatsAppLink = ({ children, region, message }) => {
  // Encode the message for URL
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${
    region === "GB" ? "4407459783573" : "13233872101"
  }?text=${encodedMessage}`; // `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <a
      href={whatsappUrl}
      className="tbk-text-blue tbk-underline hover:tbk-text-blue-light"
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

// PropTypes for type checking
WhatsAppLink.propTypes = {
  region: PropTypes.string.isRequired,
  message: PropTypes.string,
};

// Default props
WhatsAppLink.defaultProps = {
  region: process.env.REACT_APP_REGION,
  message: "",
};

export default WhatsAppLink;
