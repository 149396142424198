import React from "react";

export default function WhatsAppLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0037 24.0021C10.0021 16.2746 16.2651 10.009 23.9925 10.0073C26.6121 10.0048 29.1797 10.7376 31.4032 12.1224C37.9625 16.2076 39.9681 24.8366 35.883 31.3959C31.7979 37.9552 23.1688 39.9609 16.6095 35.8757L10.7796 37.957C10.7168 37.9797 10.6505 37.9912 10.5837 37.9908C10.5117 37.9909 10.4404 37.9777 10.3733 37.9517C10.0729 37.8358 9.92333 37.4983 10.0393 37.1979L12.2161 31.5499C10.7721 29.2973 10.0043 26.6778 10.0037 24.0021ZM28.34 25.2247C29.2343 25.6911 30.6626 26.3907 30.6626 26.3907C30.862 26.4889 30.9882 26.6919 30.988 26.9143V28.0802C30.6947 29.7782 29.2131 31.0128 27.4901 30.9951C25.6584 30.9951 22.1418 28.8054 20.6645 27.327C19.1871 25.8485 16.9963 22.3319 16.9963 20.5013C16.9786 18.7783 18.2132 17.2967 19.9112 17.0034H21.0772C21.2971 17.0036 21.4982 17.1276 21.5972 17.3241C21.5972 17.3252 22.3026 18.7524 22.7632 19.6479C23.282 20.6599 22.3084 21.8481 21.7534 22.4089C22.0879 23.28 22.5929 24.0756 23.2389 24.749C23.9124 25.3948 24.708 25.8999 25.579 26.2345C26.1387 25.6794 27.328 24.7035 28.34 25.2247Z"
        fill="#4CAF50"
      />
    </svg>
  );
}
