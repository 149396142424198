import React from "react";

export default function CaretDownIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? `${className} ` : ""}tbk-shrink-0`}
    >
      <path d="M17 8.5l1.5 1.5-6.5 6.5L5.5 10 7 8.5l5 5z" fill="currentColor" />
    </svg>
  );
}
