import React from "react";

export default function BlockerIcon({ size, className }) {
  return (
    <svg
      width={size || 40}
      height={size || 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <path
        d="M19.5833 8.3335C13.1433 8.3335 7.91663 13.5602 7.91663 20.0002C7.91663 26.4402 13.1433 31.6668 19.5833 31.6668C26.0233 31.6668 31.25 26.4402 31.25 20.0002C31.25 13.5602 26.0233 8.3335 19.5833 8.3335Z"
        fill="#EB5757"
      />
      <rect x="12.9166" y="19.1665" width="13.3333" height="2.5" fill="white" />
    </svg>
  );
}
