import { useEffect, useCallback, useRef } from "react";

const useContentScrollerItemVisibility = ({
  autoRecalcOnMount = true,
  stateManagementApi,
}) => {
  const { scrollingContainer } = stateManagementApi || {},
    autoRecalcOnMountRef = useRef(!autoRecalcOnMount);

  useEffect(() => {
    if (scrollingContainer && !autoRecalcOnMountRef.current) {
      autoRecalcOnMountRef.current = true;

      if (stateManagementApi != null) {
        stateManagementApi.recalc({
          reason: "reset",
        });
      }
    }
  }, [scrollingContainer, stateManagementApi]);

  const getVisibleItemIndexesCb = useCallback(() => {
    var currentState = stateManagementApi.state;

    const state =
      stateManagementApi == null ||
      currentState === null ||
      currentState === undefined
        ? undefined
        : currentState.current;

    if (state == null || !state.elementStateByIndex) {
      return [];
    }

    const arrKeys = [];

    for (const [key, val] of state.elementStateByIndex) {
      val && arrKeys.push(key);
    }

    return arrKeys;
  }, [stateManagementApi]);

  const getVisibleCountCb = useCallback(
    () => getVisibleItemIndexesCb().length,
    [getVisibleItemIndexesCb]
  );

  const getTotalCountCb = useCallback(() => {
    var scrollingContainer = stateManagementApi.scrollingContainer,
      scrollingContainerChildren = scrollingContainer.children;

    return (
      (stateManagementApi == null ||
      scrollingContainer === null ||
      scrollingContainer === undefined ||
      scrollingContainerChildren === null ||
      scrollingContainerChildren === undefined
        ? undefined
        : scrollingContainerChildren.length) || 0
    );
  }, [stateManagementApi]);

  const isVisibleCb = useCallback(
    (idx) => {
      var currentState = stateManagementApi.state;
      const state =
        stateManagementApi == null ||
        currentState === null ||
        currentState === undefined
          ? undefined
          : currentState.current;

      return null == state ? undefined : state.elementStateByIndex.get(idx);
    },
    [stateManagementApi]
  );

  return {
    getCurrentChangeset: useCallback((state) => {
      const changeSet = {
        hasChanges: false,
        visible: [],
        hidden: [],
      };

      for (const [key, elIndex] of state.elementStateByIndex) {
        if (state.previousElementStateByIndex.get(key) !== elIndex) {
          changeSet.hasChanges = true;
          elIndex ? changeSet.visible.push(key) : changeSet.hidden.push(key);
        }
      }

      return changeSet;
    }, []),
    getVisibleCount: getVisibleCountCb,
    getVisibleItemIndexes: getVisibleItemIndexesCb,
    getTotalCount: getTotalCountCb,
    isVisible: isVisibleCb,
  };
};

export default useContentScrollerItemVisibility;
