import React, { useState } from "react";
import { uniqueId } from "../../utils/_";

export default function SessionTypeIcon({ className }) {
  const [_id] = useState(uniqueId());

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="16"
        cy="16"
        r="15.5"
        fill={`url(#${_id}_paint0_linear_9471_13827)`}
        stroke="white"
      />
      <path
        d="M16 6.66667C12.2847 6.66667 9.27979 9.67155 9.27979 13.3869C9.27979 18.427 16 25.8672 16 25.8672C16 25.8672 22.7201 18.427 22.7201 13.3869C22.7201 9.67155 19.7153 6.66667 16 6.66667ZM16 16.2673C14.5162 16.2673 13.312 15.0385 13.312 13.5244C13.312 12.0103 14.5162 10.7815 16 10.7815C17.4838 10.7815 18.6881 12.0103 18.6881 13.5244C18.6881 15.0385 17.4838 16.2673 16 16.2673Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`${_id}_paint0_linear_9471_13827`}
          x1="-5.96159e-08"
          y1="0.0692684"
          x2="18.9981"
          y2="41.9803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}

// <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
//  <g filter="url(#filter0_d_9471_13827)">
//    <circle cx="36" cy="32" r="15.5" fill="url(#paint0_linear_9471_13827)" stroke="white"/>
//    <path fillRule="evenodd" clipRule="evenodd" d="M36.0005 22.6667C32.2852 22.6667 29.2803 25.6716 29.2803 29.3869C29.2803 34.4271 36.0005 41.8673 36.0005 41.8673C36.0005 41.8673 42.7206 34.4271 42.7206 29.3869C42.7206 25.6716 39.7158 22.6667 36.0005 22.6667ZM36.0005 32.2674C34.5167 32.2674 33.3124 31.0386 33.3124 29.5245C33.3124 28.0104 34.5167 26.7815 36.0005 26.7815C37.4843 26.7815 38.6886 28.0104 38.6886 29.5245C38.6886 31.0386 37.4843 32.2674 36.0005 32.2674Z" fill="white"/>
//  </g>
//  <defs>
//    <filter id="filter0_d_9471_13827" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
//      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
//      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
//      <feOffset dy="4"/>
//      <feGaussianBlur stdDeviation="10"/>
//      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
//      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9471_13827"/>
//      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9471_13827" result="shape"/>
//    </filter>
//    <linearGradient id="paint0_linear_9471_13827" x1="20" y1="16.0693" x2="38.9981" y2="57.9803" gradientUnits="userSpaceOnUse">
//      <stop stopColor="#FFC397"/>
//      <stop offset="1" stopColor="#FF6C4D"/>
//    </linearGradient>
//  </defs>
// </svg>
