import React from "react";

export default function ReZenMasterIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <circle cx="32" cy="32" r="32" fill="#84DCC6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04395 30.0135L26.9731 14.5064L27.6467 13.9249C29.4334 12.5873 31.6946 11.8593 34.1031 12.0227C38.615 12.3279 42.0226 16.2032 41.7144 20.6785C41.5759 22.6882 40.5835 24.4374 39.113 25.6026L22.2179 40.2352L9.04395 30.0135Z"
        fill={`url(#${id}_paint0_linear_5436_18982)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4729 28.8467C58.5064 27.9335 57.7869 27.1664 56.8664 27.133C56.7918 27.1304 56.7189 27.1343 56.6463 27.1412L38.4769 26.1486L22.4376 39.8738C23.2724 38.8214 23.808 37.5171 23.9074 36.0801C24.2156 31.6052 20.8078 27.729 16.2956 27.4238C10.987 27.0635 6.38938 31.039 6.02684 36.3046C5.59993 42.4987 10.3168 47.8631 16.5621 48.2854C20.2802 48.5372 34.2536 49.4216 34.4967 49.4302C35.5683 49.4688 36.539 49.003 37.1817 48.2492C37.1817 48.2492 57.9056 30.0413 58.03 29.9085C58.2919 29.6286 58.458 29.2584 58.4729 28.8467Z"
        fill={`url(#${id}_paint1_linear_5436_18982)`}
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1959 44.6212C14.2225 44.4872 12.3998 43.528 11.064 41.92C9.75414 40.3429 9.08236 38.2977 9.21909 36.3082C9.33264 34.6544 10.0889 33.144 11.3485 32.0551C12.6073 30.9665 14.2212 30.4292 15.8851 30.5421C17.265 30.6353 18.5259 31.2568 19.4351 32.2905C20.3442 33.324 20.7923 34.6475 20.6983 36.0155C20.5384 38.3421 18.5001 40.1026 16.1539 39.9463C14.2359 39.8163 12.7824 38.1629 12.9135 36.2612C12.9647 35.5142 13.3067 34.8319 13.8752 34.3406C14.444 33.8485 15.1698 33.6062 15.9255 33.6567C16.5291 33.6976 17.0793 33.9685 17.4766 34.4205C17.8739 34.873 18.0706 35.4507 18.0292 36.05C18.0253 36.0911 17.9785 36.2768 17.8291 36.5339C17.6185 36.7019 17.4771 36.953 17.4666 37.242C17.4473 37.7706 17.8634 38.2145 18.3966 38.2339C18.8379 38.2497 19.2167 37.9673 19.3462 37.5699C19.6483 37.0886 19.8357 36.5875 19.8638 36.1741C19.939 35.0896 19.5834 34.0411 18.8632 33.2219C18.143 32.4027 17.1442 31.9106 16.0502 31.8365C14.814 31.752 13.6059 32.1537 12.6666 32.9652C11.7272 33.7773 11.1636 34.9034 11.0786 36.1371C10.8786 39.0423 13.0984 41.5676 16.0283 41.766C19.3789 41.9925 22.304 39.4698 22.5334 36.1401C22.6613 34.2857 22.0532 32.4918 20.8211 31.0914C19.5888 29.6904 17.8806 28.8485 16.011 28.7219C13.8528 28.5735 11.7684 29.2722 10.1388 30.6807C8.50997 32.0891 7.53153 34.0441 7.38399 36.1836C7.21508 38.6325 8.03878 41.147 9.6442 43.0806C11.3047 45.0799 13.5873 46.2737 16.0705 46.4415C18.5321 46.6082 30.0326 47.3559 37.6526 47.8342L39.5871 46.1281C32.137 45.6639 18.8619 44.8026 16.1959 44.6212Z"
        fill="white"
      />
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.7694 31.0253C49.628 30.5789 37.8857 29.8177 34.4573 29.5884L32.5776 31.1969C32.9901 31.285 33.4103 31.3463 33.8367 31.3752C36.2362 31.5376 47.2223 32.2521 54.8328 32.7311C55.5927 32.0623 56.2503 31.4829 56.7694 31.0253Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18982`}
          x1="9.04395"
          y1="12"
          x2="9.04395"
          y2="40.2352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5515F" />
          <stop offset="1" stopColor="#9F041B" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18982`}
          x1="6"
          y1="26.1486"
          x2="6"
          y2="49.4325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF4112" />
          <stop offset="1" stopColor="#E64F3A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
