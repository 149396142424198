import React from "react";

export default function CloseBigFilledIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#F8F8F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 17.75L10.5556 23L9 21.5L14.1852 16.5L9 11.5L10.5556 10L16 15.25L21.4444 10L23 11.5L17.8148 16.5L23 21.5L21.4444 23L16 17.75Z"
        fill="#203B54"
      />
    </svg>
  );
}
