import React from "react";

export default function BoltIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7478 21C10.6036 20.9997 10.4614 20.9694 10.3327 20.9115C9.90563 20.7185 9.67528 20.261 9.78086 19.8142L10.7538 16.1512H6.99273C6.61007 16.1445 6.26656 15.9411 6.11214 15.6298C5.94156 15.3065 5.96685 14.9283 6.17933 14.6253L12.4382 5.41185C12.7093 5.03649 13.2132 4.8968 13.6463 5.08021C14.0782 5.26363 14.3194 5.7179 14.2222 6.16897L13.2228 10.3331H17.0079C17.3822 10.3331 17.7253 10.5389 17.8945 10.8663C18.0623 11.1957 18.0283 11.5785 17.8045 11.8804L11.5456 20.6086C11.3702 20.8496 11.0707 20.9965 10.7478 21Z"
        fill="currentColor"
      />
    </svg>
  );
}
