import React from "react";

export default function CheckNiceIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-shrink-0${className ? " " + className : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 8L17 9.5L10.5 16L7 12.5L8.5 11L10.5 13L15.5 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
