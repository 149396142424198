import React from "react";

// Icons
import UserIcon from "../../../../icons/UserIcon";

export const TrainerCardPlaceholder = ({ className }) => (
  <div
    className={`${
      className ? `${className} ` : ""
    }tbk-relative tbk-pt-3_ tbk-pb-3_`}
  >
    <div className="tbk-h-58_ tbk-flex tbk-h-full tbk-flex-col tbk-rounded-2xl tbk-border tbk-border-blue-grey-light tbk-bg-basic-white tbk-shadow-trube">
      <div className="tbk-relative tbk-flex">
        <div className="tb-profile-picture-placeholder tbk-flex tbk-h-54 tbk-w-58 tbk-shrink-0 tbk-grow-0 tbk-basis-58 tbk-justify-center tbk-rounded-l-2xl">
          <UserIcon
            size={216}
            className="tbk-h-full tbk-object-cover tbk-text-grey-inactive"
          />
        </div>
        <ul className="tbk-absolute tbk-right-0 tbk-top-0 tbk-mt-1 tbk-mr-1 tbk-flex tbk-flex-col">
          <li className="tbk-mb-1 tbk-h-4 tbk-w-4 tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white tbk-text-primary" />
          <li className="tbk-mb-1 tbk-h-4 tbk-w-4 tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white tbk-text-primary" />
          <li className="tbk-mb-1 tbk-h-4 tbk-w-4 tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white tbk-text-primary" />
        </ul>

        <div
          className="tbk-absolute tbk-left-0 tbk-bottom-0 tbk-mb-1 tbk-ml-1 tbk-w-6 tbk-rounded-lg tbk-bg-basic-white tbk-shadow-trube"
          style={{
            height: "28px",
          }}
        ></div>
      </div>
      <div
        className="tb-trainer-card-placeholder-footer-cta tbk-shadow-trube_ tbk-rounded-b-2xl tbk-bg-basic-white tbk-px-2 tbk-pt-1 tbk-pb-2"
        style={{}}
      >
        <div
          className="tbk-mb-0.5 tbk-w-32 tbk-rounded-lg tbk-bg-grey-inactive"
          style={{
            height: "32px",
          }}
        />
        <div
          className="tbk-h-5 tbk-w-full tbk-rounded-lg tbk-bg-grey-light tbk-p-0.5"
          style={{
            height: "54px",
          }}
        />
      </div>
      <div className="tbk-absolute tbk--top-3 tbk-left-0 tbk-right-0 tbk-bottom-0 tbk-animate-placeholder-shimmer tbk-bg-coach-card-placeholder" />
    </div>
  </div>
);

export default TrainerCardPlaceholder;
