import React from "react";

export default function UserIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M15.4711 6.89069C15.4711 9.59172 13.6932 11.7813 11.5003 11.7813C9.3072 11.7813 7.5293 9.59172 7.5293 6.89069C7.5293 4.1896 8.11312 2 11.5003 2C14.8874 2 15.4711 4.1896 15.4711 6.89069ZM13.6745 12.1566C18.312 13.0053 18.9181 14.346 18.9916 19.0513C18.9963 19.3522 18.9988 19.4043 19 19.3774C18.9998 19.4572 18.9997 19.5745 18.9997 19.7431C18.9997 19.7431 17.8955 22 11.5002 22C5.1047 22 4.00062 19.7431 4.00062 19.7431C4.00062 19.4806 4.00028 19.3301 4 19.2487C4.00124 19.2922 4.00445 19.2725 4.01138 18.8984C4.09591 14.3169 4.7388 12.996 9.3257 12.1566C9.3257 12.1566 9.97862 13.0001 11.5002 13.0001C13.0217 13.0001 13.6745 12.1566 13.6745 12.1566Z"
        fill="currentColor"
      />
    </svg>
  );
}
