import React from "react";

export default function TwitterLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#4682C8"
        d="M18.806 36c10.566 0 16.345-8.465 16.345-15.805 0-.241 0-.48-.017-.719A11.463 11.463 0 0 0 38 16.601c-1.047.45-2.16.744-3.298.874a5.617 5.617 0 0 0 2.525-3.073 11.756 11.756 0 0 1-3.648 1.349c-2.176-2.237-5.814-2.345-8.129-.241-1.49 1.356-2.125 3.379-1.661 5.308-4.619-.225-8.923-2.334-11.84-5.804-1.525 2.538-.745 5.785 1.78 7.415a5.851 5.851 0 0 1-2.609-.695v.07c.001 2.644 1.93 4.921 4.609 5.445a5.905 5.905 0 0 1-2.593.095c.752 2.264 2.91 3.814 5.366 3.858a11.779 11.779 0 0 1-7.134 2.383 12.18 12.18 0 0 1-1.368-.08 16.676 16.676 0 0 0 8.806 2.49"
      />
    </svg>
  );
}
