import React from "react";

export default function CymbiotikaLogo({ className, width, height }) {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.9999 34C65.9999 51.673 51.673 66 33.9999 66C16.3274 66 2 51.673 2 34C2 16.327 16.3274 2 33.9999 2C51.673 2 65.9999 16.327 65.9999 34"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3997 20.7587C40.422 24.5138 37.3969 27.5769 33.6419 27.5999C29.8848 27.6233 26.8229 24.5964 26.7999 20.8413C26.7775 17.0851 29.8033 14.022 33.5593 14.0001C37.3142 13.9767 40.3762 17.0025 40.3997 20.7587Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7994 47.6088C50.7994 47.6088 27.8054 34.1141 17.9995 48.9904C17.9995 48.9904 31.8392 61.1343 50.7994 47.6088Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1986 29.3155C37.1986 29.3155 34.3867 38.8007 28.3166 38.8007C22.9607 38.8007 17.8281 27.3658 16.3987 27.2775C16.3987 27.2775 19.5246 23.5094 23.5859 26.1243C31.1128 30.9697 34.2071 30.557 37.1986 29.3155Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7991 38.7988C45.4298 38.9544 58.9408 26.2668 44.5272 21.2002C43.0607 31.0352 35.9328 37.2103 34.7991 38.7988Z"
        fill="white"
      />
    </svg>
  );
}
