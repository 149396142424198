import React from "react";

export default function Calendar(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_318_4350)">
        <path
          d="M14.7636 22.0678C14.7636 21.5667 14.3479 21.1608 13.8351 21.1608H10.595C10.0827 21.1608 9.66699 21.5667 9.66699 22.0678V25.2356C9.66699 25.7374 10.0827 26.1435 10.595 26.1435H13.8351C14.3479 26.1435 14.7636 25.7374 14.7636 25.2356V22.0678Z"
          fill="url(#paint0_linear_318_4350)"
        />
        <path
          d="M22.8608 22.0678C22.8608 21.5667 22.445 21.1608 21.9332 21.1608H18.6926C18.1804 21.1608 17.7646 21.5667 17.7646 22.0678V25.2356C17.7646 25.7374 18.1804 26.1435 18.6926 26.1435H21.9332C22.445 26.1435 22.8608 25.7374 22.8608 25.2356V22.0678Z"
          fill="url(#paint1_linear_318_4350)"
        />
        <path
          d="M30.9589 22.0678C30.9589 21.5667 30.5432 21.1608 30.0309 21.1608H26.7908C26.2779 21.1608 25.8623 21.5667 25.8623 22.0678V25.2356C25.8623 25.7374 26.2779 26.1435 26.7908 26.1435H30.0309C30.5432 26.1435 30.9589 25.7374 30.9589 25.2356V22.0678Z"
          fill="url(#paint2_linear_318_4350)"
        />
        <path
          d="M14.7636 29.9866C14.7636 29.4847 14.3479 29.0791 13.8351 29.0791H10.595C10.0827 29.0791 9.66699 29.4847 9.66699 29.9866V33.1539C9.66699 33.6553 10.0827 34.0613 10.595 34.0613H13.8351C14.3479 34.0613 14.7636 33.6552 14.7636 33.1539V29.9866Z"
          fill="url(#paint3_linear_318_4350)"
        />
        <path
          d="M22.8608 29.9866C22.8608 29.4847 22.445 29.0791 21.9332 29.0791H18.6926C18.1804 29.0791 17.7646 29.4847 17.7646 29.9866V33.1539C17.7646 33.6553 18.1804 34.0613 18.6926 34.0613H21.9332C22.445 34.0613 22.8608 33.6552 22.8608 33.1539V29.9866Z"
          fill="url(#paint4_linear_318_4350)"
        />
        <path
          d="M30.9589 29.9866C30.9589 29.4847 30.5432 29.0791 30.0313 29.0791H26.7908C26.2779 29.0791 25.8623 29.4847 25.8623 29.9866V33.1539C25.8623 33.6553 26.2779 34.0613 26.7908 34.0613H30.0313C30.5432 34.0613 30.9589 33.6552 30.9589 33.1539V29.9866Z"
          fill="url(#paint5_linear_318_4350)"
        />
        <path
          d="M36.7521 4.4539V9.29255C36.7521 11.4796 34.9377 13.242 32.7014 13.242H30.1461C27.9097 13.242 26.0712 11.4796 26.0712 9.29255V4.43652H14.5538V9.29255C14.5538 11.4796 12.7154 13.242 10.4793 13.242H7.9235C5.68731 13.242 3.87297 11.4796 3.87297 9.29255V4.4539C1.91852 4.5115 0.3125 6.09289 0.3125 8.03663V36.3935C0.3125 38.3737 1.95396 40.0001 3.97915 40.0001H36.6459C38.668 40.0001 40.3125 38.3704 40.3125 36.3935V8.03663C40.3125 6.09289 38.7066 4.5115 36.7521 4.4539ZM35.5653 34.6276C35.5653 35.4834 34.8556 36.1777 33.9801 36.1777H6.57509C5.69955 36.1777 4.98994 35.4834 4.98994 34.6276V19.9803C4.98994 19.1242 5.69946 18.4299 6.57509 18.4299H33.98C34.8556 18.4299 35.5652 19.1242 35.5652 19.9803L35.5653 34.6276Z"
          fill="url(#paint6_linear_318_4350)"
        />
        <path
          d="M7.91462 10.6479H10.4422C11.2094 10.6479 11.8314 10.0406 11.8314 9.29038V1.35802C11.8314 0.607762 11.2094 0 10.4422 0H7.91462C7.14735 0 6.52539 0.607762 6.52539 1.35802V9.29038C6.52539 10.0406 7.14735 10.6479 7.91462 10.6479Z"
          fill="url(#paint7_linear_318_4350)"
        />
        <path
          d="M30.1138 10.6479H32.6413C33.408 10.6479 34.03 10.0406 34.03 9.29038V1.35802C34.0301 0.607762 33.4081 0 32.6413 0H30.1138C29.3467 0 28.7246 0.607762 28.7246 1.35802V9.29038C28.7246 10.0406 29.3467 10.6479 30.1138 10.6479Z"
          fill="url(#paint8_linear_318_4350)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_318_4350"
          x1="9.66699"
          y1="21.1716"
          x2="12.581"
          y2="27.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_318_4350"
          x1="17.7646"
          y1="21.1716"
          x2="20.6789"
          y2="27.7468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_318_4350"
          x1="25.8623"
          y1="21.1716"
          x2="28.7763"
          y2="27.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_318_4350"
          x1="9.66699"
          y1="29.0899"
          x2="12.5806"
          y2="35.6649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_318_4350"
          x1="17.7646"
          y1="29.0899"
          x2="20.6784"
          y2="35.6647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_318_4350"
          x1="25.8623"
          y1="29.0899"
          x2="28.7759"
          y2="35.6649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_318_4350"
          x1="0.3125"
          y1="4.51351"
          x2="19.7798"
          y2="52.8169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_318_4350"
          x1="6.52539"
          y1="0.0230489"
          x2="14.8935"
          y2="9.22223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_318_4350"
          x1="28.7246"
          y1="0.0230489"
          x2="37.0928"
          y2="9.22132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
        <clipPath id="clip0_318_4350">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
