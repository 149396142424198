import React from "react";

export default function StarFullIcon({ size, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      className={className ? className : ""}
    >
      <path
        d="M13.686 10.833L12 7l-1.686 3.838L6 11.192l3.276 2.738L8.292 18 12 15.84 15.708 18l-.978-4.07L18 11.192z"
        fill="currentColor"
      />
    </svg>
  );
}
