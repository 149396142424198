import React from "react";

export default function GiftBigIcon({ className, size }) {
  return (
    <svg
      width={size || 48}
      height={size || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_22023_5345)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.375 17.9089H30.2335C30.6024 17.6399 30.9193 17.3726 31.1402 17.1331C32.4516 15.7255 32.4516 13.4346 31.1402 12.027C29.8662 10.6559 27.6465 10.6594 26.3741 12.027C25.6689 12.782 23.8001 15.8557 24.0585 17.9089H23.9415C24.1983 15.8557 22.3295 12.782 21.6259 12.027C20.3519 10.6594 18.1321 10.6594 16.8597 12.027C15.55 13.4346 15.55 15.7255 16.8581 17.1331C17.0807 17.3726 17.3976 17.6399 17.7665 17.9089H12.625C11.7296 17.9089 11 18.6881 11 19.6444V22.2478C11 22.7268 11.364 23.1156 11.8125 23.1156H22.375V19.6444H25.625V23.1156H36.1875C36.636 23.1156 37 22.7268 37 22.2478V19.6444C37 18.6881 36.272 17.9089 35.375 17.9089ZM22.2791 17.8516C22.2791 17.8516 22.2109 17.9089 21.9785 17.9089C20.8556 17.9089 18.7123 16.6627 18.0119 15.9095C17.331 15.1771 17.331 13.983 18.0119 13.2506C18.3418 12.8965 18.7789 12.7022 19.2436 12.7022C19.7067 12.7022 20.1439 12.8965 20.4738 13.2506C21.569 14.4273 22.6577 17.4333 22.2791 17.8516ZM26.0199 17.9089C25.7891 17.9089 25.7209 17.8533 25.7209 17.8516C25.3423 17.4333 26.431 14.4273 27.5263 13.2506C28.1811 12.5442 29.3268 12.5408 29.9881 13.2506C30.6706 13.983 30.6706 15.1771 29.9881 15.9095C29.2878 16.6627 27.1444 17.9089 26.0199 17.9089ZM12.625 24.8515V35.2649C12.625 36.2229 13.3546 37.0004 14.25 37.0004H22.375V24.8515H12.625ZM25.6214 37.0004V24.8515H35.3714V35.2649C35.3714 36.2229 34.6434 37.0004 33.7464 37.0004H25.6214Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_22023_5345"
          x1="-8.94239e-08"
          y1="0.103903"
          x2="28.4972"
          y2="62.9704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
