import React from "react";
import moment from "moment";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import CheckOIcon from "../../../icons/CheckOIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceIcon";
// import CheckNiceIcon from "../../../icons/CheckNiceOIcon";
import CheckNiceIcon from "../../../icons/CheckboxOIcon";
import { Link } from "react-router-dom";

const OfferCard = ({ imgUrl, logoUrl, title, subtitle, status }) => {
  return (
    <div className="tbk-rounded-2xl tbk-shadow-trube">
      <img
        src={imgUrl}
        alt={title}
        className="tbk-h-40 tbk-w-full tbk-grow tbk-rounded-t-lg tbk-object-cover tbk-object-left-top"
      />
      <div className="tbk-mt-4_ tbk-relative tbk-flex tbk-items-center tbk-justify-between tbk-p-1">
        <div className="tbk-ml-0 tbk-mr-2 tbk-shrink-0">
          <img className="tbk-h-7 tbk-w-7" src={logoUrl} alt={title} />
        </div>
        <div className="tbk-min-w-0 tbk-overflow-hidden">
          <h3
            className={`tbk-text-h3-subtitle tbk-mb-0.5 tbk-text-left ${
              status === "SOLD_OUT" ? "tbk-text-blue-grey" : "tbk-text-primary"
            } tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap`}
          >
            {title}
          </h3>
          <div className="tbk-text-small tbk-overflow-hidden tbk-text-ellipsis tbk-whitespace-nowrap tbk-text-grey">
            {subtitle}
          </div>
        </div>
        <div className="tbk-ml-2">
          {status === "UPCOMING_FULL" ? (
            <CheckNiceIcon width={24} height={24} className="tbk-text-green" />
          ) : (
            <ArrowRightIcon className="tbk-text-coral" />
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
