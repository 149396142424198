import React from "react";

export default function EveningIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C15.3137 7 18 9.686 18 12.9994C18 14.0925 17.7076 15.1174 17.1967 16H6.80325C6.2924 15.1174 6 14.0925 6 12.9994C6 9.686 8.68629 7 12 7Z"
        fill="#FF8E00"
      />
      <rect x="4" y="16" width="16" height="2" fill="#FF8E00" />
    </svg>
  );
}
