/**
 * PhoneNumber link to contact Support
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/10/22
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";
import PropTypes from "prop-types";

// Utils
import { phoneNumbers } from "../../constants";

const PhoneNumberLink = ({
  className,
  region,
  gbFormat,
  usFormat,
  withZero,
}) => {
  let phoneNumberLink;
  let phoneNumberText;

  if (region === "GB") {
    phoneNumberLink = `tel:${phoneNumbers["GB"][`${gbFormat}Number`]}`;
    phoneNumberText = phoneNumbers["GB"][`${gbFormat}NumberFormatted`];
    phoneNumberText = withZero
      ? phoneNumberText.replace(/^\+44\s/, "+44 (0) ")
      : phoneNumberText;
  } else if ("US_redirect") {
    phoneNumberLink = `tel:${phoneNumbers["US_redirect"][`${usFormat}Number`]}`;
    phoneNumberText = phoneNumbers["US_redirect"][`${usFormat}NumberFormatted`];
  } else {
    phoneNumberLink = `tel:${phoneNumbers["US"][`${gbFormat}Number`]}`;
    phoneNumberText = phoneNumbers["US"][`${usFormat}NumberFormatted`];
  }

  return (
    <a href={phoneNumberLink} className={className}>
      {phoneNumberText}
    </a>
  );
};

// PropTypes for type checking
PhoneNumberLink.propTypes = {
  region: PropTypes.string.isRequired,
  //format: PropTypes.string.isRequired,
  gbFormat: PropTypes.string,
  usFormat: PropTypes.string,
};

// Default props
PhoneNumberLink.defaultProps = {
  region: process.env.REACT_APP_REGION,
  gbFormat: "international",
  usFormat: "international",
};

export default PhoneNumberLink;
