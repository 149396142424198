/**
 * CorporateContactForm component.
 * Used for sending corporate enquiries
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/06
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useRef, useState } from "react";
import { useAppState } from "../../state";

// Components
import Button from "../Button/Button";

const CorporateContactForm = ({ onSubmit }) => {
  const { user, sendSupportRequest, isFetching } = useAppState();

  const [clientName, setClientName] = useState(
    user ? `${user.firstName} ${user.lastName}` : ""
  );
  const [clientEmail, setClientEmail] = useState(user ? user.email : "");
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [hasRequestBeenSubmitted, setHasRequestBeenSubmitted] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasNameError, setHasNameError] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const clientEmailRef = useRef();
  const clientPhoneNumberRef = useRef();

  const handleClientNameChange = useCallback((event) => {
    const value = event.target.value;

    setClientName(value);
    setHasNameError(false);
  }, []);

  const handleClientEmailChange = useCallback((event) => {
    const value = event.target.value;

    setClientEmail(value);
    setHasEmailError(false);
  }, []);

  const handleClientPhoneNumberChange = useCallback((event) => {
    const value = event.target.value;

    setClientPhoneNumber(value);
    setHasPhoneError(false);
  }, []);

  const handleClientRequestSubmit = useCallback(
    (e) => {
      e.preventDefault();
      // const userId = user.id;
      let clientEmail = clientEmailRef.current.value;
      let clientPhoneNumber = clientPhoneNumberRef.current.value;

      if (!clientName) {
        setHasNameError(true);
      }

      if (!clientPhoneNumber) {
        setHasPhoneError(true);
      }

      if (!clientEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clientEmail)) {
        setHasEmailError(true);

        return;
      }

      if (clientPhoneNumber) {
        sendSupportRequest(
          `${clientName}`,
          clientEmail,
          "Corporate Enquiry",
          clientPhoneNumber
        ).then(() => {
          setHasRequestBeenSubmitted(true);

          onSubmit && onSubmit();
        });
      }
    },
    [clientName] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <form
      id=""
      className="tbk-text-main js-contact-from tbk-mb-5 tbk-rounded-lg tbk-bg-grey-light tbk-p-4 md:tbk-mb-0"
      action=""
      method="POST"
    >
      <div className="form-group">
        {/*<label*/}
        {/*  className="form-check-label tbk-c-basic-white hidden"*/}
        {/*  htmlFor="name"*/}
        {/*>*/}
        {/*  Your name*/}
        {/*</label>*/}
        <input
          type="text"
          name="name"
          className={`tbk-input-field tbk-text-h3-subtitle tbk-w-full tbk-text-grey-main${
            hasNameError ? " tbk-border-coral" : ""
          }`}
          id="name"
          aria-describedby="emailHelp"
          placeholder="Type Your Name"
          value={clientName}
          onChange={handleClientNameChange}
          required
        />
        <small id="name" className="form-text text-muted tbk-hidden">
          We'll never share your email with anyone else.
        </small>
      </div>
      <div className="form-group">
        {/*<label*/}
        {/*  className="form-check-label tbk-c-basic-white hidden"*/}
        {/*  htmlFor="email"*/}
        {/*>*/}
        {/*  E-mail*/}
        {/*</label>*/}
        <input
          ref={clientEmailRef}
          type="email"
          name="email"
          className={`tbk-input-field tbk-text-h3-subtitle tbk-w-full tbk-text-grey-main${
            hasEmailError ? " tbk-border-coral" : ""
          }`}
          id="email"
          placeholder="Type your email"
          value={clientEmail}
          onChange={handleClientEmailChange}
          required
        />
      </div>
      <div className="form-group form-check">
        {/*<label*/}
        {/*  className="form-check-label tbk-c-basic-white hidden"*/}
        {/*  htmlFor="exampleCheck1"*/}
        {/*>*/}
        {/*  Phone number*/}
        {/*</label>*/}
        <input
          ref={clientPhoneNumberRef}
          type="tel"
          name="phone"
          className={`tbk-input-field tbk-text-h3-subtitle tbk-w-full tbk-text-grey-main${
            hasPhoneError ? " tbk-border-coral" : ""
          }`}
          id="phone-number"
          placeholder="Contact phone number"
          value={clientPhoneNumber}
          onChange={handleClientPhoneNumberChange}
          required
        />
      </div>
      <div className="tbk-text-center">
        <Button
          type="submit"
          className="tbk-mt-1 tbk-w-full tbk-uppercase"
          disabled={
            //   !clientName ||
            //   !clientEmail ||
            //   !clientPhoneNumber ||
            isFetching
          }
          showLoader={isFetching}
          onClick={handleClientRequestSubmit}
        >
          Contact Us Today
        </Button>
        {hasRequestBeenSubmitted ? (
          <p className="tbk-text-main tbk-mt-3 tbk-text-grey">
            Your request has been sent successfully. Thank you for your enquiry.
          </p>
        ) : null}
      </div>
    </form>
  );
};

export default CorporateContactForm;
