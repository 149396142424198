/**
 * Stories Carousel component.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/07
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React from "react";
import Slider from "react-slick";

// Components
import { SliderNextButton } from "../../SliderNextButton/SliderNextButton";
import { SliderPrevButton } from "../../SliderPrevButton/SliderPrevButton";

// Icons
import QuoteMark from "../../Testimonials/QuoteMark";

const responsiveSettings = [
  {
    breakpoint: 640,
    settings: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "48px",
      initialSlide: 5,
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesToShow: 2,
      centerMode: true,
      centerPadding: "50px",
      initialSlide: 5,
    },
  },
  {
    breakpoint: 1180,
    settings: {
      slidesToShow: 2,
      centerMode: true,
      centerPadding: "100px",
      initialSlide: 2,
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "48px",
      initialSlide: 2,
    },
  },
  {
    breakpoint: 1600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "144px",
      initialSlide: 2,
    },
  },
  {
    breakpoint: 1920,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "144px",
      initialSlide: 2,
    },
  },
  {
    breakpoint: 2560,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 2,
      centerMode: true,
      centerPadding: "144px",
      initialSlide: 2,
    },
  },
  {
    breakpoint: 5000,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "144px",
      initialSlide: 2,
    },
  },
  { breakpoint: 10000, settings: "unslick" },
];

const StorySlide = ({ text, name, session }) => {
  return (
    <div
      //id="slide-1"
      className="slide tbk-h-120 tbk-px-1 tbk-pt-3 tbk-pb-5 lg:tbk-h-132 lg:tbk-pb-6 3xl:tbk-h-144"
    >
      <div className="content slide-bg js-progressive-image tbk-relative tbk-h-full tbk-w-full tbk-rounded-2xl tbk-bg-grey-light tbk-px-2 tbk-pt-2 tbk-pb-2 lg:tbk-px-3 lg:tbk-pt-3 lg:tbk-pb-3">
        <QuoteMark
          className="tbk-absolute"
          style={{
            top: "-14px",
            left: "14px",
          }}
        />
        <a>
          <div className="tbk-relative tbk-flex tbk-h-full tbk-flex-col tbk-justify-between tbk-rounded-lg">
            <div className="tbk-text-secondary lg:tbk-text-main tbk-mb-1">
              {text}
            </div>
            <div>
              <div className="tbk-text-main">{name}</div>
              <div className="tbk-text-small tbk-text-blue-grey">{session}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const HomeStoriesCarousel = () => {
  return (
    <Slider
      className="home-stories-carousel tbk-overflow-hidden"
      dots
      dotsClass={
        "slick-dots tbk-left-1/2 tbk--translate-x-1/2 tbk-whitespace-nowrap"
      }
      infinite={true}
      initialSlide={1}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      nextArrow={<SliderNextButton buttonClassName="tbk-h-9 tbk-w-9" />}
      prevArrow={<SliderPrevButton buttonClassName="tbk-h-9 tbk-w-9" />}
      responsive={responsiveSettings}
    >
      <StorySlide
        text="I'm still a beginner to yoga, but even though I don't know
                the names of all the poses and movements yet, I was still
                able to follow along easily by the guidance of my
                instructor. I worked up a sweat, but never felt overwhelmed.
                My coach was really kind and Welcoming"
        name="Andrea W."
        session="Yoga"
      />
      <StorySlide
        text="This has been a phenomenal journey. My trainer has helped
                push me to be in the best shape of my life. The focus on
                results is phenomenal and so different from any other
                trainer experience I've had before. Excited to keep pushing
                and see even more results."
        name="Brandon S."
        session="Personal Training"
      />
      <StorySlide
        text="No wonder I wasn't seeing results in the programs I've done
                before this, I realized how little I knew! With everything
                I'm learning with my coach, I'm confident that I'll see a
                great transformation through the program and that I will be
                able to carry these habits and learnings beyond the program,
                which was my main motivator."
        name="Jose H."
        session="Personal Training"
      />
      <StorySlide
        text="Thank you so much! At 29 I look better than I looked at 19!
                And it's not only about slim body. I mean my self-confidence
                too."
        name="Mary K."
        session="Pilates"
      />
      <StorySlide
        text="I was surprised with how comfortable I was with talking
                about the things going on in my head that could affect my
                week/progress in the program. I’ve been supported through
                all of it through the last three months and I can’t believe
                how far I’ve come in my fitness journey! I am eternally
                grateful for helping me get to where I am today (I can now
                climb V1/V2 bouldering and 5.10a top-roping!) and I
                definitely can see myself moving toward my goals with the
                solid foundation she set up!"
        name="Jason G."
        //session="Personal Training"
      />
      <StorySlide
        text="I've had a lot of trainers and you’ve been the best.
                Attentive, knowledgeable, and patient, and pushing me to do
                my best. You’ve helped me create a lifestyle, not just
                something I'll give up on in a month."
        name="James C."
        session="Personal Training"
      />
      <StorySlide
        text="I feel so fortunate to have you as my personal trainer, yoga
                teacher and nutrition coach. You have a wealth of experience
                in fitness, exercise, health, and wellness and she is a
                remarkably intuitive instructor and coach. I’m always being
                challenged to achieve more, and you continuously vary our
                routines to keep things interesting. The icing on the cake
                is that you are a truly good person -- You’re friendly,
                empathetic, and genuine. All in all, I give you my highest
                recommendation."
        name="Peter E."
        session="Yoga"
      />
    </Slider>
  );
};

export default HomeStoriesCarousel;
