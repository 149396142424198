import getModifierKey from "./getModifierKey";
import getModifierKeyFull from "./getModifierKeyFull";
import dateOperations from "./dateOperations";
import { availabilityCalendar as calendarConstants } from "../../../constants";

export const addModifier = function (
  modifiers_o,
  date_f,
  key_c,
  props_b,
  state_O
) {
  const {
      numberOfMonths: numberOfMonths_y = 1,
      enableOutsideDays: enableOutsideDays_h,
      orientation: orientation_p,
    } = props_b,
    { currentMonth: currentMonth_D, visibleDays: visibleDays_L } = state_O;
  let currentMonth_R = currentMonth_D,
    counter_j = numberOfMonths_y;

  if (
    orientation_p === calendarConstants.CalendarOrientation.VERTICAL_SCROLLABLE
  ) {
    counter_j = Object.keys(visibleDays_L).length;
  } else {
    // currentMonth_R = currentMonth_R; // TODO: use cache
    counter_j += 2;
  }
  if (
    !date_f ||
    !dateOperations(date_f, currentMonth_R, counter_j, enableOutsideDays_h)
  ) {
    return modifiers_o;
  }

  const key_M = getModifierKeyFull(date_f);
  let newModifiers = {
    ...modifiers_o,
  };

  if (enableOutsideDays_h) {
    const var_t = Object.keys(visibleDays_L).filter(
      (val_t) => Object.keys(visibleDays_L[val_t]).indexOf(key_M) > -1
    );

    newModifiers = var_t.reduce((acc_t, val_n) => {
      const s = modifiers_o[val_n] || visibleDays_L[val_n];

      if (!s[key_M] || !s[key_M].has(key_c)) {
        const newSet_l = new Set(s[key_M]);

        newSet_l.add(key_c);

        acc_t[val_n] = {
          ...s,
          [key_M]: newSet_l,
        };
      }

      return acc_t;
    }, newModifiers);
  } else {
    const key_t = getModifierKey(date_f),
      modifier_n = modifiers_o[key_t] || visibleDays_L[key_t] || {};

    if (!modifier_n[key_M] || !modifier_n[key_M].has(key_c)) {
      const newSet_s = new Set(modifier_n[key_M]);

      newSet_s.add(key_c);

      newModifiers[key_t] = {
        ...modifier_n,
        [key_M]: newSet_s,
      };
    }
  }

  return newModifiers;
};

export const deleteModifier = function (
  modifiers_o,
  date_f,
  arg_c,
  props_b,
  state_O
) {
  const {
      numberOfMonths: numberOfMonths_y = 1,
      enableOutsideDays: enableOutsideDays_h,
      orientation: orientation_p,
    } = props_b,
    { currentMonth: currentMonth_D, visibleDays: visibleDays_L } = state_O;
  let R = currentMonth_D,
    counter_j = numberOfMonths_y;

  if (
    orientation_p === calendarConstants.CalendarOrientation.VERTICAL_SCROLLABLE
  ) {
    counter_j = Object.keys(visibleDays_L).length;
  } else {
    // R = R; // TODO: use cache instead

    counter_j += 2;
  }

  if (!date_f || !dateOperations(date_f, R, counter_j, enableOutsideDays_h)) {
    return modifiers_o;
  }

  const key_M = getModifierKeyFull(date_f);

  let newModifiers = {
    ...modifiers_o,
  };

  if (enableOutsideDays_h) {
    const const_t = Object.keys(visibleDays_L).filter(
      (t) => Object.keys(visibleDays_L[t]).indexOf(key_M) > -1
    );

    newModifiers = const_t.reduce((acc_t, val_n) => {
      const const_s = modifiers_o[val_n] || visibleDays_L[val_n];

      if (const_s[key_M] && const_s[key_M].has(arg_c)) {
        const const_l = new Set(const_s[key_M]);

        const_l.delete(arg_c);

        acc_t[val_n] = {
          ...const_s,
          [key_M]: const_l,
        };
      }
      return acc_t;
    }, newModifiers);
  } else {
    const key_t = getModifierKey(date_f),
      modifier_n = modifiers_o[key_t] || visibleDays_L[key_t] || {};

    if (modifier_n[key_M] && modifier_n[key_M].has(arg_c)) {
      const newSet_s = new Set(modifier_n[key_M]);

      newSet_s.delete(arg_c);

      newModifiers[key_t] = {
        ...modifier_n,
        [key_M]: newSet_s,
      };
    }
  }

  return newModifiers;
};
