import React from "react";

export default function ArrowLeftIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.5L14.5 6L8 12.5L14.5 19L16 17.5L11 12.5L16 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
