import React from "react";

export default function ReMindfulnessIcon({ id, className, size, style }) {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <circle
        cx="16"
        cy="16"
        r="16"
        fill={`url(#${id}_paint0_linear_12669_13681)`}
      />
      <path
        d="M7.98875 11.8944C7.9824 11.4034 8.01729 10.9055 8.09793 10.4044L8.22179 9.63464L9.06328 9.77008C9.94779 9.91242 10.7901 10.1851 11.5693 10.5741C11.2685 11.3177 11.045 12.0928 10.9034 12.8896C9.99862 12.4248 9.01936 12.0858 7.98875 11.8944Z"
        fill={`url(#${id}_paint1_linear_12669_13681)`}
      />
      <path
        d="M26.4305 20.2279C27.187 20.5943 27.8865 21.0863 28.5028 21.6939L29 22.1839L28.5561 22.6343C26.3458 24.8766 23.0976 25.4889 20.3203 24.4616C22.8278 23.82 24.9832 22.2898 26.4305 20.2279Z"
        fill={`url(#${id}_paint2_linear_12669_13681)`}
      />
      <path
        d="M21.0272 12.8945C20.8827 12.1038 20.6581 11.3346 20.3566 10.5968C21.1482 10.1957 22.0058 9.91511 22.9071 9.77008L23.7486 9.63464L23.8725 10.4044C23.9528 10.9035 23.9882 11.3996 23.9823 11.8887C22.9367 12.0797 21.9437 12.4226 21.0272 12.8945Z"
        fill={`url(#${id}_paint3_linear_12669_13681)`}
      />
      <path
        d="M5.0938 14.0969V13.2341H6.03305C7.7485 13.2341 9.35501 13.7079 10.7299 14.5306C10.7248 14.6727 10.7214 14.8151 10.7214 14.958C10.7214 18.0776 11.9363 21.0105 14.1422 23.2164L14.1987 23.273C9.17159 23.234 5.0938 19.1329 5.0938 14.0969Z"
        fill={`url(#${id}_paint4_linear_12669_13681)`}
      />
      <path
        d="M3.497 21.6938C4.10125 21.0982 4.78537 20.6136 5.5249 20.2496C6.98029 22.3123 9.14596 23.8392 11.6631 24.472C10.7968 24.791 9.87086 24.9547 8.91937 24.9486C6.84481 24.9336 4.90019 24.1118 3.44378 22.6343L2.99986 22.1839L3.497 21.6938Z"
        fill={`url(#${id}_paint5_linear_12669_13681)`}
      />
      <path
        d="M15.217 7.77453L15.9404 7.05115L16.7233 7.83399C18.642 9.75276 19.6987 12.3039 19.6987 15.0174C19.6987 17.7309 18.642 20.282 16.7233 22.2008L15.9999 22.9242L15.217 22.1414C13.2983 20.2226 12.2415 17.6715 12.2415 14.958C12.2416 12.2444 13.2983 9.6933 15.217 7.77453Z"
        fill={`url(#${id}_paint6_linear_12669_13681)`}
      />
      <path
        d="M21.2084 14.5315C22.5838 13.7082 24.1912 13.2341 25.9074 13.2341H26.8467V14.0969C26.8467 19.1129 22.8013 23.2011 17.8019 23.2722C20.0055 21.0667 21.2191 18.1354 21.2191 15.0175C21.2191 14.855 21.215 14.693 21.2084 14.5315Z"
        fill={`url(#${id}_paint7_linear_12669_13681)`}
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_12669_13681`}
          x1="21.5"
          y1="0.999999"
          x2="9"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9F6FF" />
          <stop offset="1" stopColor="#E0FFF7" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_12669_13681`}
          x1="9.77868"
          y1="9.63464"
          x2="9.77868"
          y2="12.8896"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_12669_13681`}
          x1="24.6601"
          y1="20.2279"
          x2="24.6601"
          y2="24.9446"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint3_linear_12669_13681`}
          x1="22.1698"
          y1="9.63464"
          x2="22.1698"
          y2="12.8945"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint4_linear_12669_13681`}
          x1="9.64627"
          y1="13.2341"
          x2="9.64627"
          y2="23.273"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint5_linear_12669_13681`}
          x1="7.33147"
          y1="20.2496"
          x2="7.33147"
          y2="24.9487"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint6_linear_12669_13681`}
          x1="15.9701"
          y1="7.05115"
          x2="15.9701"
          y2="22.9242"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint7_linear_12669_13681`}
          x1="22.3243"
          y1="13.2341"
          x2="22.3243"
          y2="23.2722"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="#F26BBC" />
          <stop offset="1" stopColor="#FFB4B4" />
        </linearGradient>
      </defs>
    </svg>
  );
}
