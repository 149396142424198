import React from "react";

export default function CaretRightIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5L9.5 6L16 12.5L9.5 19L8 17.5L13 12.5L8 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
