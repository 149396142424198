/**
 * Based on Date Arithmetic lib from https://github.com/jquense/date-math
 */

const MILI = "milliseconds",
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  DECADE = "decade",
  CENTURY = "century";

const multiplierMilli = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
};

const multiplierMonth = {
  month: 1,
  year: 12,
  decade: 10 * 12,
  century: 100 * 12,
};

function daysOf(year) {
  return [31, daysInFeb(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
}

function daysInFeb(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
}

export function add(d, num, unit) {
  d = new Date(d);

  // eslint-disable-next-line default-case
  switch (unit) {
    case MILI:
    case SECONDS:
    case MINUTES:
    case HOURS:
    case DAY:
    case WEEK:
      return addMillis(d, num * multiplierMilli[unit]);
    case MONTH:
    case YEAR:
    case DECADE:
    case CENTURY:
      return addMonths(d, num * multiplierMonth[unit]);
  }

  throw new TypeError('Invalid units: "' + unit + '"');
}

function addMillis(d, num) {
  var nextDate = new Date(+d + num);

  return solveDST(d, nextDate);
}

function addMonths(d, num) {
  var year = d.getFullYear(),
    month = d.getMonth(),
    day = d.getDate(),
    totalMonths = year * 12 + month + num,
    nextYear = Math.trunc(totalMonths / 12),
    nextMonth = totalMonths % 12,
    nextDay = Math.min(day, daysOf(nextYear)[nextMonth]);

  var nextDate = new Date(d);
  nextDate.setFullYear(nextYear);

  // To avoid a bug when sets the Feb month
  // with a date > 28 or date > 29 (leap year)
  nextDate.setDate(1);

  nextDate.setMonth(nextMonth);
  nextDate.setDate(nextDay);

  return nextDate;
}

function solveDST(currentDate, nextDate) {
  var currentOffset = currentDate.getTimezoneOffset(),
    nextOffset = nextDate.getTimezoneOffset();

  // if is DST, add the difference in minutes
  // else the difference is zero
  var diffMinutes = nextOffset - currentOffset;

  return new Date(+nextDate + diffMinutes * multiplierMilli["minutes"]);
}

export function subtract(d, num, unit) {
  return add(d, -num, unit);
}

export function startOf(d, unit, firstOfWeek) {
  d = new Date(d);

  // eslint-disable-next-line default-case
  switch (unit) {
    case CENTURY:
    case DECADE:
    case YEAR:
      d = month(d, 0);

    // break;
    // eslint-disable-next-line no-fallthrough
    case MONTH:
      d = date(d, 1);

    // break;
    // eslint-disable-next-line no-fallthrough
    case WEEK:
    case DAY:
      d = hours(d, 0);

    // break;
    // eslint-disable-next-line no-fallthrough
    case HOURS:
      d = minutes(d, 0);

    // break;
    // eslint-disable-next-line no-fallthrough
    case MINUTES:
      d = seconds(d, 0);

    // break;
    // eslint-disable-next-line no-fallthrough
    case SECONDS:
      d = milliseconds(d, 0);
  }

  if (unit === DECADE) {
    d = subtract(d, year(d) % 10, "year");
  }

  if (unit === CENTURY) {
    d = subtract(d, year(d) % 100, "year");
  }

  if (unit === WEEK) {
    d = weekday(d, 0, firstOfWeek);
  }

  return d;
}

export function endOf(d, unit, firstOfWeek) {
  d = new Date(d);
  d = startOf(d, unit, firstOfWeek);

  // eslint-disable-next-line default-case
  switch (unit) {
    case CENTURY:
    case DECADE:
    case YEAR:
    case MONTH:
    case WEEK:
      d = add(d, 1, unit);
      d = subtract(d, 1, DAY);
      d.setHours(23, 59, 59, 999);

      break;
    case DAY:
      d.setHours(23, 59, 59, 999);

      break;
    case HOURS:
    case MINUTES:
    case SECONDS:
      d = add(d, 1, unit);
      d = subtract(d, 1, MILI);
  }

  return d;
}

export const eq = createComparer(function (a, b) {
  return a === b;
});
export const neq = createComparer(function (a, b) {
  return a !== b;
});
export const gt = createComparer(function (a, b) {
  return a > b;
});
export const gte = createComparer(function (a, b) {
  return a >= b;
});
export const lt = createComparer(function (a, b) {
  return a < b;
});
export const lte = createComparer(function (a, b) {
  return a <= b;
});

export function min() {
  return new Date(Math.min.apply(Math, arguments));
}

export function max() {
  return new Date(Math.max.apply(Math, arguments));
}

// eslint-disable-next-line
export function inRange(day, min, max, unit) {
  unit = unit || "day";

  return (!min || gte(day, min, unit)) && (!max || lte(day, max, unit));
}

export const milliseconds = (d, val) => {
  var hourLength = 3600000;

  if (val === undefined) {
    return d.getMilliseconds();
  }

  var dateOut = new Date(d);

  dateOut.setMilliseconds(val);

  if (
    dateOut.getMilliseconds() !== val &&
    val >= hourLength &&
    dateOut.getHours() - d.getHours() < Math.floor(val / hourLength)
  ) {
    // Skip DST hour, if it occurs
    dateOut.setMilliseconds(val + hourLength);
  }

  return dateOut;
};

export const seconds = (d, val) => {
  const hourLength = 3600;

  if (val === undefined) {
    return d.getSeconds();
  }

  var dateOut = new Date(d);

  dateOut.setSeconds(val);

  if (
    dateOut.getSeconds() !== val &&
    val >= hourLength &&
    dateOut.getHours() - d.getHours() < Math.floor(val / hourLength)
  ) {
    // Skip DST hour, if it occurs
    dateOut.setSeconds(val + hourLength);
  }

  return dateOut;
};

export const minutes = (d, val) => {
  const hourLength = 60;

  if (val === undefined) {
    return d.getMinutes();
  }

  var dateOut = new Date(d);

  dateOut.setMinutes(val);

  if (
    dateOut.getMinutes() !== val &&
    val >= hourLength &&
    dateOut.getHours() - d.getHours() < Math.floor(val / hourLength)
  ) {
    // Skip DST hour, if it occurs
    dateOut.setMinutes(val + hourLength);
  }

  return dateOut;
};

export const hours = (d, val) => {
  const hourLength = 1;

  if (val === undefined) {
    return d.getHours();
  }

  var dateOut = new Date(d);

  dateOut.setHours(val);

  if (dateOut.getHours() !== val) {
    // Skip DST hour, if it occurs
    dateOut.setHours(val + hourLength);
  }

  return dateOut;
};

export const day = (d, val) => {
  if (val === undefined) {
    return d.getDay();
  }

  var dateOut = new Date(d);

  dateOut.setDay(val);

  return dateOut;
};

export const date = (d, val) => {
  if (val === undefined) {
    return d.getDate();
  }

  var dateOut = new Date(d);

  dateOut.setDate(val);

  return dateOut;
};

export const month = (d, val) => {
  if (val === undefined) {
    return d.getMonth();
  }

  var dateOut = new Date(d);

  dateOut.setMonth(val);

  return dateOut;
};

export const year = (d, val) => {
  if (val === undefined) {
    return d.getFullYear();
  }

  var dateOut = new Date(d);

  dateOut.setFullYear(val);

  return dateOut;
};

export function decade(d, val) {
  return val === undefined ? year(startOf(d, DECADE)) : add(d, val + 10, YEAR);
}

export function century(d, val) {
  return val === undefined
    ? year(startOf(d, CENTURY))
    : add(d, val + 100, YEAR);
}

export function weekday(d, val, firstDay) {
  var w = (day(d) + 7 - (firstDay || 0)) % 7;

  return val === undefined ? w : add(d, val - w, DAY);
}

export function diff(date1, date2, unit, asFloat) {
  var dividend, divisor, result;

  switch (unit) {
    case MILI:
    case SECONDS:
    case MINUTES:
    case HOURS:
    case DAY:
    case WEEK:
      dividend = date2.getTime() - date1.getTime();

      break;
    case MONTH:
    case YEAR:
    case DECADE:
    case CENTURY:
      dividend = (year(date2) - year(date1)) * 12 + month(date2) - month(date1);

      break;
    default:
      throw new TypeError('Invalid units: "' + unit + '"');
  }

  switch (unit) {
    case MILI:
      divisor = 1;
      break;
    case SECONDS:
      divisor = 1000;
      break;
    case MINUTES:
      divisor = 1000 * 60;
      break;
    case HOURS:
      divisor = 1000 * 60 * 60;
      break;
    case DAY:
      divisor = 1000 * 60 * 60 * 24;
      break;
    case WEEK:
      divisor = 1000 * 60 * 60 * 24 * 7;
      break;
    case MONTH:
      divisor = 1;
      break;
    case YEAR:
      divisor = 12;
      break;
    case DECADE:
      divisor = 120;
      break;
    case CENTURY:
      divisor = 1200;
      break;
    default:
      throw new TypeError('Invalid units: "' + unit + '"');
  }

  result = dividend / divisor;

  return asFloat ? result : Math.round(result);
}

// function createAccessor(method){
//   var hourLength = (function(method) {
//     switch(method) {
//       case 'Milliseconds':
//         return 3600000;
//       case 'Seconds':
//         return 3600;
//       case 'Minutes':
//         return 60;
//       case 'Hours':
//         return 1;
//       default:
//         return null;
//     }
//   })(method);

//   return function(d, val){
//     if (val === undefined)
//       return d['get' + method]()

//     var dateOut = new Date(d)
//     dateOut['set' + method](val)

//     if(hourLength && dateOut['get'+method]() != val && (method === 'Hours' || val >=hourLength && (dateOut.getHours()-d.getHours()<Math.floor(val/hourLength))) ){
//       //Skip DST hour, if it occurs
//       dateOut['set'+method](val+hourLength);
//     }

//     return dateOut
//   }
// }

function createComparer(operator) {
  return function (a, b, unit) {
    return operator(+startOf(a, unit), +startOf(b, unit));
  };
}

// eslint-disable-next-line
export const monthsInYear = (year) => {
  // eslint-disable-next-line
  var date = new Date(year, 0, 1);

  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (i) {
    return month(date, i);
  });
};

// eslint-disable-next-line
export const merge = (date, time, defaultDate) => {
  if (time == null && date == null) {
    return null;
  }

  if (time == null) {
    time = defaultDate || new Date();
  }

  if (date == null) {
    date = defaultDate || new Date();
  }

  date = startOf(date, "day");
  date = hours(date, hours(time));
  date = minutes(date, minutes(time));
  date = seconds(date, seconds(time));

  return milliseconds(date, milliseconds(time));
};

export const today = () => {
  return startOf(new Date(), "day");
};

export const tomorrow = () => {
  return add(startOf(new Date(), "day"), 1, "day");
};

// var _default = dates;
// exports.default = _default;
// module.exports = exports["default"];
