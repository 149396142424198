import React from "react";

// Icons
import UserIcon from "../../../icons/UserIcon";

export const TrainerCardPlaceholder = ({ className }) => (
  <div
    className={`${
      className ? `${className} ` : ""
    }tbk-relative tbk-m-1 tbk-flex tbk-flex-col tbk-overflow-hidden tbk-rounded-2xl tbk-border tbk-border-blue-grey-light tbk-bg-basic-white md:tbk-m-0 md:tbk-h-52`}
  >
    <div className="tbk-mx-3 tbk-mb-0 tbk-hidden !tbk-hidden tbk-items-center md:tbk-flex">
      <div className="tbk-mr-1 tbk-h-4 tbk-w-44 tbk-rounded-b-lg tbk-border-l tbk-border-r tbk-border-b tbk-border-solid tbk-border-blue-grey-light tbk-bg-basic-white" />
      <div className="tbk-mr-1 tbk-h-4 tbk-w-44 tbk-rounded-b-lg tbk-border-l tbk-border-r tbk-border-b tbk-border-solid tbk-border-blue-grey-light tbk-bg-basic-white" />
      <div className="tbk-mr-1 tbk-h-4 tbk-w-44 tbk-rounded-b-lg tbk-border-l tbk-border-r tbk-border-b tbk-border-solid tbk-border-blue-grey-light tbk-bg-basic-white" />
    </div>
    <div className="tbk-flex tbk-flex-col tbk-pt-2 md:tbk-flex-row md:tbk-pt-0">
      <div className="tb-profile-picture-placeholder md:tbk-self- tbk--mr-2 tbk--ml-1 tbk-flex tbk-h-85 tbk-w-full tbk-shrink-0 tbk-grow-0 tbk-basis-40 tbk-items-center tbk-justify-center tbk-self-center tbk-rounded-l-2xl md:tbk-m-0 md:tbk-mr-0 md:tbk-h-52 md:tbk-w-52 md:tbk-basis-52">
        <UserIcon
          // size={208}
          size="100%"
          className="tbk-h-full tbk-object-cover tbk-text-grey-light"
        />
      </div>
      <div className="tbk-items-between tbk-flex tbk-min-w-0 tbk-grow tbk-flex-col">
        <div className="tbk-w-full tbk-min-w-0 tbk-p-2 tbk-pl-2 md:tbk-p-2 md:tbk-pb-3 md:tbk-pr-3">
          <div className="tb-trainer-card-title-placeholder tbk-mb-0.5 tbk-mr-4 tbk-box-content tbk-h-3 tbk-rounded-lg tbk-bg-grey-inactive tbk-pt-0.5 md:tbk-mb-2 md:tbk-mr-0 md:tbk-w-1/2">
            <div className="content-placeholder-background" />
          </div>
          <div className="tb-trainer-card-text-placeholder tbk-mb-1 tbk-mb-1.5 tbk-box-content tbk-h-3 tbk-rounded-lg tbk-bg-grey-light tbk-pt-0.5 md:tbk-mr-8 md:tbk-mt-0 md:tbk-mb-1 md:tbk-w-[82.653061%] md:tbk-pr-5" />
          <div className="tb-trainer-card-text-placeholder tbk-mb-1_ second-line tbk-mr-4 tbk-box-content tbk-h-3 tbk-rounded-lg tbk-bg-grey-light tbk-pt-0.5 md:tbk-mb-2 md:tbk-mr-0 md:tbk-w-[82.653061%]" />
          <div className="tb-trainer-card-placeholder-ctas tbk-space-between tbk-hidden md:tbk-flex">
            <div className="tb-time-slots-button-placeholder tbk-mr-8 tbk-box-content tbk-h-5 tbk-w-34 tbk-grow tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white tbk-pr-1.5" />
            <div className="tb-cta-placeholder tbk-h-5 tbk-w-34 tbk-grow tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white" />
          </div>
        </div>
      </div>
    </div>
    <div
      className="tb-trainer-card-placeholder-footer-cta tbk-grid tbk-h-6 tbk-grid-cols-2 tbk-rounded-lg tbk-bg-basic-white tbk-p-1 tbk-shadow-trube md:tbk-hidden"
      style={{
        gap: "10px",
      }}
    >
      <div className="tb-time-slots-button-placeholder tbk-h-5 tbk-w-full tbk-grow tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white" />
      <div className="tb-cta-placeholder tbk-h-5 tbk-w-full tbk-grow tbk-rounded-lg tbk-border tbk-border-blue-grey-light tbk-bg-basic-white" />
    </div>
    <div className="tbk-absolute tbk-top-0 tbk-left-0 tbk-right-0 tbk-bottom-0 tbk-animate-placeholder-shimmer tbk-bg-coach-card-placeholder" />
  </div>
);

export default TrainerCardPlaceholder;
