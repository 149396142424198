/* global google */
import React from "react";
import PropTypes from "prop-types";

import { contextTypes as googleMapsContextTypes } from "../GoogleMaps";

import { has, lowerFirst, isFunction } from "../../../utils/_";

const propTypes = {
  /**
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapTypeRegistry
   * @type Array<[id:string, mapType:MapType|*]>
   */
  defaultExtraMapTypes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),

  /**
   * @type LatLng|LatLngLiteral
   */
  defaultCenter: PropTypes.any,

  /**
   * @type boolean
   */
  defaultClickableIcons: PropTypes.bool,

  /**
   * @type number
   */
  defaultHeading: PropTypes.number,

  /**
   * @type MapTypeId|string
   */
  defaultMapTypeId: PropTypes.any,

  /**
   * @type MapOptions
   */
  defaultOptions: PropTypes.any,

  /**
   * @type StreetViewPanorama
   */
  defaultStreetView: PropTypes.any,

  /**
   * @type number
   */
  defaultTilt: PropTypes.number,

  /**
   * @type number
   */
  defaultZoom: PropTypes.number,

  /**
   * @type LatLng|LatLngLiteral
   */
  center: PropTypes.any,

  /**
   * @type boolean
   */
  clickableIcons: PropTypes.bool,

  /**
   * @type number
   */
  heading: PropTypes.number,

  /**
   * @type MapTypeId|string
   */
  mapTypeId: PropTypes.any,

  /**
   * @type MapOptions
   */
  options: PropTypes.any,

  /**
   * @type StreetViewPanorama
   */
  streetView: PropTypes.any,

  /**
   * @type number
   */
  tilt: PropTypes.number,

  /**
   * @type number
   */
  zoom: PropTypes.number,

  /**
   * function
   */
  onDblClick: PropTypes.func,

  /**
   * function
   */
  onDragEnd: PropTypes.func,

  /**
   * function
   */
  onDragStart: PropTypes.func,

  /**
   * function
   */
  onMapTypeIdChanged: PropTypes.func,

  /**
   * function
   */
  onMouseMove: PropTypes.func,

  /**
   * function
   */
  onMouseOut: PropTypes.func,

  /**
   * function
   */
  onMouseOver: PropTypes.func,

  /**
   * function
   */
  onRightClick: PropTypes.func,

  /**
   * function
   */
  onTilesLoaded: PropTypes.func,

  /**
   * function
   */
  onBoundsChanged: PropTypes.func,

  /**
   * function
   */
  onCenterChanged: PropTypes.func,

  /**
   * function
   */
  onClick: PropTypes.func,

  /**
   * function
   */
  onDrag: PropTypes.func,

  /**
   * function
   */
  onHeadingChanged: PropTypes.func,

  /**
   * function
   */
  onIdle: PropTypes.func,

  /**
   * function
   */
  onProjectionChanged: PropTypes.func,

  /**
   * function
   */
  onResize: PropTypes.func,

  /**
   * function
   */
  onTiltChanged: PropTypes.func,

  /**
   * function
   */
  onZoomChanged: PropTypes.func,
};

class GoogleMap extends React.PureComponent {
  static propTypes = propTypes;

  static contextTypes = {
    ...googleMapsContextTypes,
    // map: PropTypes.object,
  };

  /**
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
   * @public
   */
  fitBounds(...args) {
    return this.state.map.fitBounds(...args);
  }

  /**
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
   * @public
   */
  panBy(...args) {
    return this.state.map.panBy(...args);
  }

  /**
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
   * @public
   */
  panTo(...args) {
    return this.state.map.panTo(...args);
  }

  /**
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
   * @public
   */
  panToBounds(...args) {
    return this.state.map.panToBounds(...args);
  }

  applyUpdaterToNextProps(prevProps, nextProps, instance) {
    Object.keys(updaterMap).forEach((key) => {
      const fn = updaterMap[key];
      const nextValue = nextProps[key];

      if (nextValue !== prevProps[key]) {
        fn(instance, nextValue);
      }
    });
  }

  /*
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
   */
  constructor(props, context) {
    super(props, context);

    if (!this.context.map) {
      throw new Error(
        "Did you wrap <GoogleMap> component with GoogleMaps() HOC?"
      );
    }

    // eslint-disable-next-line
    const { nextProps } = Object.keys(propTypes).reduce(
      (acc, value /*, key*/) => {
        const key = value;

        if (has(acc.prevProps, key)) {
          const match = key.match(/^default(\S+)/);

          if (match) {
            const unprefixedKey = lowerFirst(match[1]);

            if (!has(acc.nextProps, unprefixedKey)) {
              acc.nextProps[unprefixedKey] = acc.prevProps[key];
            }
          } else {
            acc.nextProps[key] = acc.prevProps[key];
          }
        }

        return acc;
      },
      {
        nextProps: {},
        prevProps: this.props,
      }
    );

    this.applyUpdaterToNextProps(
      {
        /* empty prevProps for construct */
      },
      nextProps,
      this.context.map
    );

    this.registerEvents = this.registerEvents.bind(this);
  }

  componentDidMount() {
    this.registerEvents();
  }

  componentDidUpdate(prevProps) {
    this.unregisterAllEvents();

    this.applyUpdaterToNextProps(prevProps, this.props, this.context.map);

    this.registerEvents();
  }

  componentWillUnmount() {
    this.unregisterAllEvents();
  }

  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }

  registerEvents(instance) {
    const registeredList = Object.keys(eventMap).reduce((acc, value) => {
      const googleEventName = eventMap[value];
      const onEventName = value;

      if (isFunction(this.props[onEventName])) {
        acc.push(
          google.maps.event.addListener(
            this.context.map,
            googleEventName,
            this.props[onEventName]
          )
        );
      }
      return acc;
    }, []);

    this.unregisterAllEvents = () => {
      registeredList.forEach(unregisterEvent);
    };
  }

  /**
   * Returns the lat/lng bounds of the current viewport. If more than one copy of the world is visible, the bounds range in longitude from -180 to 180 degrees inclusive. If the map is not yet initialized (i.e. the mapType is still null), or center and zoom have not been set then the result is `null` or `undefined`.
   * @type LatLngBoundsnullundefined
   * @public
   */
  getBounds() {
    return this.context.map.getBounds();
  }

  /**
   * Returns the position displayed at the center of the map. Note that this `LatLng` object is _not_ wrapped. See `[LatLng](#LatLng)` for more information.
   * @type LatLngLatLngLatLng
   * @public
   */
  getCenter() {
    return this.context.map.getCenter();
  }

  /**
   * Returns the clickability of the map icons. A map icon represents a point of interest, also known as a POI. If the returned value is true, then the icons are clickable on the map.
   * @type boolean
   * @public
   */
  getClickableIcons() {
    return this.context.map.getClickableIcons();
  }

  /**
   *
   * @type Element
   * @public
   */
  getDiv() {
    return this.context.map.getDiv();
  }

  /**
   * Returns the compass heading of aerial imagery. The heading value is measured in degrees (clockwise) from cardinal direction North.
   * @type number
   * @public
   */
  getHeading() {
    return this.context.map.getHeading();
  }

  /**
   *
   * @type MapTypeId|string
   * @public
   */
  getMapTypeId() {
    return this.context.map.getMapTypeId();
  }

  /**
   * Returns the current `Projection`. If the map is not yet initialized (i.e. the mapType is still null) then the result is null. Listen to `projection_changed` and check its value to ensure it is not null.
   * @type ProjectionProjectionprojection_changed
   * @public
   */
  getProjection() {
    return this.context.map.getProjection();
  }

  /**
   * Returns the default `StreetViewPanorama` bound to the map, which may be a default panorama embedded within the map, or the panorama set using `setStreetView()`. Changes to the map's `streetViewControl` will be reflected in the display of such a bound panorama.
   * @type StreetViewPanoramaStreetViewPanoramasetStreetView()streetViewControl
   * @public
   */
  getStreetView() {
    return this.context.map.getStreetView();
  }

  /**
   * Returns the current angle of incidence of the map, in degrees from the viewport plane to the map plane. The result will be `0` for imagery taken directly overhead or `45` for 45° imagery. 45° imagery is only available for `satellite` and `hybrid` map types, within some locations, and at some zoom levels. **Note:** This method does not return the value set by `setTilt`. See `setTilt` for details.
   * @type number045satellitehybridsetTiltsetTilt
   * @public
   */
  getTilt() {
    return this.context.map.getTilt();
  }

  /**
   *
   * @type number
   * @public
   */
  getZoom() {
    return this.context.map.getZoom();
  }
}

export default GoogleMap;

const eventMap = {
  onDblClick: "dblclick",
  onDragEnd: "dragend",
  onDragStart: "dragstart",
  onMapTypeIdChanged: "maptypeid_changed",
  onMouseMove: "mousemove",
  onMouseOut: "mouseout",
  onMouseOver: "mouseover",
  onRightClick: "rightclick",
  onTilesLoaded: "tilesloaded",
  onBoundsChanged: "bounds_changed",
  onCenterChanged: "center_changed",
  onClick: "click",
  onDrag: "drag",
  onHeadingChanged: "heading_changed",
  onIdle: "idle",
  onProjectionChanged: "projection_changed",
  onResize: "resize",
  onTiltChanged: "tilt_changed",
  onZoomChanged: "zoom_changed",
};

const updaterMap = {
  extraMapTypes(instance, extra) {
    extra.forEach((it) => instance.mapTypes.set(...it));
  },

  center(instance, center) {
    instance.setCenter(center);
  },

  clickableIcons(instance, clickableIcons) {
    instance.setClickableIcons(clickableIcons);
  },

  heading(instance, heading) {
    instance.setHeading(heading);
  },

  mapTypeId(instance, mapTypeId) {
    instance.setMapTypeId(mapTypeId);
  },

  options(instance, options) {
    instance.setOptions(options);
  },

  streetView(instance, streetView) {
    instance.setStreetView(streetView);
  },

  tilt(instance, tilt) {
    instance.setTilt(tilt);
  },

  zoom(instance, zoom) {
    instance.setZoom(zoom);
  },
};

function unregisterEvent(registered) {
  google.maps.event.removeListener(registered);
}
