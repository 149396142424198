import React from "react";

export default function OneOnOneSessionPriceIcon({ className, width, height }) {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8496 4.33754C16.8496 3.87493 16.4693 3.5 16 3.5C15.5307 3.5 15.1504 3.87493 15.1504 4.33754V7.92589C15.1504 8.3885 15.5307 8.76343 16 8.76343C16.4693 8.76343 16.8496 8.3885 16.8496 7.92589V4.33754ZM11.7592 9.4153C11.9249 9.57866 12.1424 9.66067 12.3599 9.66067C12.5772 9.66067 12.7947 9.57888 12.9606 9.4153C13.2923 9.08813 13.2923 8.55791 12.9606 8.23074L11.1403 6.43657C10.8087 6.1094 10.2708 6.1094 9.93895 6.43657C9.60707 6.76373 9.60707 7.29396 9.93895 7.62112L11.7592 9.4153ZM20.2407 9.41508C20.075 9.57866 19.8575 9.66045 19.64 9.66045C19.4228 9.66045 19.2053 9.57866 19.0393 9.41508C18.7075 9.08813 18.7075 8.55791 19.0393 8.23074L20.8594 6.43657C21.1912 6.1094 21.7291 6.1094 22.061 6.43657C22.3926 6.76351 22.3926 7.29396 22.061 7.62091L20.2407 9.41508ZM13.5421 11.5914L13.5169 11.6122L9.84365 14.7163C9.50336 15.0038 9.06838 15.1623 8.61968 15.1623H5.98877C3.50964 15.1623 1.5 17.14 1.5 19.5874V27.6625C1.5 28.1249 1.88033 28.5 2.34961 28.5H9.6297C10.0992 28.5 10.4793 28.1246 10.4793 27.6625V19.9736L14.8026 16.3205C15.0234 16.134 15.1504 15.8616 15.1504 15.575V12.3594C15.1504 11.5415 14.1866 11.0763 13.5421 11.5914ZM23.3803 15.1623H26.0112C28.4904 15.1623 30.5 17.14 30.5 19.5874V27.6622C30.5 28.1248 30.1197 28.4998 29.6504 28.4998H22.3703C21.9008 28.4998 21.5207 28.1246 21.5207 27.6622V19.9736L17.1974 16.3205C16.9766 16.134 16.8496 15.8616 16.8496 15.575V12.3594C16.8496 11.5415 17.8134 11.0763 18.4579 11.5914L18.4831 11.6122L22.1563 14.7161C22.4966 15.0038 22.9316 15.1623 23.3803 15.1623ZM9.56924 11.6333C9.56924 9.68754 7.96339 8.10449 5.98959 8.10449C4.0158 8.10449 2.40995 9.68754 2.40995 11.6333C2.40995 13.5793 4.0158 15.1623 5.98959 15.1623C7.96339 15.1623 9.56924 13.5793 9.56924 11.6333ZM26.0103 8.10449C27.9841 8.10449 29.5899 9.68754 29.5899 11.6333C29.5899 13.5793 27.9841 15.1623 26.0103 15.1623C24.0365 15.1623 22.4306 13.5793 22.4306 11.6333C22.4306 9.68754 24.0365 8.10449 26.0103 8.10449Z"
        fill="currentColor"
      />
    </svg>
  );
}
