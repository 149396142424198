import React, { useRef } from "react";
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from "react-transition-group/Transition";

// Components
import Button from "../Button/Button";

// Icons
import CloseIcon from "../../icons/CloseIcon";

const transitionClasses = {
  [ENTERED]: "update-available-notification-transition-entered",
  [ENTERING]: "update-available-notification-transition-entering",
  [EXITING]: "update-available-notification-transition-exiting",
  [EXITED]: "update-available-notification-transition-exited",
};

export default function UpdateAvailableNotification({
  open,
  onClick,
  onClose,
}) {
  const updateAvailableNotificationRef = useRef(null);

  // if (!open) {
  //   // return null;
  // }

  return (
    <Transition
      nodeRef={updateAvailableNotificationRef}
      in={open}
      timeout={300}
      // addEndListener={this.handleTransitionEnd}
    >
      {(status, innerProps) => (
        <div
          // className="modal-cookies md:tbk-p-4_ "
          ref={updateAvailableNotificationRef}
          className={`tbk-fixed tbk-right-2 tbk-top-8 tbk-z-1005 tbk-w-72 tbk-rounded-2xl tbk-bg-basic-white tbk-p-2 tbk-shadow-trube tbk-transition-transform tbk-duration-300 tbk-ease-out ${
            status === ENTERING || status === EXITED
              ? "tbk-pointer-events-none tbk--translate-y-full tbk-opacity-0"
              : status === EXITING
              ? "tbk-pointer-events-none tbk--translate-y-full tbk-opacity-0"
              : "tbk-translate-y-0 tbk-opacity-100"
          } ${transitionClasses[status]}`}
        >
          <button
            onClick={onClose}
            className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-text-coral"
          >
            <CloseIcon />
          </button>
          <div className="tbk-text-secondary tbk-mb-2 tbk-text-primary">
            A new version is available!
          </div>
          <Button size="md" className="tbk-w-full" onClick={onClick}>
            Reload
          </Button>
        </div>
      )}
    </Transition>
  );
}
