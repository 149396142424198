import React from "react";

export default function CreditCardIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : null}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.47093 4.00176L21.3246 4.00005C22.2549 4.00005 22.5923 4.09691 22.9324 4.27879C23.2725 4.46068 23.5394 4.72759 23.7213 5.06769C23.9031 5.40778 24 5.74513 24 6.67541V16.9769C24 17.9071 23.9031 18.2445 23.7213 18.5846C23.5394 18.9247 23.2725 19.1916 22.9324 19.3735C22.5923 19.5554 22.2549 19.6522 21.3246 19.6522H2.67536C1.74508 19.6522 1.40774 19.5554 1.06764 19.3735C0.727543 19.1916 0.460633 18.9247 0.278747 18.5846C0.110853 18.2706 0.0154049 17.9591 0.00171405 17.1813L0 6.67541C0 5.74513 0.0968615 5.40778 0.278747 5.06769C0.460633 4.72759 0.727543 4.46068 1.06764 4.27879C1.38157 4.1109 1.69316 4.01545 2.47093 4.00176ZM1.04348 11.3044L1.04451 17.1537L1.05478 17.4723C1.07364 17.7911 1.11772 17.9407 1.1989 18.0925C1.28353 18.2507 1.40149 18.3687 1.55974 18.4533C1.7549 18.5577 1.94646 18.6007 2.49855 18.6077H21.5014L21.8201 18.5974C22.1389 18.5786 22.2885 18.5345 22.4403 18.4533C22.5985 18.3687 22.7165 18.2507 22.8011 18.0925C22.9055 17.8973 22.9485 17.7058 22.9555 17.1537L22.9555 11.3044H1.04348ZM1.04348 8.17395H22.9555L22.9555 6.49858L22.9452 6.17994C22.9264 5.86118 22.8823 5.71157 22.8011 5.55978C22.7165 5.40153 22.5985 5.28357 22.4403 5.19893C22.2451 5.09456 22.0535 5.05151 21.5014 5.04454H2.49855L2.1799 5.05481C1.86114 5.07367 1.71153 5.11776 1.55974 5.19893C1.40149 5.28357 1.28353 5.40153 1.1989 5.55978C1.09453 5.75493 1.05148 5.94649 1.04451 6.49858L1.04348 8.17395Z"
        fill="currentColor"
      />
    </svg>
  );
}
