import React from "react";

export default function WeightIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-shrink-0${className ? ` ${className}` : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.001 14.002L5 13.001L6.001 12L8.5 14.499L14.499 8.5L12 6.001L13.001 5L14.002 6.001L15.003 5L16.501 6.498L17.502 5.497L18.503 6.498L17.502 7.499L19 8.997L17.999 9.998L19 10.999L17.999 12L15.5 9.501L9.501 15.5L12 17.999L10.999 19L9.998 17.999L8.997 19L7.499 17.502L6.498 18.503L5.497 17.502L6.498 16.501L5 15.003L6.001 14.002Z"
        fill="currentColor"
      />
    </svg>
  );
}
