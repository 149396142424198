/**
 * Hook for formatting currency values based on a given region.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/10/01
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import { useMemo } from "react";

const RegionCurrencySymbol = {
  GB: { symbol: "£", currencyCode: "GBP" },
  US: { symbol: "$", currencyCode: "USD" },
  FR: { symbol: "€", currencyCode: "EUR" },
  // Add more regions and their corresponding currency symbols and codes
};
const useCurrencyFormat = (region) => {
  return useMemo(() => {
    const { symbol, currencyCode } = RegionCurrencySymbol[region];
    return (amount) => {
      const formattedAmount = amount.toLocaleString(`en-${region}`, {
        style: "currency",
        currency: currencyCode,
      });
      // return `${symbol}${formattedAmount}`;
      return `${formattedAmount.replace(/\.00$/, "")}`;
    };
  }, [region]);
};

export default useCurrencyFormat;
