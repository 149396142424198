import React from "react";

export default function ChooseYourRegionImage({ className }) {
  return (
    <svg
      width="170"
      height="171"
      viewBox="0 0 170 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <mask
        id="mask0_9304_3769"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="170"
        height="171"
      >
        <path
          d="M168.5 169V2H1.5V169H168.5Z"
          fill="white"
          stroke="white"
          strokeWidth="3"
        />
      </mask>
      <g mask="url(#mask0_9304_3769)">
        <path
          d="M121.121 116.892C121.121 122.413 116.614 126.889 111.055 126.889C105.495 126.889 100.989 122.413 100.989 116.892C100.989 111.37 105.495 106.895 111.055 106.895C116.614 106.895 121.121 111.37 121.121 116.892Z"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2888 41.1904C25.2888 46.7117 20.7821 51.1875 15.2229 51.1875C9.6634 51.1875 5.15674 46.7117 5.15674 41.1904C5.15674 35.669 9.6634 31.1932 15.2229 31.1932C20.7821 31.1932 25.2888 35.669 25.2888 41.1904Z"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.2986 35.2514C97.2986 40.7728 92.7919 45.2485 87.2327 45.2485C81.6732 45.2485 77.1665 40.7728 77.1665 35.2514C77.1665 29.7301 81.6732 25.2543 87.2327 25.2543C92.7919 25.2543 97.2986 29.7301 97.2986 35.2514Z"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.697 154.744C79.4143 154.81 78.1231 154.843 76.8238 154.843C35.7705 154.843 2.48999 121.79 2.48999 81.0176C2.48999 70.3953 4.7488 60.2972 8.81552 51.171"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.2412 8.24559C68.3312 7.55297 72.535 7.19239 76.8239 7.19239C117.877 7.19239 151.158 40.2451 151.158 81.0175C151.158 117.059 125.154 147.067 90.7629 153.547"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.8496 31.3257C30.6 21.7831 41.8346 14.5272 54.5384 10.5674"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.23943 31.8267C5.65947 24.3656 4.98312 18.2187 7.83627 14.1789C12.6574 7.35263 26.6043 7.88887 45.0281 14.2679"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.084 112.438C91.7911 107.058 80.9377 100.487 70.0367 92.8933C49.411 78.5243 32.3103 63.0663 20.9944 49.3789"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.159 87.2883C164.771 103.258 170.79 117.023 165.736 124.178C160.286 131.895 143.176 130.203 121.12 121.3"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.7903 26.5141C113.218 9.56592 129.52 1.4949 137.551 7.09029C143.633 11.3267 143.91 22.6589 139.445 37.8882"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.4288 153.834C44.7265 164.192 33.1612 168.345 26.7939 163.91C13.6062 154.723 27.7096 112.17 58.2943 68.8653C65.099 59.2311 72.146 50.3708 79.1363 42.5186"
          stroke="#CBCFD5"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
