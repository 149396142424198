/**
 * FAQ page component
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/12
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useRef, useState } from "react";
import { Title, Meta } from "react-meta-elements";

import { useAppState } from "../../state";

// Components
import Button from "../Button/Button";
import AskConciergeModal from "../Homepage/AskConciergeModal/AskConciergeModal";
import HeadlineWithSeparator from "../HeadlineWithSeparator/HeadlineWithSeparator";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

// Hooks
import useCurrencyFormat from "../../hooks/useCurrencyFormat/useCurrencyFormat";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import CaretDownIcon from "../../icons/CaretDownIcon";
import CaretUpIcon from "../../icons/CaretUpIcon";

// Images
import askConcierge from "../Homepage/AskConciergeModal/ask-concierge.svg";

const AccordionItem = ({ className, title, text }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <div className="tbk-mb-2 tbk-border-b tbk-border-solid tbk-border-blue-grey-light">
      <button
        className={`${
          className ? `${className} ` : ""
        }tbk-flex tbk-w-full tbk-items-start tbk-justify-between tbk-pb-1 tbk-text-primary hover:tbk-text-coral`}
        onClick={handleClick}
      >
        <div className="tbk-text-h3-subtitle tbk-grow tbk-text-left">
          {title}
        </div>
        {collapsed ? <CaretDownIcon size={32} /> : <CaretUpIcon />}
      </button>
      <div
        className="tbk-text-main tbk-overflow-hidden tbk-text-left tbk-text-primary tbk-transition-height"
        style={{
          height: collapsed ? "0" : "auto",
          marginBottom: collapsed ? "0" : "24px",
        }}
      >
        {text}
      </div>
    </div>
  );
};

const FAQPage = () => {
  const { user } = useAppState();

  const formatCurrency = useCurrencyFormat(
    user ? user.region : process.env.REACT_APP_REGION
  );

  const [showMoreQuestions, setShowMoreQuestions] = useState(true);
  const [isAskConciergeModalOpen, setIsAskConciergeModalOpen] = useState(false);

  const questionsSectionRef = useRef();

  const handleMoreQuestionsClick = useCallback(() => {
    setShowMoreQuestions(true);
  }, []);

  const membershipPrice = 54;

  return (
    <>
      <ScrollToTopOnMount />
      <Meta
        name="description"
        content={`On-demand, in person, personal training in Los Angeles.`}
      />
      <Header
        behaviour="none"
        isFixed
        //isLoggedIn={isUserLoggedIn}
        //heroRef={heroRef}
      />
      <div />
      <div
        id="faq"
        ref={questionsSectionRef}
        className="tbk-relative tbk-bg-basic-white tbk-pt-4 tbk-pb-7 lg:tbk-pt-9 lg:tbk-pb-0"
      >
        <div className="tbk-container tbk-z-1 tbk-mx-auto tbk-px-2 lg:tbk-px-8 lg:tbk-pb-9">
          <div className="tbk-flex tbk-flex-col-reverse md:tbk-grid md:tbk-grid-cols-homepage-two-cols-layout md:tbk-items-start md:tbk-gap-8">
            <div className="tbk-flex tbk-flex-col tbk-items-center tbk-rounded-2xl tbk-bg-grey-light tbk-p-3">
              <img src={askConcierge} alt="Ask TruBe Concierge" />
              <p className="tbk-text-epic-medium tbk-my-4 tbk-text-center !tbk-leading-none tbk-text-primary">
                We're here
                <br /> to <span className="tbk-text-coral">help</span>
              </p>
              <Button
                size="md"
                className="tbk-w-68 lg:tbk-w-full"
                endIcon={<ArrowRightIcon />}
                onClick={() => setIsAskConciergeModalOpen(true)}
              >
                Ask TruBe Concierge
              </Button>
            </div>

            <div className="tbk-mb-5 tbk-text-center lg:tbk-mb-0">
              <HeadlineWithSeparator
                component="h2"
                textAlign={"left"}
                titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
                className="tbk-mb-4 lg:tbk-mb-6"
                separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
              >
                Lifestyle Membership
              </HeadlineWithSeparator>

              <AccordionItem
                className="tbk-mt-3"
                title={"What is the TruBe Lifestyle membership?"}
                text={`The TruBe Lifestyle membership is a subscription-based service that grants you access to curated wellness classes designed to enhance your overall well-being. For a monthly fee of ${formatCurrency(
                  membershipPrice
                )}, you can attend up to 3 classes per month, each focusing on various TruBe offerings such as HIIT, Mobility, Pilates, Yoga, Meditation, Sound Healing, and more.`}
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"What can I expect from TruBe Lifestyle classes?"}
                text={
                  "TruBe Lifestyle classes present an array of activities guided by leading coaches spanning various wellness modalities. Whether you seek an invigorating HIIT session or serene meditation and sound healing, our classes accommodate all preferences and fitness levels. Held at exclusive venues and led by top TruBe coaches, each class includes pre and post-session brand classes and curated TruBe swag bags."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"How do I sign up for the TruBe Lifestyle membership?"}
                text={
                  "Signing up for the TruBe Lifestyle membership is easy! Simply download the TruBe app, click on the TruBe lifestyle option, and choose the TruBe subscription membership plan. Once subscribed, you'll gain instant access to our complete class calendar and can start RSVPing for your preferred classes right away."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={
                  "Can I attend classes at exclusive venues with the TruBe Lifestyle membership?"
                }
                text={
                  "Absolutely! TruBe Lifestyle classes are held at exclusive venues to provide you with a premium wellness experience. From chic studios to tranquil outdoor settings, each venue is carefully selected to elevate your overall event class."
                }
              />
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"How do I RSVP for TruBe Lifestyle classes?"}
                  text={
                    "To RSVP for TruBe Lifestyle classes, simply browse through our complete class calendar on the TruBe app and select the classes you wish to attend. Once you've made your selections, click on the RSVP button, and your ticket(s) will be automatically added to your Apple Wallet upon confirmation. All classes are subject to availability."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"What if I can't attend all 3 classes in a month?"}
                  text={
                    "We understand that life can get busy, and you may not always be able to attend all 3 classes in a month. That's why your TruBe Lifestyle membership allows you the flexibility to attend up to 3 classes per month, giving you the freedom to prioritize your well-being at your own pace."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={
                    "How can I connect with other members of the TruBe community?"
                  }
                  text={
                    "The TruBe Lifestyle membership not only offers you access to curated wellness classes but also provides you with an opportunity to connect with like-minded individuals who share your passion for health and wellness. Whether it's striking up a conversation with fellow attendees at a class or joining our online community forums, there are plenty of ways to forge meaningful connections within the TruBe community."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"How can I cancel my TruBe Lifestyle membership?"}
                  text={
                    "To cancel your TruBe Lifestyle membership, simply log in to your TruBe account on the app or website and navigate to the subscription settings. From there, you'll find the option to cancel your membership."
                  }
                />
              ) : null}

              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={
                    "What happens if I don’t cancel my RSVP 12 hours before a class?"
                  }
                  text={`If you cancel your RSVP less than 12 hours before a class or fail to show up, you will be charged a ${formatCurrency(
                    10
                  )} penalty.`}
                />
              ) : null}

              {/*{showMoreQuestions ? (*/}
              {/*  <AccordionItem*/}
              {/*    className="tbk-mt-3"*/}
              {/*    title={"Questions?"}*/}
              {/*    text={*/}
              {/*      <p>*/}
              {/*        Email us anytime at{" "}*/}
              {/*        <a href="mailto:members@trubeapp.com">*/}
              {/*          members@trubeapp.com*/}
              {/*        </a>*/}
              {/*        . We’re here to help!*/}
              {/*      </p>*/}
              {/*    }*/}
              {/*  />*/}
              {/*) : null}*/}

              {!showMoreQuestions ? (
                <button
                  className="tbk-text-main tbk-mt-1 tbk-mb-1 tbk-inline-flex tbk-items-center tbk-text-blue-grey tbk-underline lg:tbk-mb-0"
                  onClick={handleMoreQuestionsClick}
                >
                  <div className="">More Questions</div>
                  <CaretDownIcon />
                </button>
              ) : null}
              {/*</div>*/}

              {/*<div className="tbk-mb-5 tbk-text-center lg:tbk-mb-0">*/}
              <HeadlineWithSeparator
                component="h2"
                textAlign={"left"}
                titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
                className="tbk-mt-7 tbk-mb-4 lg:tbk-mb-6"
                separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
              >
                Private Coaching
              </HeadlineWithSeparator>

              <AccordionItem
                title={"What is unique about TruBe Coaches?"}
                text={
                  "Your TruBe coach is a certified professional and expert in their field of fitness, sports, mindfulness and/or body/nutrition. All coaching on TruBe is done in-person at a location designated by the Member and at a time of their choice.  As a Member of TruBe you will be able to explore multiple fitness disciplines that best suit your workout goals. You can read about each TruBe coach (ratings, reviews, accreditation) and book your session."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"How do I book a session with TruBe?"}
                text={
                  <p>
                    TruBe offers two booking platforms to make booking simple
                    and convenient:
                    <ul>
                      <li>
                        TruBe website{" "}
                        <a href="https://us.trubeapp.com">us.trubeapp.com</a>
                      </li>
                      <li>TruBe App available iOS.</li>
                      <li>
                        In order to book a session, you must first become a
                        member of TruBe. All memberships are FREE.
                      </li>
                    </ul>
                  </p>
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"What is the booking process with TruBe?"}
                text={
                  <ul>
                    <li>
                      Members can access the TruBe platform and explore all of
                      our offerings.
                    </li>
                    <li>
                      Select your Discipline of choice —> confirm your location
                      —> date & time of your preferred session —> book the coach
                      of your choice —-> purchase the session.{" "}
                      <b>
                        TruBe is here to make your fitness goals accessible and
                        efficient
                      </b>
                      .
                    </li>
                  </ul>
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"What is TruBe’s cancellation policy?"}
                text={
                  "Members can cancel any session with a 12 hour advanced notification. If you are outside of the cancellation forgiveness time, you will be responsible for the session cost. In certain extenuating circumstances, please reach out to our customer care team and we will work with you to rebook the session to the best of our ability."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"How Long is a TruBe Session?"}
                text={"All TruBe sessions are one hour long."}
              />
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"What if I have an injury or special needs?"}
                  text={
                    <p>
                      If your medical professional has cleared you for exercise,
                      then working with a personal coach, in-person can be
                      highly beneficial for preventing or managing many common
                      chronic conditions and injuries. Your TruBe coach will
                      design safe, effective workouts that meet your specific
                      needs. If you have any further questions, please email us
                      at{" "}
                      <a href="mailto:hello@trubeapp.com">hello@trubeapp.com</a>
                      , and we'll be more than happy to make a recommendation.
                    </p>
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"How do I pay for a session on TruBe?"}
                  text={
                    "TruBe accepts all major forms of payment. As a member, you will have the ability to add in your payment details to your profile. You will only be charged for session when you book and complete your checkout."
                  }
                />
              ) : null}
              {/*{showMoreQuestions ? (*/}
              {/*  <AccordionItem*/}
              {/*    className="tbk-mt-3"*/}
              {/*    title={"How do I book repeat sessions?"}*/}
              {/*    text={*/}
              {/*      "TruBe offers you package deals to book multiple sessions at a given time. Visit the “packages” section in your profile for further information."*/}
              {/*    }*/}
              {/*  />*/}
              {/*) : null}*/}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"Questions?"}
                  text={
                    <p>
                      Email us anytime at{" "}
                      <a href="mailto:members@trubeapp.com">
                        members@trubeapp.com
                      </a>
                      . We’re here to help!
                    </p>
                  }
                />
              ) : null}

              {!showMoreQuestions ? (
                <button
                  className="tbk-text-main tbk-mt-1 tbk-mb-1 tbk-inline-flex tbk-items-center tbk-text-blue-grey tbk-underline lg:tbk-mb-0"
                  onClick={handleMoreQuestionsClick}
                >
                  <div className="">More Questions</div>
                  <CaretDownIcon />
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <AskConciergeModal
        isOpen={isAskConciergeModalOpen}
        onClose={() => setIsAskConciergeModalOpen(false)}
      />
    </>
  );
};

export default FAQPage;
