import React from "react";

export default function QuoteMark({ className, style, width, height }) {
  return (
    <svg
      width={width || 29}
      height={height || 24}
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`tbk-min-w-0 tbk-shrink-0${className ? ` ${className}` : ""}`}
      style={style}
    >
      <path
        d="M12.206 11.4H7.544L10.763 0.299995H5.768L0.44 12.621V23.166H12.206V11.4ZM28.412 11.4H23.75L26.969 0.299995H21.974L16.646 12.621V23.166H28.412V11.4Z"
        fill="currentColor"
      />
    </svg>
  );
}
