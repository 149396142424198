import React from "react";

export default function Partners() {
  return (
    <svg
      width="90"
      height="76"
      viewBox="0 0 90 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tbk-inline-block"
    >
      <path
        d="M86.525 75.05l-21.713.459c.577-1.226.93-2.584.93-4.037 0-.957-.103-9.565-3.703-17.02l-.121-.252c-1.593-3.298-2.968-5.788-5.615-8.05a12.828 12.828 0 01-1.624-4.946c-.477-.666-.874-1.535-1.128-2.64-.483-2.124-.223-4.153.644-5.707-.875-3.069-2.014-7.948.381-11.289a6.29 6.29 0 013.535-2.443c1.866-2.258 5.474-3.545 10.219-3.545 5.537 0 11.822 1.919 12.862 7.302.689 3.564-.681 7.628-1.638 10.164.784 1.528 1.007 3.481.543 5.518-.252 1.104-.648 1.973-1.127 2.639a14.13 14.13 0 01-2.584 6.695v2.975c.476.182.945.36 1.406.534 7.913 2.999 7.637 4.033 9.651 8.2 2.53 5.24 2.56 11.618 2.56 11.843 0 1.987-1.557 3.6-3.478 3.6zm-39.25-46.14c-.318 1.388-.816 2.482-1.418 3.32a18.016 18.016 0 01-1.341 5.056 17.38 17.38 0 01-1.91 3.365v3.742c.599.229 1.19.454 1.769.673 9.954 3.772 11.026 6.267 13.56 11.51 1.591 3.294 2.396 6.949 2.803 9.813.203 1.432.308 2.667.361 3.562.053.896.055 1.45.055 1.521 0 1.25-.489 2.382-1.28 3.201A4.287 4.287 0 0156.78 76H4.378C1.963 76 .004 73.972.004 71.472c0-.282.037-8.306 3.219-14.896 2.53-5.243 3.599-7.736 13.556-11.51l1.773-.673v-4.099a15.646 15.646 0 01-2.73-5.426 17.206 17.206 0 01-.52-2.636 7.9 7.9 0 01-.815-1.454 10.756 10.756 0 01-.604-1.868 12.088 12.088 0 01-.283-1.973c-.075-1.291.072-2.522.421-3.634.175-.556.4-1.083.672-1.571-.688-2.413-1.505-5.715-1.286-8.878.131-1.898.635-3.746 1.765-5.322a7.891 7.891 0 012.924-2.5 8.358 8.358 0 011.523-.574C21.966 1.619 26.505 0 32.473 0c.87 0 1.755.038 2.641.117.885.079 1.77.2 2.641.366 1.741.332 3.424.846 4.928 1.57a13.62 13.62 0 012.111 1.251c1.949 1.415 3.367 3.342 3.858 5.881.866 4.483-.857 9.595-2.06 12.785.986 1.922 1.266 4.378.683 6.94z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
