export default function getAddressComponent(addressComponents, key) {
  var addressComponent = addressComponents.filter((addrComp) =>
    addrComp.types.some((typesItem) => typesItem === key)
  );

  if (addressComponent != null && addressComponent.length > 0) {
    return addressComponent[0];
  }

  return null;
}
