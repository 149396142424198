import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import App from "./App";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import theme from "./theme";

import * as Sentry from "@sentry/react";

const isProd = process.env.NODE_ENV === "production";

Sentry.init({
  dsn: "https://9d25747460f0a4d6cafa76cb34ce094e@o307107.ingest.sentry.io/4506257887526912",
  environment: isProd ? "Production" : "Development",
  release: process.env.REACT_APP_VERSION,
  // integrations: [
  //   new Sentry.BrowserTracing({
  //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   }),
  //   new Sentry.Replay(),
  // ],
  // // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const ReactApp = () => (
  <ThemeProvider theme={theme}>
    <UnsupportedBrowserWarning>
      <App />
    </UnsupportedBrowserWarning>
  </ThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(); we register the sw in ServiceWorkerWrapper

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
