import React from "react";

export default function WorkAndPlay(props) {
  return (
    <svg
      width="184"
      height="45"
      viewBox="0 0 184 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_5_1952)">
        <path
          d="M56.6011 11.1329H58.08L61.5251 21.2746L64.8307 11.0984H65.9505L69.256 21.2746L72.6983 11.1329H74.1231L69.8117 23.3962H68.6719L65.3635 13.528L62.0409 23.3962H60.9011L56.6011 11.1329Z"
          fill="white"
        />
        <path
          d="M75.7959 17.2704V17.2359C75.7959 13.8649 78.2779 10.9373 81.9453 10.9373C85.6127 10.9373 88.0747 13.8448 88.0747 17.2013V17.2359C88.0747 20.5924 85.5785 23.5316 81.9111 23.5316C78.2437 23.5316 75.7959 20.6126 75.7959 17.2704ZM86.6784 17.2704V17.2359C86.6784 14.4694 84.6837 12.2096 81.9253 12.2096C79.1669 12.2096 77.1922 14.4205 77.1922 17.1869V17.2215C77.1922 19.9879 79.1869 22.2477 81.9453 22.2477C84.7037 22.2477 86.6642 20.0225 86.6642 17.2704H86.6784Z"
          fill="white"
        />
        <path
          d="M91.2944 11.1328H96.4778C97.9567 11.1328 99.145 11.5848 99.8973 12.3505C100.202 12.6688 100.441 13.0448 100.601 13.4568C100.761 13.8689 100.838 14.3089 100.829 14.7514V14.7859C100.829 16.801 99.4499 17.987 97.5549 18.3353L101.259 23.3098H99.5867L96.0988 18.5772H92.6537V23.3155H91.2944V11.1328ZM96.3581 17.3422C98.1648 17.3422 99.4556 16.4037 99.4556 14.8377V14.8032C99.4556 13.3062 98.3158 12.4023 96.3752 12.4023H92.6537V17.3422H96.3581Z"
          fill="white"
        />
        <path
          d="M104.089 11.1328H105.449V18.4044L112.37 11.1328H114.126L108.959 16.4555L114.351 23.3155H112.641L108.013 17.4113L105.449 20.0395V23.3155H104.089V11.1328Z"
          fill="white"
        />
        <path
          d="M125.353 17.8116H121.788V16.5593H125.353V12.9753H126.644V16.5593H130.223V17.8116H126.644V21.3956H125.353V17.8116Z"
          fill="white"
        />
        <path
          d="M138.937 11.1328H143.447C146.169 11.1328 147.958 12.5952 147.958 14.9932V15.0277C147.958 17.656 145.79 19.0118 143.225 19.0118H140.299V23.3155H138.937V11.1328ZM143.276 17.7538C145.271 17.7538 146.582 16.6743 146.582 15.0766V15.0421C146.582 13.3005 145.291 12.3966 143.345 12.3966H140.299V17.7596L143.276 17.7538Z"
          fill="white"
        />
        <path
          d="M150.782 11.1328H152.144V22.0402H158.926V23.3155H150.782V11.1328Z"
          fill="white"
        />
        <path
          d="M166.244 11.0466H167.518L173.012 23.3157H171.542L170.137 20.0915H163.583L162.138 23.3157H160.745L166.244 11.0466ZM169.604 18.8393L166.866 12.6472L164.11 18.8393H169.604Z"
          fill="white"
        />
        <path
          d="M177.608 18.4908L172.599 11.1328H174.251L178.315 17.2213L182.413 11.1328H183.997L178.987 18.4735V23.3155H177.608V18.4908Z"
          fill="white"
        />
        <path
          d="M89.6361 32.3462L87.7896 29.6316H88.3994L89.8982 31.877L91.4085 29.6316H91.9784L90.1319 32.3376V34.1224H89.6218L89.6361 32.3462Z"
          fill="white"
        />
        <path
          d="M91.8103 32.4784C91.8103 32.0204 91.9904 31.581 92.311 31.2571C92.6317 30.9332 93.0665 30.7512 93.52 30.7512C93.9735 30.7512 94.4083 30.9332 94.729 31.2571C95.0496 31.581 95.2297 32.0204 95.2297 32.4784C95.2298 32.7065 95.1851 32.9323 95.0983 33.1428C95.0114 33.3533 94.8842 33.5443 94.7239 33.7049C94.5637 33.8655 94.3735 33.9924 94.1644 34.0784C93.9553 34.1643 93.7315 34.2076 93.5057 34.2057C93.2815 34.2072 93.0591 34.1635 92.8518 34.0771C92.6444 33.9907 92.4563 33.8633 92.2983 33.7024C92.1404 33.5415 92.0159 33.3503 91.9321 33.1402C91.8482 32.93 91.8068 32.705 91.8103 32.4784ZM94.7339 32.4784C94.7392 32.3119 94.7117 32.146 94.6529 31.9904C94.5941 31.8348 94.5052 31.6925 94.3914 31.5719C94.2776 31.4514 94.1412 31.3549 93.9901 31.2881C93.839 31.2213 93.6763 31.1856 93.5115 31.183C93.3482 31.1844 93.1869 31.2191 93.0373 31.285C92.8876 31.3509 92.7527 31.4467 92.6407 31.5666C92.5286 31.6865 92.4417 31.8281 92.3852 31.9828C92.3287 32.1376 92.3037 32.3022 92.3118 32.4669C92.3061 32.6325 92.3331 32.7976 92.3913 32.9526C92.4495 33.1075 92.5377 33.2491 92.6507 33.3692C92.7638 33.4893 92.8994 33.5854 93.0496 33.6519C93.1998 33.7184 93.3617 33.754 93.5257 33.7566C93.689 33.756 93.8506 33.722 94.0006 33.6569C94.1507 33.5917 94.2862 33.4966 94.399 33.3772C94.5118 33.2579 94.5996 33.1168 94.6572 32.9623C94.7147 32.8079 94.7408 32.6433 94.7339 32.4784Z"
          fill="white"
        />
        <path
          d="M96.0679 32.8643V30.8003H96.558V32.7377C96.558 33.3537 96.8885 33.7395 97.4642 33.7395C97.5982 33.7417 97.7312 33.7159 97.8548 33.6637C97.9785 33.6115 98.0901 33.5341 98.1827 33.4362C98.2753 33.3383 98.3469 33.2222 98.3929 33.095C98.4389 32.9679 98.4584 32.8325 98.4501 32.6974V30.8003H98.9317V34.1166H98.4501V33.5408C98.3393 33.7428 98.1757 33.9103 97.9773 34.025C97.7788 34.1397 97.5531 34.1972 97.3245 34.1914C96.5323 34.1943 96.0679 33.6531 96.0679 32.8643Z"
          fill="white"
        />
        <path
          d="M100.031 30.8002H100.521V31.6638C100.625 31.3899 100.808 31.1544 101.048 30.9888C101.287 30.8231 101.571 30.7352 101.861 30.7368V31.2723H101.824C101.106 31.2723 100.521 31.7904 100.521 32.7922V34.1136H100.031V30.8002Z"
          fill="white"
        />
        <path
          d="M105.77 30.0979H104.292V29.6316H107.785V30.0979H106.286V34.1281H105.779L105.77 30.0979Z"
          fill="white"
        />
        <path
          d="M108.438 29.5337H109.007V30.0806H108.438V29.5337ZM108.469 30.8061H108.959V34.1224H108.469V30.8061Z"
          fill="white"
        />
        <path
          d="M110.096 30.8003H110.586V31.3761C110.69 31.1841 110.843 31.0241 111.03 30.9131C111.217 30.8021 111.43 30.7443 111.646 30.7456C111.867 30.7375 112.086 30.7961 112.273 30.9141C112.461 31.032 112.61 31.2039 112.701 31.4077C112.817 31.2032 112.986 31.0339 113.19 30.9175C113.393 30.8011 113.624 30.7417 113.857 30.7456C114.613 30.7456 115.077 31.258 115.077 32.0813V34.1339H114.587V32.1965C114.587 31.5545 114.271 31.1947 113.732 31.1947C113.194 31.1947 112.84 31.5545 112.84 32.2109V34.1223H112.35V32.1706C112.35 31.5488 112.028 31.1832 111.495 31.1832C111.366 31.1877 111.239 31.2189 111.122 31.2748C111.005 31.3307 110.9 31.4101 110.814 31.5081C110.729 31.6062 110.664 31.7209 110.624 31.8452C110.583 31.9695 110.569 32.1008 110.58 32.231V34.1223H110.09L110.096 30.8003Z"
          fill="white"
        />
        <path
          d="M115.935 32.4728C115.923 32.2542 115.954 32.0353 116.028 31.8292C116.101 31.6231 116.214 31.4339 116.361 31.2729C116.508 31.1119 116.686 30.9822 116.883 30.8917C117.08 30.8011 117.294 30.7514 117.511 30.7456C118.482 30.7456 119.041 31.5286 119.041 32.4987C119.044 32.5515 119.044 32.6043 119.041 32.6571H116.431C116.438 32.9605 116.564 33.2486 116.78 33.4588C116.997 33.6691 117.287 33.7844 117.587 33.7798C117.783 33.7821 117.977 33.7417 118.155 33.6612C118.334 33.5807 118.493 33.462 118.622 33.3134L118.927 33.6013C118.762 33.8012 118.555 33.9609 118.32 34.0686C118.086 34.1763 117.831 34.2291 117.573 34.2231C117.35 34.2243 117.128 34.1791 116.923 34.0903C116.717 34.0016 116.532 33.8711 116.378 33.707C116.225 33.5429 116.106 33.3487 116.03 33.1364C115.953 32.9241 115.921 32.6983 115.935 32.4728ZM118.548 32.28C118.496 31.6783 118.152 31.1515 117.499 31.1515C116.929 31.1515 116.496 31.6322 116.431 32.28H118.548Z"
          fill="white"
        />
        <path
          d="M121.56 33.1607C121.56 32.4612 122.13 32.0899 122.962 32.0899C123.304 32.0865 123.645 32.1341 123.974 32.2309V32.1158C123.974 31.5199 123.612 31.2119 122.993 31.2119C122.647 31.2159 122.306 31.2985 121.996 31.4537L121.851 31.0507C122.222 30.8637 122.63 30.7652 123.045 30.7628C123.237 30.7488 123.43 30.774 123.613 30.8368C123.795 30.8996 123.964 30.9987 124.108 31.1284C124.225 31.2589 124.316 31.4121 124.374 31.5788C124.431 31.7455 124.455 31.9223 124.444 32.0985V34.1136H123.974V33.6213C123.836 33.8069 123.656 33.9561 123.449 34.0563C123.242 34.1566 123.015 34.2049 122.785 34.1971C122.176 34.1942 121.56 33.8401 121.56 33.1607ZM123.979 32.9045V32.5821C123.666 32.4895 123.34 32.442 123.013 32.4411C122.398 32.4411 122.056 32.7117 122.056 33.1291C122.056 33.5465 122.435 33.7912 122.879 33.7912C123.484 33.8027 123.979 33.4285 123.979 32.9045Z"
          fill="white"
        />
        <path
          d="M125.487 30.8004H125.974V31.3761C126.086 31.1718 126.251 31.0029 126.453 30.8885C126.654 30.7741 126.883 30.7186 127.114 30.7284C127.909 30.7284 128.371 31.2667 128.371 32.0555V34.1166H127.883V32.1793C127.883 31.5632 127.553 31.1775 126.974 31.1775C126.84 31.1744 126.707 31.1995 126.583 31.2512C126.459 31.3029 126.348 31.3801 126.255 31.4778C126.162 31.5756 126.091 31.6917 126.045 31.8189C125.999 31.9461 125.98 32.0816 125.988 32.2167V34.1166H125.501L125.487 30.8004Z"
          fill="white"
        />
        <path
          d="M129.208 32.4728C129.195 32.2544 129.226 32.0355 129.298 31.8292C129.371 31.623 129.483 31.4336 129.63 31.2724C129.777 31.1112 129.954 30.9814 130.151 30.8909C130.348 30.8004 130.562 30.751 130.778 30.7456C131.03 30.7458 131.277 30.8106 131.497 30.934C131.717 31.0574 131.902 31.2353 132.035 31.4509V29.4358H132.525V34.1195H132.035V33.4516C131.907 33.6742 131.723 33.8594 131.503 33.9891C131.283 34.1188 131.033 34.1885 130.778 34.1914C130.562 34.1872 130.349 34.1388 130.152 34.0492C129.954 33.9597 129.777 33.8308 129.63 33.6702C129.484 33.5097 129.371 33.3209 129.298 33.1151C129.226 32.9093 129.195 32.6909 129.208 32.4728ZM132.058 32.4728C132.064 32.3098 132.038 32.1472 131.981 31.9945C131.924 31.8417 131.838 31.7018 131.728 31.5829C131.617 31.464 131.485 31.3685 131.337 31.3018C131.19 31.2352 131.031 31.1988 130.869 31.1947C130.234 31.1947 129.712 31.6697 129.712 32.4728C129.701 32.6347 129.723 32.7972 129.775 32.9505C129.828 33.1038 129.911 33.2447 130.019 33.3647C130.127 33.4847 130.258 33.5814 130.404 33.6487C130.551 33.7161 130.709 33.7528 130.869 33.7567C131.031 33.7512 131.19 33.7133 131.338 33.6454C131.485 33.5775 131.617 33.4808 131.728 33.361C131.838 33.2411 131.923 33.1004 131.98 32.947C132.036 32.7936 132.061 32.6305 132.055 32.4671L132.058 32.4728Z"
          fill="white"
        />
        <path
          d="M135.315 33.4689L135.637 33.1033C135.836 33.3072 136.073 33.4687 136.335 33.578C136.597 33.6873 136.878 33.7422 137.161 33.7395C137.76 33.7395 138.153 33.4199 138.153 32.9766C138.153 32.5592 137.931 32.3231 136.996 32.113C135.973 31.8885 135.503 31.5545 135.503 30.8176C135.503 30.1123 136.121 29.5913 136.965 29.5913C137.536 29.5751 138.093 29.7719 138.529 30.144L138.244 30.5355C137.889 30.2224 137.434 30.0496 136.962 30.049C136.392 30.049 136.016 30.3685 136.016 30.7715C136.016 31.1947 136.244 31.4336 137.221 31.6351C138.199 31.8366 138.669 32.2109 138.669 32.9075C138.669 33.679 138.033 34.177 137.153 34.177C136.472 34.1931 135.812 33.9388 135.315 33.4689Z"
          fill="white"
        />
        <path
          d="M139.592 30.8002H140.08V31.4652C140.209 31.2433 140.393 31.0589 140.614 30.9298C140.834 30.8007 141.084 30.7313 141.339 30.7283C141.556 30.7324 141.77 30.7811 141.967 30.8712C142.165 30.9613 142.343 31.0911 142.489 31.2526C142.636 31.4141 142.748 31.604 142.82 31.8108C142.892 32.0176 142.921 32.2369 142.906 32.4555C142.92 32.6739 142.89 32.8929 142.818 33.0993C142.746 33.3057 142.634 33.4953 142.487 33.6567C142.341 33.818 142.164 33.9478 141.966 34.0382C141.769 34.1286 141.556 34.1777 141.339 34.1827C141.087 34.1827 140.839 34.1177 140.618 33.9938C140.398 33.8699 140.212 33.6911 140.08 33.4746V35.1385H139.592V30.8002ZM142.405 32.467C142.417 32.3049 142.396 32.1421 142.344 31.9884C142.291 31.8348 142.208 31.6935 142.1 31.5734C141.992 31.4532 141.86 31.3566 141.714 31.2895C141.567 31.2224 141.409 31.1862 141.248 31.1831C141.087 31.1879 140.928 31.2249 140.781 31.2918C140.635 31.3588 140.502 31.4544 140.392 31.5732C140.282 31.6919 140.196 31.8316 140.14 31.984C140.083 32.1364 140.057 32.2986 140.062 32.4613C140.056 32.6245 140.081 32.7875 140.137 32.9407C140.193 33.094 140.279 33.2344 140.389 33.3539C140.499 33.4734 140.632 33.5695 140.78 33.6367C140.927 33.7039 141.086 33.7407 141.248 33.7452C141.883 33.7509 142.405 33.2759 142.405 32.4728V32.467Z"
          fill="white"
        />
        <path
          d="M143.562 33.1608C143.562 32.4613 144.131 32.0899 144.966 32.0899C145.308 32.0866 145.648 32.1341 145.975 32.231V32.1158C145.975 31.5199 145.613 31.2119 144.998 31.2119C144.651 31.2149 144.31 31.2976 144 31.4537L143.855 31.0507C144.226 30.8638 144.634 30.7653 145.049 30.7628C145.241 30.7483 145.434 30.7731 145.616 30.836C145.798 30.8988 145.966 30.9983 146.109 31.1284C146.227 31.259 146.317 31.4122 146.375 31.5789C146.433 31.7456 146.457 31.9223 146.445 32.0986V34.1137H145.975V33.6214C145.838 33.8069 145.658 33.9562 145.451 34.0564C145.244 34.1566 145.016 34.2049 144.787 34.1971C144.186 34.1943 143.562 33.8402 143.562 33.1608ZM145.981 32.9046V32.5822C145.668 32.4895 145.344 32.442 145.018 32.4411C144.399 32.4411 144.057 32.7117 144.057 33.1291C144.057 33.5465 144.439 33.7912 144.884 33.7912C145.488 33.8028 145.981 33.4285 145.981 32.9046Z"
          fill="white"
        />
        <path
          d="M147.286 32.4785C147.283 32.1256 147.387 31.7802 147.584 31.4887C147.781 31.1973 148.062 30.9738 148.388 30.8484C148.715 30.7229 149.072 30.7016 149.41 30.7871C149.749 30.8727 150.054 31.0611 150.284 31.327L149.959 31.6638C149.839 31.5121 149.687 31.3886 149.515 31.3021C149.343 31.2156 149.154 31.1681 148.962 31.1629C148.801 31.1677 148.642 31.2049 148.496 31.2723C148.349 31.3398 148.217 31.4361 148.108 31.5557C147.998 31.6752 147.914 31.8156 147.859 31.9687C147.804 32.1217 147.78 32.2843 147.788 32.4468C147.781 32.612 147.807 32.7769 147.864 32.9318C147.921 33.0867 148.009 33.2284 148.121 33.3486C148.233 33.4688 148.368 33.5651 148.518 33.6317C148.668 33.6983 148.829 33.7339 148.993 33.7365C149.186 33.7308 149.375 33.6837 149.549 33.5983C149.722 33.513 149.876 33.3913 149.999 33.2414L150.31 33.5292C150.086 33.8003 149.786 33.9958 149.449 34.0894C149.112 34.1829 148.755 34.17 148.426 34.0524C148.097 33.9349 147.811 33.7183 147.607 33.4317C147.403 33.1452 147.291 32.8025 147.286 32.4497V32.4785Z"
          fill="white"
        />
        <path
          d="M150.819 32.4728C150.819 31.5171 151.486 30.7456 152.392 30.7456C153.364 30.7456 153.925 31.5286 153.925 32.4987C153.93 32.5514 153.93 32.6044 153.925 32.6571H151.31C151.317 32.9605 151.443 33.2486 151.659 33.4588C151.876 33.6691 152.166 33.7844 152.467 33.7798C152.662 33.7818 152.856 33.7412 153.035 33.6607C153.214 33.5802 153.374 33.4618 153.504 33.3134L153.809 33.6013C153.644 33.8012 153.437 33.9609 153.202 34.0686C152.968 34.1763 152.713 34.2291 152.455 34.2231C152.232 34.2239 152.011 34.1784 151.805 34.0895C151.6 34.0005 151.415 33.87 151.262 33.706C151.109 33.5419 150.99 33.3478 150.914 33.1357C150.838 32.9236 150.806 32.698 150.819 32.4728ZM153.43 32.28C153.378 31.6783 153.036 31.1515 152.381 31.1515C151.811 31.1515 151.378 31.6322 151.312 32.28H153.43Z"
          fill="white"
        />
        <path
          d="M4.20605 4.38811H4.96404L6.72793 9.56979L8.42058 4.35645H8.99049L10.6888 9.58418L12.4527 4.4025H13.1851L10.9795 10.6723H10.3896L8.69414 5.60868L6.98439 10.6723H6.41448L4.20605 4.38811Z"
          fill="white"
        />
        <path
          d="M35.4258 34.6895H37.7368C39.1302 34.6895 40.0478 35.4379 40.0478 36.6671V36.6844C40.0478 38.0316 38.9365 38.7254 37.6228 38.7254H36.1211V40.9276H35.4258V34.6895ZM37.6485 38.0835C38.6714 38.0835 39.3411 37.5336 39.3411 36.7132V36.693C39.3411 35.8035 38.68 35.34 37.6826 35.34H36.1211V38.0835H37.6485Z"
          fill="white"
        />
        <path
          d="M21.9387 22.8522H20.1064V22.2102H21.9387V20.3765H22.6027V22.2102H24.4264V22.8522H22.6027V24.6888H21.9387V22.8522Z"
          fill="white"
        />
        <path
          d="M44.5417 44.9981H-0.00292969V-0.00195312H44.5417V44.9981ZM1.18249 43.7976H43.3562V1.19559H1.18249V43.7976Z"
          fill="white"
        />
        <path
          d="M32.6601 11.7175L25.2471 19.2063L25.4566 19.418L32.8696 11.9292L32.6601 11.7175Z"
          fill="white"
        />
        <path
          d="M18.9338 25.5797L11.5293 33.073L11.739 33.2845L19.1436 25.7912L18.9338 25.5797Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_1952">
          <rect
            width="184"
            height="45"
            fill="white"
            transform="translate(-0.00292969 -0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
