import React from "react";

export default function Idea(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6003 3.33337C17.5061 3.33337 14.5386 4.56254 12.3507 6.75046C10.1628 8.93839 8.93359 11.9058 8.93359 15C8.93359 18.9667 10.9169 22.45 13.9336 24.5667V28.3334C13.9336 28.7754 14.1092 29.1993 14.4217 29.5119C14.7343 29.8244 15.1582 30 15.6003 30H25.6003C26.0423 30 26.4662 29.8244 26.7788 29.5119C27.0913 29.1993 27.2669 28.7754 27.2669 28.3334V24.5667C30.2836 22.45 32.2669 18.9667 32.2669 15C32.2669 11.9058 31.0378 8.93839 28.8498 6.75046C26.6619 4.56254 23.6945 3.33337 20.6003 3.33337ZM15.6003 35C15.6003 35.4421 15.7759 35.866 16.0884 36.1786C16.401 36.4911 16.8249 36.6667 17.2669 36.6667H23.9336C24.3756 36.6667 24.7995 36.4911 25.1121 36.1786C25.4247 35.866 25.6003 35.4421 25.6003 35V33.3334H15.6003V35Z"
        fill="url(#paint0_linear_318_4618)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4618"
          x1="8.93359"
          y1="3.40553"
          x2="32.9448"
          y2="40.4846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
