import React from "react";

export default function FacebookLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#2389FF"
        d="M28.638 27.12h2.78c.718.001.848-.12.938-.837.1-.794.206-1.587.32-2.378.102-.7-.122-.975-.82-.975H28.68c-.024-.048-.054-.079-.053-.11.01-1.047-.012-2.096.046-3.14.05-.9.562-1.396 1.462-1.47.667-.056 1.34-.045 2.011-.055.599-.01.808-.21.81-.81.002-.73.003-1.46 0-2.191-.003-.593-.216-.83-.798-.838-1.14-.014-2.288-.09-3.42.006-2.392.204-4.174 1.992-4.377 4.382-.107 1.263-.065 2.54-.09 3.81-.002.127 0 .253 0 .417h-1.584c-.483 0-.966-.007-1.449.004-.373.008-.626.248-.63.62-.01.977-.011 1.955 0 2.933.005.407.26.628.689.631.86.005 1.72.003 2.58.003h.394v.408l.002 9.47c0 .094.001.188-.003.282-.02.476-.25.71-.731.711-1.45.006-2.899.004-4.348.003-1.767 0-3.534.014-5.301-.01-2.148-.028-3.884-1.797-3.886-3.953-.006-6.678-.007-13.356 0-20.035a3.962 3.962 0 0 1 3.993-3.993c6.68-.007 13.36-.006 20.04 0 2.167.003 3.95 1.762 3.953 3.925.012 6.713.012 13.427 0 20.14-.004 2.142-1.78 3.897-3.928 3.916-1.543.014-3.087.004-4.63.003-.592 0-.81-.208-.812-.808-.006-2.026-.004-4.052-.004-6.078 0-1.19 0-2.379.003-3.569 0-.126.01-.252.018-.414"
      />
    </svg>
  );
}
