import React from "react";

export default function StarEmpty() {
  return (
    <svg
      width="48"
      height="45"
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.182 16.2853L24 2L17.818 16.3068L2 17.6232L14.012 27.83L10.404 43L24 34.9511L37.596 43L34.01 27.83L46 17.6232L30.182 16.2853Z"
        stroke="#CBCFD5"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
}
