import React from "react";

export default function Arrow({ className }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        d="M1.3999 7C0.847618 7 0.399902 7.44772 0.399902 8C0.399902 8.55228 0.847617 9 1.3999 9L1.3999 7ZM14.107 8.70711C14.4975 8.31658 14.4975 7.68342 14.107 7.29289L7.74305 0.928933C7.35252 0.538408 6.71936 0.538408 6.32884 0.928933C5.93831 1.31946 5.93831 1.95262 6.32884 2.34315L11.9857 8L6.32883 13.6569C5.93831 14.0474 5.93831 14.6805 6.32883 15.0711C6.71936 15.4616 7.35252 15.4616 7.74305 15.0711L14.107 8.70711ZM1.3999 9L7.3999 9L7.3999 7L1.3999 7L1.3999 9ZM7.3999 9L13.3999 9L13.3999 7L7.3999 7L7.3999 9Z"
        fill="#72879A"
      />
    </svg>
  );
}
