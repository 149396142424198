import React from "react";

export default function ReTransformationSpecialistIcon({
  id,
  className,
  size,
  style,
}) {
  return (
    <svg
      width={size || 64}
      height={size || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <circle
        cx="32"
        cy="32"
        r="32"
        fill={`url(#${id}_paint0_linear_5436_18966)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.228 51.6886C51.3712 59.1825 42.2482 64 32 64C21.7518 64 12.6287 59.1825 6.77197 51.6886C7.62549 49.1195 9.35219 46.0148 11.173 42.7408C14.185 37.3249 17.4545 31.4461 17.4545 26.7636C17.4545 20.1106 12.8709 12.595 9.94335 8.81575C15.6836 3.35299 23.4502 0 32 0C40.5497 0 48.3163 3.35299 54.0566 8.81575C51.129 12.595 46.5454 20.1106 46.5454 26.7636C46.5454 31.4461 49.8149 37.3249 52.827 42.7408C54.6477 46.0147 56.3745 49.1195 57.228 51.6886Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.228 51.6886C51.3712 59.1825 42.2482 64 32 64C21.7518 64 12.6287 59.1825 6.77197 51.6886C7.62549 49.1195 9.35219 46.0148 11.173 42.7408C14.185 37.3249 17.4545 31.4461 17.4545 26.7636C17.4545 20.1106 12.8709 12.595 9.94335 8.81575C15.6836 3.35299 23.4502 0 32 0C40.5497 0 48.3163 3.35299 54.0566 8.81575C51.129 12.595 46.5454 20.1106 46.5454 26.7636C46.5454 31.4461 49.8149 37.3249 52.827 42.7408C54.6477 46.0147 56.3745 49.1195 57.228 51.6886Z"
        fill="#F2AC8D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 64C42.2482 64 51.3712 59.1825 57.228 51.6885C56.3745 49.1195 54.6477 46.0147 52.827 42.7408C51.3446 40.0755 49.8 37.298 48.6133 34.6062C43.361 36.1506 37.3068 37.0308 30.8616 37.0308C25.2309 37.0308 19.8986 36.3586 15.1387 35.1587C13.9893 37.6768 12.5541 40.2575 11.173 42.7408C9.35219 46.0147 7.62549 49.1195 6.77197 51.6886C12.6287 59.1825 21.7518 64 32 64Z"
        fill={`url(#${id}_paint1_linear_5436_18966)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0907 11.5874C52.7799 10.5317 53.4534 9.59442 54.0566 8.81575C48.3163 3.35299 40.5497 0 32 0C23.4502 0 15.6836 3.35299 9.94335 8.81575C10.4193 9.43023 10.9391 10.1435 11.4759 10.9357C17.0899 9.0761 23.7369 7.99978 30.8616 7.99978C38.7828 7.99978 46.1135 9.3293 52.0907 11.5874ZM51.5344 40.4032C51.9637 41.1886 52.3979 41.9694 52.827 42.7408C54.6477 46.0147 56.3745 49.1195 57.228 51.6886C51.3712 59.1825 42.2482 64 32 64C21.7518 64 12.6287 59.1825 6.77197 51.6886C7.62549 49.1195 9.35219 46.0148 11.173 42.7408C11.4834 42.1827 11.7965 41.6197 12.1085 41.0536C17.5865 42.7872 24.0027 43.7847 30.8616 43.7847C38.5388 43.7847 45.6613 42.5359 51.5344 40.4032Z"
        fill={`url(#${id}_paint2_linear_5436_18966)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26268 25.2977L7.94017 27.0558H4.83668C3.78562 27.0558 2.90918 27.9528 2.90918 29.091C2.90918 30.2291 3.78562 31.1261 4.83668 31.1261H7.94017L6.26268 32.8842C5.50321 33.6801 5.50321 34.977 6.26268 35.7729C7.01424 36.5606 8.22692 36.5606 8.97847 35.7729L13.9759 30.5353C14.7354 29.7394 14.7354 28.4425 13.9759 27.6466L8.97847 22.409C8.22692 21.6213 7.01424 21.6213 6.26268 22.409C5.50321 23.2049 5.50321 24.5018 6.26268 25.2977Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.7373 32.8841L56.0598 31.126L59.1633 31.126C60.2144 31.1261 61.0908 30.2291 61.0908 29.0909C61.0908 27.9527 60.2144 27.0558 59.1633 27.0558L56.0598 27.0558L57.7373 25.2977C58.4968 24.5017 58.4968 23.2049 57.7373 22.4089C56.9858 21.6213 55.7731 21.6213 55.0215 22.4089L50.0241 27.6465C49.2646 28.4425 49.2646 29.7393 50.0241 30.5353L55.0215 35.7729C55.7731 36.5606 56.9858 36.5606 57.7373 35.7729C58.4968 34.9769 58.4968 33.6801 57.7373 32.8841Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_5436_18966`}
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E5C6" />
          <stop offset="1" stopColor="#6BC5D9" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_5436_18966`}
          x1="37.0908"
          y1="30.5452"
          x2="5.8181"
          y2="44.3633"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7057" />
          <stop offset="1" stopColor="#D23214" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_5436_18966`}
          x1="31.9999"
          y1="-23.5547"
          x2="31.9999"
          y2="84.3548"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365687" />
          <stop offset="1" stopColor="#20272F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
