import React from "react";

export default function PlusIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.7143H12.7143V20H10.2857V12.7143H3V10.2857H10.2857V3H12.7143V10.2857H20V12.7143Z"
        fill="currentColor"
      />
    </svg>
  );
}
