import React from "react";

export default function MenuBurgerIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <rect width="16" height="2" x="4" y="7" fill="currentColor" rx="1" />
        <rect width="16" height="2" x="4" y="15" fill="currentColor" rx="1" />
      </g>
    </svg>
  );
}
