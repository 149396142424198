import React from "react";

export default function HealthAndSafety(props) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7005 21.6667H13.5339V16.6667H17.7005V12.5H22.7005V16.6667H26.8672V21.6667H22.7005V25.8334H17.7005V21.6667ZM20.2005 3.33337L6.86719 8.33337V18.4834C6.86719 26.9 12.5505 34.75 20.2005 36.6667C27.8505 34.75 33.5339 26.9 33.5339 18.4834V8.33337L20.2005 3.33337Z"
        fill="url(#paint0_linear_318_4557)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4557"
          x1="6.86719"
          y1="3.40553"
          x2="29.44"
          y2="43.2432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
