import React from "react";

export default function MapMarkerIcon({ className, size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.65015C9.0975 4.65015 6.75 6.99765 6.75 9.90015C6.75 13.8376 12 19.6501 12 19.6501C12 19.6501 17.25 13.8376 17.25 9.90015C17.25 6.99765 14.9025 4.65015 12 4.65015ZM12.0001 12.1505C10.8409 12.1505 9.90015 11.1905 9.90015 10.0076C9.90015 8.82475 10.8409 7.86475 12.0001 7.86475C13.1593 7.86475 14.1001 8.82475 14.1001 10.0076C14.1001 11.1905 13.1593 12.1505 12.0001 12.1505Z"
        fill="#FF7557"
      />
    </svg>
  );
}
