import React from "react";

export default function WoStressManagementIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        d="M12.2009 9.49722C13.1771 9.49722 13.9707 8.25618 13.9707 7.27612C13.9707 6.29606 13.3624 5.38818 12.2009 5.38818C11.0672 5.38818 10.4312 6.29606 10.4312 7.27612C10.4312 8.25618 11.2248 9.49722 12.2009 9.49722Z"
        fill="currentColor"
      />
      <path
        d="M11.933 4.1833C11.9579 4.2777 12.0298 4.35544 12.1321 4.3832C12.2786 4.42207 12.4307 4.33323 12.4694 4.1833L12.8234 2.80622C12.8483 2.70349 12.8511 2.59244 12.8234 2.48416C12.7349 2.13989 12.3837 1.93166 12.0408 2.0205C11.6979 2.10935 11.4905 2.46195 11.579 2.80622L11.933 4.1833Z"
        fill="currentColor"
      />
      <path
        d="M9.14835 4.14174L10.3679 4.8636C10.4508 4.91357 10.5587 4.91635 10.6499 4.8636C10.7826 4.78586 10.8241 4.6165 10.7467 4.48324L10.0277 3.25885C9.97518 3.17001 9.89775 3.08672 9.80097 3.03119C9.49402 2.85072 9.10134 2.95345 8.9216 3.25885C8.73909 3.56703 8.8414 3.96128 9.14835 4.14174Z"
        fill="currentColor"
      />
      <path
        d="M14.0344 4.86629L15.2539 4.14443C15.3424 4.09168 15.4254 4.01394 15.4807 3.91676C15.6604 3.60859 15.5581 3.21434 15.2539 3.03388C14.947 2.85341 14.5543 2.95614 14.3745 3.26154L13.6556 4.48592C13.6058 4.56921 13.603 4.67749 13.6556 4.76911C13.733 4.8996 13.9044 4.94402 14.0344 4.86629Z"
        fill="currentColor"
      />
      <path
        d="M16.2246 9.51954C16.8883 7.70657 15.5388 6.18233 15.4807 6.11848C15.4807 6.11848 14.8475 5.55487 14.2806 6.04074C13.7275 6.51272 14.2529 7.24014 14.2529 7.24014L14.2502 7.23458C14.2585 7.24291 14.9857 8.08693 14.6705 8.94761C14.3857 9.72777 13.5395 9.86381 13.2104 9.90824C12.8897 10.097 12.5523 10.1942 12.2039 10.1942C11.8554 10.1942 11.5181 10.097 11.1973 9.90824C10.8682 9.86381 10.022 9.72777 9.73721 8.94761C9.42196 8.08971 10.1492 7.24291 10.1575 7.23458L10.1548 7.24014C10.1548 7.24014 10.6802 6.51272 10.1271 6.04074C9.56023 5.55765 8.92697 6.11848 8.92697 6.11848C8.8689 6.18233 7.51944 7.70657 8.18311 9.51954C8.42646 10.1803 8.85784 10.6995 9.46621 11.0577C9.88653 11.3048 10.3871 11.4713 10.9539 11.5519L10.9622 12.854L10.9512 14.8113C10.8986 14.8557 10.8516 14.9057 10.8101 14.964L8.81913 17.6155C8.69469 17.782 8.62556 17.9819 8.62556 18.1874L8.57025 21.6461C8.56472 22.182 8.99058 22.6234 9.52704 22.629C9.52981 22.629 9.53534 22.629 9.53811 22.629C10.0663 22.629 10.5004 22.2014 10.506 21.6683L10.5585 18.5289L12.179 16.3716C12.1817 16.3716 12.1873 16.3716 12.19 16.3716C12.1928 16.3716 12.1956 16.3716 12.1983 16.3716C12.2122 16.3716 12.2232 16.3716 12.237 16.3716L13.8575 18.5317L13.899 21.6711C13.9045 22.2042 14.3387 22.6318 14.8668 22.6318C14.8696 22.6318 14.8751 22.6318 14.8779 22.6318C15.4116 22.6262 15.8402 22.1848 15.8347 21.6489L15.7794 18.1902C15.7766 17.9819 15.7075 17.782 15.5858 17.6182L13.5948 14.9668C13.5478 14.9029 13.4953 14.8474 13.4372 14.8002L13.4482 12.8651C13.4482 12.8595 13.4482 12.854 13.4482 12.8512L13.4399 11.5574C14.0151 11.4769 14.5212 11.3103 14.9443 11.0604C15.5526 10.6995 15.984 10.1803 16.2246 9.51954Z"
        fill="currentColor"
      />
    </svg>
  );
}
