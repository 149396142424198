import React from "react";

export default function WoYogaIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9924 1.00328C17.4969 0.961 17.9402 1.33244 17.983 1.83072C17.9952 1.98473 18.2826 5.66898 15.9192 7.87952C15.2313 8.52578 14.4517 9.04217 13.6445 9.38946L13.6567 11.3071V11.3222L13.6414 13.7894C13.6414 13.8407 13.6384 13.8951 13.6323 13.9464L13.8218 17.7847C13.8232 17.8011 13.824 17.817 13.8241 17.8327C13.8242 17.8517 13.8235 17.8706 13.8218 17.8904L13.6078 21.9974C13.5803 22.5621 13.1064 23 12.5408 23H12.4857C11.8956 22.9698 11.4401 22.4715 11.4706 21.8887L11.6541 18.3615C11.6449 18.3796 11.6357 18.3977 11.6235 18.4128C11.4187 18.7148 11.0793 18.8779 10.7369 18.8779C10.532 18.8779 10.3241 18.8205 10.1407 18.6967L6.47173 16.2506C6.12319 16.0181 5.9428 15.6043 6.01618 15.1936C6.08957 14.7829 6.39837 14.4538 6.80806 14.3511L10.8958 13.3304L10.9081 11.3161L10.8958 9.29282C10.1682 8.94856 9.46497 8.46839 8.8382 7.87952C6.48396 5.672 6.77137 1.98776 6.7836 1.83072C6.8264 1.33244 7.26974 0.961 7.77726 1.00328C8.27868 1.04556 8.65476 1.48344 8.61194 1.98172C8.60889 2.02098 8.43461 4.47311 9.69121 6.1129C9.81352 6.53266 10.0184 6.94639 10.2844 7.29971C10.529 7.62283 10.8102 7.88254 11.119 8.07279C11.489 8.29928 11.8803 8.41404 12.2839 8.41404C12.6875 8.41404 13.0789 8.29928 13.4488 8.07279C13.7607 7.88254 14.042 7.62283 14.2835 7.29971C14.473 7.05208 14.629 6.77123 14.7482 6.48132C15.5676 5.65992 15.91 4.51539 16.0507 3.68795C16.2062 2.77781 16.1589 2.0485 16.1549 1.98602L16.1546 1.98172C16.1118 1.48344 16.4879 1.04556 16.9924 1.00328ZM9.65147 15.8188L11.3392 16.9452C11.4737 17.0358 11.5807 17.1505 11.6602 17.2774L11.5654 15.3386L9.65147 15.8188ZM14.241 5.24324C14.241 6.33359 13.3657 7.71429 12.2892 7.71429C11.2126 7.71429 10.3373 6.33359 10.3373 5.24324C10.3373 4.15289 11.0388 3.14286 12.2892 3.14286C13.57 3.14286 14.241 4.15289 14.241 5.24324Z"
        fill="currentColor"
      />
    </svg>
  );
}
