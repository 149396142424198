import React from "react";

export default function HeavyPlusIcon({ className, size, style }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} tbk-inline-block tbk-align-text-bottom`}
      style={style}
    >
      <path
        d="M17.6758 9.09458H14.2756V5.69432C14.2756 5.51548 14.1307 5.37048 13.9517 5.37048H9.41805C9.23905 5.37048 9.09421 5.51548 9.09421 5.69432V9.09458H5.69395C5.51496 9.09458 5.37012 9.23958 5.37012 9.41841V13.9521C5.37012 14.1309 5.51496 14.2759 5.69395 14.2759H9.09421V17.6762C9.09421 17.855 9.23905 18 9.41805 18H13.9517C14.1307 18 14.2756 17.855 14.2756 17.6762V14.2759H17.6758C17.8548 14.2759 17.9997 14.1309 17.9997 13.9521V9.41841C17.9997 9.23958 17.8548 9.09458 17.6758 9.09458Z"
        fill="currentColor"
      />
    </svg>
  );
}
