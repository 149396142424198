import React from "react";

export default function Lotus({ className }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0001 20.0001C40.0001 31.0458 31.0457 40.0001 20 40.0001C8.95468 40.0001 0 31.0458 0 20.0001C0 8.95438 8.95468 0 20 0C31.0457 0 40.0001 8.95438 40.0001 20.0001Z"
        fill="#FF7557"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9998 11.7242C24.0138 14.0711 22.1231 15.9856 19.7762 15.9999C17.428 16.0146 15.5143 14.1228 15.5 11.7758C15.486 9.42822 17.377 7.51375 19.7246 7.50008C22.0714 7.48542 23.9852 9.37658 23.9998 11.7242Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4997 28.5054C30.4997 28.5054 16.1284 20.0713 9.99963 29.369C9.99963 29.369 18.6495 36.9589 30.4997 28.5054Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9992 17.0723C21.9992 17.0723 20.2417 23.0005 16.4479 23.0005C13.1004 23.0005 9.89254 15.8537 8.99915 15.7985C8.99915 15.7985 10.9528 13.4435 13.4912 15.0778C18.1955 18.1061 20.1294 17.8482 21.9992 17.0723Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4994 22.9991C27.1436 23.0964 35.588 15.1666 26.5795 12C25.6629 18.1469 21.2079 22.0064 20.4994 22.9991Z"
        fill="white"
      />
    </svg>
  );
}
