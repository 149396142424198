import React from "react";

export default function DoubleBedIcon({ className }) {
  return (
    <svg
      width="94"
      height="93"
      viewBox="0 0 94 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <g clipPath="url(#clip0_10743_8003)">
        <path
          d="M71.1582 46.5245V29.5518C71.1582 25.4233 67.7372 22.0647 63.5324 22.0647H30.5823C26.3774 22.0647 22.9566 25.4235 22.9566 29.5518V46.7434C20.9199 48.834 19.667 51.6666 19.667 54.7811V67.6507C19.667 70.0131 21.6245 71.9352 24.0308 71.9352C26.4369 71.9352 28.3945 70.0131 28.3945 67.6507V66.3216H65.9396V67.6507C65.9396 70.0131 67.8972 71.9352 70.3033 71.9352C72.7094 71.9352 74.667 70.0132 74.667 67.6507V54.7811C74.667 51.5564 73.3238 48.6341 71.1582 46.5245ZM30.5823 24.1741H63.5324C66.5527 24.1741 69.0097 26.5866 69.0097 29.5518V44.8645C68.3518 44.4668 67.6516 44.1303 66.9173 43.8635V40.3562C66.9173 37.247 64.3409 34.7175 61.1741 34.7175H51.8361C49.9137 34.7175 48.2101 35.6505 47.1671 37.0787C46.1241 35.6505 44.4205 34.7175 42.4981 34.7175H33.1601C29.9934 34.7175 27.4169 37.2469 27.4169 40.3562V43.8635C26.6004 44.1602 25.826 44.5428 25.1051 45.0001V29.5518C25.105 26.5866 27.5621 24.1741 30.5823 24.1741ZM31.5192 43.1444C30.8537 43.1444 30.201 43.1995 29.5652 43.3035V40.3562C29.5652 38.4101 31.1778 36.8268 33.1599 36.8268H42.4979C44.48 36.8268 46.0927 38.4101 46.0927 40.3562V43.1445H31.5192V43.1444ZM48.2412 40.3561C48.2412 38.41 49.8538 36.8267 51.836 36.8267H61.1739C63.1561 36.8267 64.7687 38.41 64.7687 40.3561V43.3034C64.1328 43.1994 63.4802 43.1443 62.8147 43.1443H48.2412V40.3561ZM31.5192 45.2538H62.8147C68.1654 45.2538 72.5184 49.5277 72.5184 54.7811V57.91H61.7445C61.1512 57.91 60.6702 58.3823 60.6702 58.9647C60.6702 59.5471 61.1512 60.0194 61.7445 60.0194H72.5186V64.2123H21.8154V60.0194H51.0023C51.5956 60.0194 52.0765 59.5471 52.0765 58.9647C52.0765 58.3823 51.5956 57.91 51.0023 57.91H21.8154V54.7811C21.8154 49.5277 26.1685 45.2538 31.5192 45.2538ZM26.246 67.6507C26.246 68.85 25.2523 69.8258 24.0308 69.8258C22.8093 69.8258 21.8154 68.85 21.8154 67.6507V66.3216H26.246V67.6507ZM70.3032 69.8258C69.0817 69.8258 68.0879 68.85 68.0879 67.6507V66.3216H72.5186V67.6507C72.5186 68.85 71.5248 69.8258 70.3032 69.8258Z"
          fill="#F8F8F8"
        />
        <path
          d="M56.3726 60.0199C56.9662 60.0199 57.4474 59.5474 57.4474 58.9646C57.4474 58.3819 56.9662 57.9094 56.3726 57.9094C55.779 57.9094 55.2979 58.3819 55.2979 58.9646C55.2979 59.5474 55.779 60.0199 56.3726 60.0199Z"
          fill="#F8F8F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_10743_8003">
          <rect
            width="55"
            height="54"
            fill="white"
            transform="translate(19.667 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
