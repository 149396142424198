import { useState, useEffect } from "react";
import { useIntersectionObserver } from "../useIntersectionObserver/useIntersectionObserver";
import { useEvent } from "../useEvent/useEvent";

export default function useContentScrollerItem({
  contentScrollerApi,
  element,
  isScrollerVisible,
  key,
  onItemVisible,
  onItemVisibleChange,
  preloadCount,
  threshold = 1,
}) {
  const [isWithinPreloadRange, setIsWithinPreloadRange] = useState(
      preloadCount !== undefined && key <= preloadCount
    ),
    { entry, ref, inView } = useIntersectionObserver(
      {
        disconnect: () => inView,
        root:
          contentScrollerApi == null
            ? undefined
            : contentScrollerApi.scrollingContainer,
        rootMargin: "0px",
        threshold: threshold,
        delay: 100,
      },
      []
    );

  useEffect(() => {
    ref(element);
  }, [element, ref]);

  const onWithinPreloadRangeCb = useEvent(() => {
    if (preloadCount !== undefined && !isWithinPreloadRange) {
      setIsWithinPreloadRange(true);
    }
  });

  useEffect(() => {
    if (!isWithinPreloadRange && contentScrollerApi) {
      return contentScrollerApi.addStateChangeHandler(key, {
        handler: onWithinPreloadRangeCb,
        preloadCount: preloadCount,
      });
    }
  }, [
    isWithinPreloadRange,
    key,
    onWithinPreloadRangeCb,
    preloadCount,
    contentScrollerApi,
  ]);

  const onVisibilityChanged = useEvent((_contentScrollerApi, isInView) => {
    _contentScrollerApi.recalc({
      reason: "child_visible_changed",
      key: key,
      entry: entry,
    });

    if (onItemVisibleChange != null) {
      onItemVisibleChange(key, isInView, element);
    }

    if (isInView && onItemVisible != null) {
      onItemVisible(key);
    }
  });

  useEffect(() => {
    contentScrollerApi &&
      entry &&
      onVisibilityChanged(contentScrollerApi, inView);
  }, [contentScrollerApi, entry, inView, onVisibilityChanged]);

  const rootAttributes = {
    "aria-hidden": !inView,
    tabIndex: inView ? undefined : -1,
  };

  return {
    isVisible: inView,
    isWithinPreloadRange: isWithinPreloadRange,
    rootAttributes,
  };
}

// eslint-disable-next-line
const s = Object.freeze("ContentScrollerItem");
