import React from "react";
import { Link } from "react-router-dom";

function BreadcrumbNav({ to, children }) {
  const Component = to ? Link : "div";

  return (
    <Component to={to}>
      <div
        className={`tbk-text-small tbk-whitespace-nowrap tbk-rounded-r-lg tbk-rounded-bl-lg tbk-border tbk-border-blue-grey-light tbk-pb-0.5 tbk-pt-0.5 tbk-pr-2 tbk-pl-2 tbk-text-basic-trueblack ${
          to ? "" : " tbk-bg-blue-grey-light"
        }`}
      >
        {children}
      </div>
    </Component>
  );
}

export default BreadcrumbNav;
