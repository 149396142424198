import { useState, useEffect } from "react";
import moment from "moment-timezone";

// Utils
import * as dates from "../../../utils/dates";
import { merge, getMomentWithTz } from "../../../utils/date";

function _dateValues({ min, max, value, currentDate, preserveDate, region }) {
  value = value || currentDate || dates.today();
  let useDate = preserveDate,
    start,
    end;

  // compare just the time regardless of whether they fall on the same day
  if (!useDate) {
    start = dates.startOf(
      merge(new Date(), min, currentDate, region),
      "minutes"
    );
    end = dates.startOf(merge(new Date(), max, currentDate, region), "minutes");

    if (dates.lte(end, start) && dates.gt(max, min, "day")) {
      end = dates.tomorrow();
    }

    return {
      min: start,
      max: end,
    };
  }

  start = dates.today();
  end = dates.tomorrow();

  // date parts are equal
  return {
    min: dates.eq(value, min, "day")
      ? merge(start, min, currentDate, region)
      : start,
    max: dates.eq(value, max, "day")
      ? merge(start, max, currentDate, region)
      : end,
  };
}

export default function useTimeSlots(
  min,
  max,
  timeSlots,
  step,
  value,
  currentDate,
  culture,
  region,
  format = "HH:mm",
  preserveDate = false
) {
  const [times, setTimes] = useState([]);

  useEffect(() => {
    var values, start, startDay;

    if (timeSlots && timeSlots.length !== 0) {
      let _times = [];
      timeSlots.forEach((v) => {
        values = _dateValues({
          min: v.startTime,
          max: v.endTime,
          value,
          currentDate,
          preserveDate,
          region,
        });

        start = values.min;
        startDay = getMomentWithTz(start, region).date(); //dates.date(start);

        while (
          getMomentWithTz(start, region).date() /*dates.date(start)*/ ===
            startDay &&
          dates.lte(start, values.max)
        ) {
          _times.push({
            date: start,
            label: getMomentWithTz(start, region).format(format),
            labelAmPm: getMomentWithTz(start, region).format("A"),
          });
          start = dates.add(start, step || 30, "minutes");
        }
      });
      setTimes(_times);
    } else {
      let _times = [];

      values = _dateValues({
        min,
        max,
        value,
        currentDate,
        preserveDate,
        region,
      });

      start = values.min;
      startDay = getMomentWithTz(start, region).date(); //dates.date(start);

      while (
        getMomentWithTz(start, region).date() === startDay &&
        dates.lte(start, values.max)
      ) {
        _times.push({
          date: start,
          label: getMomentWithTz(start, region).format(format),
          labelAmPm: getMomentWithTz(start, region).format("A"),
        });
        start = dates.add(start, step || 30, "minutes");
      }

      setTimes(_times);
    }
  }, [
    min,
    max,
    timeSlots,
    step,
    value,
    currentDate,
    culture,
    format,
    preserveDate,
  ]);

  return times;
}
