import React from "react";

export default function PoundShield(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.33329L20 1.66663L35 8.33329V18.3333C35 27.5833 28.6 36.2333 20 38.3333C11.4 36.2333 5 27.5833 5 18.3333V8.33329ZM25.5926 21.4906C25.7115 21.4906 25.8092 21.531 25.8856 21.6121C25.962 21.6926 26.0002 21.7887 26 21.8993V26.5909C26 26.7104 25.962 26.8082 25.8856 26.8851C25.8094 26.9616 25.7116 27 25.5926 27H13.4078C13.2889 27 13.1911 26.9616 13.1146 26.8851C13.0381 26.8082 13 26.7104 13 26.5909V24.6733C13 24.5627 13.0403 24.4668 13.1211 24.3857C13.2017 24.3047 13.2974 24.2642 13.4078 24.2642H14.6441V19.3679H13.4333C13.3144 19.3679 13.2166 19.3275 13.1401 19.2465C13.0636 19.1654 13.0254 19.0696 13.0254 18.959V17.2843C13.0254 17.1648 13.0636 17.0669 13.1401 16.9902C13.2166 16.9137 13.3144 16.8752 13.4333 16.8752H14.6441V14.0244C14.6441 12.5669 15.1688 11.3653 16.2182 10.4191C17.2675 9.47312 18.6038 9 20.2267 9C21.7987 9 23.222 9.53285 24.4963 10.5982C24.5727 10.6665 24.6152 10.7537 24.6237 10.8603C24.6322 10.9668 24.6028 11.0627 24.5345 11.1479L23.2217 12.7717C23.1452 12.8655 23.0519 12.9165 22.9414 12.9252C22.8308 12.9421 22.7332 12.9123 22.6483 12.8356C22.606 12.7932 22.4953 12.712 22.3169 12.5928C22.1384 12.4733 21.8453 12.337 21.4374 12.1837C21.0296 12.0302 20.6344 11.9534 20.252 11.9534C19.5298 11.9534 18.9479 12.1537 18.5059 12.5543C18.0641 12.955 17.8433 13.4791 17.8433 14.1267V16.8753H21.7305C21.841 16.8753 21.9366 16.9138 22.0173 16.9903C22.0981 17.0671 22.1384 17.165 22.1384 17.2844V18.9592C22.1384 19.0701 22.0981 19.166 22.0173 19.2469C21.9366 19.3278 21.841 19.3683 21.7305 19.3683H17.8433V24.2136H23.1199V21.8997C23.1199 21.7889 23.1581 21.6932 23.2344 21.6121C23.3108 21.531 23.4086 21.4906 23.5277 21.4906H25.5926Z"
        fill="url(#paint0_linear_318_4740)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_318_4740"
          x1="5"
          y1="1.746"
          x2="29.5405"
          y2="46.0406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC397" />
          <stop offset="1" stopColor="#FF6C4D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
