/**
 * Custom hook to detect user location based on IP geolocation
 * and fallback to browser-based geolocation if necessary.
 *
 * This hook provides the detected region, country flag, phone code,
 * and coordinates, allowing for centralized management of user location.
 *
 * @module useUserLocation
 * @function useUserLocation
 * @returns {Object} - An object containing the user's region, country flag, phone code, and latLng.
 * @example
 * const { countryFlag, phoneCode, userRegion, latLng } = useUserLocation();
 *
 * @created 2024/10/28
 * @modified 2024/10/28
 * @author Vladimir Shishlyannikov
 * @copyright 2024-present iWoo Ltd.
 * All rights reserved.
 */
import { useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";

// Hooks
import useCountryName from "../useCountryName/useCountryName";

function getStoredRegion() {
  return localStorage.getItem("userRegion");
}

function useUserLocation() {
  const [countryFlag, setCountryFlag] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [userRegion, setUserRegion] = useState(getStoredRegion() || null);
  const [latLng, setLatLng] = useState(null);

  const [, countryShortName, lookupAddress] = useCountryName(latLng);

  useEffect(() => {
    if (userRegion) {
      // If region is already in local storage, use it directly
      return;
    }

    const fetchLocationData = async () => {
      try {
        const response = await fetch(
          `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`
        );
        const data = await response.json();
        const countryCode = data.country_code2;

        let detectedRegion;
        if (countryCode === "US") {
          setCountryFlag("🇺🇸");
          setPhoneCode("+1");
          detectedRegion = "US";
        } else {
          setCountryFlag("🇬🇧");
          setPhoneCode("+44");
          detectedRegion = "GB";
        }

        setUserRegion(detectedRegion);
        localStorage.setItem("userRegion", detectedRegion); // Store the detected region in local storage
      } catch (error) {
        Sentry.captureMessage(
          `IP geolocation failed: ${JSON.stringify(error)}`
        );
        // Fallback to browser-based geolocation
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latLngValue = `${position.coords.latitude},${position.coords.longitude}`;
              setLatLng(latLngValue);
              lookupAddress(latLngValue); // Perform address lookup if needed
            },
            (err) => {
              console.warn(`Geolocation error (${err.code}): ${err.message}`);
              // Fallback coordinates if user denies location access
              setLatLng("34.098907,-118.327759");
            }
          );
        }
      }
    };

    fetchLocationData();
  }, [userRegion, lookupAddress]);

  return {
    countryFlag,
    phoneCode,
    userLocation: userRegion,
    latLng,
  };
}

export default useUserLocation;
