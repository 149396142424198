import React from "react";

export default function WoPersonalTrainingIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1753 5C13.9427 5 14.8685 6.37265 14.8685 7.86196C14.8685 9.34879 13.6622 11.2278 12.1753 11.2278C10.6885 11.2278 9.48218 9.34879 9.48218 7.86196C9.48218 6.37265 10.4502 5 12.1753 5ZM13.7391 11.7019C16.9709 12.2926 17.3929 13.2259 17.445 16.5049C17.4475 16.7159 17.45 16.7506 17.45 16.7308V16.9864C17.45 16.9864 16.6805 18.5577 12.225 18.5577C7.76948 18.5577 7 16.9864 7 16.9864V16.6414C7 16.6737 7.00248 16.6588 7.00745 16.3982C7.06702 13.2061 7.51629 12.2877 10.7109 11.7019C10.7109 11.7019 11.1651 12.2902 12.225 12.2902C13.2849 12.2902 13.7391 11.7019 13.7391 11.7019Z"
        fill="currentColor"
      />
    </svg>
  );
}
