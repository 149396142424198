import moment from "moment";
import isEqual from "./isEqual";
import getModifierKey from "./getModifierKey";

const map_u = new Map(),
  map_s = new Map(),
  map_o = new Map(),
  map_p = new Map();

function dateLessThan(date_t, date_n) {
  if (!date_t || !date_n) {
    return false;
  }

  const year_u = date_t.year(),
    month_o = date_t.month(),
    year_c = date_n.year(),
    month_f = date_n.month(),
    isSameYear_s = year_u === year_c,
    isSameMonth__ = month_o === month_f;

  return isSameYear_s && isSameMonth__
    ? date_t.date() < date_n.date()
    : isSameYear_s
    ? month_o < month_f
    : year_u < year_c;
}

function checkDate(date_l, date_f) {
  return (
    !(!date_l || !date_f) &&
    !dateLessThan(date_l, date_f) &&
    !isEqual(date_l, date_f)
  );
}

export default function datesOperations(
  date_c,
  date_h,
  counter_b,
  enableOutsideDays_O = false
) {
  if (!moment.isMoment(date_c)) {
    return false;
  }

  const key_w = getModifierKey(date_h),
    var_M = key_w + "+" + counter_b;

  if (enableOutsideDays_O) {
    return (
      map_u.has(key_w) ||
        map_u.set(key_w, date_h.clone().startOf("month").startOf("week")),
      !dateLessThan(date_c, map_u.get(key_w)) &&
        (map_s.has(var_M) ||
          map_s.set(
            var_M,
            date_h
              .clone()
              .endOf("week")
              .add(counter_b - 1, "months")
              .endOf("month")
              .endOf("week")
          ),
        !checkDate(date_c, map_s.get(var_M)))
    );
  }

  if (!map_o.has(key_w)) {
    map_o.set(key_w, date_h.clone().startOf("month"));
  }

  if (dateLessThan(date_c, map_o.get(key_w))) {
    return !1;
  }

  if (!map_p.has(var_M)) {
    map_p.set(
      var_M,
      date_h
        .clone()
        .add(counter_b - 1, "months")
        .endOf("month")
    );
  }

  return !checkDate(date_c, map_p.get(var_M));
}
