import moment from "moment";
import { availabilityCalendar as calendarConstants } from "../../../constants";

export default function format(date, format) {
  const _format = format
      ? [format, calendarConstants.DISPLAY_FORMAT, calendarConstants.ISO_FORMAT]
      : [calendarConstants.DISPLAY_FORMAT, calendarConstants.ISO_FORMAT],
    momentDate = moment(date, _format, true);

  return momentDate.isValid() ? momentDate.hour(12) : null;
}
