import React from "react";

export default function WoSoundBathsIcon({ className, size }) {
  return (
    <svg
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className ? className + " " : ""}tbk-shrink-0`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51749 10.3832C3.65347 10.2763 2.86301 10.9515 3.02005 11.8078C3.79965 16.0588 7.52362 19.2804 12 19.2804C16.4764 19.2804 20.2003 16.0588 20.98 11.8078C21.137 10.9515 20.3465 10.2763 19.4825 10.3832C17.8299 10.5877 15.0916 10.8526 12 10.8526C8.90839 10.8526 6.17008 10.5877 4.51749 10.3832Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2631 8.39902C8.32528 8.0162 8.686 7.75626 9.06882 7.81838L8.95632 8.51162C9.06892 7.81839 9.06882 7.81838 9.06882 7.81838L9.07135 7.81879L9.08191 7.82046C9.0916 7.82198 9.10653 7.8243 9.12633 7.8273C9.16596 7.83331 9.22507 7.84204 9.30089 7.8526C9.45263 7.87372 9.67067 7.90206 9.93275 7.93044C10.4594 7.98747 11.1528 8.04341 11.839 8.04341C12.5258 8.04341 13.2417 7.98739 13.7918 7.93014C14.0654 7.90166 14.2949 7.8732 14.4554 7.85196C14.5356 7.84135 14.5984 7.83256 14.6407 7.82649C14.6618 7.82346 14.6778 7.82111 14.6882 7.81956L14.6997 7.81784L14.7026 7.81741C14.7026 7.81741 15.4049 7.70036 15.5112 8.39458C15.6175 9.08879 14.9151 9.20585 14.9151 9.20585L14.9092 9.20674L14.8945 9.20894C14.8819 9.21082 14.8636 9.2135 14.8402 9.21687C14.7932 9.2236 14.7253 9.2331 14.6397 9.24444C14.4686 9.26709 14.2259 9.29716 13.9372 9.32721C13.3626 9.38701 12.5936 9.44803 11.839 9.44803C11.0838 9.44803 10.3359 9.38693 9.78155 9.3269C9.50313 9.29675 9.27076 9.26657 9.10721 9.2438C9.02538 9.23241 8.96063 9.22285 8.9158 9.21605C8.89339 9.21266 8.87594 9.20995 8.86381 9.20804L8.84965 9.2058L8.84401 9.20489C8.84401 9.20489 8.84373 9.20484 8.95632 8.51162L8.84373 9.20484C8.46087 9.14266 8.20091 8.78188 8.2631 8.39902Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26032 5.00918L6.26694 5.01025L6.28986 5.01387C6.31046 5.01711 6.34156 5.02195 6.38249 5.02815C6.46433 5.04056 6.58538 5.05844 6.74002 5.07996C7.0494 5.12304 7.49265 5.18064 8.02499 5.23828C9.09212 5.35383 10.5062 5.4683 11.9131 5.4683C13.3207 5.4683 14.779 5.35375 15.8913 5.23798C16.4461 5.18023 16.9117 5.12252 17.238 5.07933C17.4011 5.05774 17.5293 5.0398 17.6161 5.02734C17.6596 5.0211 17.6927 5.01624 17.7147 5.01297L17.7393 5.0093L17.7464 5.00822L17.7466 5.00818C18.13 4.9495 18.4884 5.2127 18.5471 5.59608C18.6058 5.97949 18.3426 6.3379 17.9592 6.39662L17.8529 5.70241C17.9592 6.39662 17.9593 6.39661 17.9592 6.39662L17.9488 6.3982L17.921 6.40236C17.8968 6.40595 17.8614 6.41115 17.8157 6.41772C17.7241 6.43085 17.5909 6.4495 17.4223 6.4718C17.0854 6.5164 16.6066 6.57574 16.0368 6.63505C14.8999 6.75337 13.3884 6.87292 11.9131 6.87292C10.4373 6.87292 8.96865 6.75329 7.87378 6.63474C7.32511 6.57533 6.86754 6.51588 6.54634 6.47117C6.38569 6.4488 6.25901 6.4301 6.17196 6.4169C6.12843 6.4103 6.09479 6.40508 6.07175 6.40146L6.04523 6.39726L6.03548 6.39569C6.0354 6.39568 6.03513 6.39563 6.14773 5.70241L6.03513 6.39563C5.65227 6.33345 5.39232 5.97267 5.4545 5.58981C5.51668 5.20698 5.87749 4.94704 6.26032 5.00918Z"
        fill="currentColor"
      />
    </svg>
  );
}
