/**
 * Normalize the language code to uppercase and extract the region.
 * @param {string} language - The language code to normalize.
 * @returns {string} The normalized region code.
 */
export const normalizeLanguageCode = (language) => {
  const parts = language.split(/-|_/);
  return parts.length > 1 ? parts[1].toUpperCase() : language.toUpperCase();
};

/**
 * Selects a region ('GB' or 'US') based on the user's preferred languages.
 * @returns {string} The selected region ('GB' or 'US') or defaulting to 'US' if none found.
 */
export const selectRegionBasedOnUserLanguages = () => {
  if (navigator.languages && navigator.languages.length > 0) {
    const userLanguages = navigator.languages.map((language) =>
      normalizeLanguageCode(language)
    );

    if (userLanguages.includes("GB") && userLanguages.includes("US")) {
      const selectedRegion =
        userLanguages.indexOf("GB") < userLanguages.indexOf("US") ? "GB" : "US";
      return selectedRegion;
    } else if (userLanguages.includes("GB")) {
      return "GB";
    } else if (userLanguages.includes("US")) {
      return "US";
    }
  }

  return "US"; // Default to 'US' if no matching region found or navigator.languages is empty
};
