import getModifierKey from "./getModifierKey";

export default function dates(
  date,
  numberOfMonths,
  enableOutsideDays = false,
  vertical = false
) {
  const values = {};

  let dateCloned = date.clone();

  for (
    let index = 0;
    index < (vertical ? numberOfMonths : numberOfMonths + 2);
    index += 1
  ) {
    const dates = [],
      newDate = dateCloned.clone(),
      startDate = newDate.clone().startOf("month"), //.hour(12),
      endDate = newDate.clone().endOf("month"), //.hour(12),
      startDateCloned = startDate.clone();

    // Add dates from the previous month
    if (enableOutsideDays) {
      for (let i = 0; i < startDateCloned.weekday(); i += 1) {
        const dateToAdd = startDateCloned.clone().subtract(i + 1, "day");

        dates.unshift(dateToAdd);
      }
    }

    for (; startDateCloned < endDate; ) {
      dates.push(startDateCloned.clone());
      startDateCloned.add(1, "day");
    }

    // Add dates from the current month
    if (enableOutsideDays && startDateCloned.weekday() !== 0) {
      for (
        let weekday = startDateCloned.weekday(), i = 0;
        weekday < 7;
        weekday += 1, i += 1
      ) {
        const dateToAdd = startDateCloned.clone().add(i, "day");

        dates.push(dateToAdd);
      }
    }

    values[getModifierKey(dateCloned)] = dates;

    dateCloned = dateCloned.clone().add(1, "month");
  }

  return values;
}
